import { volunteeringVolunteerImage13 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen13 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-5-4" className='text-volunteering'>{t('learning.volunteering.volunteer.86')}</h4>
      <p>{t('learning.volunteering.volunteer.87')}</p>
      <p>{t('learning.volunteering.volunteer.88')}</p>
      <div className='box mb-5'>
        <i>
          {t('learning.volunteering.volunteer.89.1')}
          <a href="https://www.youtube.com/watch?v=vh2q-vR2WQY" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.89.2')}</a>
          {t('learning.volunteering.volunteer.89.3')}
        </i>
      </div>
      <Image src={volunteeringVolunteerImage13} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen13;
import { ThemeProvider } from 'react-bootstrap';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'assets/font-awesome';
import { AppConstants } from 'constant';

import Page404Screen from './screens/404';

import HomeScreen from './screens/home';
import CommunityListScreen from 'screens/community/list';
import CommunityViewScreen from 'screens/community/view';
import ContactScreen from 'screens/contact';
import FAQScreen from 'screens/faq';
import NewsListScreen from 'screens/news/list';
import NewsViewScreen from 'screens/news/view';
import ProfileViewScreen from 'screens/profile/view';
import ProfileEditScreen from 'screens/profile/edit';
import ProfileNewsNewScreen from 'screens/profile/news/new';
import ProfilePublicViewScreen from 'screens/profile/public';
import FunctionEngageScreen from 'screens/functions/engage/view';
import EngageVistaViewScreen from 'screens/functions/engage/vista/view';
import FunctionEmpowerScreen from 'screens/functions/empower/view';
import EmpowerPoTScreen from 'screens/functions/empower/pot/view';
import EmpowerVistaViewScreen from 'screens/functions/empower/vista/view';
import EmpowerEventViewScreen from 'screens/functions/empower/vista/event';
import EmpowerVistaConferenceViewScreen from 'screens/functions/empower/vista/conference/view';
import FunctionConnectScreen from 'screens/functions/connect/view';
import ConnectVistaFairViewScreen from 'screens/functions/connect/vista/view';
import ConnectLearningScreen from 'screens/functions/connect/learning/view';
import EmpowerLearningScreen from 'screens/functions/empower/learning/view';
import EngageLearningScreen from 'screens/functions/engage/learning/view';
import VolunteeringOrganizationLearningScreen from 'screens/functions/volunteering/learning/organization/view';
import VolunteeringOrganizationLearningSimulation1Screen from 'screens/functions/volunteering/learning/organization/simulation-1';
import VolunteeringOrganizationLearningSimulation2Screen from 'screens/functions/volunteering/learning/organization/simulation-2';
import VolunteeringOrganizationLearningSimulation3Screen from 'screens/functions/volunteering/learning/organization/simulation-3';
import VolunteeringOrganizationLearningSimulation4Screen from 'screens/functions/volunteering/learning/organization/simulation-4';
import VolunteeringOrganizationLearningSimulation5Screen from 'screens/functions/volunteering/learning/organization/simulation-5';
import VolunteeringOrganizationLearningSimulation6Screen from 'screens/functions/volunteering/learning/organization/simulation-6';
import VolunteeringVolunteerLearningScreen from 'screens/functions/volunteering/learning/volunteer/view';
import VolunteeringVolunteerLearningSimulation1Screen from 'screens/functions/volunteering/learning/volunteer/simulation-1';
import VolunteeringVolunteerLearningSimulation2Screen from 'screens/functions/volunteering/learning/volunteer/simulation-2';
import FunctionVolunteeringScreen from 'screens/functions/volunteering/view';

import AdminCommunityListScreen from 'screens/admin/community/list';
import AdminCommunityViewScreen from 'screens/admin/community/view';
import AdminFairListScreen from 'screens/admin/fairs/list';
import AdminFairsEditScreen from 'screens/admin/fairs/edit';
import AdminFairsNewScreen from 'screens/admin/fairs/new';
import AdminFairOrganizationsViewScreen from 'screens/admin/fairs/organization/list';
import AdminNewsListScreen from 'screens/admin/news/list';
import AdminNewsNewScreen from 'screens/admin/news/new';
import AdminNewsViewScreen from 'screens/admin/news/view';
import AdminFairViewScreen from 'screens/admin/fairs/view';
import MemberRegistration from 'screens/activation/view';
import EngageLearningScreenAnswers from 'screens/functions/engage/learning/answers';
import AdminNewsEditScreen from 'screens/admin/news/edit';
import PrivacyPolicy from 'screens/privacy';
import ProfileDeleteScreen from 'screens/profile/delete';
import VolunteeringRegistration from 'screens/functions/volunteering/activation';
import OpportunityListScreen from 'screens/opportunities/list';
import OpportunityViewScreen from 'screens/opportunities/view';
import ProfileOpportunitiesNewScreen from 'screens/profile/opportunity/new';
import ProfileOpportunitiesEditScreen from 'screens/profile/opportunity/edit';
import ForumThemesScreen from 'screens/forum/list';
import ForumThemePostsScreen from 'screens/forum/view';
import VolunteerVistaViewScreen from 'screens/functions/volunteering/vista/view';

const {
  PATH_HOME, PATH_NEWS, PATH_OPPORTUNITIES, PATH_CONNECT, PATH_EMPOWER, PATH_ENGAGE, PATH_EVENT, PATH_ENGAGE_OFFICE, PATH_EMPOWER_LOBBY, PATH_EMPOWER_CONFERENCE, PATH_CONNECT_FAIR, PATH_COMMUNITY, PATH_FAQ, PATH_CONTACT, PATH_PROFILE, PATH_PROFILE_DELETION, PATH_PROFILE_NEWS_NEW, PATH_PROFILE_OPPORTUNITY_NEW, PATH_PROFILE_OPPORTUNITY, PATH_VOLUNTEERING, PATH_VOLUNTEERING_ACTIVATION, PATH_VOLUNTEERING_LEARNING_ORGANIZATION, PATH_VOLUNTEERING_LEARNING_VOLUNTEER, PATH_VOLUNTEERING_SPACE,
  PATH_ADMIN_COMMUNITY, PATH_ADMIN_NEWS, PATH_ADMIN_FAIRS, PATH_ACTIVATION,
  PATH_CONNECT_LEARNING, PATH_EMPOWER_LEARNING, PATH_ENGAGE_LEARNING, PATH_EMPOWER_POT,
  PATH_FORUM,
  PATH_PRIVACY_AND_POLICY
} = AppConstants;

function App() {

  const router = createBrowserRouter([
    { path: `${PATH_HOME}`, element: <HomeScreen /> },
    { path: `${PATH_CONTACT}`, element: <ContactScreen /> },
    { path: `${PATH_COMMUNITY}`, element: <CommunityListScreen /> },
    { path: `${PATH_COMMUNITY + '/:id'}`, element: <CommunityViewScreen /> },
    { path: `${PATH_FAQ}`, element: <FAQScreen /> },
    { path: `${PATH_NEWS}`, element: <NewsListScreen /> },
    { path: `${PATH_NEWS + '/:id'}`, element: <NewsViewScreen /> },
    { path: `${PATH_OPPORTUNITIES}`, element: <OpportunityListScreen /> },
    { path: `${PATH_OPPORTUNITIES + '/:id'}`, element: <OpportunityViewScreen /> },
    { path: `${PATH_PROFILE}`, element: <ProfileViewScreen /> },
    { path: `${PATH_PROFILE_DELETION}`, element: <ProfileDeleteScreen /> },
    { path: `${PATH_PROFILE + '/edit'}`, element: <ProfileEditScreen /> },
    { path: `${PATH_PROFILE+ '/public'}`, element: <ProfilePublicViewScreen /> },
    { path: `${PATH_PROFILE_NEWS_NEW}`, element: <ProfileNewsNewScreen /> },
    { path: `${PATH_PROFILE_OPPORTUNITY_NEW}`, element: <ProfileOpportunitiesNewScreen /> },
    { path: `${PATH_PROFILE_OPPORTUNITY + '/:id/edit'}` , element: <ProfileOpportunitiesEditScreen /> },
    { path: `${PATH_VOLUNTEERING}`, element: <FunctionVolunteeringScreen /> },
    { path: `${PATH_VOLUNTEERING_ACTIVATION + '/:code'}`, element: <VolunteeringRegistration /> },
    { path: `${PATH_ACTIVATION + '/:code'}`, element: <MemberRegistration /> },
    { path: `${PATH_ENGAGE}`, element: <FunctionEngageScreen /> },
    { path: `${PATH_ENGAGE_OFFICE}`, element: <EngageVistaViewScreen /> },
    { path: `${PATH_EMPOWER}`, element: <FunctionEmpowerScreen /> },
    { path: `${PATH_EMPOWER_LOBBY}`, element: <EmpowerVistaViewScreen /> },
    { path: `${PATH_EMPOWER_CONFERENCE + '/:eventId'}`, element: <EmpowerVistaConferenceViewScreen /> },
    { path: `${PATH_CONNECT}`, element: <FunctionConnectScreen /> },
    { path: `${PATH_CONNECT_FAIR + '/:fairId'}`, element: <ConnectVistaFairViewScreen /> },
    { path: `${PATH_EVENT + '/:eventId'}`, element: <EmpowerEventViewScreen /> },
    { path: `${PATH_ACTIVATION + '/:code'}`, element: <MemberRegistration /> },
    { path: `${PATH_PRIVACY_AND_POLICY}`, element: <PrivacyPolicy /> },

    { path: `${PATH_FORUM}`, element: <ForumThemesScreen /> },
    { path: `${PATH_FORUM}/:id`, element: <ForumThemePostsScreen /> },

    { path: `${PATH_CONNECT_LEARNING}`, element: <ConnectLearningScreen /> },
    { path: `${PATH_EMPOWER_LEARNING}`, element: <EmpowerLearningScreen /> },
    { path: `${PATH_ENGAGE_LEARNING}`, element: <EngageLearningScreen /> },
    { path: `${PATH_ENGAGE_LEARNING}/answers`, element: <EngageLearningScreenAnswers /> },
    { path: `${PATH_EMPOWER_POT}`, element: <EmpowerPoTScreen /> },

    { path: `${PATH_VOLUNTEERING_LEARNING_ORGANIZATION}`, element: <VolunteeringOrganizationLearningScreen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_ORGANIZATION}/simulation-1`, element: <VolunteeringOrganizationLearningSimulation1Screen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_ORGANIZATION}/simulation-2`, element: <VolunteeringOrganizationLearningSimulation2Screen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_ORGANIZATION}/simulation-3`, element: <VolunteeringOrganizationLearningSimulation3Screen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_ORGANIZATION}/simulation-4`, element: <VolunteeringOrganizationLearningSimulation4Screen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_ORGANIZATION}/simulation-5`, element: <VolunteeringOrganizationLearningSimulation5Screen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_ORGANIZATION}/simulation-6`, element: <VolunteeringOrganizationLearningSimulation6Screen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_VOLUNTEER}`, element: <VolunteeringVolunteerLearningScreen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_VOLUNTEER}/simulation-1`, element: <VolunteeringVolunteerLearningSimulation1Screen /> },
    { path: `${PATH_VOLUNTEERING_LEARNING_VOLUNTEER}/simulation-2`, element: <VolunteeringVolunteerLearningSimulation2Screen /> },
    { path: `${PATH_VOLUNTEERING_SPACE}`, element: <VolunteerVistaViewScreen /> },

    { path: `${PATH_ADMIN_COMMUNITY}`, element: <AdminCommunityListScreen /> },
    { path: `${PATH_ADMIN_COMMUNITY + '/:id'}`, element: <AdminCommunityViewScreen /> },
    { path: `${PATH_ADMIN_FAIRS}`, element: <AdminFairListScreen /> },
    { path: `${PATH_ADMIN_FAIRS + '/new'}`, element: <AdminFairsNewScreen /> },
    { path: `${PATH_ADMIN_FAIRS + '/:id/edit'}`, element: <AdminFairsEditScreen /> },
    { path: `${PATH_ADMIN_FAIRS + '/:id'}`, element: <AdminFairViewScreen /> },
    { path: `${PATH_ADMIN_FAIRS + '/:id/participants'}`, element: <AdminFairOrganizationsViewScreen /> },
    { path: `${PATH_ADMIN_NEWS}`, element: <AdminNewsListScreen /> },
    { path: `${PATH_ADMIN_NEWS + '/:id'}`, element: <AdminNewsViewScreen /> },
    { path: `${PATH_ADMIN_NEWS + '/new'}`, element: <AdminNewsNewScreen /> },
    { path: `${PATH_ADMIN_NEWS + '/:id/edit'}`, element: <AdminNewsEditScreen /> },

    { path: `${'*'}`, element: <Page404Screen /> },
  ]);

  return (
    <ThemeProvider>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;

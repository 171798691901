import { volunteeringVolunteerIllustration18, volunteeringVolunteerImage10 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen10 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Image src={volunteeringVolunteerImage10} width={'100%'} height={440} style={{ marginTop: -32, objectFit: 'cover' }} />
      <br/><br/>
      <h2 id="step-4">{t('learning.volunteering.volunteer.68')}</h2>
      <div className='d-flex flex-direction-row pb-3'>
        <Image src={volunteeringVolunteerIllustration18} width={320} height={220} className='mt-3' />
        <div className='pl-4'>
          <p>{t('learning.volunteering.volunteer.69')}</p>
          <p>{t('learning.volunteering.volunteer.70')}</p>
          <p>{t('learning.volunteering.volunteer.71')}</p>
        </div>
      </div>

      <p>
        <a href="https://digital-youth.org/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.72.1')}</a>
        {t('learning.volunteering.volunteer.72.2')}
      </p>
      <p>
        {t('learning.volunteering.volunteer.73.1')}
        <a href="https://turningdigital.net/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.73.2')}</a>
        {t('learning.volunteering.volunteer.73.3')}
      </p>
    </>
  )

}

export default VolunteeringLearningScreen10;
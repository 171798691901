import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import en from './language/en.json';
import gr from './language/gr.json';
import es from './language/es.json';
import sl from './language/sl.json';
import sr from './language/sr.json';
import { AppUtils } from "utils";

const resources = {
  en,
  gr,
  es,
  sl,
  sr
};

i18next
  .use(initReactI18next)
  .init({
    resources,
    lng: (() => {
      const lang = AppUtils.getLanguage();
      return lang
    })(),
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    debug: process.env.NODE_ENV === 'development',
  });
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower35 } from 'assets/learning';

const EmpowerLearningScreen15 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="resources">{t('learning.empower.140')}</h2>
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower35} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <p><b>{t('learning.empower.141')}</b></p>
        </div>
      </div>
      <ul>
        <li>{t('learning.empower.142')}<a href="https://teambuilding.com/blog/zoom-games-for-large-groups" target='_blank' rel='nooppener noreferrer'>https://teambuilding.com/blog/zoom-games-for-large-groups</a></li>
        <li>{t('learning.empower.143')}<a href="https://www.scavify.com/" target='_blank' rel='nooppener noreferrer'>https://www.scavify.com/</a></li>
        <li>{t('learning.empower.144')}<a href="https://joint-research-centre.ec.europa.eu/digcompedu_en" target='_blank' rel='nooppener noreferrer'>https://joint-research-centre.ec.europa.eu/digcompedu_en</a></li>
        <li>{t('learning.empower.145.1')} - <a href="https://www.coe.int/en/web/compass" target='_blank' rel='nooppener noreferrer'>https://www.coe.int/en/web/compass</a> - {t('learning.empower.145.2')}</li>
        <li>
          {t('learning.empower.146.1')}<a href="https://www.connect-international.org/resources/" target='_blank' rel='nooppener noreferrer'>https://www.connect-international.org/resources/</a>{t('learning.empower.146.2')}
          <ul>
            <li><a href="https://www.connect-international.org/2021/01/15/nf-guide-for-online-non-formal-education-is-now-online/" target='_blank' rel='nooppener noreferrer'>NF@ – Guide for Online Non-Formal Education</a> – {t('learning.empower.147')}</li>
            <li><a href="https://educateproject.org/" target='_blank' rel='nooppener noreferrer'>https://educateproject.org/</a> - {t('learning.empower.148')}</li>
            <li><a href="https://turningdigital.net/about/" target='_blank' rel='nooppener noreferrer'>https://turningdigital.net/about/</a> - {t('learning.empower.149')}</li>
            <li>{t('learning.empower.150')} <a href="https://www.connect-international.org/2019/08/20/updataed-guide-is-now-available-online/" target='_blank' rel='nooppener noreferrer'>https://www.connect-international.org/2019/08/20/updataed-guide-is-now-available-online/</a></li>
          </ul>
        </li>
        <li>{t('learning.empower.151.1')}<a href="https://www.youthpass.eu/en/" target='_blank' rel='nooppener noreferrer'>https://www.youthpass.eu/en/</a>{t('learning.empower.151.2')}<a href="https://www.youthpass.eu/en/login/" target='_blank' rel='nooppener noreferrer'>https://www.youthpass.eu/en/login/</a></li>
        <li>{t('learning.empower.152')}<a href="https://www.youtube.com/watch?v=Sx-GhudMoG8" target='_blank' rel='nooppener noreferrer'>https://www.youtube.com/watch?v=Sx-GhudMoG8</a></li>
        <li>{t('learning.empower.153')}<a href="https://www.nbcnews.com/science/weird-science/scientists-explain-why-watching-internet-cat-videos-good-you-n378156" target='_blank' rel='nooppener noreferrer'>https://www.nbcnews.com/science/weird-science/scientists-explain-why-watching-internet-cat-videos-good-you-n378156</a></li>
      </ul>
    </>
  )

}

export default EmpowerLearningScreen15;
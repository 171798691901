import React from 'react';
import CountdownBeforeTimer from './before';
import CountdownAfterTimer from './after';

const Countdown = ({ countdownTo, completed, renderer }) => {

  return (
    <div className="timer">
      {completed ?
        <CountdownAfterTimer
          countdownTo={countdownTo}
          renderer={renderer}
        /> :
        <CountdownBeforeTimer
          countdownTo={countdownTo}
          renderer={renderer}
        />
      }
    </div>
  )
}

export default Countdown;
import React, { useCallback, useEffect, useState } from 'react'

import { Empty, Layout } from 'components';
import { AppConstants } from 'constant';
import EmpowerEventFormModal from './modal/lobby.js';
import EmpowerHotspotModal from './conference/hotspot.js';
import hotspotTypes from '../../../../assets/hotspots.js';
import { AppUtils } from 'utils/index.js';
import { useTranslation } from 'react-i18next';

const { HEADER_TYPES } = AppConstants;

const baseSrc = '/vista/empower/index.htm?skip-loading#media-name=Lobby_PreLive';
const rooms = {
  LOBBY: 0,
  CONFERENCE: 1
}

const EmpowerVistaLobbyViewScreen = () => {

  const { t } = useTranslation();
  const [src, setSrc] = useState(baseSrc);
  const [room, setRoom] = useState(rooms.LOBBY);
  const [finished, setFinished] = useState(false);
  const [showModal, setShowModal] = useState(undefined);
  const [event, seEvent] = useState(undefined);

  useEffect(() => {
    if (!finished) {
      return;
    }
    if (room === rooms.LOBBY) {
      window.frames['vista'].triggerAction = (e, type) => setShowModal({ type: type });
    } else if (room === rooms.CONFERENCE) {
      window.frames['vista'].onLoadAction = (e) => { }
      window.frames['vista'].triggerAction = (e, type) => setShowModal({ ...hotspotTypes.Empower[type], type: type });
      window.frames['vista'].backToLobby = () => setRoom(rooms.LOBBY);
    }
  }, [finished, room]);

  const editRoom = useCallback((event) => {
    seEvent(event);
    setRoom(rooms.CONFERENCE);
    setShowModal(undefined);
    setSrc(baseSrc + '&trigger-overlay-name=GT_CONFERENCE_' + event.type + (AppUtils.isEventDraft(event) ? '&son=GT_LOBBY' : '&son=GT_LOBBY_LIVE'))
  }, []);

  if (!AppUtils.isUserLogged()) {
    return (
      <Layout type={HEADER_TYPES.EMPOWER}>
        <div>
          <div className='text-center'><Empty messageKey={t('functions.empower.message.login')} /></div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout type={HEADER_TYPES.EMPOWER}>
      <div>
        {room === rooms.LOBBY ?
          <EmpowerEventFormModal el={showModal} show={showModal} editRoom={editRoom} onDismiss={() => setShowModal(undefined)} />
          : room === rooms.CONFERENCE ?
            <EmpowerHotspotModal event={event} el={showModal} onDismiss={() => setShowModal(undefined)} show={showModal} />
            : undefined}
        <iframe
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          src={src}
          id='vista'
          name="vista"
          title="vista"
          width="100%"
          height="100%"
          allowFullScreen={false}
          onLoad={() => setFinished(true)}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
          sandbox="allow-scripts allow-same-origin">
        </iframe>
      </div>
    </Layout>
  )
}

export default EmpowerVistaLobbyViewScreen;
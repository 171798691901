import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower30, empower31, empower32 } from 'assets/learning';

const EmpowerLearningScreen12 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="storage">{t('learning.empower.116')}</h2>
      <p>{t('learning.empower.117')}</p>
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower30} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.118')}</b></h5>
          <p>{t('learning.empower.119')}</p>
          <p><span className="text-success">{t('learning.empower.120.1')}</span>: {t('learning.empower.120.2')}</p>
          <p><span className="text-danger">{t('learning.empower.121.1')}</span>: {t('learning.empower.121.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower31} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.122')}</b></h5>
          <p>{t('learning.empower.123')}</p>
          <p><span className="text-success">{t('learning.empower.124.1')}</span>: {t('learning.empower.124.2')}</p>
          <p><span className="text-danger">{t('learning.empower.125.1')}</span>: {t('learning.empower.125.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower32} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.126')}</b></h5>
          <p>{t('learning.empower.127')}</p>
          <p><span className="text-success">{t('learning.empower.128.1')}</span>: {t('learning.empower.128.2')}</p>
          <p><span className="text-danger">{t('learning.empower.129.1')}</span>: {t('learning.empower.129.2')}</p>
        </div>
      </div>
    </>
  )

}

export default EmpowerLearningScreen12;
import React from 'react';
import { Image } from 'react-bootstrap';

import { connect1 } from 'assets/learning';

const ConnectLearningScreen2 = () => {
  return (
    <div className='text-center'>
      <Image src={connect1} width={'50%'} />
    </div>
  )
}

export default ConnectLearningScreen2;
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Empty, Layout, Loader } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { calendar, connectYellow, email, paperSmall, opportunitySmall, phone, pin } from 'assets/icons';
import { profilePicture } from 'assets/images';
import { useFetch } from 'hooks/fetch';

import FunctionConnectCard from 'screens/functions/connect/card';
import NewsCard from 'screens/news/card';
import ForgetMyPasswordModal from './forget-password';
import EventItem from 'screens/functions/empower/item';
import OpportunityCard from 'screens/opportunities/card';

const { PATH_PROFILE, PATH_PROFILE_NEWS_NEW, PATH_PROFILE_OPPORTUNITY_NEW, PATH_PROFILE_DELETION } = AppConstants;
const { API_METHOD_GET, API_URL_ORGANIZATION, API_URL_FAIRS_APPLIED, API_URL_NEWS_MY, API_URL_EVENTS, API_URL_VOLUNTEERING_OPPORTUNITIES_MY } = ServerConstants;

const ProfileViewScreen = () => {

  const { t } = useTranslation();
  const loggedUser = AppUtils.getLoggedUser();
  const profileFetch = useFetch(API_METHOD_GET, API_URL_ORGANIZATION + '/' + loggedUser.id);
  const eventsFetch = useFetch(API_METHOD_GET, API_URL_EVENTS);
  const fairsFetch = useFetch(API_METHOD_GET, API_URL_FAIRS_APPLIED);
  const newsFetch = useFetch(API_METHOD_GET, API_URL_NEWS_MY);
  const opportunitiesFetch = useFetch(API_METHOD_GET, API_URL_VOLUNTEERING_OPPORTUNITIES_MY);

  const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);
  const [profile, setProfile] = useState(undefined);
  const events = useRef([]);
  const fairs = useRef([]);
  const news = useRef([]);
  const opportunities = useRef([]);
  const [, setReload] = useState(0);

  useEffect(() => {
    if (profileFetch.response) {
      setProfile(ModelUtils.convertOrganizationB2F(profileFetch.response.data));
    }
  }, [profileFetch.response]);

  useEffect(() => {
    if (eventsFetch.response) {
      events.current = eventsFetch.response.data.map(el => ModelUtils.convertEventModelB2F(el));
      setReload(old => old + 1);
    }
  }, [eventsFetch.response]);

  useEffect(() => {
    if (fairsFetch.response) {
      fairs.current = fairsFetch.response.data.map(el => ModelUtils.convertFairModelB2F(el));
      setReload(old => old + 1);
    }
  }, [fairsFetch.response]);

  useEffect(() => {
    if (newsFetch.response) {
      news.current = newsFetch.response.data.map(el => ModelUtils.convertArticleModelB2F(el));
      setReload(old => old + 1);
    }
  }, [newsFetch.response]);

  useEffect(() => {
    if (opportunitiesFetch.response) {
      opportunities.current = opportunitiesFetch.response.data.map(el => ModelUtils.convertOpportunityModelB2F(el));
      setReload(old => old + 1);
    }
  }, [opportunitiesFetch.response]);

  return (
    <Layout>
      <Container className="my-5">
        {profileFetch.loading ? <Loader /> :
          profile ?
            <>
              {forgetPasswordModalVisible ? <ForgetMyPasswordModal email={profile?.email} onDismiss={() => setForgetPasswordModalVisible(false)} show={forgetPasswordModalVisible} t={t} /> : undefined}
              <div className="mb-4">
                <div className="mt-5 mb-4">
                  <div className="d-flex flex-direction-row justify-content-between align-items-center flex-grow">
                    <div>
                      <div className="d-flex flex-direction-row align-items-center">
                        <Image src={profile?.profilePicture ? profile.profilePicture : profilePicture} width={104} height={104} style={{ borderRadius: 104, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
                        <div className="mx-4">
                          <h3><b>{profile.name}</b></h3>
                          {profile.website ?
                            <p>
                              <FontAwesomeIcon icon={'globe'} className="text-primary" />
                              <a href={AppUtils.renderWebAddress(profile.website)} target="_blank" rel="nooppener noreferrer">{profile.website}</a>
                            </p>
                            : undefined
                          }
                        </div>
                      </div>
                    </div>
                    <div>
                      <Button onClick={() => setForgetPasswordModalVisible(true)} className="m-1">
                        <FontAwesomeIcon icon={'lock'} />&nbsp;&nbsp;
                        <span>{t('organizations.password.change')}</span>
                      </Button>
                      <Button onClick={() => window.location.href = PATH_PROFILE + '/edit'} className="m-1">
                        <FontAwesomeIcon icon={'pen-to-square'} />&nbsp;&nbsp;
                        <span>{t('organizations.edit.title')}</span>
                      </Button>
                      <Button variant="secondary" onClick={() => window.location.href = PATH_PROFILE + '/public'} className="m-1">
                        <FontAwesomeIcon icon={'eye'} />&nbsp;&nbsp;
                        <span>{t('organizations.preview')}</span>
                      </Button>
                      <Button variant="secondary" onClick={() => window.location.href = PATH_PROFILE_DELETION} className="m-1">
                        <FontAwesomeIcon icon={'trash-alt'} />
                      </Button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex flex-direction-row justify-content-between align-items-start">
                    <div className="light-gray-background px-3 py-4">
                      <h5><b>{t('organizations.contact')}</b></h5>
                      {profile.address ?
                        <div className="d-flex flex-direction-row align-items-center py-3">
                          <Image src={pin} />
                          <span className="pl-3">{profile.address}</span>
                        </div>
                        : undefined}
                      {profile.phone ?
                        <div className="d-flex flex-direction-row align-items-center py-3">
                          <Image src={phone} />
                          <span className="pl-3">{profile.phone}</span>
                        </div>
                        : undefined}
                      <div className="d-flex flex-direction-row align-items-center py-3">
                        <Image src={email} />
                        <span className="pl-3">{profile.email}</span>
                      </div>
                    </div>
                    <div style={{ flexGrow: 1, marginLeft: 48 }}>
                      {(profile.instagram || profile.linkedIn || profile.facebook || profile.twitter || profile.youtube) ?
                        <>
                          <h5><b>{t('organizations.social')}</b></h5>
                          <div className="d-flex flex-direction-row align-items-center pt-1 pb-3">
                            {profile.instagram ? <a href={AppUtils.renderWebAddress(profile.instagram)} target="_blank" rel="nooppener noreferrer" className="social-icon white-background"><FontAwesomeIcon icon={['fab', 'instagram']} size={'lg'} color='black' /></a> : undefined}
                            {profile.linkedIn ? <a href={AppUtils.renderWebAddress(profile.linkedIn)} target="_blank" rel="nooppener noreferrer" className="social-icon white-background"><FontAwesomeIcon icon={['fab', 'linkedin-in']} size={'lg'} color='black' /></a> : undefined}
                            {profile.facebook ? <a href={AppUtils.renderWebAddress(profile.facebook)} target="_blank" rel="nooppener noreferrer" className="social-icon white-background"><FontAwesomeIcon icon={['fab', 'facebook-f']} size={'lg'} color='black' /></a> : undefined}
                            {profile.twitter ? <a href={AppUtils.renderWebAddress(profile.twitter)} target="_blank" rel="nooppener noreferrer" className="social-icon white-background"><FontAwesomeIcon icon={['fab', 'twitter']} size={'lg'} color='black' /></a> : undefined}
                            {profile.youtube ? <a href={AppUtils.renderWebAddress(profile.youtube)} target="_blank" rel="nooppener noreferrer" className="social-icon white-background"><FontAwesomeIcon icon={['fab', 'youtube']} size={'lg'} color='black' /></a> : undefined}
                          </div>
                        </>
                        : undefined}
                      {profile.fields && profile.fields.length > 0
                        ?
                        <>
                          <h5><b>{t('organizations.fields')}</b></h5>
                          <p>{profile.fields.map((el, i) => (i > 0 ? ', ' : '') + el)}</p>
                        </>
                        : undefined}
                      {profile.about ?
                        <>
                          <h5><b>{t('organizations.about')}</b></h5>
                          <p>{profile.about}</p>
                        </>
                        : undefined}
                    </div>
                  </div>
                  <div className="line-bottom" />
                  <div>
                    <div className="d-flex flex-direction-row align-items-center mt-5">
                      <Image src={calendar} />
                      <h3 className="text-primary mb-0">{t('organizations.events')}</h3>
                    </div>
                    <div className="mt-4">
                      {events.current.length === 0 ?
                        <Empty messageKey={t('functions.empower.events.empty')} className="mx-2" /> :
                        <Row>
                          {events.current.map(el => < Col className="col-4"><EventItem events={events} el={el} t={t} setReload={() => eventsFetch.forceFetchData()} /></Col>)}
                        </Row>
                      }
                    </div>
                  </div>
                  <div>
                    <div className="d-flex flex-direction-row align-items-end mt-5">
                      <Image src={paperSmall} />
                      <h3 className="text-blue mb-0">{t('organizations.news')}</h3>
                      <Button variant="info" className="mx-2 px-3" onClick={() => window.location.href = PATH_PROFILE_NEWS_NEW}><FontAwesomeIcon icon={'plus'} /></Button>
                    </div>
                    <div className="mt-4">
                      {news.current.length === 0 ?
                        <Empty messageKey={t('news.empty')} className="mx-2" /> :
                        <Row>
                          {news.current.map(el => <NewsCard el={el} t={t} />)}
                        </Row>
                      }
                    </div>
                  </div>
                  <div>
                    <div className="d-flex flex-direction-row align-items-end mt-5">
                      <Image src={opportunitySmall} />
                      <h3 className="text-volunteering mb-0">{t('organizations.opportunities')}</h3>
                      <Button variant="volunteering" className="mx-2 px-3" onClick={() => window.location.href = PATH_PROFILE_OPPORTUNITY_NEW}><FontAwesomeIcon icon={'plus'} /></Button>
                    </div>
                    <div className="mt-4">
                      {opportunities.current.length === 0 ?
                        <Empty messageKey={t('opportunity.empty')} className="mx-2" /> :
                        <Row>
                          {opportunities.current.map(el => <OpportunityCard el={el} t={t} />)}
                        </Row>
                      }
                    </div>
                  </div>
                  <div>
                    <div className="d-flex flex-direction-row align-items-end mt-5">
                      <Image src={connectYellow} />
                      <h3 className="text-connect mb-0">{t('organizations.fairs')}</h3>
                    </div>
                    <div className="mt-4">
                      {fairs.current.length === 0 ?
                        <Empty messageKey={t('fairs.empty')} className="mx-2" /> :
                        <Row>
                          {fairs.current.map(el => <FunctionConnectCard el={el} t={t} />)}
                        </Row>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
            : undefined
        }
      </Container>
    </Layout>
  )
}

export default ProfileViewScreen;
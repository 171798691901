import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { backslash, bum, connect, euLogo, moveit, tipovej } from 'assets/learning';

const EmpowerLearningScreen20 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="acknowledgements">{t('learning.connect.192')}</h2>
      <p>{t('learning.connect.193')}</p>
      <p><b><i>{t('learning.connect.194')}</i></b></p>
      <p><b>{t('learning.connect.195')}</b></p>
      <p>{t('learning.connect.196')}</p>
      <p>{t('learning.connect.197')}</p>
      <div className='d-flex flex-direction-row justify-content-center py-5'>
        <Image src={connect} width={160} height={80} className='mx-3' />
        <Image src={bum} width={160} height={80} className='mx-3' />
        <Image src={backslash} width={160} height={80} className='mx-3' />
        <Image src={moveit} width={160} height={80} className='mx-3' />
        <Image src={tipovej} width={160} height={80} className='mx-3' />
      </div>
      <div className='text-center mt-5'>
        <Image src={euLogo} width={320} height={64} />
      </div>
    </>
  )

}

export default EmpowerLearningScreen20;
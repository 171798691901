import React, { useCallback } from 'react';
import { Accordion, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { faq } from 'assets/icons';
import { Layout } from 'components';
import { AppConstants } from 'constant';

const { PATH_CONTACT } = AppConstants;
const numberOfFaqs = 6;

const FAQScreen = (props) => {

  const { t } = useTranslation();

  const RenderItems = useCallback(() => {
    let faqs = [];
    for (let i = 1; i <= numberOfFaqs; i++) {
      faqs.push(
        <Accordion.Item eventKey={i}>
          <Accordion.Header>{i}. {t(`faq.${i}.title`)}</Accordion.Header>
          <Accordion.Body>{t(`faq.${i}.description`)}</Accordion.Body>
        </Accordion.Item>
      )
    }
    return faqs;
  }, []);

  return (
    <Layout>
      <Container className="my-5">
        <div className="d-flex flex-direction-row align-items-start mb-4">
          <Image src={faq} />
          <h2 className="logo-name mx-3">{t('faq.title')}</h2>
        </div>
        <p>{t('faq.subtitle')}</p>
        <Accordion>
          <RenderItems />
        </Accordion>
        <h5 className="footnote">
          <span>{t('faq.more')}</span>
          <a href={PATH_CONTACT}>{t('faq.contact')}</a>
        </h5>
      </Container>
    </Layout>
  )
}

export default FAQScreen;
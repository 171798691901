import React from 'react';
import { useTranslation } from 'react-i18next';
import { volunteeringOrganizationIllustration37, volunteeringOrganizationPhoto37 } from 'assets/learning';
import { Image } from 'react-bootstrap';

const VolunteeringLearningScreen21 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32 }}>
        <Image src={volunteeringOrganizationPhoto37} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br />
      <h2 id="step-7">{t('learning.volunteering.organization.267')}</h2>

      <p>
        {t('learning.volunteering.organization.268.1')}
        <br/>
        {t('learning.volunteering.organization.268.2')}
      </p>
      <div className='text-center'>
        <Image src={volunteeringOrganizationIllustration37} width={400} height={'50%'} />
      </div>
    </>
  )
}

export default VolunteeringLearningScreen21;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Empty, Layout, Loader, Pager, ScrollToTop } from 'components';
import { forum } from 'assets/icons';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import ForumThemeNewModal from './modal/new';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { HEADER_TYPES, PATH_FORUM } = AppConstants;
const { API_METHOD_GET, API_URL_FORUM_THEMES } = ServerConstants;

const ForumThemesScreen = () => {

  const { t } = useTranslation();
  const themesListFetch = useFetch(API_METHOD_GET);
  const themesCountFetch = useFetch(API_METHOD_GET, API_URL_FORUM_THEMES);
  const [themes, setThemes] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, ] = useState(20);
  const [themesCount, setThemesCount] = useState(0);
  const [newVisible, setNewVisible] = useState(false);

  useEffect(() => {
    themesListFetch.setFetchUrl(API_URL_FORUM_THEMES + '/' + page + '/' + itemsPerPage);
    themesListFetch.forceFetchData();
  }, []);

  useEffect(() => {
    if (themesCountFetch.response) {
      setThemesCount(themesCountFetch.response.data.themesCount);
    }
  }, [themesCountFetch.response])

  useEffect(() => {
    if (themesListFetch.response) {
      setThemes(themesListFetch.response.data.map(el => ModelUtils.convertForumThemeModelB2F(el)));
    }
  }, [themesListFetch.response]);

  const onDismiss = useCallback((reload) => {
    setNewVisible(false);
    if (reload) {
      setPage(0);
      themesListFetch.setFetchUrl(API_URL_FORUM_THEMES + '/' + 0 + '/' + itemsPerPage);
      themesListFetch.forceFetchData();
    }
  }, [page, itemsPerPage]);

  const onPageChange = useCallback((val) => {
    setPage(val);
    themesListFetch.setFetchUrl(API_URL_FORUM_THEMES + '/' + val + '/' + itemsPerPage);
    themesListFetch.forceFetchData();
  }, [itemsPerPage])

  const isLoading = useCallback(() => (themesListFetch && themesListFetch.loading) || (themesCountFetch && themesCountFetch.loading), [themesListFetch]);

  return (
    <Layout type={HEADER_TYPES.FORUM}>
      <Container className="mt-4 mb-5">
        {newVisible ? <ForumThemeNewModal onDismiss={onDismiss} t={t} visible={newVisible} /> : <></>}
        <div className="mb-5">
          <div className="d-flex flex-direction-row justify-content-between flex-wrap">
            <div className="mt-4">
              <div className="d-flex flex-direction-row align-items-start">
                <Image src={forum} />
                <h2 className="logo-name mx-3 text-primary">{t('forum.theme.title')}</h2>
                {AppUtils.isUserOrganization() && <Button variant="primary" className="mx-2 px-3" onClick={() => setNewVisible(true)}><FontAwesomeIcon icon={'plus'} /></Button>}
              </div>
            </div>
          </div>
        </div>
        <div>
          {isLoading() ? <Loader /> :
          <div>
            {(!themes || themes.length === 0) ? <Empty messageKey={t('forum.theme.empty')} /> :
              <table class="table">
                <thead>
                  <tr>
                    <th>{t('forum.theme.name')}</th>
                    <th className='w-20'>{t('forum.theme.createdAt')}</th>
                    <th className='w-20'>{t('forum.theme.authorName')}</th>
                  </tr>
                </thead>
                <tbody>
                  {themes.map(el => <ThemeItem el={el} t={t} />)}
                </tbody>
              </table>
            }
            <Pager
              page={page}
              numberOfItems={themesCount}
              itemsPerPage={itemsPerPage}
              onPageChange={onPageChange}
            />
          </div>
          }
        </div>
        <ScrollToTop />
      </Container>
    </Layout>
  )
}

const ThemeItem = ({ el, t }) => {
  return (
    <tr>
      <td><a href={PATH_FORUM + '/' + el.id}>{el.title}</a></td>
      <td>{AppUtils.convertDateToLocaleDate(el.createdAt, t('format.date.time'))}</td>
      <td>{el.authorName}</td>
    </tr>
  )
}

export default ForumThemesScreen;
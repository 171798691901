import React, { useCallback } from 'react';
import { Button, Image, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AppUtils } from 'utils';
import { AppConstants } from 'constant';
import { logo } from 'assets/icons';

const { PATH_ADMIN_COMMUNITY, PATH_ADMIN_FAIRS, PATH_ADMIN_NEWS } = AppConstants;

const AdminHeader = () => {

  const { t } = useTranslation();

  const logout = useCallback(() => {
    AppUtils.logOut();
    window.location.href = '/';
  }, []);

  return (
    <>
      <Navbar expand="md" className="header" sticky="top" >
        <Navbar.Brand href="/">
          <div className="d-flex flex-row align-items-center">
            <Image src={logo} width={73} height={73} />
            <div className="d-flex flex-column align-items-center justify-content-center logo-name">
              <span>Digital</span>
              <span>YOu</span>
            </div>
          </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="align-items-center">
            <Nav.Link href={PATH_ADMIN_COMMUNITY}>{t('navbar.organizations')}</Nav.Link>
            <Nav.Link href={PATH_ADMIN_FAIRS}>{t('navbar.fairs')}</Nav.Link>
            <Nav.Link href={PATH_ADMIN_NEWS}>{t('navbar.news')}</Nav.Link>
            {AppUtils.isUserLogged() ?
              <Nav.Item><span className="muted mx-2">{AppUtils.getLoggedUser()?.fullName ?? 'DY Admin'}</span></Nav.Item>
              : undefined
            }
            <Button variant="secondary" className="mx-2" onClick={logout}>{t('logout')}</Button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default AdminHeader;
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Image } from 'react-bootstrap';

import { Layout, Loader } from 'components';
import { CountriesData } from 'data';
import { AppConstants, ServerConstants } from 'constant';
import OrganizationApproveModal from './modal/approve';
import OrganizationRejectModal from './modal/reject';
import { useFetch } from 'hooks/fetch';
import { profilePicture } from 'assets/images';

const { HEADER_TYPES, ORGANIZATION_STATUS_APPROVED, ORGANIZATION_STATUS_REJECTED, ORGANIZATION_STATUS_PENDING } = AppConstants;
const { API_METHOD_GET, API_URL_ORGANIZATION, API_URL_ORGANIZATION_OVERVIEW } = ServerConstants;

const modals = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
};

const AdminCommunityViewScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[3];
  const { loading, response } = useFetch(API_METHOD_GET, API_URL_ORGANIZATION + '/' + id + API_URL_ORGANIZATION_OVERVIEW)

  const [organization, setOrganization] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(undefined);

  useEffect(() => {
    if (response) {
      setOrganization(response.data);
    }
  }, [response]);

  const showModals = useCallback((modal) => setModalVisible(modal), []);
  const hideModals = useCallback(() => setModalVisible(undefined), []);

  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="my-5">
        {loading ? <Loader /> :
          organization ?
            <>
              <OrganizationApproveModal el={organization} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.APPROVE} />
              <OrganizationRejectModal el={organization} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.REJECT} />
              <div className="d-flex flex-direction-row align-items-center">
                <Image src={organization?.profilePicture ? organization.profilePicture : profilePicture} width={104} height={104} style={{ borderRadius: 104, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
                <div className="mx-2"></div>
                <div>
                  <h6>{t('organizations.name')}</h6>
                  <h2>{organization.organizationName}</h2>
                </div>
              </div>
              <div className="d-flex flex-direction-row align-items-center my-4">
                {organization.status === ORGANIZATION_STATUS_APPROVED ?
                  <div>
                    <FontAwesomeIcon icon={'circle-check'} className="text-success" size={'lg'} />
                    <span className="mx-2">{t('organizations.admin.filter.' + ORGANIZATION_STATUS_APPROVED.toUpperCase())}</span>
                    <a className='clickable mx-2' onClick={() => showModals(modals.REJECT)}>{t('organizations.status.change.to.' + ORGANIZATION_STATUS_REJECTED.toUpperCase())}</a>
                  </div>
                  : undefined}
                {organization.status === ORGANIZATION_STATUS_REJECTED ?
                  <div>
                    <FontAwesomeIcon icon={'circle-xmark'} className="text-danger" size={'lg'} />
                    <span className="mx-2">{t('organizations.admin.filter.' + ORGANIZATION_STATUS_REJECTED.toUpperCase())}</span>
                    <a className='clickable mx-2' onClick={() => showModals(modals.APPROVE)}>{t('organizations.status.change.to.' + ORGANIZATION_STATUS_APPROVED.toUpperCase())}</a>
                  </div>
                  : undefined}
              </div>
              <div className="d-flex flex-direction-row align-items-center mt-4">
                {organization.email ?
                  <div className="info">
                    <label>{t('organizations.email')}</label>
                    <span>{organization.email}</span>
                  </div>
                  : undefined}
                {organization.contactEmail ?
                  <div className="info">
                    <label>{t('organizations.contact')}</label>
                    <span>{organization.contactEmail}</span>
                  </div>
                  : undefined}
                {organization.country ?
                  <div className="info">
                    <label>{t('organizations.country')}</label>
                    <span>{CountriesData[organization.country]}</span>
                  </div>
                  : undefined}
                {organization.piC_OID ?
                  <div className="info">
                    <label>{t('organizations.pic')}</label>
                    <span>{organization.piC_OID}</span>
                  </div>
                  : undefined}
              </div>
              {organization.alternativeReason ?
                <div className="info" style={{ background: 'none' }}>
                  <label>{t('organizations.reason')}</label>
                  <p>{organization.alternativeReason}</p>
                </div>
                : undefined}
              {organization.about ?
                <div className="info" style={{ background: 'none' }}>
                  <label>{t('organizations.about')}</label>
                  <p>{organization.about}</p>
                </div>
                : undefined}
              {organization.status === ORGANIZATION_STATUS_PENDING ?
                <div className="d-flex flex-direction-row align-items-center my-4">
                  <h5 className="text-success clickable" onClick={() => showModals(modals.APPROVE)}>
                    <FontAwesomeIcon icon={'circle-check'} size={'lg'} />
                    <span className="mx-2">{t('organizations.action.approve')}</span>
                  </h5>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <h5 className="text-danger clickable" onClick={() => showModals(modals.REJECT)}>
                    <FontAwesomeIcon icon={'circle-xmark'} size={'lg'} />
                    <span className="mx-2">{t('organizations.action.reject')}</span>
                  </h5>
                </div>
                : undefined}
            </>
            : undefined}
      </Container>
    </Layout>
  )
}

export default AdminCommunityViewScreen;
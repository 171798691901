import HotspotItems from './hotspot-items';

export default class Hotspot {
  private __id: number;
  private __type: string;
  private __text: string;
  private __image: string;
  private __videoLink: string;
  private __items: HotspotItems[];

  constructor(
    id: number,
    type: string,
    text: string,
    image: string,
    videoLink: string,
    items: HotspotItems[],
  ) {
    this.__id = id;
    this.__type = type
    this.__text = text;
    this.__image = image;
    this.__videoLink = videoLink;
    this.__items = items;
  }

  public get id() { return this.__id; }
  public get type() { return this.__type; }
  public get text() { return this.__text; }
  public get image() { return this.__image; }
  public get videoLink() { return this.__videoLink; }
  public get items() { return this.__items; }

  public toJSON() {
    return {
      id: this.__id,
      type: this.__type,
      text: this.__text,
      image: this.__image,
      videoLink: this.__videoLink,
      items: this.__items
    }
  }

}
import VolunteeringLearningScreen1 from "./1";
import VolunteeringLearningScreen2 from "./2";
import VolunteeringLearningScreen3 from "./3";
import VolunteeringLearningScreen4 from "./4";
import VolunteeringLearningScreen5 from "./5";
import VolunteeringLearningScreen6 from "./6";
import VolunteeringLearningScreen7 from "./7";
import VolunteeringLearningScreen8 from "./8";
import VolunteeringLearningScreen9 from "./9";
import VolunteeringLearningScreen10 from "./10";
import VolunteeringLearningScreen11 from "./11";
import VolunteeringLearningScreen12 from "./12";
import VolunteeringLearningScreen13 from "./13";
import VolunteeringLearningScreen14 from "./14";
import VolunteeringLearningScreen15 from "./15";
import VolunteeringLearningScreen16 from "./16";
import VolunteeringLearningScreen17 from "./17";
import VolunteeringLearningScreen18 from "./18";

const screens = {
  page1: <VolunteeringLearningScreen1 />,
  page2: <VolunteeringLearningScreen2 />,
  page3: <VolunteeringLearningScreen3 />,
  page4: <VolunteeringLearningScreen4 />,
  page5: <VolunteeringLearningScreen5 />,
  page6: <VolunteeringLearningScreen6 />,
  page7: <VolunteeringLearningScreen7 />,
  page8: <VolunteeringLearningScreen8 />,
  page9: <VolunteeringLearningScreen9 />,
  page10: <VolunteeringLearningScreen10 />,
  page11: <VolunteeringLearningScreen11 />,
  page12: <VolunteeringLearningScreen12 />,
  page13: <VolunteeringLearningScreen13 />,
  page14: <VolunteeringLearningScreen14 />,
  page15: <VolunteeringLearningScreen15 />,
  page16: <VolunteeringLearningScreen16 />,
  page17: <VolunteeringLearningScreen17 />,
  page18: <VolunteeringLearningScreen18 />,
}

export default screens;
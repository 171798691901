import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Empty, Layout, Loader, ScrollToTop } from 'components';
import { connectWhite, empowerWhite, engageWhite, infinityWhite, paper } from 'assets/icons';
import NewsCard from './card';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import { ModelUtils } from 'utils';

const { NEWS_CATEGORY_GENERAL, NEWS_CATEGORY_CONNECT, NEWS_CATEGORY_EMPOWER, NEWS_CATEGORY_ENGAGE } = AppConstants;
const { API_METHOD_GET, API_URL_NEWS } = ServerConstants;

const filterTypes = [
  { icon: infinityWhite, type: NEWS_CATEGORY_GENERAL, filter: 'General' },
  { icon: engageWhite, type: NEWS_CATEGORY_ENGAGE, filter: 'Engage' },
  { icon: empowerWhite, type: NEWS_CATEGORY_EMPOWER, filter: 'Empower' },
  { icon: connectWhite, type: NEWS_CATEGORY_CONNECT, filter: 'Connect' },
]

const NewsListScreen = () => {

  const { t } = useTranslation();
  const { response, loading } = useFetch(API_METHOD_GET, API_URL_NEWS)
  const [filterType, setFilterType] = useState(undefined);
  const [filteredNewsArray, setFilteredNewsArray] = useState([]);
  const [news, setNews] = useState([]);

  useEffect(() => {
    if (response) {
      setNews(response.data.map(el => ModelUtils.convertArticleModelB2F(el)));
    }
  }, [response])

  useEffect(() => {
    if (!filterType) {
      setFilteredNewsArray(news);
      return;
    }
    setFilteredNewsArray(news.filter(el => el.type === filterType));
  }, [filterType, news]);

  const getFilterClass = useCallback((el) => {
    if (filterType === undefined) {
      return;
    }
    return filterType === el ? 'active' : 'inactive';
  }, [filterType]);

  const filterByType = useCallback((type) => setFilterType(old => old !== type ? type : undefined), [])

  return (
    <Layout>
      <Container className="mt-4 mb-5">
        <div className="mb-5">
          <div className="d-flex flex-direction-row justify-content-between flex-wrap">
            <div className="mt-4">
              <div className="d-flex flex-direction-row align-items-start">
                <Image src={paper} />
                <h2 className="logo-name mx-3">{t('news.title')}</h2>
              </div>
            </div>
            <div className="mt-4">
              <div className="filter">
                <span>{t('news.filter')}</span>
                {filterTypes.map(el =>
                  <Button key={el.type} color="dark" variant="light" onClick={() => filterByType(el.filter)} className={getFilterClass(el.filter)}>
                    <Image src={el.icon} className={`${el.type.toLowerCase()}-background`} style={{ color: 'white' }} />
                    <span className={`${el.type.toLowerCase()}-color`}>{t('functions.' + el.type.toLowerCase() + '.title')}</span>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <p>{t('news.subtitle')}</p>
        <div>
          <Row>
            {loading ? <Loader /> :
            (!filteredNewsArray || filteredNewsArray.length === 0) ? <Empty messageKey={t('news.empty')} /> :
            filteredNewsArray.map(el => <NewsCard el={el} t={t} />)}
          </Row>
        </div>
        <ScrollToTop />
      </Container>
    </Layout>
  )
}

export default NewsListScreen;
import React from 'react';
import { FormDate, FormInput, FormSelect } from 'components';
import { AppConstants } from 'constant';
import { AppUtils } from 'utils';

const { EMPOWER_EVENT_TYPES } = AppConstants;

const EmpowerEventForm = ({ event, onValueChange, values, t }) => {
  return (
    <div>
      {
        (!event || !event.id)
          ? <h3 className="mt-2 mb-4"><b>{t('functions.empower.events.new')}</b></h3>
          : undefined}
      {(event && event.id)
        ? <>
          <FormSelect id="type" required={true} label={t('functions.empower.events.type.title')} onChange={onValueChange} value={values?.type}>
            {EMPOWER_EVENT_TYPES.map(el => (
              <option value={el}>{t('functions.empower.events.type.' + el)}</option>
            ))}
          </FormSelect>
        </>
        : undefined}
      <FormInput id="name" required={true} label={t('functions.empower.events.name')} onChange={(onValueChange)} type="text" defaultValue={values['name']} />
      <FormDate
        required={true}
        label={t('functions.empower.events.start')}
        minDate={new Date()}
        selected={values['startTime'] ? new Date(!values['editedStartTime'] ? AppUtils.convertDateToLocaleDate(values['startTime']) : (values['startTime'])) : undefined}
        onChange={(e) => {
          onValueChange({ target: { id: 'editedStartTime', value: true } });
          onValueChange({ target: { id: 'startTime', value: e } });
          onValueChange({ target: { id: 'endTime', value: undefined } });
        }}
        timeIntervals={15}
        showTimeSelect={true}
        dateFormat={t('format.picker.date.time')}
      />
      <FormDate
        required={true}
        disabled={!values['startTime']}
        label={t('functions.empower.events.end')}
        minDate={values['startTime'] ? new Date(values['startTime']) : new Date()}
        selected={values['endTime'] ? new Date(!values['editedEndTime'] ? AppUtils.convertDateToLocaleDate(values['endTime']) : (values['endTime'])) : undefined}
        onChange={(e) => {
          onValueChange({ target: { id: 'editedEndTime', value: true } });
          onValueChange({ target: { id: 'endTime', value: e } });
        }}
        timeIntervals={15}
        showTimeSelect={true}
        dateFormat={t('format.picker.date.time')}
      />
    </div>
  )
}

export default EmpowerEventForm;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step16 } from 'assets/learning';

const EngageLearningScreen8 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-1-6" className='text-engage'>{t('learning.engage.32')}</h4>
      <p>{t('learning.engage.33.1')}<b>{t('learning.engage.33.2')}</b>{t('learning.engage.33.3')}</p>
      <p>{t('learning.engage.34')}</p>
      <p>{t('learning.engage.35')}</p>
      <br /><br />
      <div className='text-center'>
        <Image src={step16} width={'80%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen8;
import React from 'react';
import { Container, Image } from 'react-bootstrap';

import { Modal } from 'components';
import { AppUtils } from 'utils';

const InfoModal = ({ fair, onDismiss, visible, t }) => {

  return (
    <Modal onHide={onDismiss} show={visible}>
      <Container>
        <Image src={fair.featuredImage} alt={fair.featuredImage} style={{ height: '300px', width: '100%', objectFit: 'cover', backgroundRepeat: 'contain'}} />
        <h5 className="pt-1 my-3">
          <span>{fair.title}</span>
        </h5>
        <div className="d-flex flex-direction-row align-items-center mt-2 flex-wrap">
          {fair.applicationDateTime ?
            <div className="info">
              <label>{t('fairs.application')}</label>
              <span>{AppUtils.convertDateToLocaleDate(fair.applicationDateTime, t('format.date.time'))}</span>
            </div>
            : undefined}
          {fair.preLiveDateTime ?
            <div className="info">
              <label>{t('fairs.prelive')}</label>
              <span>{AppUtils.convertDateToLocaleDate(fair.preLiveDateTime, t('format.date.time'))}</span>
            </div>
            : undefined}
          {fair.startDateTime ?
            <div className="info">
              <label>{t('fairs.start.date')}</label>
              <span>{AppUtils.convertDateToLocaleDate(fair.startDateTime, t('format.date.time'))}</span>
            </div>
            : undefined}
          {fair.durationInMinutes ?
            <div className="info">
              <label>{t('fairs.duration')}</label>
              <span>{fair.durationInMinutes} min</span>
            </div>
            : undefined}
          {fair.numberOfParticipants ?
            <div className="info">
              <label>{t('fairs.participants.number')}</label>
              <span>{fair.numberOfParticipants}</span>
            </div>
            : undefined}
        </div>
        {fair.description ?
          <div className="info" style={{ background: 'none' }}>
            <label>{t('fairs.description')}</label>
            <span>{fair.description}</span>
          </div>
          : undefined}
      </Container>
    </Modal>
  )
}

export default InfoModal;
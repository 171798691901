import ConnectLearningScreen1 from "./1";
import ConnectLearningScreen2 from "./2";
import ConnectLearningScreen3 from "./3";
import ConnectLearningScreen4 from "./4";
import ConnectLearningScreen5 from "./5";
import ConnectLearningScreen6 from "./6";
import ConnectLearningScreen7 from "./7";
import ConnectLearningScreen8 from "./8";
import ConnectLearningScreen9 from "./9";
import ConnectLearningScreen10 from "./10";
import ConnectLearningScreen11 from "./11";
import ConnectLearningScreen12 from "./12";
import ConnectLearningScreen13 from "./13";
import ConnectLearningScreen14 from "./14";
import ConnectLearningScreen15 from "./15";
import ConnectLearningScreen16 from "./16";
import ConnectLearningScreen17 from "./17";
import ConnectLearningScreen18 from "./18";
import ConnectLearningScreen19 from "./19";
import ConnectLearningScreen20 from "./20";

const screens = {
  page1: <ConnectLearningScreen1 />,
  page2: <ConnectLearningScreen2 />,
  page3: <ConnectLearningScreen3 />,
  page4: <ConnectLearningScreen4 />,
  page5: <ConnectLearningScreen5 />,
  page6: <ConnectLearningScreen6 />,
  page7: <ConnectLearningScreen7 />,
  page8: <ConnectLearningScreen8 />,
  page9: <ConnectLearningScreen9 />,
  page10: <ConnectLearningScreen10 />,
  page11: <ConnectLearningScreen11 />,
  page12: <ConnectLearningScreen12 />,
  page13: <ConnectLearningScreen13 />,
  page14: <ConnectLearningScreen14 />,
  page15: <ConnectLearningScreen15 />,
  page16: <ConnectLearningScreen16 />,
  page17: <ConnectLearningScreen17 />,
  page18: <ConnectLearningScreen18 />,
  page19: <ConnectLearningScreen19 />,
  page20: <ConnectLearningScreen20 />,
}

export default screens;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step31 } from 'assets/learning';

const EngageLearningScreen13 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-3-2" className='text-engage'>{t('learning.engage.52')}</h4>
      <p>{t('learning.engage.53')}</p>
      <div className='text-center pt-2'>
        <Image src={step31} width={'60%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen13;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step471 } from 'assets/learning';

const EngageLearningScreen45 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-7-1" className='text-engage'>{t('learning.engage.279')}</h4>
      <p><b>{t('learning.engage.280.1')}</b>{t('learning.engage.280.2')}</p>
      <p>{t('learning.engage.281')}</p>
      <p>{t('learning.engage.282.1')}<a href="https://www.scoro.com/blog/list-of-pr-tools/" rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.282.2')}</a>{t('learning.engage.282.3')}</p>
      <p>{t('learning.engage.283.1')}<b>{t('learning.engage.283.2')}</b>{t('learning.engage.283.3')}</p>
      <ul>
        <li>{t('learning.engage.284')}</li>
        <li>{t('learning.engage.285')}</li>
        <li>{t('learning.engage.286')}</li>
        <li>{t('learning.engage.287')}</li>
      </ul>
      <br/>
      <div className='text-center'>
        <Image src={step471} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen45;
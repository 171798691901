import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';
import { connect26 } from 'assets/learning';

const ConnectLearningScreen12 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-3-3" className='text-connect'>{t('learning.connect.102')}</h4>
      <p>{t('learning.connect.103')}</p>
      <ul>
        <li style={{ listStyle: 'none' }}>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.104.1')}</b> - {t('learning.connect.104.2')}</span>
        </li>
        <li style={{ listStyle: 'none' }}>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.105.1')}</b> - {t('learning.connect.105.2')}</span>
        </li>
        <li style={{ listStyle: 'none' }}>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.106.1')}</b> - {t('learning.connect.106.2')}</span>
        </li>
        <li style={{ listStyle: 'none' }}>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.107.1')}</b> - {t('learning.connect.107.2')}</span>
        </li>
        <li style={{ listStyle: 'none' }}>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.108.1')}</b> - {t('learning.connect.108.2')}</span>
        </li>
      </ul>
      <p>{t('learning.connect.109.1')}<a href="https://www.proprofs.com/quiz-school/story.php?title=test-your-networking-skills" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.109.2')}</a></p>
      <div className='text-center'>
        <Image src={connect26} width={'50%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen12;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect, backslash, bum, euLogo, moveit, tipovej } from 'assets/learning';

const EngageLearningScreen54 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h2 id="acknowledgements">{t('learning.engage.463')}</h2>
      <p>{t('learning.engage.464')}</p>
      <p><i>{t('learning.engage.465')}</i></p>
      <p><b>{t('learning.engage.466')}</b></p>
      <p>{t('learning.engage.467')}</p>
      <p>{t('learning.engage.468')}</p>
      <div className='d-flex flex-direction-row justify-content-center py-5'>
        <Image src={connect} width={160} height={80} className='mx-3' />
        <Image src={bum} width={160} height={80} className='mx-3' />
        <Image src={backslash} width={160} height={80} className='mx-3' />
        <Image src={moveit} width={160} height={80} className='mx-3' />
        <Image src={tipovej} width={160} height={80} className='mx-3' />
      </div>
      <div className='text-center mt-5'>
        <Image src={euLogo} width={320} height={64} />
      </div>
    </>
  )

}

export default EngageLearningScreen54;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower20 } from 'assets/learning';

const EmpowerLearningScreen8 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="presentations">{t('learning.empower.67')}</h2>
      <p>
        {t('learning.empower.68.1')}
        <b>{t('learning.empower.68.2')}</b>
        <b>{t('learning.empower.68.3')}</b>
        <b>{t('learning.empower.68.4')}</b>
        <b>{t('learning.empower.68.5')}</b>
        {t('learning.empower.68.6')}
        <a href="https://edpuzzle.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.68.7')}</a>
        {t('learning.empower.68.8')}
      </p>
      <div className='text-center'>
        <Image src={empower20} width={'57%'} />
      </div>
      <br />
      <p>{t('learning.empower.69.1')}<b>{t('learning.empower.69.2')}</b>{t('learning.empower.69.3')}</p>
    </>
  )

}

export default EmpowerLearningScreen8;
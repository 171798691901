import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step4141, step4142, step4143, step4144 } from 'assets/learning';

const EngageLearningScreen18 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-1-4" className='text-engage'>{t('learning.engage.77')}</h4>
      <p><a href="https://www.nature.org/en-us/about-us/who-we-are/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.78.1')}</a>{t('learning.engage.78.2')}</p>
      <p>{t('learning.engage.79.1')}<a href="https://www.nature.org/en-us/about-us/who-we-are/our-science/science-for-nature-and-people-partnership/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.79.2')}</a>{t('learning.engage.79.3')}</p>
      <p>{t('learning.engage.80')}</p>
      <div className='d-flex flex-direction-row justify-content-center m-5'>
        <Image src={step4141} width={100} className='mx-3' />
        <Image src={step4142} width={100} className='mx-3' />
        <Image src={step4143} width={100} className='mx-3' />
        <Image src={step4141} width={100} className='mx-3' />
        <Image src={step4142} width={100} className='mx-3' />
        <Image src={step4143} width={100} className='mx-3' />
      </div>
      <p>{t('learning.engage.81.1')} <b>{t('learning.engage.81.2')}</b> {t('learning.engage.81.3')}</p>
      <ol>
        <li>
          <b>{t('learning.engage.82')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.83')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.84')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.85')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.86')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ol>
      <p><b>{t('learning.engage.87')}</b></p>
      <ol>
        <li>
          <b>{t('learning.engage.88')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.89')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.90')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ol>
      <div className='text-center'>
        <Image src={step4144} width={'25%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen18;
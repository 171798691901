import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step15 } from 'assets/learning';

const EngageLearningScreen7 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-1-5" className='text-engage'>{t('learning.engage.31')}</h4>
      <div className='text-center'>
        <Image src={step15} width={'70%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen7;
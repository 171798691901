import React, { useEffect } from 'react';

const VirtualRoomModal = ({ fair, onDismiss, show }) => {

  useEffect(() => {
    if (show && fair.roomUrl) {
      window.open(fair.roomUrl, '_blank').focus();
      onDismiss();
    }
  }, [show, fair.roomUrl, onDismiss])

  return <></>
}

export default VirtualRoomModal;
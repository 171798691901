import { volunteeringOrganizationPhoto1 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen2 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Image src={volunteeringOrganizationPhoto1} width={'100%'} height={440} style={{ marginTop: -32, objectFit: 'cover' }} />
      <br/><br/>
      <h2 id="step-1">{t('learning.volunteering.organization.3')}</h2>
      <h4 id="step-1-1" className='text-volunteering'>{t('learning.volunteering.organization.4')}</h4>
      <p>{t('learning.volunteering.organization.5')}</p>
      <p><b>{t('learning.volunteering.organization.6.1')}</b> {t('learning.volunteering.organization.6.2')}</p>
      <p>{t('learning.volunteering.organization.7')}</p>
      <p>{t('learning.volunteering.organization.8.1')}<a href="https://www.youtube.com/watch?v=2Zk_yDbL0-o" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.8.2')}</a>{t('learning.volunteering.organization.8.3')}</p>
    </>
  )

}

export default VolunteeringLearningScreen2;
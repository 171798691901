import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Layout, Loader } from 'components';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppUtils, ModelUtils } from 'utils';

const { PATH_ADMIN_FAIRS, HEADER_TYPES } = AppConstants;
const { API_METHOD_GET, API_URL_FAIR } = ServerConstants;

const AdminFairViewScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[3];
  const { loading, response } = useFetch(API_METHOD_GET, API_URL_FAIR + '/' + id);

  const [fair, setFair] = useState(undefined);

  useEffect(() => {
    if (response) {
      setFair(ModelUtils.convertFairModelB2F(response.data));
    }
  }, [response]);

  const isEditable = useCallback(() => {
    if (!fair) {
      return true;
    }
    return !AppUtils.isFairLive(fair) && !AppUtils.isFairFinished(fair);
  }, [fair])


  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="mt-4 mb-5">
        {loading ? <Loader /> :
          fair ?
            <>
              <div className="mb-4">
                <div className="mt-5 mb-4">
                  <div className="d-flex flex-direction-row justify-content-between align-items-center flex-grow">
                    <div>
                      <span>{t('fairs.single')}</span><br />
                      <h3 className="pr-4">{fair.title}</h3>
                    </div>
                    <div>
                      {!isEditable() ? undefined :
                        <Button onClick={() => window.location.href = PATH_ADMIN_FAIRS + '/' + id + '/edit'} className='mr-2'>
                          <FontAwesomeIcon icon={'pencil-alt'} />&nbsp;&nbsp;
                          <span>{t('fairs.edit')}</span>
                        </Button>
                      }
                      <Button onClick={() => window.location.href = PATH_ADMIN_FAIRS + '/' + id + '/participants'}>
                        <FontAwesomeIcon icon={'users'} />&nbsp;&nbsp;
                        <span>{t('fairs.participants.title')}</span>
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-direction-row align-items-center mt-4">
                  {fair.applicationDateTime ?
                    <div className="info">
                      <label>{t('fairs.application')}</label>
                      <span>{AppUtils.convertDateToLocaleDate(fair.applicationDateTime, t('format.date.time'))}</span>
                    </div>
                    : undefined}
                  {fair.preLiveDateTime ?
                    <div className="info">
                      <label>{t('fairs.prelive')}</label>
                      <span>{AppUtils.convertDateToLocaleDate(fair.preLiveDateTime, t('format.date.time'))}</span>
                    </div>
                    : undefined}
                  {fair.startDateTime ?
                    <div className="info">
                      <label>{t('fairs.start.date')}</label>
                      <span>{AppUtils.convertDateToLocaleDate(fair.startDateTime, t('format.date.time'))}</span>
                    </div>
                    : undefined}
                  {fair.durationInMinutes ?
                    <div className="info">
                      <label>{t('fairs.duration')}</label>
                      <span>{fair.durationInMinutes} min</span>
                    </div>
                    : undefined}
                  {fair.numberOfParticipants ?
                    <div className="info">
                      <label>{t('fairs.participants.number')}</label>
                      <span>{fair.numberOfParticipants}</span>
                    </div>
                    : undefined}
                  {fair.roomUrl ?
                    <div className="info">
                      <label>{t('fairs.room.title')}</label>
                      <span>{fair.roomUrl}</span>
                    </div>
                    : undefined}
                </div>
                {fair.description ?
                  <div className="info" style={{ background: 'none' }}>
                    <label>{t('fairs.description')}</label>
                    <span>{fair.description}</span>
                  </div>
                  : undefined}
                {fair.featuredImage ?
                  <div className="info" style={{ background: 'none' }}>
                    <label>{t('fairs.featured')}</label>
                    <span>
                      <Image
                        src={fair.featuredImage}
                        style={{
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          height: 200,
                          width: 300,
                          borderRadius: 4
                        }} />
                    </span>
                  </div>
                  : undefined}
              </div>
            </>
            : undefined}
      </Container>
    </Layout>
  )
}

export default AdminFairViewScreen;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const ScrollToTop = () => {

  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const listener = window.addEventListener('scroll', () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
        setVisible(true);
      }
      else if (scrolled <= 300) {
        setVisible(false);
      }
    });
  return (() => {
    window.removeEventListener('scroll', listener);
  })
}, []);

const scrollToTop = useCallback(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}, []);


return (
  <Button onClick={scrollToTop} className="scroll-to-top" style={{ display: visible ? 'inline' : 'none' }}>
    <FontAwesomeIcon icon={"arrow-up"} />
  </Button>
);
}

export default ScrollToTop;

import React, { useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Layout, Loader } from 'components';
import { connectWhite, empowerWhite, engageWhite, infinityWhite } from 'assets/icons';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import ApplyModal from 'screens/modal/fair-apply';
import { AppUtils, ModelUtils } from 'utils';

const iconTypes = {
  'general': infinityWhite,
  'connect': connectWhite,
  'engage': engageWhite,
  'empower': empowerWhite
}

const { NEWS_CATEGORY_CONNECT, PATH_COMMUNITY } = AppConstants;
const { API_METHOD_GET, API_URL_NEWS, API_URL_FAIR } = ServerConstants;

const NewsViewScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[2];

  const newsFetch = useFetch(API_METHOD_GET, API_URL_NEWS + '/' + id);
  const fairFetch = useFetch(API_METHOD_GET);
  const [applyModalVisible, setApplyModalVisible] = useState(false);
  const [article, setArticle] = useState(undefined);
  const [fair, setFair] = useState(undefined);

  useEffect(() => {
    if (newsFetch.response) {
      const data = ModelUtils.convertArticleModelB2F(newsFetch.response.data);
      if (data.fairId && data.fairId > 0) {
        fairFetch.setFetchUrl(API_URL_FAIR + '/' + data.fairId)
        fairFetch.forceFetchData();
      }
      setArticle(data);
    }
  }, [id, newsFetch.response]);

  useEffect(() => {
    if (fairFetch.response && fairFetch.response.data) {
      setFair(ModelUtils.convertFairModelB2F(fairFetch.response.data));
    }
  }, [fairFetch.response]);

  return (
    <Layout>
      <Container className="my-5">
        {newsFetch.loading || fairFetch.loading ? <Loader /> :
          article ?
            <>
              <ApplyModal fairId={fair && fair.id} onDismiss={() => setApplyModalVisible(false)} visible={applyModalVisible} t={t} />
              <div className='d-table'>
                <div className={`pill-sm ${article.type.toLowerCase()}-background`}>
                  <Image src={iconTypes[article.type.toLowerCase()]} />
                  <span style={{ color: 'var(--white)', fontSize: 16 }}>{t('functions.' + article.type.toLowerCase() + '.title')}</span>
                </div>
              </div>
              <h2>{article.title}</h2>
              <p>
                <i>{AppUtils.convertDateToLocaleDate(article.created, t('format.date.news'))}</i>
                <span className="mx-2">&#x2022;</span>
                <i><a href={`${PATH_COMMUNITY}/${article.organizationId}`}>{article.organizationName}</a></i>
              </p>
              <Image src={article.featuredImage} alt={article.featuredImage} className="box-shadow" style={{ width: '100%', height: 400, objectFit: 'contain' }} />
              {article.intro ? <p className="my-4">{article.intro}</p> : undefined}
              {article.text ? <p className="my-4">{article.text}</p> : undefined}
              {article.type === NEWS_CATEGORY_CONNECT && fair && AppUtils.isFairApplyAllowed(fair) ?
                <div className="text-center my-5">
                  <Button onClick={() => setApplyModalVisible(true)}>{t('news.apply')}</Button>
                </div>
                : undefined}
            </>
            : undefined}
      </Container>
    </Layout>
  )
}

export default NewsViewScreen;
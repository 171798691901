import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step50, step52 } from 'assets/learning';

const EngageLearningScreen50 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-5-1" className='text-engage'>{t('learning.engage.381')}</h4>
      <p>{t('learning.engage.382')}</p>
      <p><b>{t('learning.engage.383')}</b></p>
      <p>{t('learning.engage.384')}</p>
      <p><b>{t('learning.engage.385')}</b></p>
      <p>{t('learning.engage.386.1')}<b>{t('learning.engage.386.2')}</b>{t('learning.engage.386.3')}</p>
      <p><b>{t('learning.engage.387')}</b></p>
      <p>{t('learning.engage.388.1')}<b>{t('learning.engage.388.2')}</b>{t('learning.engage.388.3')}<b>{t('learning.engage.388.4')}</b>{t('learning.engage.388.5')}<b>{t('learning.engage.388.6')}</b>{t('learning.engage.388.7')}</p>
      <p><b>{t('learning.engage.389')}</b></p>
      <p>{t('learning.engage.390.1')}<b>{t('learning.engage.390.2')}</b>{t('learning.engage.390.3')}<b>{t('learning.engage.390.4')}</b>{t('learning.engage.390.5')}</p>
      <p><b>{t('learning.engage.391')}</b></p>
      <p>{t('learning.engage.392')}</p>
      <div className='text-center'>
        <Image src={step52} width={'100%'} />
      </div>
      <br />
      <p>{t('learning.engage.393')}</p>

      <div className='page-break'></div>
      <p><b>{t('learning.engage.394')}</b></p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <b>{t('learning.engage.395')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ul>
      <p><b>{t('learning.engage.396')}</b></p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <b>{t('learning.engage.397')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ul>
      <p><b>{t('learning.engage.398')}</b></p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <b>{t('learning.engage.399')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ul>
      <p><b>{t('learning.engage.400')}</b></p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <b>{t('learning.engage.401')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ul>
      <p><b>{t('learning.engage.402')}</b></p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <b>{t('learning.engage.403')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ul>
      <p><b>{t('learning.engage.404')}</b></p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.405.1')}<a href="https://www.planetcrust.com/what-are-the-steps-of-digital-transformation?utm_campaign=blog" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.405.2')}</a>{t('learning.engage.405.3')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.406.1')}<a href="https://www.youtube.com/watch?v=508CR1fd8ws" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.406.2')}</a>{t('learning.engage.406.3')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.407.1')}<a href="https://www.youtube.com/watch?v=PeD35QUO_Vs" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.407.2')}</a>{t('learning.engage.407.3')}</span>
          </span>
        </li>
      </ul>
      <div className='text-center'>
        <Image src={step50} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen50;
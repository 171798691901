import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect4 } from 'assets/learning';

const ConnectLearningScreen9 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-2-2" className='text-connect'>{t('learning.connect.75')}</h4>
      <p>
        {t('learning.connect.76.1')}
        <b>{t('learning.connect.76.2')}</b>
        {t('learning.connect.76.3')}
        <b>{t('learning.connect.76.4')}</b>
        {t('learning.connect.76.5')}
        <b>{t('learning.connect.76.6')}</b>
        {t('learning.connect.76.7')}
        <b>{t('learning.connect.76.8')}</b>
        {t('learning.connect.76.9')}
        <b>{t('learning.connect.76.10')}</b>
        {t('learning.connect.76.11')}
        <b>{t('learning.connect.76.12')}</b>
        {t('learning.connect.76.13')}
        <b>{t('learning.connect.76.14')}</b>
        {t('learning.connect.76.15')}
      </p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/U4PVThQ7G5Y?si=dugR3hXokUflMYOm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
      <div className='text-center'>
        <Image src={connect4} width={'50%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen9;
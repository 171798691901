import { useCallback, useEffect, useState } from 'react';

import APIKit from 'config/axios/api';
import { ServerConstants } from 'constant';
import { AppUtils } from 'utils';

const requests = {};
const { API_METHOD_DELETE, API_METHOD_GET, API_METHOD_POST } = ServerConstants;
const noTokenRoutes = ['/invitations', '/member/create-account', '/articles', '/organization', '/user', '/community']

const methods = {
  delete: APIKit.delete,
  get: APIKit.get,
  patch: APIKit.patch,
  post: APIKit.post,
  put: APIKit.put,
}
export function useFetch(
  method = API_METHOD_GET,
  url = '',
  params = '',
) {
  const [fetchMethod, setFetchMethod] = useState(method)
  const [fetchUrl, setFetchUrl] = useState(url)
  const [body, setBody] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [refetch, setRefetch] = useState(0);

  useEffect(() => {
    if (!fetchUrl) {
      setLoading(false);
      return;
    }
    if (fetchMethod !== API_METHOD_GET && fetchMethod !== API_METHOD_DELETE) {
      if (!body) {
        setLoading(false);
        return;
      }
    }
    console.log(fetchMethod, fetchUrl, body);
    (
      async function () {
        try {
          setLoading(true);
          if (requests[fetchUrl] && requests[fetchUrl][fetchMethod] && new Date().getTime() - requests[fetchUrl][fetchMethod].date < 1000) {
            if (fetchMethod === API_METHOD_GET || (fetchMethod === API_METHOD_POST && body === requests[fetchUrl][fetchMethod].body)) {
              setLoading(false);
              return;
            }
          }
          if (AppUtils.isUserLogged()) {
            const tokenValidTo = AppUtils.getUserValidToDate();
            if (tokenValidTo && tokenValidTo < new Date().getTime() && noTokenRoutes.filter(el => fetchUrl.startsWith(el)).length === 0) {
              AppUtils.logOut();
              window.location.href = '/?login';
              return;
            }
          }
          let urlWithParams = fetchUrl + (params ? ('?' + params) : '');
          const responseObj = await (methods)[fetchMethod](urlWithParams, body);
          if (responseObj !== response) {
            setResponse(responseObj);
          }
          if (!requests[fetchUrl]) {
            requests[fetchUrl] = {};
          }
          requests[fetchUrl][fetchMethod] = {
            date: new Date().getTime(),
            body: body
          };
        } catch (error) {
          let err = JSON.parse(JSON.stringify(error));
          console.log('[API Error]', err);
          //if (err.status === 401 || err.status === 403 || !err.status) {
            if (err.status === 401 || !err.status) {
            AppUtils.logOut();
            window.location.href = '/';
          }
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    )();
  }, [body, refetch]);

  const forceFetchData = useCallback(() => setRefetch(old => old + 1), [refetch]);
  const clearData = useCallback(() => {
    setBody(undefined);
    setResponse(undefined);
    setError(undefined);
  }, []);

  return { response, error, loading, body, setFetchMethod, setFetchUrl, setBody, forceFetchData, clearData }
}
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower23, empower24, empower25, empower26 } from 'assets/learning';

const EmpowerLearningScreen10 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="mingling">{t('learning.empower.78')}</h2>
      <p>{t('learning.empower.79')}</p>
      <p>{t('learning.empower.80.1')}<a href="https://www.youtube.com/watch?v=dbwYvud_LgQ" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.80.2')}</a>{t('learning.empower.80.3')}</p>
      <p>{t('learning.empower.81')}</p>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower23} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.82')}</b></h5>
          <p>{t('learning.empower.83')}</p>
          <p><span className="text-success">{t('learning.empower.84.1')}</span>: {t('learning.empower.84.2')}</p>
          <p><span className="text-danger">{t('learning.empower.85.1')}</span>: {t('learning.empower.85.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.86')}</b></h5>
          <p>{t('learning.empower.87.1')}<a href="https://www.canva.com/graphs/mind-maps/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.87.2')}</a>, <a href="https://www.mindmeister.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.87.3')}</a>{t('learning.empower.87.4')}<a href="https://www.mindmup.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.87.5')}</a>.</p>
          <p><span className="text-success">{t('learning.empower.88.1')}</span>: {t('learning.empower.88.2')}</p>
          <p><span className="text-danger">{t('learning.empower.89.1')}</span>: {t('learning.empower.89.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower24} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.90')}</b></h5>
          <p>{t('learning.empower.91.1')}<a href="https://miro.com/blog/10-unique-miro-use-cases/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.91.2')}</a>.</p>
          <p><span className="text-success">{t('learning.empower.92.1')}</span>: {t('learning.empower.92.2')}</p>
          <p><span className="text-danger">{t('learning.empower.93.1')}</span>: {t('learning.empower.93.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower25} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.94')}</b></h5>
          <p>{t('learning.empower.95')}</p>
          <p><span className="text-success">{t('learning.empower.96.1')}</span>: {t('learning.empower.96.2')}</p>
          <p><span className="text-danger">{t('learning.empower.97.1')}</span>: {t('learning.empower.97.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower26} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.98')}</b></h5>
          <p>{t('learning.empower.99')}</p>
          <p><span className="text-success">{t('learning.empower.100.1')}</span>: {t('learning.empower.100.2')}.</p>
          <p><span className="text-danger">{t('learning.empower.101.1')}</span>: {t('learning.empower.101.2')}</p>
        </div>
      </div>
    </>
  )

}

export default EmpowerLearningScreen10;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import { FormInput, Layout, Modal } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppUtils } from 'utils';
import { User } from 'models';

const { HEADER_TYPES, PATH_HOME, AUTH_TOKEN, USER_DATA } = AppConstants;
const { API_METHOD_GET, API_METHOD_POST, API_URL_VOLUNTEERING_INVITATION, API_URL_VOLUNTEERING_CREATE, API_URL_USER_LOGIN, API_URL_VOLUNTEER, API_URL_USER_FIND } = ServerConstants;
const modals = {
  CONTEXT: 1,
  PASSWORD: 2,
  SUCCESS: 3,
  ERROR: 4,
}

const VolunteeringRegistration = () => {

  const verificationCode = window.location.pathname.split('/')[3];
  const { t } = useTranslation();

  const invitationAcceptFetch = useFetch(API_METHOD_POST, API_URL_VOLUNTEERING_INVITATION + '/' + verificationCode + '/accept');
  const signUpFetch = useFetch(API_METHOD_POST, API_URL_VOLUNTEERING_CREATE);
  const userFetch = useFetch(API_METHOD_GET);
  const loginFetch = useFetch(API_METHOD_POST, API_URL_USER_LOGIN);
  const volunteerInfoFetch = useFetch(API_METHOD_GET);
  const [volunteer, setVolunteer] = useState(undefined);
  const [step, setStep] = useState(modals.CONTEXT);
  const tempPassword = useRef(undefined);

  useEffect(() => {
    AppUtils.logOut();
  }, []);

  useEffect(() => {
    if (invitationAcceptFetch.response) {
      setVolunteer(invitationAcceptFetch.response.data);
      setStep(modals.PASSWORD);
    } else if (invitationAcceptFetch.error) {
      setStep(modals.ERROR);
    }
  }, [invitationAcceptFetch.response, invitationAcceptFetch.error]);

  useEffect(() => {
    if (signUpFetch.response) {
      setStep(modals.SUCCESS);
    }
  }, [signUpFetch.response]);

  useEffect(() => {
    if (loginFetch.response) {
      const { accessToken } = loginFetch.response.data;
      localStorage.setItem(AUTH_TOKEN, accessToken);
      userFetch.setFetchUrl(API_URL_USER_FIND + "?email=" + volunteer.volunteerEmail);
      userFetch.forceFetchData();
    }
  }, [loginFetch.response])

  useEffect(() => {
    if (userFetch.response && userFetch.response.status === 200) {
      const { id, role } = userFetch.response.data;
      const userModel = new User(id, role, volunteer.volunteerEmail);
      localStorage.setItem(USER_DATA, JSON.stringify(userModel.toJSON()));
      volunteerInfoFetch.setFetchUrl(API_URL_VOLUNTEER + '/' + volunteer.volunteerId);
      volunteerInfoFetch.forceFetchData();
    }
  }, [userFetch.response]);

  useEffect(() => {
    if (volunteerInfoFetch.response) {
      localStorage.setItem(USER_DATA, JSON.stringify({
        ...JSON.parse(localStorage.getItem(USER_DATA)),
        ...volunteerInfoFetch.response.data
      }));
      window.location.href = PATH_HOME;
    }
  }, [volunteerInfoFetch.response])

  const changeStep = useCallback((requestedStep, values) => {
    if (requestedStep === modals.PASSWORD) {
      invitationAcceptFetch.setBody(values);
    } else if (requestedStep === modals.SUCCESS) {
      tempPassword.current = values['password'];
      signUpFetch.setBody({
        'volunteerId': volunteer.volunteerId,
        'password': values['password']
      })
    } else if (requestedStep > modals.SUCCESS) {
      loginFetch.setBody({
        'email': volunteer.volunteerEmail,
        'password': tempPassword.current
      })
    }
  }, [volunteer])

  return (
    <Layout type={HEADER_TYPES.NONE}>
      <Container>
        <Modal show={true}>
          <Container>
            {step === modals.CONTEXT ?
              <Step1 loading={invitationAcceptFetch.loading} changeStep={() => changeStep(modals.PASSWORD, {})} t={t} /> : undefined}
            {step === modals.PASSWORD ?
              <Step2 loading={signUpFetch.loading} volunteer={volunteer} changeStep={(values) => changeStep(modals.SUCCESS, values)} t={t} /> : undefined}
            {step === modals.SUCCESS ?
              <Step3 loading={loginFetch.loading || userFetch.loading || volunteerInfoFetch.loading} changeStep={(values) => changeStep(Object.keys(modals).length + 1, {})} t={t} /> : undefined}
            {step === modals.ERROR ?
              <StepError changeStep={() => window.location.href = PATH_HOME} t={t} /> : undefined}
          </Container>
        </Modal>
      </Container>
    </Layout>
  )
}

const Step1 = ({ loading, changeStep, t }) => (
  <div>
    <h3 className="pt-2">{t('functions.volunteering.invitations.registration.intro.title')}</h3>
    <p className="pt-2">{t('functions.volunteering.invitations.registration.intro.description')}</p>
    <Row className="mt-5">
      <Col className="col-12">
        <Button onClick={changeStep} disabled={loading} className="w-100">{t('functions.volunteering.invitations.registration.intro.action')}</Button>
      </Col>
    </Row>
  </div>
)

const Step2 = ({ loading, volunteer, changeStep, t }) => {

  const { values, onValueChange } = useValues();

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }
    if (values['password'] && values['confirmation'] && (values['password'] !== values['confirmation'])) {
      return;
    }
    changeStep(values);
    return;
  }, [values]);

  return (
    <div>
      <h3 className="pt-2">{t('functions.volunteering.invitations.registration.password.title', { name: volunteer?.volunteerName })}</h3>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <FormInput id="password" required={true} label={t('functions.volunteering.invitations.registration.password.new')} onChange={onValueChange} type="password" />
        <FormInput id="confirmation" required={true} label={t('signup.confirmation')} onChange={onValueChange} type="password" isInvalid={values['password'] && values['password'] !== values['confirmation']} />
        <Row className="mt-5">
          <Col className="col-12">
            <Button type='submit' disabled={loading} className="w-100">{t('functions.volunteering.invitations.registration.password.action')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const Step3 = ({ loading, changeStep, t }) => (
  <div>
    <div className="d-flex flex-direction-row justify-content-center align-items-center pt-2">
      <FontAwesomeIcon icon={'circle-check'} size={'4x'} className="text-primary" />
      <h3 className="mx-3">{t('functions.volunteering.invitations.registration.success.title')}</h3>
    </div>
    <Row className="mt-5">
      <Col className="col-12">
        <Button onClick={changeStep} disabled={loading} className="w-100">{t('functions.volunteering.invitations.registration.success.action')}</Button>
      </Col>
    </Row>
  </div>
)

const StepError = ({ changeStep, t }) => (
  <div>
    <div className="d-flex flex-direction-row justify-content-center align-items-center pt-2">
      <FontAwesomeIcon icon={'circle-xmark'} size={'4x'} className="text-primary" />
      <h3 className="mx-3">{t('functions.volunteering.invitations.registration.error.invalid')}</h3>
    </div>
    <Row className="mt-5">
      <Col className="col-12">
        <Button onClick={changeStep} className="w-100">{t('ok')}</Button>
      </Col>
    </Row>
  </div>
)

export default VolunteeringRegistration;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Empty, Loader, Modal } from 'components';
import { useFetch } from 'hooks/fetch';
import { ServerConstants } from 'constant';
import { ModelUtils } from 'utils';
import InfoContent from './info';
import CalendarContent from './calendar';
import SessionContent from './session';
import DeleteContent from './delete';
import ScheduleContent from './schedule';

const { API_METHOD_GET, API_URL_FAIR, API_URL_CONNECT } = ServerConstants;
const views = {
  INFO: 1,
  CALENDAR: 2,
  SESSION_INFO: 3,
  SESSION_SCHEDULE: 4,
  SESSION_DELETE: 5
}

const OrganizationInfoModal = ({ fair, meetingLink, standNumber, onDismiss, show, updateMeetingLink, loadingUpdateMeetingLink, reload }) => {

  const { t } = useTranslation();
  const organizationFetch = useFetch(API_METHOD_GET);
  const membersFetch = useFetch(API_METHOD_GET);
  const applicationFetch = useFetch(API_METHOD_GET);
  const [showModal, setShowModal] = useState(views.INFO);
  const [members, setMembers] = useState(undefined);
  const [organization, setOrganization] = useState(undefined);
  const [applicationDetails, setApplicationDetails] = useState(undefined);
  const selectedMember = useRef(undefined);
  const session = useRef(undefined);

  useEffect(() => {
    if (show) {
      organizationFetch.setFetchUrl(API_URL_CONNECT + '/' + fair.id + '/' + standNumber + '/organization');
      organizationFetch.forceFetchData();
      membersFetch.setFetchUrl(API_URL_CONNECT + '/' + fair.id + '/' + standNumber + '/members');
      membersFetch.forceFetchData();
    }
    return (() => {
      setTimeout(() => setShowModal(views.INFO), 200);
      setOrganization(undefined);
      setMembers(undefined);
      setApplicationDetails(undefined);
      organizationFetch.setFetchUrl(undefined);
      membersFetch.setFetchUrl(undefined);
      applicationFetch.setFetchUrl(undefined);
    })
  }, [show]);

  useEffect(() => {
    if (organizationFetch.response) {
      const data = organizationFetch.response.data;
      if (data) {
        const converted = ModelUtils.convertOrganizationB2F(data);
        setOrganization(converted);
        applicationFetch.setFetchUrl(API_URL_FAIR + '/' + fair.id + '/' + converted.id + '/application')
        applicationFetch.forceFetchData();
      } else {
        setOrganization({});
      }
    }
  }, [organizationFetch.response]);

  useEffect(() => {
    if (membersFetch.response) {
      const data = membersFetch.response.data;
      setMembers(data ? data.map(el => ModelUtils.convertMemberModelB2F(el)) : []);
    }
  }, [membersFetch.response]);

  useEffect(() => {
    if (applicationFetch.response) {
      setApplicationDetails(applicationFetch.response.data);
    }
  }, [applicationFetch.response]);

  const getModalSize = useCallback(() => {
    if (showModal === views.INFO || showModal === views.CALENDAR) {
      return 'lg'
    }
    return '';
  }, [showModal]);

  const isLoading = () => !organization || organizationFetch.loading || membersFetch.loading || applicationFetch.loading;

  return (
    <>
      <Modal onHide={onDismiss} show={!!show} size={getModalSize()} className='p-0' xClassName={!isLoading() && showModal === views.INFO && organization && Object.keys(organization).length > 0 ? 'light-gray-background' : undefined}>
        {isLoading() ? <div className="m-5"><Loader /></div>
          :
          Object.keys(organization).length > 0 ?
            <>
              <InfoContent
                show={showModal === views.INFO}
                members={members}
                organization={organization}
                applicationDetails={applicationDetails}
                t={t}
                showCalendar={(member) => { selectedMember.current = member; setShowModal(views.CALENDAR); }}
              />
              <CalendarContent
                show={showModal === views.CALENDAR}
                member={selectedMember.current}
                organization={organization}
                meetingLink={meetingLink}
                updateMeetingLink={updateMeetingLink}
                loadingUpdateMeetingLink={loadingUpdateMeetingLink}
                reload={reload}
                t={t}
                fair={fair}
                goBack={() => { selectedMember.current = undefined; setShowModal(views.INFO); }}
                showInfo={(s) => { session.current = s; setShowModal(views.SESSION_INFO); }}
                scheduleSession={(s) => { session.current = s; setShowModal(views.SESSION_SCHEDULE) }}
              />
              <SessionContent
                fair={fair}
                show={showModal === views.SESSION_INFO}
                session={session.current}
                goBack={() => { session.current = undefined; setShowModal(views.CALENDAR); }}
                deleteConfirmation={() => setShowModal(views.SESSION_DELETE)}
                t={t}
              />
              <ScheduleContent
                fair={fair}
                member={selectedMember.current}
                session={session.current}
                show={showModal === views.SESSION_SCHEDULE}
                onSuccess={() => setShowModal(views.CALENDAR)}
                goBack={() => setShowModal(views.CALENDAR)}
                t={t}
              />
              <DeleteContent
                fair={fair}
                session={session.current}
                show={showModal === views.SESSION_DELETE}
                onSuccess={() => { session.current = undefined; setShowModal(views.CALENDAR); }}
                goBack={() => setShowModal(views.SESSION_INFO)}
                t={t}
              />
            </>
            : <div className="text-center"><Empty messageKey={t('functions.connect.fairs.stand.empty')} /></div>
        }
      </Modal>
    </>
  )
}


export default OrganizationInfoModal;
import { volunteeringVolunteerIllustration28, volunteeringVolunteerImage18 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen17 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className='text-volunteering'>{t('learning.volunteering.volunteer.132')}</h4>
      <p>{t('learning.volunteering.volunteer.133')}</p>
      <div className='text-center'>
        <Image src={volunteeringVolunteerIllustration28} width={320} height={220}/>
      </div>
      <p>{t('learning.volunteering.volunteer.134')}</p>
      <br/>
      <Image src={volunteeringVolunteerImage18} width={'100%'} height={400} style={{ objectFit: 'cover'}} />
    </>
  )

}

export default VolunteeringLearningScreen17;
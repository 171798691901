import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step14 } from 'assets/learning';

const EngageLearningScreen6 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-1-4" className='text-engage'>{t('learning.engage.30')}</h4>
      <div className='text-center'>
        <Image src={step14} width={'70%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen6;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Empty, FormInput, FormUpload, Layout, Loader } from 'components';
import { ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';

const { API_METHOD_GET, API_METHOD_PUT, API_URL_VOLUNTEERING_OPPORTUNITY } = ServerConstants;

const ProfileOpportunitiesEditScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[3];
  const getFetch = useFetch(API_METHOD_GET, API_URL_VOLUNTEERING_OPPORTUNITY + '/' + id);
  const submitFetch = useFetch(API_METHOD_PUT, API_URL_VOLUNTEERING_OPPORTUNITY + '/' + id);
  const { values, onValueChange, setValue, addValues } = useValues({});

  useEffect(() => {
    if (getFetch.response) {
      addValues(ModelUtils.convertOpportunityModelB2F(getFetch.response.data))
    }
  }, [getFetch.response])

  useEffect(() => {
    if (submitFetch.response) {
      window.history.back();
    }
  }, [submitFetch.response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    submitFetch.setBody(ModelUtils.convertOpportunityModelF2B(values));
  }, [values]);

  const isEditable = useCallback(() => {
    if (!values) {
      return false;
    }
    const loggedUser = AppUtils.getLoggedUser();
    if (!AppUtils.isUserOrganization()) {
      return false;
    }
    return values.active && loggedUser.id === values.organizationId;
  }, [values]);

  return (
    <Layout>
      <Container className="my-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <h2>{t('profile.opportunity.edit')}</h2>
          </div>
        </div>
        {getFetch.loading ? <Loader /> :
          !isEditable() ?
            <div className='text-center'><Empty messageKey={t('opportunity.message.rights')} /></div>
          :
          <Form onSubmit={(e) => handleSubmit(e)}>
            <FormInput id="title" required={true} label={t('opportunity.name')} onChange={onValueChange} type="text" defaultValue={values["title"]} className="w-50" />
            <FormUpload id="featuredImage" required={true} label={t('opportunity.image')} onValueChange={onValueChange} removeImage={() => setValue('featuredImage', undefined)} value={values['featuredImage']} inputClassName={'w-50'} />
            <FormInput id="text" required={true} label={t('opportunity.description')} onChange={onValueChange} type="text" as="textarea" rows={10} defaultValue={values["text"]} />
            <FormInput id="formUrl" required={true} label={t('opportunity.formUrl')} onChange={onValueChange} type="text" defaultValue={values["formUrl"]} className="w-50" />
            <div className="text-center">
              <Button className="mr-2 px-5" variant="secondary" onClick={() => window.history.back()} disabled={submitFetch.loading}>{t('cancel')}</Button>
              <Button className="ml-2 px-5" type="submit" disabled={submitFetch.loading}>{t('save')}</Button>
            </div>
          </Form>
        }
      </Container>
    </Layout>
  )
}

export default ProfileOpportunitiesEditScreen;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step463 } from 'assets/learning';

const EngageLearningScreen42 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-6-1" className='text-engage'>{t('learning.engage.214')}</h4>
      <p>{t('learning.engage.215')}</p>
      <div className='text-center'>
        <Image src={step463} width={'50%'} height={'315'} style={{ objectFit: 'contain' }} />
      </div>

      <div className='page-break'></div>
      <div className='d-flex justify-content-center'>
        <table className='table three-columns' style={{ width: '75%' }}>
          <colgroup>
            <col></col>
            <col className='w-50'></col>
            <col></col>
          </colgroup>
          <tbody>
            <tr>
              <td>{t('learning.engage.216')}</td>
              <td>
                {t('learning.engage.217')}<br />
                {t('learning.engage.218')}<br />
                {t('learning.engage.219')}<br />
                {t('learning.engage.220')}<br />
                {t('learning.engage.221')}
              </td>
              <td>{t('learning.engage.222')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.223')}</td>
              <td>
                {t('learning.engage.224')}<br />
                {t('learning.engage.225')}<br />
                {t('learning.engage.226')}<br />
                {t('learning.engage.227')}
              </td>
              <td>{t('learning.engage.228')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.229')}</td>
              <td>
                {t('learning.engage.230')}<br />
                {t('learning.engage.231')}<br />
                {t('learning.engage.232')}<br />
                {t('learning.engage.233')}
              </td>
              <td>{t('learning.engage.234')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.235')}</td>
              <td>
                {t('learning.engage.236')}<br />
                {t('learning.engage.237')}<br />
                {t('learning.engage.238')}
              </td>
              <td>{t('learning.engage.239')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.240')}</td>
              <td>
                {t('learning.engage.241')}<br />
                {t('learning.engage.242')}
              </td>
              <td>{t('learning.engage.243')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.244')}</td>
              <td>
                {t('learning.engage.245')}<br />
                {t('learning.engage.246')}<br />
                {t('learning.engage.247')}
              </td>
              <td>{t('learning.engage.248')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.249')}</td>
              <td>
                {t('learning.engage.250')}<br />
                {t('learning.engage.251')}<br />
                {t('learning.engage.252')}
              </td>
              <td>{t('learning.engage.253')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.254')}</td>
              <td>
                {t('learning.engage.255')}<br />
                {t('learning.engage.256')}<br />
                {t('learning.engage.257')}
              </td>
              <td>{t('learning.engage.258')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )

}

export default EngageLearningScreen42;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInput, Layout } from 'components';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';

import { paperPlane } from 'assets/icons';
import pot from 'assets/pot.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFetch } from 'hooks/fetch';
import { ServerConstants } from 'constant';
import ContactEmail from 'screens/modal/contact-email';

const { API_METHOD_POST, API_URL_EMAIL_POT } = ServerConstants;
const valuesModel = {
  name: undefined,
  organizationName: undefined,
  contactMail: undefined,
  message: undefined,
}

const EmpowerPoTScreen = () => {

  const { t } = useTranslation();
  const emailFetch = useFetch(API_METHOD_POST, API_URL_EMAIL_POT);
  const [values, setValues] = useState(valuesModel);
  const [emailModalVisible, setEmailModalVisible] = useState(false);

  useEffect(() => {
    if (!emailFetch.response) {
      return;
    }
    if (emailFetch.response.status === 200) {
      setValues(valuesModel);
      setEmailModalVisible(true);
    }
    emailFetch.clearData();
  } ,[emailFetch]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    emailFetch.setBody(values);
  }, [values]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  }, [values]);

  const isLoading = useCallback(() => emailFetch.loading, [emailFetch.loading])
  const closeModal = useCallback(() => {
    setEmailModalVisible(false);
    setValues(valuesModel);
  }, []);

  return (
    <Layout>
      <ContactEmail onDismiss={closeModal} t={t} visible={emailModalVisible} />
      <Container className="my-5">
        <h2 className='mb-4 text-center'>{t('learning.pot.title')}</h2>
        <p className='my-4 text-center'>{t('learning.pot.content.1')}</p>
        <p className='my-4 text-center'>{t('learning.pot.content.2')}</p>

        <h4 className='mt-5 text-center'>{t('learning.pot.content.3')}</h4>
        <p className='my-4 text-center'>{t('learning.pot.content.4')}</p>

        <div className='text-center'>
          <Button href={pot} className='text-center' target='_blank' rel='noopener noreferrer'>
            <FontAwesomeIcon icon={'file-pdf'} className='pr-2' />
            {t('learning.pot.content.5')}
          </Button>
        </div>

        <h4 className='mt-5 text-center'>{t('learning.pot.content.6')}</h4>
        <p className='my-4 text-center'>{t('learning.pot.content.7')}</p>
        <div>
          <Row>
            <Col xs={0} sm={0} md={1} lg={3}  />
            <Col xs={12} sm={12} md={10} lg={6} >
              <div className="contact-card pot">
                <div className="contact-card-header">
                  <h3>{t('learning.pot.content.8')}</h3>
                  <Image src={paperPlane} />
                </div>
                <div className="contact-card-body">
                  <Form onSubmit={handleSubmit}>
                    <Row>
                      <Col className='col-6'>
                        <FormInput
                          id="name"
                          label={t('learning.pot.content.9')}
                          required={true}
                          onChange={onFormChange}
                        />
                        <FormInput
                          id="organizationName"
                          label={t('learning.pot.content.10')}
                          required={true}
                          onChange={onFormChange}
                        />
                        <FormInput
                          id="contactMail"
                          label={t('learning.pot.content.11')}
                          type="email"
                          required={true}
                          onChange={onFormChange}
                        />
                      </Col>
                      <Col className='col-6'>
                        <FormInput
                          id="message"
                          label={t('learning.pot.content.12')}
                          as="textarea"
                          rows={8}
                          required={true}
                          onChange={onFormChange}
                        />
                      </Col>
                    </Row>
                    <div className="mt-2 text-center">
                      <Button type='submit' disabled={isLoading()}>{t('contact.submit')}</Button>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={0} md={1} lg={3}  />
          </Row>
      </div>
      </Container>
    </Layout>
  )
}

export default EmpowerPoTScreen;
const app = {
  AUTH_TOKEN: "jwt_token",
  AUTH_TOKEN_TO_DATE: "jwt_token_to_date",
  USER_DATA: "user_data",
  DEFAULT_LANG: "lang",

  USER_ROLE_ADMIN: 'PlatformAdmin',
  USER_ROLE_ORGANIZATION: 'Organization',
  USER_ROLE_MEMBER: 'Member',
  USER_ROLE_VOLUNTEER: 'Volunteer',

  FAIRS_STATUS_PUBLISHED: 'Published',
  FAIRS_STATUS_DRAFTED: 'Drafted',

  NEWS_STATUS_PUBLISHED: 'Published',
  NEWS_STATUS_SUBMITTED: 'Submitted',
  NEWS_STATUS_APPROVED: 'Approved',
  NEWS_STATUS_REJECTED: 'Rejected',

  NEWS_CATEGORY_GENERAL: 'General',
  NEWS_CATEGORY_CONNECT: 'Connect',
  NEWS_CATEGORY_EMPOWER: 'Empower',
  NEWS_CATEGORY_ENGAGE: 'Engage',

  ORGANIZATION_STATUS_APPROVED: 'Approved',
  ORGANIZATION_STATUS_REJECTED: 'Rejected',
  ORGANIZATION_STATUS_PENDING: 'Pending',

  PATH_HOME: '/',
  PATH_ABOUT: '/about',
  PATH_ACTIVATION: '/invitation',
  PATH_EVENT: '/event',
  PATH_VOLUNTEERING: '/functions/volunteering',
  PATH_VOLUNTEERING_ACTIVATION: '/volunteering/invitation',

  PATH_VOLUNTEERING_SPACE: '/functions/volunteering/space',
  PATH_VOLUNTEERING_LEARNING_ORGANIZATION: '/functions/volunteering/learning/organization',
  PATH_VOLUNTEERING_LEARNING_VOLUNTEER: '/functions/volunteering/learning/volunteer',
  PATH_OPPORTUNITIES: '/opportunities',
  PATH_COMMUNITY: '/community',
  PATH_FORUM: '/forum',
  PATH_CONNECT: '/functions/connect',
  PATH_CONNECT_FAIR: '/functions/connect/fair',
  PATH_CONNECT_LEARNING: '/functions/connect/learning',
  PATH_CONTACT: '/contact',
  PATH_EMPOWER: '/functions/empower',
  PATH_EMPOWER_LOBBY: '/functions/empower/lobby',
  PATH_EMPOWER_CONFERENCE: '/functions/empower/conference',
  PATH_EMPOWER_LEARNING: '/functions/empower/learning',
  PATH_EMPOWER_POT: '/pot',
  PATH_ENGAGE: '/functions/engage',
  PATH_ENGAGE_OFFICE: '/functions/engage/office',
  PATH_ENGAGE_LEARNING: '/functions/engage/learning',
  PATH_FAQ: '/faq',
  PATH_NEWS: '/news',
  PATH_PROFILE: '/profile',
  PATH_PROFILE_DELETION: '/profile/deletion',
  PATH_PROFILE_NEWS_NEW: '/profile/news/new',
  PATH_PROFILE_OPPORTUNITY_NEW: '/profile/opportunity/new',
  PATH_PROFILE_OPPORTUNITY: '/profile/opportunity',
  PATH_PRIVACY_AND_POLICY: '/privacy',

  PATH_ADMIN_COMMUNITY: '/admin/community',
  PATH_ADMIN_NEWS: '/admin/news',
  PATH_ADMIN_FAIRS: '/admin/fairs',

  EVENT_STATUS: { "STARTING": 1, "STARTED": 2, "ENDED": 3 },
  EMPOWER_EVENT_TYPES: ['ROOM_1', 'ROOM_2', 'ROOM_3', 'ROOM_4', 'ROOM_5'],
  HEADER_TYPES: { "DEFAULT": 0, "ADMIN": 1, "ENGAGE": 2, "EMPOWER": 3, "CONNECT": 4, "VOLUNTEER": 5, "FORUM": 6, "NONE": 7 },

  FAIR_APPLICATION_DEADLINE: 1 * 24 * 60 * 60 * 1000, // 1 days
  FAIR_PARTICIPANTS_MAX: 46
}

export default app;
import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';

import { AppUtils } from 'utils';
import { profilePicture } from 'assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const InfoContent = ({ show, members, organization, applicationDetails, showCalendar, t }) => {
  if (!show) {
    return <></>;
  }
  return (
    <div>
      <div className="light-gray-background p-4">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="d-flex flex-direction-row align-items-center">
              <Image src={organization?.profilePicture ? organization.profilePicture : profilePicture} width={104} height={104} style={{ borderRadius: 104, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
              <div className="mx-4">
                <h3><b>{organization.name}</b></h3>
                {organization.website ?
                  <p>
                    <FontAwesomeIcon icon={'globe'} className="text-primary" />
                    <a href={AppUtils.renderWebAddress(organization.website)} target='_blank'>{organization.website}</a>
                  </p>
                  : undefined
                }
              </div>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              {organization.instagram ? <a href={AppUtils.renderWebAddress(organization.instagram)} target="_blank" className="social-icon light-gray-background ml-3"><FontAwesomeIcon icon={['fab', 'instagram']} size={'lg'} color='black' /></a> : undefined}
              {organization.linkedIn ? <a href={AppUtils.renderWebAddress(organization.linkedIn)} target="_blank" className="social-icon light-gray-background ml-3"><FontAwesomeIcon icon={['fab', 'linkedin-in']} size={'lg'} color='black' /></a> : undefined}
              {organization.facebook ? <a href={AppUtils.renderWebAddress(organization.facebook)} target="_blank" className="social-icon light-gray-background ml-3"><FontAwesomeIcon icon={['fab', 'facebook-f']} size={'lg'} color='black' /></a> : undefined}
              {organization.twitter ? <a href={AppUtils.renderWebAddress(organization.twitter)} target="_blank" className="social-icon light-gray-background ml-3"><FontAwesomeIcon icon={['fab', 'twitter']} size={'lg'} color='black' /></a> : undefined}
              {organization.youtube ? <a href={AppUtils.renderWebAddress(organization.youtube)} target="_blank" className="social-icon light-gray-background ml-3"><FontAwesomeIcon icon={['fab', 'youtube']} size={'lg'} color='black' /></a> : undefined}
            </div>
          </div>
        </div>
      </div>
      <Container className="p-4">
        <div>
          <Row>
            <Col className="col-6">
              <p>{applicationDetails?.message}</p>
            </Col>
            <Col className="col-6 light-gray-background p-4">
              <h5 className="mb-3">{t('fairs.members.title')}</h5>
              <hr className="my-1" />
              {members && members.map(el => (
                <div className="d-flex align-items-center my-3">
                  <Image src={el.profilePicture ?? profilePicture} height={56} width={56} style={{ borderRadius: 56, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
                  <p className="m-0 pl-2">
                    <small className="pl-1">{el.position}</small><br />
                    {AppUtils.isCalendarViewAllowed(el, organization) ?
                      <a href="/#" onClick={(e) => { e.preventDefault(); showCalendar(el) }}><span style={{ fontWeight: 700 }}>{el.fullName}{AppUtils.isMemberLoggedUser(el, organization) && (" (" + t('you') + ")")}</span></a>
                      :
                      <span className="pl-1" style={{ fontWeight: 700 }}>{el.fullName}</span>
                    }
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default InfoContent;
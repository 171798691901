import { useCallback, useState } from 'react';

export function useValues(
  defaultValues = {}
) {
  const [values, setValues] = useState(defaultValues);

  const onValueChange = useCallback((e) => {
    const name = e.target.id;
    let value = e.target.value;
    if (e.target.type === 'file') {
      var reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        value = reader.result;
        setValues(old => ({ ...old, [name]: value }));
      };
      reader.onerror = function (error) { console.log('Error: ', error) };
    } else {
      setValues(old => ({ ...old, [name]: value }));
    }
  }, []);

  const setValue = useCallback((name, value) => {
    setValues(old => ({ ...old, [name]: value }));
  }, [])

  const addValues = useCallback((v) => {
    setValues(old => ({ ...old, ...v }));
  }, [])

  const resetValues = useCallback(() => {
    setValues({});
  }, []);


  return { addValues, values, onValueChange, setValue, resetValues }
}
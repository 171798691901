import React, { useCallback, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';

import { ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import { Modal } from 'components';

const { API_METHOD_POST, API_URL_ORGANIZATION_RESOLVE } = ServerConstants;

const OrganizationApproveModal = ({ el, onDismiss, visible }) => {

  const { t } = useTranslation();
  const { loading, response, setBody } = useFetch(API_METHOD_POST, API_URL_ORGANIZATION_RESOLVE);

  useEffect(() => {
    if (!response) {
      return;
    }
    window.location.reload();
  }, [response]);

  const sendRequest = useCallback(() => {
    setBody({
      "organizationId": el.organizationId,
      "resolution": "Approve"
    })
  }, [el])

  return (
    <Modal onHide={!loading ? onDismiss : undefined} show={visible}>
      <p className="mt-4 mb-5 text-center"><Trans i18nKey={"organizations.status.change.question.approve"} /></p>
      <Row>
        <Col className="col-6">
          <Button variant='secondary' className="w-100" onClick={onDismiss}>{t('no')}</Button>
        </Col>
        <Col className="col-6">
          <Button onClick={sendRequest} className='w-100'>{t('yes')}</Button>
        </Col>
      </Row>
    </Modal>
  )
}

export default OrganizationApproveModal;
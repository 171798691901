import { volunteeringVolunteerImage19 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen17 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className='text-volunteering'>{t('learning.volunteering.volunteer.135')}</h4>
      <ol>
        <li>
          {t('learning.volunteering.volunteer.136')}<br/>
          <a href="https://vcla.net/a-short-history-of-volunteering/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://vcla.net/a-short-history-of-volunteering/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.137')}<br/>
          <a href="https://www.iave.org/iavewp/wp-content/uploads/2015/06/The-Universal-Declaration-on-Volunteering.pdf" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.iave.org/iavewp/wp-content/uploads/2015/06/The-Universal-Declaration-on-Volunteering.pdf/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.138')}<br/>
          <a href="https://www.connect-international.org/2023/11/01/results-of-the-e-volunteering-research/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.connect-international.org/2023/11/01/results-of-the-e-volunteering-research/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.139')}<br/>
          <a href="https://digital-strategy.ec.europa.eu/en/library/european-declaration-digital-rights-and-principles" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://digital-strategy.ec.europa.eu/en/library/european-declaration-digital-rights-and-principles</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.140')}<br/>
          <a href="https://op.europa.eu/en/publication-detail/-/publication/297a33c8-a1f3-11e9-9d01-01aa75ed71a1/language-en" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://op.europa.eu/en/publication-detail/-/publication/297a33c8-a1f3-11e9-9d01-01aa75ed71a1/language-en</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.141')}<br/>
          <a href="https://www.youtube.com/watch?v=jbV1TDZQAFc" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=jbV1TDZQAFc</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.142')}<br/>
          <a href="https://www.goodthingsfoundation.org/our-work/areas-of-work/digital-inclusion/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.goodthingsfoundation.org/our-work/areas-of-work/digital-inclusion/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.143')}<br/>
          <a href="https://www.duolingo.com/mission" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.duolingo.com/mission</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.144')}<br/>
          <a href="https://www.greenpeace.org/usa/act/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.greenpeace.org/usa/act/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.145')}<br/>
          <a href="https://op.europa.eu/en/publication-detail/-/publication/297a33c8-a1f3-11e9-9d01-01aa75ed71a1/language-en" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://op.europa.eu/en/publication-detail/-/publication/297a33c8-a1f3-11e9-9d01-01aa75ed71a1/language-en</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.146')}<br/>
          <a href="https://joint-research-centre.ec.europa.eu/digcomp_en" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://joint-research-centre.ec.europa.eu/digcomp_en</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.147')}<br/>
          <a href="https://europa.eu/europass/digitalskills/screen/home?referrer=epass&route=%2Fsl" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://europa.eu/europass/digitalskills/screen/home?referrer=epass&route=%2Fsl</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.148')}<br/>
          <a href="https://test.ikanos.eus/index.php/70" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://test.ikanos.eus/index.php/70</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.149')}<br/>
          <a href="https://digital-competence.eu/dc/en/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://digital-competence.eu/dc/en/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.151')}<br/>
          <a href="http://mentep.eun.org/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">http://mentep.eun.org/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.152')}<br/>
          <a href="https://www.ted.com/talks/jane_mcgonigal_gaming_can_make_a_better_world?subtitle=en&trigger=0s" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.ted.com/talks/jane_mcgonigal_gaming_can_make_a_better_world?subtitle=en&trigger=0s</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.153')}<br/>
          <a href="https://digital-youth.org/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://digital-youth.org/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.154')}<br/>
          <a href="https://turningdigital.net/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://turningdigital.net/</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.155')}<br/>
          <a href="https://www.youtube.com/watch?v=iONDebHX9qk" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=iONDebHX9qk</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.156')}<br/>
          <a href="https://www.youtube.com/watch?v=1-SvuFIQjK8" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=1-SvuFIQjK8</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.157')}<br/>
          <a href="https://www.youtube.com/watch?v=geRKHFzTxNY" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=geRKHFzTxNY</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.158')}<br/>
          <a href="https://www.youtube.com/watch?v=mNBmG24djoY" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=mNBmG24djoY</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.159')}<br/>
          <a href="https://www.youtube.com/watch?v=QGHBq5OEsBM" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=QGHBq5OEsBM</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.160')}<br/>
          <a href="https://www.youtube.com/watch?v=vh2q-vR2WQY" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=vh2q-vR2WQY</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.161')}<br/>
          <a href="https://www.youtube.com/watch?v=ERhuMm9lFgw" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=ERhuMm9lFgw</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.162')}<br/>
          <a href="https://www.leadershipiq.com/blogs/leadershipiq/team-player-quiz-what-type-of-team-player-are-you" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.leadershipiq.com/blogs/leadershipiq/team-player-quiz-what-type-of-team-player-are-you</a>
        </li>
        <li>
          {t('learning.volunteering.volunteer.163')}<br/>
          <a href="https://www.youtube.com/watch?v=n0uwTBrgqxI" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=n0uwTBrgqxI</a>
        </li>
      </ol>
      <Image src={volunteeringVolunteerImage19} width={'100%'} height={400} style={{ objectFit: 'cover'}} />
    </>
  )

}

export default VolunteeringLearningScreen17;
import React, { useCallback, useEffect, useState } from 'react';
import APIKit from 'config/axios/api';
import { Loader, Modal } from 'components';
import { ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import HotspotModalForm from 'screens/functions/hotspot/form';
import hotspotTypes from '../../../../../assets/hotspots.js';
import HotspotModalView from 'screens/functions/hotspot/preview.js';

const { API_URL_CONNECT } = ServerConstants;

const ConnectHotspotModal = ({ fair, standNumber, onDismiss, el, show, myOrganization }) => {

  const [hotspot, setHotspot] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(undefined);

  useEffect(() => {
    setHotspot(el);
    if (show) {
      setLoading(true);
      APIKit.get(API_URL_CONNECT + '/' + fair.id + '/' + standNumber + '/hotspots/' + el.type).then(res => {
        if (res.data) {
          setHotspot(ModelUtils.convertHotspotModelB2F(res.data));
        } else {
          if (editModeAllowed()) {
            setEditMode(true);
          }
        }
        setLoading(false);
      }).catch(e => console.log(e))
    }
  }, [show]);

  const onCancel = useCallback(() => {
    onDismiss();
    setTimeout(() => setEditMode(false), 500);
  }, []);

  const editModeAllowed = useCallback(() => myOrganization && AppUtils.isFairHotspotChangeAllowed(fair), [fair])

  return (
    <Modal onHide={onCancel} show={!!show} size={'lg'}>
      {!hotspot || loading ? <div className="m-5"><Loader /></div>
        :
        editMode ?
          <HotspotModalForm el={hotspot} onCancel={onCancel} urlPrefix={API_URL_CONNECT + '/' + fair.id + '/' + standNumber + '/hotspots'} type={true} defaultValue={hotspotTypes.Connect} editModelAllowed={editModeAllowed()} />
          :
          !editMode ?
            <HotspotModalView
              el={hotspot}
              toEditMode={editModeAllowed() ? (e) => { e.preventDefault(); setEditMode(true); } : undefined}
              defaultValue={hotspotTypes.Connect}
              functionType={'CONNECT'}
            />
            : undefined
      }
    </Modal >
  )
}

export default ConnectHotspotModal;
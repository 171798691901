import React, { useCallback, useEffect } from 'react';
import { Button, Container, Form, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Typeahead } from 'react-bootstrap-typeahead';

import { FormInput, FormUpload, Layout } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { empower, engage, infinity } from 'assets/icons';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';

const { PATH_PROFILE } = AppConstants;
const { API_METHOD_POST, API_URL_NEWS_NEW } = ServerConstants;

const categories = [
  { icon: engage, key: "Engage" },
  { icon: empower, key: "Empower" },
  { icon: infinity, key: "General" },
]

const ProfileNewsNewScreen = () => {

  const { t } = useTranslation();
  const submitFetch = useFetch(API_METHOD_POST, API_URL_NEWS_NEW);
  const { values, onValueChange, setValue } = useValues({});

  useEffect(() => {
    setValue('organizationId', AppUtils.getLoggedUser().id);
  }, []);

  useEffect(() => {
    if (submitFetch.response) {
      window.location.href = PATH_PROFILE;
    }
  }, [submitFetch.response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    submitFetch.setBody(ModelUtils.convertArticleModelF2B(values));
  }, [values]);

  return (
    <Layout>
      <Container className="my-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <h2>{t('profile.news.new')}</h2>
          </div>
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormInput id="title" required={true} label={t('news.name')} onChange={onValueChange} type="text" defaultValue={values["title"]} className="w-50" />
          <Form.Group className="mb-3 w-50">
            <Form.Label htmlFor={'category'}>{t('news.category.title')}</Form.Label>
            <Typeahead
              maxResults={categories.length}
              options={categories}
              emptyLabel={t('news.category.empty')}
              iconName={'chevron-down'}
              id={'category'}
              label={t('news.category.label')}
              labelKey={option => `${option.key}`}
              t={t}
              renderMenuItemChildren={(item) => (
                <div key={item.key}>
                  <Image src={item.icon} width={28} height={28} />
                  <span className="pl-4">{t('functions.' + item.key.toLowerCase() + '.title')}</span>
                </div>
              )}
              inputProps={{ required: true, defaultValue: values['category'] }}
              onChange={(selected) => onValueChange({ target: { id: 'category', value: selected && selected.length > 0 ? selected[0].key : undefined } })}
              selected={values['category'] && values['category'].length > 0 ? [categories.find(el => el.key === values['category'])] : []}
            />
          </Form.Group>
          <FormUpload id="featuredImage" required={true} label={t('news.image')} onValueChange={onValueChange} removeImage={() => setValue('featuredImage', undefined)} value={values['featuredImage']} inputClassName={'w-50'} />
          <FormInput id="intro" required={true} label={t('news.intro')} onChange={onValueChange} type="text" as="textarea" rows={3} defaultValue={values["intro"]} />
          <FormInput id="description" required={true} label={t('news.description')} onChange={onValueChange} type="text" as="textarea" rows={10} defaultValue={values["description"]} />
          <div className="text-center">
            <Button className="mr-2 px-5" variant="secondary" onClick={() => window.location.href = PATH_PROFILE} disabled={submitFetch.loading}>{t('cancel')}</Button>
            <Button className="ml-2 px-5" type="submit" disabled={submitFetch.loading}>{t('publish')}</Button>
          </div>
        </Form>
      </Container>
    </Layout>
  )
}

export default ProfileNewsNewScreen;
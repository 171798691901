export default class HotspotItem {
  private __id: number;
  private __label: string;
  private __link: string;
  private __description: string;
  private __tempId: number;

  constructor(
    id: number,
    label: string,
    link: string,
    description: string
  ) {
    this.__id = id;
    this.__label = label
    this.__link = link;
    this.__description = description;
  }

  public get id() { return this.__id; }
  public get label() { return this.__label; }
  public get link() { return this.__link; }
  public get description() { return this.__description; }
  public get tempId() { return this.__tempId; }
  public set tempId(val: number) { this.__tempId = val }

  public toJSON() {
    return {
      id: this.__id,
      label: this.__label,
      link: this.__link,
      description: this.__description,
      tempId: this.__tempId
    }
  }

}
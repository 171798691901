export default class Opportunity {
  private __id: number;
  private __featuredImage: string;
  private __title: string;
  private __text: string;
  private __datePublished: string;
  private __organizationId: number;
  private __organizationName: string;
  private __active: boolean;
  private __formUrl: string;

  constructor(
    id: number,
    featuredImage: string,
    title: string,
    text: string,
    datePublished: string,
    organizationId: number,
    organizationName: string,
    active: boolean,
    formUrl: string,

  ) {
    this.__id = id;
    this.__featuredImage = featuredImage;
    this.__title = title;
    this.__text = text;
    this.__datePublished = datePublished;
    this.__organizationId = organizationId;
    this.__organizationName = organizationName;
    this.__active = active;
    this.__formUrl = formUrl;
  }

  public get id() { return this.__id; }
  public get featuredImage() { return this.__featuredImage; }
  public get title() { return this.__title; }
  public get text() { return this.__text; }
  public get datePublished() { return this.__datePublished; }
  public get organizationId() { return this.__organizationId; }
  public get organizationName() { return this.__organizationName; }
  public get active() { return this.__active; }
  public get formUrl() { return this.__formUrl; }

  public toJSON() {
    return {
      id: this.__id,
      featuredImage: this.__featuredImage,
      title: this.__title,
      text: this.__text,
      datePublished: this.__datePublished,
      organizationId: this.__organizationId,
      organizationName: this.__organizationName,
      active: this.__active,
      formUrl: this.__formUrl
    }
  }

}
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step47 } from 'assets/learning';

const EngageLearningScreen44 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-7" className='text-engage'>{t('learning.engage.277')}</h4>
      <p>{t('learning.engage.278.1')}<b>{t('learning.engage.278.2')}</b>{t('learning.engage.278.3')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/kPNiSpX4L_g?si=UYsHQguBIX7tBqTq" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
      <br />
      <div className='text-center'>
        <Image src={step47} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen44;
export default class Member {
  private __id: number;
  private __profilePicture: string;
  private __email: string;
  private __position: string;
  private __fullName: string;
  private __organizationId: number;
  private __accountType: string;

  constructor(
    id: number,
    profilePicture: string,
    email: string,
    position: string,
    fullName: string,
    organizationId: number,
    accountType: string,
  ) {
    this.__id = id;
    this.__profilePicture = profilePicture;
    this.__email = email;
    this.__position = position;
    this.__fullName = fullName;
    this.__organizationId = organizationId;
    this.__accountType = accountType;
  }

  public get id() { return this.__id; }
  public get profilePicture() { return this.__profilePicture; }
  public get email() { return this.__email; }
  public get position() { return this.__position; }
  public get fullName() { return this.__fullName; }
  public get organizationId() { return this.__organizationId; }
  public get accountType() { return this.__accountType; }

  public toJSON() {
    return {
      id: this.__id,
      profilePicture: this.__profilePicture,
      email: this.__email,
      position: this.__position,
      fullName: this.__fullName,
      organizationId: this.__organizationId,
      accountType: this.__accountType
    }
  }

}
import { volunteeringVolunteerImage3 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-1-2" className='text-volunteering'>{t('learning.volunteering.volunteer.16')}</h4>
      <div className='d-flex flex-direction-row pb-3'>
        <div className='pl-4 pt-3'>
          <p>{t('learning.volunteering.volunteer.17')}</p>
          <p>
            <b>{t('learning.volunteering.volunteer.18.1')}</b>
            {t('learning.volunteering.volunteer.18.2')}
            <b>{t('learning.volunteering.volunteer.18.3')}</b>
          </p>
        </div>
      </div>
      <p>
        <b>{t('learning.volunteering.volunteer.19.1')}</b>
        {t('learning.volunteering.volunteer.19.2')}
      </p>
      <p>{t('learning.volunteering.volunteer.20')}</p>
      <Image src={volunteeringVolunteerImage3} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen5;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { volunteeringOrganization1 } from 'assets/learning';

const VolunteeringLearningScreen1 = () => {
  const { t } = useTranslation();

  return (
    <div className='p-5 pt-2'>
      <div className='text-center mb-5'>
        <Image src={volunteeringOrganization1} width={'75%'} />
      </div>
        <div className='text-center mt-4'>
          <h1 className='text-volunteering'>{t('learning.volunteering.volunteer.1')}</h1>
          <h2>{t('learning.volunteering.volunteer.2')}</h2>
        </div>
    </div>
  )

}

export default VolunteeringLearningScreen1;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Layout, Loader } from 'components';
import { CountriesData } from 'data';
import { Container, Image } from 'react-bootstrap';
import { useFetch } from 'hooks/fetch';
import { ServerConstants } from 'constant';
import { ModelUtils } from 'utils';
import { profilePicture } from 'assets/images';

const { API_METHOD_GET, API_URL_ORGANIZATION } = ServerConstants;

const CommunityViewScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[2];
  const { loading, response } = useFetch(API_METHOD_GET, API_URL_ORGANIZATION + '/' + id);
  const [organization, setOrganization] = useState(undefined);

  useEffect(() => {
    if (response) {
      setOrganization(ModelUtils.convertOrganizationB2F(response.data));
    }
  }, [id, response]);

  return (
    <Layout>
      <Container className="my-5">
        {loading ? <Loader /> :
          organization ?
            <>
              <div className="d-flex flex-direction-row align-items-center pb-4">
                <Image src={organization?.profilePicture ? organization.profilePicture : profilePicture} width={104} height={104} style={{ borderRadius: 104, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
                <div className="mx-2"></div>
                <div>
                  <h6>{t('organizations.name')}</h6>
                  <h2>{organization.name}</h2>
                </div>
              </div>
              {organization.fields && organization.fields.length > 0 ?
                <p><b>{organization.fields.map((el, i) => (i > 0 ? ', ' : '') + el)}</b></p>
                : undefined}
              <div className="d-flex flex-direction-row align-items-center mt-4">
                {organization.email ?
                  <div className="info">
                    <label>{t('organizations.email')}</label>
                    <span>{organization.email}</span>
                  </div>
                  : undefined}
                {organization.contactEmail ?
                  <div className="info">
                    <label>{t('organizations.contact')}</label>
                    <span>{organization.contactEmail}</span>
                  </div>
                  : undefined}
                {organization.country ?
                  <div className="info">
                    <label>{t('organizations.country')}</label>
                    <span>{CountriesData[organization.country]}</span>
                  </div>
                  : undefined}
                {organization.piC_OID ?
                  <div className="info">
                    <label>{t('organizations.pic')}</label>
                    <span>{organization.piC_OID}</span>
                  </div>
                  : undefined}
              </div>
              {organization.alternativeReason ?
                <div className="mt-4">
                  <label>{t('organizations.reason')}</label>
                  <p>{organization.alternativeReason}</p>
                </div>
                : undefined}
              {organization.about ?
                <div className="mt-4">
                  <label>{t('organizations.about')}</label>
                  <p>{organization.about}</p>
                </div>
                : undefined}
            </>
            : undefined}
      </Container>
    </Layout>
  )
}

export default CommunityViewScreen;
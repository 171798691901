import React, { useCallback, useEffect } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { useFetch } from 'hooks/fetch';
import { ServerConstants } from 'constant';
import { profilePicture } from 'assets/images';
import { AppUtils } from 'utils';

const { API_METHOD_POST, API_URL_CONNECT } = ServerConstants;

const ScheduleContent = ({ fair, session, show, member, goBack, onSuccess, t }) => {

  const scheduleFetch = useFetch(API_METHOD_POST)

  useEffect(() => {
    if (scheduleFetch.response) {
      onSuccess();
    }
  }, [scheduleFetch.response])

  const schedule = useCallback(() => {
    scheduleFetch.setFetchUrl(API_URL_CONNECT + '/' + fair.id + '/calendar/session')
    scheduleFetch.setBody({
      startTime: session.startTime,
      endTime: session.endTime,
      accountTypeTo: member.accountType,
      userIdTo: member.id
    });
  }, [session]);

  if (!show) {
    return <></>;
  }

  return (
    <div className="px-3 pb-3">
      <div className='my-2'>
        <h6>{t('functions.connect.session.action.schedule.confirmation')}</h6>
        <div className="d-flex flex-direction-row align-items-center mt-2">
          <Image src={member?.profilePicture ? member.profilePicture : profilePicture} width={64} height={64} style={{ borderRadius: 64, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
          <div className="mx-4">
            <h5 className="mb-0 pt-3"><b>{member.fullName}</b></h5>
            <p>{member.position}</p>
          </div>
        </div>
        <h6 className="my-2">
          <Trans i18nKey={'functions.connect.session.action.schedule.time'} values={{
            start: AppUtils.convertDateToLocaleDate(session.startTime, t("format.time")).toString(),
            end: AppUtils.convertDateToLocaleDate(session.endTime, t("format.time")).toString(),
            date: AppUtils.convertDateToLocaleDate(session.startTime, t("format.date.fair")).toString()
          }} />
        </h6>
        <Row className="mt-4 mb-2">
          <Col className="col-6 mb-0">
            <Button variant='secondary' className="w-100" disabled={scheduleFetch.loading} onClick={goBack}>{t('cancel')}</Button>
          </Col>
          <Col className="col-6 mb-0">
            <Button className="w-100" disabled={scheduleFetch.loading} onClick={schedule}>{t('functions.connect.session.action.schedule.submit')}</Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ScheduleContent;
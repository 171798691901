import EngageLearningScreen1 from "./1";
import EngageLearningScreen2 from "./2";
import EngageLearningScreen3 from "./3";
import EngageLearningScreen4 from "./4";
import EngageLearningScreen5 from "./5";
import EngageLearningScreen6 from "./6";
import EngageLearningScreen7 from "./7";
import EngageLearningScreen8 from "./8";
import EngageLearningScreen9 from "./9";
import EngageLearningScreen10 from "./10";
import EngageLearningScreen11 from "./11";
import EngageLearningScreen12 from "./12";
import EngageLearningScreen13 from "./13";
import EngageLearningScreen14 from "./14";
import EngageLearningScreen15 from "./15";
import EngageLearningScreen16 from "./16";
import EngageLearningScreen17 from "./17";
import EngageLearningScreen18 from "./18";
import EngageLearningScreen19 from "./19";
import EngageLearningScreen20 from "./20";
import EngageLearningScreen21 from "./21";
import EngageLearningScreen22 from "./22";
import EngageLearningScreen23 from "./23";
import EngageLearningScreen24 from "./24";
import EngageLearningScreen25 from "./25";
import EngageLearningScreen26 from "./26";
import EngageLearningScreen27 from "./27";
import EngageLearningScreen28 from "./28";
import EngageLearningScreen29 from "./29";
import EngageLearningScreen30 from "./30";
import EngageLearningScreen31 from "./31";
import EngageLearningScreen32 from "./32";
import EngageLearningScreen33 from "./33";
import EngageLearningScreen34 from "./34";
import EngageLearningScreen35 from "./35";
import EngageLearningScreen36 from "./36";
import EngageLearningScreen37 from "./37";
import EngageLearningScreen38 from "./38";
import EngageLearningScreen39 from "./39";
import EngageLearningScreen40 from "./40";
import EngageLearningScreen41 from "./41";
import EngageLearningScreen42 from "./42";
import EngageLearningScreen43 from "./43";
import EngageLearningScreen44 from "./44";
import EngageLearningScreen45 from "./45";
import EngageLearningScreen46 from "./46";
import EngageLearningScreen47 from "./47";
import EngageLearningScreen48 from "./48";
import EngageLearningScreen49 from "./49";
import EngageLearningScreen50 from "./50";
import EngageLearningScreen51 from "./51";
import EngageLearningScreen52 from "./52";
import EngageLearningScreen53 from "./53";
import EngageLearningScreen54 from "./54";

const screens = {
  page1: <EngageLearningScreen1 />,
  page2: <EngageLearningScreen2 />,
  page3: <EngageLearningScreen3 />,
  page4: <EngageLearningScreen4 />,
  page5: <EngageLearningScreen5 />,
  page6: <EngageLearningScreen6 />,
  page7: <EngageLearningScreen7 />,
  page8: <EngageLearningScreen8 />,
  page9: <EngageLearningScreen9 />,
  page10: <EngageLearningScreen10 />,
  page11: <EngageLearningScreen11 />,
  page12: <EngageLearningScreen12 />,
  page13: <EngageLearningScreen13 />,
  page14: <EngageLearningScreen14 />,
  page15: <EngageLearningScreen15 />,
  page16: <EngageLearningScreen16 />,
  page17: <EngageLearningScreen17 />,
  page18: <EngageLearningScreen18 />,
  page19: <EngageLearningScreen19 />,
  page20: <EngageLearningScreen20 />,
  page21: <EngageLearningScreen21 />,
  page22: <EngageLearningScreen22 />,
  page23: <EngageLearningScreen23 />,
  page24: <EngageLearningScreen24 />,
  page25: <EngageLearningScreen25 />,
  page26: <EngageLearningScreen26 />,
  page27: <EngageLearningScreen27 />,
  page28: <EngageLearningScreen28 />,
  page29: <EngageLearningScreen29 />,
  page30: <EngageLearningScreen30 />,
  page31: <EngageLearningScreen31 />,
  page32: <EngageLearningScreen32 />,
  page33: <EngageLearningScreen33 />,
  page34: <EngageLearningScreen34 />,
  page35: <EngageLearningScreen35 />,
  page36: <EngageLearningScreen36 />,
  page37: <EngageLearningScreen37 />,
  page38: <EngageLearningScreen38 />,
  page39: <EngageLearningScreen39 />,
  page40: <EngageLearningScreen40 />,
  page41: <EngageLearningScreen41 />,
  page42: <EngageLearningScreen42 />,
  page43: <EngageLearningScreen43 />,
  page44: <EngageLearningScreen44 />,
  page45: <EngageLearningScreen45 />,
  page46: <EngageLearningScreen46 />,
  page47: <EngageLearningScreen47 />,
  page48: <EngageLearningScreen48 />,
  page49: <EngageLearningScreen49 />,
  page50: <EngageLearningScreen50 />,
  page51: <EngageLearningScreen51 />,
  page52: <EngageLearningScreen52 />,
  page53: <EngageLearningScreen53 />,
  page54: <EngageLearningScreen54 />,
}

export default screens;
import React, { useEffect, useState } from 'react'

import { Empty, Layout, Loader } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import hotspotTypes from '../../../../../assets/hotspots.js';
import EmpowerHotspotModal from './hotspot.js';
import { useFetch } from 'hooks/fetch.js';
import { ModelUtils } from 'utils/index.js';

const { HEADER_TYPES } = AppConstants;
const { API_METHOD_GET, API_URL_EVENT } = ServerConstants;

const baseSrc = '/vista/empower/index.htm?skip-loading';

const EmpowerVistaConferenceViewScreen = ({ eId }) => {

  const eventId = eId ?? window.location.pathname.split('/')[4]
  const { response, loading } = useFetch(API_METHOD_GET, API_URL_EVENT + '/' + eventId);
  const [event, setEvent] = useState(undefined);
  const [src, setSrc] = useState(undefined);
  const [finished, setFinished] = useState(false);
  const [showModal, setShowModal] = useState(undefined);

  useEffect(() => {
    if (response && response.data) {
      setEvent(ModelUtils.convertEventModelB2F(response.data));
    }
  }, [response]);

  useEffect(() => {
    if (event) {
      setSrc(baseSrc + '#media-name=Conference_' + event.type)
    }
  }, [event]);

  useEffect(() => {
    if (!finished) {
      return;
    }
    window.frames['vista'].triggerAction = (e, type) => setShowModal({ ...hotspotTypes.Empower[type], type: type });
  }, [finished]);

  return (
    <Layout type={HEADER_TYPES.EMPOWER}>
      <div>
        {loading ? <div className="m-5"><Loader color="#fff" /></div> :
          !event || !src ? <div><Empty /></div>
            :
            <>
              <EmpowerHotspotModal event={event} el={showModal} onDismiss={() => setShowModal(undefined)} show={showModal} />
              <iframe
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                src={src}
                id='vista'
                name="vista"
                title="vista"
                width="100%"
                height="100%"
                allowFullScreen={false}
                onLoad={() => setFinished(true)}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                sandbox="allow-scripts allow-same-origin">
              </iframe>
            </>
        }
      </div>
    </Layout>
  )
}

export default EmpowerVistaConferenceViewScreen;
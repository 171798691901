import React from 'react';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppConstants } from 'constant';
import { connectColored } from 'assets/icons';

const { PATH_CONNECT } = AppConstants;

const ConnectHeader = ({ title, additional }) => {

  const { t } = useTranslation();

  return (
    <Navbar expand="md" className="header-vista" sticky="top" >
      <Nav>
        <Nav.Link href={PATH_CONNECT}>
          <FontAwesomeIcon icon={'circle-arrow-left'} size='lg' />
          <span className="mx-2">{t('functions.connect.header.vista.back')}</span>
        </Nav.Link>
      </Nav>
      <Navbar.Brand>
        <div className="d-flex flex-row align-items-center">
          <Image src={connectColored} width={32} height={32} />
          <h3 className='mb-0 mx-3'>{title}</h3>
        </div>
      </Navbar.Brand>
      {additional && (
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          {additional}
        </Navbar.Collapse>
      )}
    </Navbar>
  )
}

export default ConnectHeader;
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const Page404Screen = () => {

  const { t } = useTranslation();

  return (
    <div className="main">
      <div className="content vista">
        <Container>
          <div className='center' style={{ minWidth: 300, maxWidth: 500, width: 'auto', left: '45%' }}>
            <h1 className="text-center">{t('error.403.title')}</h1>
            <h4 className="text-center">{t('error.403.description')}</h4>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Page404Screen;
import React from 'react';
import { Form } from 'react-bootstrap';

const FormSelect = (props) => (
  <Form.Group className="mb-3">
    {props.label ? <Form.Label htmlFor={props.id}>{props.label}</Form.Label> : undefined}
    <Form.Select {...props}>
      {props.children}
    </Form.Select>
    {props.text ? <Form.Text>{props.text}</Form.Text> : undefined}
  </Form.Group>
)

export default FormSelect;
export default class Event {
  private __id: number;
  private __name: string;
  private __duration: number;
  private __date: string;
  private __startTime: number;
  private __endTime: number;
  private __link: string;
  private __type: string;

  constructor(
    id: number,
    name: string,
    startTime: number,
    endTime: number,
    link: string,
    type: string,
  ) {
    this.__id = id;
    this.__name = name;
    this.__startTime = startTime;
    this.__endTime = endTime;
    this.__link = link;
    this.__type = type;
  }

  public get id() { return this.__id; }
  public get name() { return this.__name; }
  public get startTime() { return this.__startTime; }
  public get endTime() { return this.__endTime; }
  public get link() { return this.__link; }
  public get type() { return this.__type; }

  public toJSON() {
    return {
      id: this.__id,
      name: this.__name,
      startTime: this.__startTime,
      endTime: this.__endTime,
      link: this.__link,
      type: this.__type,
    }
  }

}
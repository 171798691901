import EmpowerLearningScreen1 from "./1";
import EmpowerLearningScreen2 from "./2";
import EmpowerLearningScreen3 from "./3";
import EmpowerLearningScreen4 from "./4";
import EmpowerLearningScreen5 from "./5";
import EmpowerLearningScreen6 from "./6";
import EmpowerLearningScreen7 from "./7";
import EmpowerLearningScreen8 from "./8";
import EmpowerLearningScreen9 from "./9";
import EmpowerLearningScreen10 from "./10";
import EmpowerLearningScreen11 from "./11";
import EmpowerLearningScreen12 from "./12";
import EmpowerLearningScreen13 from "./13";
import EmpowerLearningScreen14 from "./14";
import EmpowerLearningScreen15 from "./15";
import EmpowerLearningScreen16 from "./16";

const screens = {
  page1: <EmpowerLearningScreen1 />,
  page2: <EmpowerLearningScreen2 />,
  page3: <EmpowerLearningScreen3 />,
  page4: <EmpowerLearningScreen4 />,
  page5: <EmpowerLearningScreen5 />,
  page6: <EmpowerLearningScreen6 />,
  page7: <EmpowerLearningScreen7 />,
  page8: <EmpowerLearningScreen8 />,
  page9: <EmpowerLearningScreen9 />,
  page10: <EmpowerLearningScreen10 />,
  page11: <EmpowerLearningScreen11 />,
  page12: <EmpowerLearningScreen12 />,
  page13: <EmpowerLearningScreen13 />,
  page14: <EmpowerLearningScreen14 />,
  page15: <EmpowerLearningScreen15 />,
  page16: <EmpowerLearningScreen16 />,
}

export default screens;
import React, { useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { AppConstants } from 'constant/index.js';
import ConnectLearningScreens from './index.js';
import Layout from 'components/layout.js';
import Page404Screen from 'screens/404';
import { Modal } from 'components/index.js';
import { connectWhite } from 'assets/icons/index.js';

const { PATH_CONNECT_LEARNING } = AppConstants;

const ConnectLearningScreen = () => {
  const { t } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const page = Number.parseInt(params.get('page') ?? 1);
  const element = ConnectLearningScreens['page' + page];

  const [showContent, setShowContent] = useState(false);

  if (!element) {
    return <Page404Screen />;
  }
  return (
    <Layout>
      {page !== 1 &&
        (
          <div className='connect-background pt-3 pb-3'>
            <div className='d-flex flex-direction-row align-items-center justify-content-center'>
              <Image src={connectWhite} width={24} height={24} className="text-white" />
              <h4 className='pl-2 pb-0 mb-0 text-white'>{t('learning.connect.header.1')}</h4>
            </div>
            <p className='pb-0 mb-0 text-white text-center'>{t('learning.connect.header.2')}</p>
          </div>
        )}
      <ContentModal visible={showContent} onHide={() => setShowContent(false)} t={t} />
      <div className='d-flex flex-direction-column justify-content-between p-5 pt-0 h-100'>
        <Container className='page'>
          {element}
        </Container>
        <div className='page d-flex flex-direction-row align-items-center justify-content-center mb-5'>
          <Button variant='outline-connect' disabled={page === 1} style={{ opacity: (page === 1) ? 0 : 1 }} onClick={() => window.location.href = PATH_CONNECT_LEARNING + '?page=' + (page - 1)}>
            <div className='d-flex flex-direction-row align-items-center'>
              <FontAwesomeIcon icon={'arrow-left'} className='text-connect' />
              <span>&nbsp;{t('previous')}</span>
            </div>
          </Button>
          <div className='px-5'>
            <Button variant='link' className='text-connect' onClick={() => setShowContent(true)}>
              {page} / {Object.keys(ConnectLearningScreens).length}
            </Button>
          </div>
          <Button variant='outline-connect' disabled={page === Object.keys(ConnectLearningScreens).length} style={{ opacity: (page === Object.keys(ConnectLearningScreens).length) ? 0 : 1 }} onClick={() => window.location.href = PATH_CONNECT_LEARNING + '?page=' + (page + 1)}>
            <div className='d-flex flex-direction-row align-items-center'>
              <span>{t('next')}&nbsp;</span>
              <FontAwesomeIcon icon={'arrow-right'} className='text-connect' />
            </div>
          </Button>
        </div>
      </div>
    </Layout >
  )
}

const ContentModal = ({ visible, onHide, t }) => {
  return (
    <Modal show={visible} onHide={onHide} className={'p-0'}>
      <h4 className={'px-4'}>{t('learning.connect.menu.1')}</h4>
      <ul style={{ listStyle: 'none' }} className='px-4'>
        <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=3'}>{t('learning.connect.menu.2')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=3'}>{t('learning.connect.menu.3')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=4'}>{t('learning.connect.menu.4')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=5'}>{t('learning.connect.menu.5')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=6'}>{t('learning.connect.menu.6')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=7'}>{t('learning.connect.menu.7')}</a></li>
        </ul>
        <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=8'}>{t('learning.connect.menu.8')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=9'}>{t('learning.connect.menu.9')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=10'}>{t('learning.connect.menu.10')}</a></li>
        </ul>
        <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=11'}>{t('learning.connect.menu.11')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=11'}>{t('learning.connect.menu.12')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=12'}>{t('learning.connect.menu.13')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=13'}>{t('learning.connect.menu.14')}</a></li>
        </ul>
        <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=14'}>{t('learning.connect.menu.15')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=14'}>{t('learning.connect.menu.16')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=15'}>{t('learning.connect.menu.17')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=16'}>{t('learning.connect.menu.18')}</a></li>
        </ul>
        <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=17'}>{t('learning.connect.menu.19')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=17'}>{t('learning.connect.menu.20')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=18'}>{t('learning.connect.menu.21')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=18'}>{t('learning.connect.menu.22')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=18'}>{t('learning.connect.menu.23')}</a></li>
          <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=18'}>{t('learning.connect.menu.24')}</a></li>
        </ul>
        <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=19'}>{t('learning.connect.menu.25')}</a></li>
        <li><a className='text-connect' href={PATH_CONNECT_LEARNING + '?page=20'}>{t('learning.connect.menu.26')}</a></li>
      </ul>
    </Modal>
  )
}

export default ConnectLearningScreen;
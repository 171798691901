import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { cola, connect9 } from 'assets/learning';

const ConnectLearningScreen14 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="chapter-4">{t('learning.connect.110')}</h2>
      <h4 id="chapter-4-1" className='text-connect'>{t('learning.connect.111')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.connect.112')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={connect9} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
      </div>
      <h6 id="chapter-4-1-1">{t('learning.connect.113')}</h6>
      <p>{t('learning.connect.114')}</p>
      <p><i>{t('learning.connect.115')}</i></p>
      <h6 id="chapter-4-1-2">{t('learning.connect.116')}</h6>
      <p>{t('learning.connect.117')}</p>
      <p><i>{t('learning.connect.118')}</i></p>
      <div className='text-center pt-3 pb-5'>
        <Image src={cola} height={100} style={{ objectFit: 'contain' }} />
      </div>
      <h6 id="chapter-4-1-3">{t('learning.connect.119')}</h6>
      <p>{t('learning.connect.120')}</p>
      <p><i>{t('learning.connect.121')}</i></p>
      <h6 id="chapter-4-1-4">{t('learning.connect.122')}</h6>
      <p>{t('learning.connect.123')}</p>
      <p><i>{t('learning.connect.124')}</i></p>
      <h6 id="chapter-4-1-5">{t('learning.connect.125')}</h6>
      <p>{t('learning.connect.126')}</p>
      <p><i>{t('learning.connect.127')}</i></p>
      <h6 id="chapter-4-1-6">{t('learning.connect.128')}</h6>
      <p>{t('learning.connect.129')}</p>
      <p><i>{t('learning.connect.130')}</i></p>
      <br />
      <p>{t('learning.connect.131.1')}<a href="https://www.jigsawplanet.com/?rc=play&pid=091a59e81d59" target='_blank' rel='nooppener noreferrer'>{t('learning.connect.131.2')}</a>{t('learning.connect.131.3')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/TwnPgOd9FE0?si=l722MEjR_wue2p7f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
    </>
  )
}

export default ConnectLearningScreen14;
import React from 'react';
import Countdown from "react-countdown";

const CountdownAfterTimer = ({ countdownTo, renderer }) => {

  if (!countdownTo) {
    return <></>
  }

  return (
    <Countdown
      now={() => new Date().getTime()}
      date={countdownTo}
      renderer={renderer}
      zeroPadTime={2}
      overtime={true}
    />
  )
}

export default CountdownAfterTimer;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower34 } from 'assets/learning';

const EmpowerLearningScreen14 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="agenda">{t('learning.empower.134')}</h2>
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower34} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <p>{t('learning.empower.135')}</p>
        </div>
      </div>
      <p><b>{t('learning.empower.136')}</b></p>
      <ul>
        <li>{t('learning.empower.137')}<a href="https://miro.com/templates/agenda/" target='_blank' rel='nooppener noreferrer'>https://miro.com/templates/agenda/</a></li>
        <li>{t('learning.empower.138')}<a href="https://slidemodel.com/templates/tag/agenda/" target='_blank' rel='nooppener noreferrer'>https://slidemodel.com/templates/tag/agenda/</a></li>
        <li>{t('learning.empower.139')}<a href="https://onlineboard.eu/landing" target='_blank' rel='nooppener noreferrer'>https://onlineboard.eu/landing</a></li>
      </ul>
    </>
  )

}

export default EmpowerLearningScreen14;
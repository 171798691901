import axios from "axios";
import { AppConstants } from "constant";

const { AUTH_TOKEN } = AppConstants;

const APIKit = axios.create({
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': localStorage.getItem(AUTH_TOKEN) ? ('Bearer ' + localStorage.getItem(AUTH_TOKEN)) : undefined
  },
  baseURL: 'https://webapidy.azurewebsites.net',
});

export default APIKit;
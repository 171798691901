import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FormInput, Modal } from "components";
import { useFetch } from "hooks/fetch";
import { ServerConstants } from "constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppUtils } from "utils";

const {
  API_METHOD_POST,
  API_URL_USER_PASSWORD_REQUEST_CHANGE, API_URL_USER_PASSWORD_CHANGE_VERIFY_CODE, API_URL_USER_PASSWORD_NEW
} = ServerConstants;

const propertiesPerPage = [
  ["email"],
  ["passwordChangeVerificationCode"],
  ["newPassword", "confirmation"],
]

const ForgetMyPasswordModal = ({ email, onDismiss, show, t }) => {

  const requestCodeFetch = useFetch(API_METHOD_POST, API_URL_USER_PASSWORD_REQUEST_CHANGE);
  const verifyCodeFetch = useFetch(API_METHOD_POST, API_URL_USER_PASSWORD_CHANGE_VERIFY_CODE);
  const setNewFetch = useFetch(API_METHOD_POST, API_URL_USER_PASSWORD_NEW);
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const tempUserData = useRef({});

  useEffect(() => {
    console.log(email, show);
    if (email && show) {
      const loggedUser = AppUtils.getLoggedUser();
      tempUserData.current = loggedUser;
      requestCodeFetch.setBody({
        "role": tempUserData.current.role,
        "id": tempUserData.current.id
      })
    }
    return (() => {
      tempUserData.current = {};
      setValues({});
      setStep(1);
    })
  }, [show]);

  useEffect(() => {
    if (verifyCodeFetch.response) {
      setStep(2);
    }
  }, [verifyCodeFetch.response]);

  useEffect(() => {
    if (setNewFetch.response) {
      setStep(3);
    }
  }, [setNewFetch.response]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  }, [values]);

  const handleSubmit = useCallback((event, goToStep) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    if (goToStep === 2) {
      verifyCodeFetch.setBody({
        "role": tempUserData.current.role,
        "id": tempUserData.current.id,
        "passwordChangeVerificationCode": values['passwordChangeVerificationCode']
      });
    }
    if (goToStep - 1 === 2) {
      if (values['newPassword'] && values['confirmation'] && (values['newPassword'] !== values['confirmation'])) {
        return;
      }
      setNewFetch.setBody({
        "role": tempUserData.current.role,
        "id": tempUserData.current.id,
        "newPassword": values['newPassword']
      });
    }
    return;
  }, [values]);

  const goBack = useCallback((goto) => {
    const newValues = values;
    const resetValues = propertiesPerPage[goto].map(el => el);
    resetValues.forEach(el => {
      newValues[el] = undefined;
    })
    setValues(newValues);
    setStep(goto);
  }, [values]);

  const onDismissWrapper = useCallback(() => {
    onDismiss();
    setTimeout(() => {
      setValues({});
      setStep(1);
    }, 500)
  }, [onDismiss]);

  return (
    <Modal onHide={onDismissWrapper} show={show}>
      <Container>
        {step === 1 &&
          <Step1
            loading={verifyCodeFetch.loading}
            onDismiss={onDismissWrapper}
            handleSubmit={handleSubmit}
            onFormChange={onFormChange}
            t={t}
          />
        }
        {step === 2 &&
          <Step2
            loading={setNewFetch.loading}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormChange={onFormChange}
            values={values}
            t={t}
          />
        }
        {step === 3 &&
          <Step3
            onDismiss={onDismissWrapper}
            t={t}
          />
        }
      </Container>
    </Modal>
  )
}

const Step1 = ({ loading, onDismiss, handleSubmit, onFormChange, t }) => (
  <Form onSubmit={(e) => handleSubmit(e, 2)}>
    <p className="pt-2">{t('login.forget.verification.description')}</p>
    <FormInput id="passwordChangeVerificationCode" required={true} label={t('login.forget.verification.code')} onChange={onFormChange} />
    <Row className="mt-5">
      <Col className="col-6">
        <Button onClick={onDismiss} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
      </Col>
      <Col className="col-6">
        <Button type="submit" className='w-100' disabled={loading}>{t('next')}</Button>
      </Col>
    </Row>
  </Form>
)

const Step2 = ({ handleSubmit, goBack, onFormChange, values, loading, t }) => (
  <Form onSubmit={(e) => handleSubmit(e, 3)}>
    <FormInput id="newPassword" required={true} label={t('login.forget.password.new')} onChange={onFormChange} type="password" />
    <FormInput id="confirmation" required={true} label={t('login.forget.password.confirmation')} onChange={onFormChange} type="password" isInvalid={values['newPassword'] && values['newPassword'] !== values['confirmation']} />
    <Row className="mt-5">
      <Col className="col-6">
        <Button onClick={() => goBack(1)} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
      </Col>
      <Col className="col-6">
        <Button type="submit" className='w-100' disabled={loading}>{t('login.forget.password.change')}</Button>
      </Col>
    </Row>
  </Form>
)

const Step3 = ({ onDismiss, t }) => (
  <div className="my-3">
    <div className="d-flex flex-direction-row justify-content-center align-items-center mb-3">
      <FontAwesomeIcon icon={'circle-check'} size={'4x'} className="text-primary" />
      <h3 className="mx-3">{t('login.forget.completion')}</h3>
    </div>
    <Button type="submit" className='w-100 mt-3' onClick={onDismiss}>{t('fairs.action.hurray')}</Button>
  </div>
)

export default ForgetMyPasswordModal;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower33 } from 'assets/learning';

const EmpowerLearningScreen13 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="breakout-rooms">{t('learning.empower.130')}</h2>
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower33} width={'80%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <p>{t('learning.empower.131')}</p>
        </div>
      </div>
      <p>{t('learning.empower.132.1')}<a href="https://www.youtube.com/watch?v=lS4os-COuac" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.132.2')}</a>.</p>
      <p>{t('learning.empower.133.1')}<b>{t('learning.empower.133.2')}</b>{t('learning.empower.133.3')}<a href="https://www.crowdpurr.com/trivia" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.133.4')}</a>{t('learning.empower.133.5')}</p>
    </>
  )

}

export default EmpowerLearningScreen13;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower15, empower16, empower17, empower18 } from 'assets/learning';

const EmpowerLearningScreen6 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="live-sessions">{t('learning.empower.38')}</h2>
      <p>{t('learning.empower.39')}</p>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower15} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.40')}</b></h5>
          <p>{t('learning.empower.41')}</p>
          <p><span className="text-success">{t('learning.empower.42.1')}</span>: {t('learning.empower.42.2')}</p>
          <p><span className="text-danger">{t('learning.empower.43.1')}</span>: {t('learning.empower.43.2')}</p>
          <p>{t('learning.empower.44.1')}<a href="https://www.youtube.com/watch?v=QOUwumKCW7M" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.44.2')}</a></p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower16} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.45')}</b></h5>
          <p>{t('learning.empower.46')}</p>
          <p><span className="text-success">{t('learning.empower.47.1')}</span>: {t('learning.empower.47.2')}</p>
          <p><span className="text-danger">{t('learning.empower.48.1')}</span>: {t('learning.empower.48.2')}</p>
          <p>{t('learning.empower.49.1')}<a href="https://www.youtube.com/watch?v=A2FahEEJ13A" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.49.2')}</a></p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower17} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.50')}</b></h5>
          <p>{t('learning.empower.51')}</p>
          <p><span className="text-success">{t('learning.empower.52.1')}</span>: {t('learning.empower.52.2')}</p>
          <p><span className="text-danger">{t('learning.empower.53.1')}</span>: {t('learning.empower.53.2')}</p>
          <p>{t('learning.empower.54.1')}<a href="https://www.youtube.com/watch?v=nPmdafMo1b8" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.54.2')}</a></p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower18} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.55')}</b></h5>
          <p>{t('learning.empower.56')}</p>
          <p><span className="text-success">{t('learning.empower.57.1')}</span>: {t('learning.empower.57.2')}</p>
          <p><span className="text-danger">{t('learning.empower.58.1')}</span>: {t('learning.empower.58.2')}</p>
          <p>{t('learning.empower.59.1')}<a href="https://www.youtube.com/watch?v=c8KhKBLoSMk" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.59.2')}</a></p>
        </div>
      </div>
    </>
  )

}

export default EmpowerLearningScreen6;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormInput, Loader, Modal } from 'components';
import { ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';
import { AppUtils } from 'utils';

const { API_METHOD_GET, API_METHOD_POST, API_URL_CONNECT } = ServerConstants;
const views = {
  LINK: 1,
  SKIP: 2,
  LOGO: 3,
}

const ConnectBeforeEnterModal = ({ el, show, onDismiss, onSubmit, forceView = undefined }) => {

  const { t } = useTranslation();
  const linkFetch = useFetch(API_METHOD_GET);
  const submitLinkFetch = useFetch(API_METHOD_POST, API_URL_CONNECT + '/' + el.id + '/calendar/link');
  const { values, onValueChange } = useValues();
  const [view, setView] = useState(undefined);

  useEffect(() => {
    if (show) {
      if (forceView) {
        setView(forceView);
      } else {
        linkFetch.setFetchUrl(API_URL_CONNECT + '/' + el.id + '/calendar/link');
        linkFetch.forceFetchData();
      }
    }
  }, [show]);

  useEffect(() => {
    if (linkFetch.response) {
      if (linkFetch.response.data && linkFetch.response.data.meetingLink !== '') {
        const loggedUser = AppUtils.getLoggedUser();
        if (loggedUser.profilePicture && loggedUser.profilePicture !== '') {
          onSubmit(linkFetch.response.data.meetingLink);
        } else {
          setView(views.LOGO);
        }
      } else {
        setView(views.LINK);
      }
    }
  }, [linkFetch.response]);

  useEffect(() => {
    if (submitLinkFetch.response) {
      if (submitLinkFetch.response.data && submitLinkFetch.response.data.meetingLink !== '') {
        onSubmit(submitLinkFetch.response.data.meetingLink);
      }
    }
  }, [submitLinkFetch.response]);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }
    submitLinkFetch.setBody(values);
  }, [values]);

  const isLoading = useCallback(() => linkFetch.loading || submitLinkFetch.loading, [linkFetch.loading, submitLinkFetch.loading]);
  const onDismissWrapper = useCallback(() => {
    setTimeout(() => setView(undefined), 500);
    onDismiss();
  }, []);

  return (
    <Modal onHide={onDismissWrapper} show={show}>
      <Container>
        {view === undefined ? <div className="text-center"><Loader /></div> : undefined}
        {view === views.LINK ?
          <>
            <h3>{t('functions.connect.calendar.link.title')}</h3>
            <p>{t('functions.connect.calendar.link.description')}</p>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <FormInput id="meetingLink" required={true} label={t('functions.connect.calendar.link.event')} onChange={onValueChange} type="text" defaultValue={values['meetingLink']} />
              <Row className="mt-4">
                {AppUtils.isFairLive(el) || forceView ?
                  <Col className="col-12">
                    <Button type="submit" disabled={isLoading() || !values['meetingLink']} className='w-100'>{t('continue')}</Button>
                  </Col>
                  :
                  <>
                    <Col className="col-6">
                      <Button variant='secondary' disabled={isLoading()} className="w-100" onClick={() => setView(views.SKIP)}>{t('skip')}</Button>
                    </Col>
                    <Col className="col-6">
                      <Button type="submit" disabled={isLoading()} className="w-100">{t('continue')}</Button>
                    </Col>
                  </>
                }
              </Row>
            </Form>
          </>
          : undefined}
        {view === views.SKIP ?
          <>
            <p className="mt-3">{t('functions.connect.calendar.link.skip')}</p>
            <Row className="mt-4">
              <Col className="col-6">
                <Button variant='secondary' className="w-100" onClick={() => setView(views.LINK)}>{t('back')}</Button>
              </Col>
              <Col className="col-6">
                <Button onClick={onSubmit} className="w-100">{t('continue')}</Button>
              </Col>
            </Row>
          </>
          : undefined}
        {view === views.LOGO ?
          <>
            <p className="mt-3">{t('functions.connect.calendar.picture')}</p>
            <Row className="mt-4">
              <Col className="col-12">
                <Button variant='secondary' className="w-100" onClick={() => setView(views.LINK)}>{t('ok')}</Button>
              </Col>
            </Row>
          </>
          : undefined}
      </Container>
    </Modal>
  )
}

export default ConnectBeforeEnterModal;
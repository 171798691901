/* eslint-disable react-hooks/exhaustive-deps */
import { Countdown } from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppUtils } from 'utils';

const CountdownTimer = ({ session, onFinish }) => {

  const { t } = useTranslation();
  const [countdownTo, setCountdownTo] = useState(undefined);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (!session) {
      return;
    }
    const toDate = new Date(AppUtils.convertDateToLocaleDate(session.startTime)).getTime();
    setCountdownTo(toDate);
  }, [session]);

  useEffect(() => {
    if (finished && onFinish) {
      onFinish();
    }
  }, [finished])

  const renderer = (props) => {
    let { days, hours, minutes, seconds, completed } = props;
    let display = '';
    if (hours > 0) {
      display += days * 24 + hours;
    }
    display += (display.length > 0 ? ':' : '');
    display += (minutes.toString().length === 1 ? '0' : '') + minutes
    display += ':'
    display += (seconds.toString().length === 1 ? '0' : '') + seconds
    if (finished !== completed) {
      setFinished(completed);
    }
    if (completed) {
      return <></>
    }
    return <span>{t('functions.connect.session.countdown.STARTING', { person: session.host.name, time: display })}</span>;
  };


  if (!session || !countdownTo) {
    return <></>
  }

  return (
    <Countdown
      completed={finished}
      countdownTo={countdownTo}
      renderer={renderer}
    />
  )
}

export default CountdownTimer;
import React from 'react';
import { Container } from 'react-bootstrap';
import { AppConstants } from 'constant';
import { Layout } from 'components';
import { useTranslation } from 'react-i18next';

const { PATH_PROFILE_DELETION } = AppConstants;

const PrivacyPolicy = () => {

  const { t } = useTranslation();

  return (
    <Layout>
      <Container className="my-5">
        <h4 className='text-center mb-5'><b>{t('privacy.title')}</b></h4>
        <p>{t('privacy.1')}</p>
        <p><b>{t('privacy.2')}</b></p>
        <ul>
          <li><b>{t('privacy.3.1')}</b>: {t('privacy.3.2')} ({t('privacy.3.3')}: <a href="tel:+4915126535312">+4915126535312</a>; {t('privacy.3.4')}: <a href="mailto:office@connect-international.org">office@connect-international.org</a>), <a target='_blank' rel='nooppener noreferrer' href="https://www.connect-international.org/">https://www.connect-international.org/</a></li>
          <li><b>{t('privacy.4.1')}</b>: {t('privacy.4.2')} (<a href="digital-youth.org">digital-youth.org</a>)</li>
          <li><b>{t('privacy.5.1')}</b>: {t('privacy.5.2')}</li>
          <li><b>{t('privacy.6.1')}</b>: {t('privacy.6.2')}</li>
          <li><b>{t('privacy.7.1')}</b>: {t('privacy.7.2')}</li>
        </ul>

        <br/>
        <p><b>{t('privacy.8')}</b></p>
        <p><b>{t('privacy.9.1')}</b> {t('privacy.9.2')} <a target='_blank' rel='nooppener noreferrer' href="https://www.connect-international.org/">Connect Int.</a> {t('privacy.9.3')}, <a target='_blank' rel='nooppener noreferrer' href="https://moveit-org.com/">MoveIt</a> {t('privacy.9.4')}, <a target='_blank' rel='nooppener noreferrer' href="https://www.backslash.es/">Backslash</a> {t('privacy.9.5')}, <a target='_blank' rel='nooppener noreferrer' href="https://www.tipovej.org/en/">Tipovej</a> {t('privacy.9.6')} <a target='_blank' rel='nooppener noreferrer' href="https://bum.org.rs/">BUM</a> {t('privacy.9.7')}</p>
        <p><b>{t('privacy.10.1')}</b> {t('privacy.10.2')}</p>
        <p><b>{t('privacy.11.1')}.</b> {t('privacy.11.2')}</p>
        <p><b>{t('privacy.12.1')}</b> {t('privacy.12.2')} <a target='_blank' rel='nooppener noreferrer' href="https://www.connect-international.org/2022/11/11/code-of-ethics/">{t('privacy.12.3')}</a></p>

        <br/>
        <p><b>{t('privacy.13')}</b></p>
        <p><b>{t('privacy.14.1')}</b> {t('privacy.14.2')}</p>
        <ol>
          <li><u>{t('privacy.15.1')}</u>: {t('privacy.15.2')}</li>
          <li><u>{t('privacy.16.1')}</u>: {t('privacy.16.2')}</li>
          <li><u>{t('privacy.17.1')}</u>: {t('privacy.17.2')}</li>
          <li><u>{t('privacy.18.1')}</u>: {t('privacy.18.2')}</li>
          <li><u>{t('privacy.19.1')}</u>: {t('privacy.19.2')}</li>
          <li><u>{t('privacy.20.1')}</u>: {t('privacy.20.2')}</li>
          <li><u>{t('privacy.21.1')}</u>: {t('privacy.21.2')}</li>
        </ol>
        <p><b>{t('privacy.22.1')}</b> {t('privacy.22.2')}</p>
        <p><b>{t('privacy.23.1')}</b> {t('privacy.23.2')}</p>

        <br/>
        <p><b>{t('privacy.24')}</b></p>
        <p><b>{t('privacy.25.1')}</b> {t('privacy.25.2')}</p>
        <p><b><b>{t('privacy.26.1')}</b> {t('privacy.26.2')}</b></p>
        <ul>
          <li>{t('privacy.27')}</li>
          <li>{t('privacy.28')}</li>
          <li>{t('privacy.29')}</li>
          <li>{t('privacy.30')}</li>
          <li>{t('privacy.31')}</li>
          <li>{t('privacy.32')}</li>
          <li>{t('privacy.33')}</li>
        </ul>

        <br/>
        <p><b>{t('privacy.34')}</b></p>
        <p><b>{t('privacy.35.1')}</b> {t('privacy.35.2')}</p>
        <ol>
          <li>{t('privacy.36.1')} <a target='_blank' rel='nooppener noreferrer' href="https://moveit-org.com/">MoveIt</a> {t('privacy.36.2')}, <a target='_blank' rel='nooppener noreferrer' href="https://www.backslash.es/">Backslash</a> {t('privacy.36.3')}, <a target='_blank' rel='nooppener noreferrer' href="https://www.tipovej.org/en/">Tipovej</a> {t('privacy.36.4')} <a target='_blank' rel='nooppener noreferrer' href="https://bum.org.rs/">BUM</a> {t('privacy.36.5')}, {t('privacy.36.6')}</li>
          <li>{t('privacy.37')}</li>
          <li>{t('privacy.38')}</li>
          <li>{t('privacy.39')}</li>
        </ol>
        <p><b>{t('privacy.40.1')}</b> {t('privacy.40.2')}</p>
        <p><b>{t('privacy.41.1')}</b> {t('privacy.41.2')}</p>

        <br/>
        <p><b>{t('privacy.42')}</b></p>
        <p><b>{t('privacy.43.1')}</b> {t('privacy.43.2')}</p>
        <p><b>{t('privacy.44.1')}</b> {t('privacy.44.2')}</p>
        <p><b>{t('privacy.45.1')}</b> {t('privacy.45.2')} <a href={PATH_PROFILE_DELETION}>{t('privacy.45.3')}.</a></p>

        <br/>
        <p><b>{t('privacy.46')}</b></p>
        <p><b>{t('privacy.47.1')}</b> {t('privacy.47.2')}</p>
        <ul>
          <li>{t('privacy.48')}</li>
          <li>{t('privacy.49')}</li>
          <li>{t('privacy.50.1')} <a href={PATH_PROFILE_DELETION}>{t('privacy.50.2')}</a></li>
          <li>{t('privacy.51')}</li>
        </ul>
        <p><b>{t('privacy.52.1')}</b> {t('privacy.52.2')} <a href="mailto:office@connect-international.org">office@connect-international.org.</a></p>
        <p><b>{t('privacy.53.1')}.</b> {t('privacy.53.2')} <a target='_blank' rel='nooppener noreferrer' href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679">{t('privacy.53.3')}</a>.</p>
        <p><b>{t('privacy.54.1')}</b> {t('privacy.54.2')} (<a target='_blank' rel='nooppener noreferrer' href="https://www.dataprotectionauthority.be/" >https://www.dataprotectionauthority.be/</a>).</p>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicy;
export default class User {
  private __id: number;
  private __role: undefined;
  private __email: undefined;
  private __language: 'en';

  constructor(
    id: number,
    role?: undefined,
    email?: undefined,
    language?: undefined
  ) {
    this.__id = id;
    this.__role = role;
    this.__email = email;
    this.__language = language;
  }

  public get id() { return this.__id; }
  public get role() { return this.__role; }
  public get email() { return this.__email; }
  public get language() { return this.__language; }

  public toJSON() {
    return {
      id: this.__id,
      role: this.__role,
      email: this.__email,
      language: this.__language,
    }
  }

}
import React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";

const FormDate = (props) => (
  <Form.Group className="mb-3">
    {props.label ? <Form.Label htmlFor={props.id}>{props.label}</Form.Label> : undefined}
    <DatePicker
      {...props}
    />
    {props.text ? <Form.Text>{props.text}</Form.Text> : undefined}
  </Form.Group>
)

export default FormDate;
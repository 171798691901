import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen33 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-4-3" className='text-engage'>{t('learning.engage.175')}</h4>
      <p>{t('learning.engage.176')}</p>
      <br />
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/nD6tUEp1lws?si=btUCwS4PgGkgQI2D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
    </>
  )

}

export default EngageLearningScreen33;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower27, empower28, empower29 } from 'assets/learning';

const EmpowerLearningScreen11 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="evaluation">{t('learning.empower.102')}</h2>
      <p>{t('learning.empower.103.1')}<a href="https://www.easypolls.net/#/editpoll" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.103.2')}</a>{t('learning.empower.103.3')}</p>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower27} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.104')}</b></h5>
          <p>{t('learning.empower.105')}</p>
          <p><span className="text-success">{t('learning.empower.106.1')}</span>: {t('learning.empower.106.2')}</p>
          <p><span className="text-danger">{t('learning.empower.107.1')}</span>: {t('learning.empower.107.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower28} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.108')}</b></h5>
          <p>{t('learning.empower.109')}</p>
          <p><span className="text-success">{t('learning.empower.110.1')}</span>: {t('learning.empower.110.2')}</p>
          <p><span className="text-danger">{t('learning.empower.111.1')}</span>: {t('learning.empower.111.2')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower29} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.112')}</b></h5>
          <p>{t('learning.empower.113.1')}<a href="https://ipsrsolutions.com/academix/creative-ways-of-using-mentimeter" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.113.2')}</a>{t('learning.empower.113.3')}</p>
          <p><span className="text-success">{t('learning.empower.114.1')}</span>: {t('learning.empower.114.2')}</p>
          <p><span className="text-danger">{t('learning.empower.115.1')}</span>: {t('learning.empower.115.2')}</p>
        </div>
      </div>
    </>
  )

}

export default EmpowerLearningScreen11;
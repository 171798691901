import { Article, Hotspot, HotspotItem, Event, Fair, Invitation, Member, Organization, Session, VolunteeringInvitation, Opportunity, ForumTheme, ForumThemePost } from "models";
import moment from "moment";
import { AppUtils } from "utils";

export function convertHotspotModelB2F(b) {
  let items = (b.hotspotItems && b.hotspotItems.length > 0) ? b.hotspotItems.map(el => new HotspotItem(
    el.hotspotItemId,
    el.label,
    el.link,
    el.description
  ).toJSON()) : [];
  return new Hotspot(
    b.hotspotId,
    b.type,
    b.text,
    b.image,
    b.videoLink,
    items
  ).toJSON();
}

export function convertHotspotModelF2B(f, items) {
  return ({
    'type': f.type,
    'text': f.text,
    'image': f.image,
    'videoLink': f.videoLink,
    'hotspotItems': items
  })
}

export function getHotspotModelItem() {
  return ({
    'label': undefined,
    'link': undefined,
    'description': undefined,
    "tempId": new Date().getTime()
  })
}

export function convertHotspotItemModelF2B(f) {
  return ({
    'label': f.label,
    'link': f.link,
    'description': f.description,
  })
}

export function convertVolunteeringInvitationModelB2F(b) {
  return new VolunteeringInvitation(
    b.invitationId,
    b.email,
    b.fullName,
    b.date,
    b.isAccepted
  ).toJSON();
}

export function convertInvitationModelB2F(b) {
  return new Invitation(
    b.invitationId,
    b.email,
    b.fullName,
    b.position,
    b.date,
    b.isAccepted
  ).toJSON();
}

export function convertFairModelB2F(b) {
  return new Fair(
    b.fairId,
    b.featuredImage,
    b.startDateTime,
    b.title,
    b.description,
    b.daysLeftToApply,
    b.placesLeft,
    b.isActiveNow,
    b.durationInMinutes,
    b.numberOfParticipants,
    b.preLiveDateTime,
    b.applicationDateTime,
    b.roomUrl
  ).toJSON();
}

export function convertFairModelF2B(f) {
  const startDateTime = f['editedStartDateTime'] ? moment(f['startDateTime'], "YYYY-MM-DD hh:mm").toDate().toISOString() : f['startDateTime'];
  const applicationDateTime = f['editedApplicationDateTime'] ? moment(f['applicationDateTime'], "YYYY-MM-DD hh:mm").toDate().toISOString() : f['applicationDateTime'];
  const preLiveDateTime = f['editedPreLiveDateTime'] ? moment(f['preLiveDateTime'], "YYYY-MM-DD hh:mm").toDate().toISOString() : f['preLiveDateTime'];
  return {
    "fairId": f["id"] ?? undefined,
    "name": f["title"] ?? undefined,
    "featuredImage": f["featuredImage"] ?? undefined,
    "description": f["description"] ?? undefined,
    "numberOfParticipants": f["numberOfParticipants"] ?? undefined,
    "startDateTime": startDateTime ?? undefined,
    "applicationDateTime": applicationDateTime ?? undefined,
    "preLiveDateTime": preLiveDateTime ?? undefined,
    "durationInMinutes": f["durationInMinutes"] ?? undefined,
    "roomUrl": f["roomUrl"] ?? undefined
  }
}

export function convertEventModelB2F(b) {
  return new Event(
    b.id,
    b.name,
    b.startDateTime,
    b.endDateTime,
    b.link,
    b.room
  ).toJSON();
}

export function convertEventModelF2B(values) {
  const startTime = values['editedStartTime'] ? moment(values['startTime'], "YYYY-MM-DD hh:mm").toDate().toISOString() : values['startTime'];
  const endTime = values['editedEndTime'] ? moment(values['endTime'], "YYYY-MM-DD hh:mm").toDate().toISOString() : values['endTime'];
  return {
    'room': values['type'],
    'name': values['name'],
    'startDateTime': startTime ?? undefined,
    'endDateTime': endTime ?? undefined
  }
}

export function convertOpportunityModelB2F(b) {
  return new Opportunity(
    b.opportunityId,
    b.featuredImage,
    b.title,
    b.text,
    b.datePublished,
    b.organizationId,
    b.organizationName,
    b.active,
    b.formUrl
  ).toJSON()
}

export function convertForumThemeModelB2F(b) {
  return new ForumTheme(
    b.themeId,
    b.createdAt,
    b.authorId,
    b.authorRole,
    b.authorName,
    b.authorImage,
    b.title,
    b.postsCount,
  ).toJSON()
}

export function convertForumThemePostB2F(b) {
  return new ForumThemePost(
    b.postId,
    b.createdAt,
    b.authorId,
    b.authorRole,
    b.authorName,
    b.authorImage,
    b.content,
    b.relatedPostId,
    b.relatedPostContent,
    b.relatedPostAuthorName,
  ).toJSON()
}

export function convertArticleModelB2F(b) {
  return new Article(
    b.articleId,
    b.featuredImage,
    b.title,
    b.text,
    b.intro,
    b.datePublished,
    b.category,
    b.fairId,
    b.status,
    b.organizationId,
    b.organizationName,
    b.statusChangeable,
    b.editable,
    b.deletable,
    b.submissionDate
  ).toJSON()
}

export function convertArticleModelF2B(f) {
  return {
    "title": f["title"] ?? undefined,
    "featuredImage": f["featuredImage"] ?? undefined,
    "intro": f["intro"] ?? undefined,
    "description": f["description"] ?? undefined,
    "fairId": f["fairId"] ?? undefined,
    "category": f["category"] ?? undefined,
    "organizationId": f["organizationId"] ?? undefined,
  }
}

export function convertOpportunityModelF2B(f) {
  return {
    "title": f["title"] ?? undefined,
    "featuredImage": f["featuredImage"] ?? undefined,
    "description": f["text"] ?? undefined,
    "formUrl": f["formUrl"] ?? undefined,
    "active": true,
    "organizationId": f["organizationId"] ?? undefined,
  }
}

export function convertMemberModelB2F(b) {
  return new Member(
    b.memberId ?? b.id,
    b.profilePicture,
    b.email,
    b.position,
    b.fullName,
    b.organizationId,
    b.accountType
  ).toJSON()
}

export function convertOrganizationB2F(b) {
  return new Organization(
    b.organizationId,
    b.organizationName,
    b.profilePicture,
    b.website,
    b.address,
    b.phone,
    b.email,
    b.fields,
    b.about,
    b.country,
    b.city,
    b.instagram,
    b.linkedIn,
    b.facebook,
    b.twitter,
    b.youTube,
    b.standNumber
  ).toJSON()
}

export function convertOrganizationProfileModelF2B(f) {
  return {
    "id": AppUtils.getLoggedUser().id,
    "profilePicture": f["profilePicture"] ?? undefined,
    "name": f["name"] ?? undefined,
    "about": f["about"] ?? undefined,
    "website": f["website"] ?? undefined,
    "address": f["address"] ?? undefined,
    "city": f["city"] ?? undefined,
    "country": f["country"] ?? undefined,
    "fields": f["fields"] ?? undefined,
    "instagram": f["instagram"] ?? undefined,
    "linkedIn": f["linkedIn"] ?? undefined,
    "facebook": f["facebook"] ?? undefined,
    "twitter": f["twitter"] ?? undefined,
    "youTube": f["youTube"] ?? undefined,
  }
}

export function convertLoggedUserMember() {
  const loggedUser = AppUtils.getLoggedUser();
  return {
    "memberId": loggedUser.id,
    "profilePicture": loggedUser.profilePicture
  }
}

export function convertLoggedUserMemberF2B(f) {
  const loggedUser = AppUtils.getLoggedUser();
  return {
    "memberId": loggedUser.id,
    "profilePicture": f['profilePicture']
  }
}

export function convertLoggedUserVolunteer() {
  const loggedUser = AppUtils.getLoggedUser();
  return {
    "volunteerId": loggedUser.id,
    "profilePicture": loggedUser.profilePicture
  }
}

export function convertLoggedUserVolunteerF2B(f) {
  const loggedUser = AppUtils.getLoggedUser();
  return {
    "volunteerId": loggedUser.id,
    "profilePicture": f['profilePicture']
  }
}

export function convertSessionModelB2F(b) {
  let host = undefined
  if (b.sessionMatch) {
    host = {
      id: b.sessionMatch.userId,
      accountType: b.sessionMatch.role,
      name: b.sessionMatch.fullName ?? b.sessionMatch.organizationName,
      position: b.position,
      profilePicture: b.profilePicture
    }
  } else if (b.isSessionWithMe) {
    const loggedUser = AppUtils.getLoggedUser();
    host = {
      id: loggedUser.id,
      accountType: loggedUser.role,
      name: loggedUser.fullName ?? loggedUser.organizationName,
      position: b.position,
      profilePicture: b.profilePicture
    }
  }
  return new Session(
    b.sessionId,
    b.isOpen,
    b.hostLink,
    b.hostMe,
    b.startTime,
    b.endTime,
    b.isSessionWithMe,
    host
  ).toJSON()
}
import React from 'react';
import { Modal } from 'components';
import { Container } from 'react-bootstrap';

const InfoModal = ({ fair, onDismiss, show }) => {

  return (
    <Modal onHide={onDismiss} show={!!show} size={'lg'}>
      <Container>
        <h3 className="mb-4"><b>Informations</b></h3>
        <iframe width="100%" height="315" src="https://www.youtube.com/embed/tIO--t7adSY?si=mHguxHBWPrDMT4HQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </Container>
    </Modal>
  )
}

export default InfoModal;
import { step4321 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen28 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-3-2" className='text-engage'>{t('learning.engage.147')}</h4>
      <p><b>{t('learning.engage.148')}</b></p>
      <p>{t('learning.engage.149')}</p>
      <ul>
        <li>{t('learning.engage.150')}</li>
        <li>{t('learning.engage.151')}</li>
        <li>{t('learning.engage.152')}</li>
        <li>{t('learning.engage.153')}</li>
        <li>{t('learning.engage.154')}</li>
      </ul>
      <p>{t('learning.engage.155')}</p>
      <p>{t('learning.engage.156')}</p>
      <div className='text-center'>
        <Image src={step4321} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen28;
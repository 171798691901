import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppUtils } from 'utils';

import EmpowerEventDeleteModal from './modal/delete';
import EmpowerEventShareModal from './modal/share';
import EmpowerEventEditModal from './modal/edit';

const modals = {
  SHARE: 0,
  EDIT: 1,
  DELETE: 2
}

const EventItem = ({ el, events, disabled = false, setReload, t }) => {

  const [showModal, setShowModal] = useState(undefined);

  const onDismiss = useCallback(() => setShowModal(undefined), []);
  const onSubmit = useCallback(() => {
    setReload();
    onDismiss();
  }, [setReload, onDismiss]);

  if (disabled) {
    return (
      <div key={'event-' + el.id}>
        <div className="event" >
          <div className="d-flex flex-direction-row justify-content-between align-items-center mb-3">
            <span>
              {AppUtils.isSameDay(el.startTime, el.endTime) ?
                AppUtils.convertDateToLocaleDate(el.startTime, t('format.date.event')) :
                '' + AppUtils.convertDateToLocaleDate(el.startTime, t('format.date.event')) + ' - ' + AppUtils.convertDateToLocaleDate(el.endTime, t('format.date.event'))
              }
            </span>
          </div>
          <h3 className="mb-3">{el.name}</h3>
          <p>
            <FontAwesomeIcon icon={'clock'} className="text-primary" />
            <span className="pl-2">{AppUtils.convertDateToLocaleDate(el.startTime, t('format.time'))} - {AppUtils.convertDateToLocaleDate(el.endTime, t('format.time'))}</span>
          </p>
        </div>
      </div>
    )
  }
  return (
    <div key={'event-' + el.id}>
      <EmpowerEventEditModal show={showModal === modals.EDIT} event={el} events={events} onDismiss={onDismiss} onSubmit={onSubmit} />
      <EmpowerEventShareModal show={showModal === modals.SHARE} el={el} onDismiss={onDismiss} />
      <EmpowerEventDeleteModal show={showModal === modals.DELETE} el={el} onDismiss={onDismiss} onSubmit={onSubmit} />
      <div>
        <div className="event" >
          <div className="d-flex flex-direction-row justify-content-between align-items-center mb-3">
            <span>
              {AppUtils.isSameDay(el.startTime, el.endTime) ?
                AppUtils.convertDateToLocaleDate(el.startTime, t('format.date.event')) :
                '' + AppUtils.convertDateToLocaleDate(el.startTime, t('format.date.event')) + ' - ' + AppUtils.convertDateToLocaleDate(el.endTime, t('format.date.event'))
              }
            </span>
            <div>
              {!AppUtils.isEventEnded(el) ? <a onClick={() => setShowModal(modals.SHARE)} className="ml-3 clickable"><FontAwesomeIcon icon={'share-alt'} size='xl' /></a> : undefined}
              {AppUtils.isEventDraft(el) ? <a onClick={() => setShowModal(modals.DELETE)} className="ml-3 text-muted clickable"><FontAwesomeIcon icon={'trash-alt'} size='xl' /></a> : undefined}
              {AppUtils.isEventDraft(el) ? <a onClick={() => setShowModal(modals.EDIT)} className="ml-3 text-muted clickable"><FontAwesomeIcon icon={'pencil'} size='xl' /></a> : undefined}
            </div>
          </div>
          <h3 className="mb-3">{el.name}</h3>
          <p>
            <FontAwesomeIcon icon={'clock'} className="text-primary" />
            <span className="pl-2">{AppUtils.convertDateToLocaleDate(el.startTime, t('format.time'))} - {AppUtils.convertDateToLocaleDate(el.endTime, t('format.time'))}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default EventItem;
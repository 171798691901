/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormInput, Layout } from 'components';
import { email, envelope, paperPlane } from 'assets/icons';
import { useFetch } from 'hooks/fetch';
import { ServerConstants } from 'constant';
import ContactEmail from 'screens/modal/contact-email';

const { API_METHOD_POST, API_URL_EMAIL_CONTACT } = ServerConstants;
const valuesModel = {
  contactMail: undefined,
  message: undefined,
}

const mail = "digitalyouplatform@gmail.com";

const ContactScreen = () => {

  const { t } = useTranslation();
  const emailFetch = useFetch(API_METHOD_POST, API_URL_EMAIL_CONTACT);
  const [values, setValues] = useState(valuesModel);
  const [emailModalVisible, setEmailModalVisible] = useState(false);

  useEffect(() => {
    if (!emailFetch.response) {
      return;
    }
    if (emailFetch.response.status === 200) {
      setValues(valuesModel);
      setEmailModalVisible(true);
    }
    emailFetch.clearData();
  }, [emailFetch]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    emailFetch.setBody(values);
  }, [values]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  }, [values]);

  const isLoading = useCallback(() => emailFetch.loading, [emailFetch.loading])
  const closeModal = useCallback(() => {
    setEmailModalVisible(false);
    setValues(valuesModel);
  }, []);

  return (
    <Layout>
      <ContactEmail onDismiss={closeModal} t={t} visible={emailModalVisible} />
      <Container className="mt-4 mb-5">
        <div className="d-flex flex-direction-row justify-content-around flex-wrap">
          <div className="col-6 mt-4">
            <div className="d-flex flex-direction-row align-items-start mb-5">
              <Image src={envelope} />
              <h2 className="logo-name mx-3">{t('contact.title')}</h2>
            </div>
            <p>{t('contact.description')}</p>
            <p>
              <b>{t('contact.before')}<a href="/faq">{t('contact.faq')}</a></b>
            </p>
            <div className="d-table mt-5">
              <div className="pill">
                <Image src={email} />
                <span>{mail}</span>
              </div>
            </div>
          </div>
          <div className="mt-4">
            <div className="contact-card">
              <div className="contact-card-header">
                <h3>{t('contact.send')}</h3>
                <Image src={paperPlane} />
              </div>
              <div className="contact-card-body">
                <Form onSubmit={handleSubmit}>
                  <FormInput
                    id="contactMail"
                    label={t('contact.email')}
                    type="email"
                    required={true}
                    onChange={onFormChange}
                  />
                  <FormInput
                    id="message"
                    label={t('contact.message')}
                    as="textarea"
                    rows={5}
                    required={true}
                    onChange={onFormChange}
                  />
                  <Row className="mt-5">
                    <Col className="col-12 text-center">
                      <Button type='submit' disabled={isLoading()}>{t('contact.submit')}</Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default ContactScreen;
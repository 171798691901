import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step451 } from 'assets/learning';

const EngageLearningScreen35 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-5-1" className='text-engage'>{t('learning.engage.179')}</h4>
      <p>{t('learning.engage.180.1')}<b>{t('learning.engage.180.2')}</b>{t('learning.engage.180.3')}</p>
      <br /><br />
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/uSnpAnhV2Jo?si=ZcN8nO_0eV0j8k7p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <br /><br />
      <div className='text-center'>
        <Image src={step451} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen35;
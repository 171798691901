import React from 'react';
import { Button, Col, Image } from 'react-bootstrap';
import { CountriesData } from 'data';
import { AppConstants } from 'constant';
import { profilePicture } from 'assets/images';

const { PATH_ADMIN_COMMUNITY } = AppConstants;

const OrganizationCard = ({ el, t }) => (
  <Col key={'organization-' + el.organizationId} className="col-6">
    <div className="card card-organization">
      <Image src={el.profilePicture && el.profilePicture !== '' ? el.profilePicture : profilePicture} />
      <h4>{el.name}</h4>
      {el.fields && el.fields.length > 0 ?
        <p>
          <b>
            <i>{el.fields.map((field, i) => (i > 0 ? ', ' : '' )+ field)}</i>
          </b>
        </p>
        : undefined}
      <div className="d-flex flex-direction-row align-items-center country-data">
        <Image src={`${'https://flagicons.lipis.dev/flags/4x3/' + el.country.toLowerCase() + '.svg'}`} />
        <span>{CountriesData[el.country.toUpperCase()]}</span>
      </div>
      <p>{el.about}</p>
      <Button onClick={() => window.location.href = `${PATH_ADMIN_COMMUNITY + '/' + el.id}`}>{t('organizations.view')}</Button>
    </div>
  </Col>
)

export default OrganizationCard;
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Empty, Layout, Loader } from 'components';
import { people } from 'assets/icons';
import OrganizationCard from './card';
import { ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import { Typeahead } from 'react-bootstrap-typeahead';
import { CountriesData } from 'data';
import { ModelUtils } from 'utils';

const { API_METHOD_GET, API_URL_ORGANIZATION_COMMUNITY } = ServerConstants;

const CommunityListScreen = () => {

  const { t } = useTranslation();
  const { response, loading } = useFetch(API_METHOD_GET, API_URL_ORGANIZATION_COMMUNITY)
  const [organizations, setOrganizations] = useState([]);
  const [filteredOrganizationArray, setFilteredOrganizationArray] = useState([]);
  const [fieldOptions, setFieldOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [filterValues, setFilterValues] = useState({});

  useEffect(() => {
    if (response && response.data) {
      const data = response.data.map(el => ModelUtils.convertOrganizationB2F(el));
      let fields = [];
      let countries = [];
      let names = [];
      data.forEach(el => {
        const country = CountriesData[el.country];
        if (el.country && !countries.includes(country)) {
          if (country) {
            countries.push(country);
          }
        }
        if (el.fields) {
          el.fields.forEach(field => {
            if (!fields.includes(field)) {
              fields.push(field);
            }
          })
        }
        names.push(el.name)
      });
      setNameOptions(names);
      setFieldOptions(fields);
      setCountryOptions(countries);
      setOrganizations(data);
    }
  }, [response]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setFilterValues({ ...filterValues, [name]: value });
  }, [filterValues]);

  useEffect(() => {
    let tempArray = organizations;
    if (filterValues['fields'] && filterValues['fields'].length > 0) {
      tempArray = tempArray.filter(el => {
        if (el.fields && el.fields.length > 0) {
          return el.fields.map(field => filterValues['fields'].includes(field));
        }
        return false
      });
    }
    if (filterValues['countries'] && filterValues['countries'].length > 0) {
      tempArray = tempArray.filter(el => filterValues['countries'].includes(CountriesData[el.country]));
    }
    if (filterValues['searchTerm'] && filterValues['searchTerm'].length > 0) {
      tempArray = tempArray.filter(el => filterValues['searchTerm'].includes(el.name));
    }
    setFilteredOrganizationArray(tempArray);
  }, [organizations, filterValues]);

  return (
    <Layout>
      <Container className="mt-4 mb-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <div className="d-flex flex-direction-row align-items-start">
              <Image src={people} />
              <h2 className="logo-name mx-3">{t('organizations.title')}</h2>
            </div>
          </div>
          <p>{t('organizations.subtitle')}</p>
          <div>
            <Row className="flex-shrink flex-grow">
              <Col className="col-4">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor={'field'}>{t('organizations.filter.field.label')}</Form.Label>
                  <Typeahead
                    maxResults={Object.keys(fieldOptions).length}
                    options={fieldOptions}
                    emptyLabel={t('organizations.filter.field.empty')}
                    iconName={'chevron-down'}
                    id={'fields'}
                    label={t('organizations.filter.field.label')}
                    labelKey={option => `${option}`}
                    placeholder={t('organizations.filter.field.placeholder')}
                    renderItem={(options) => (
                      <div key={options}>
                        <p className="my-1">{options}</p>
                      </div>
                    )}
                    t={t}
                    multiple={true}
                    onChange={(selected) => onFormChange({ target: { id: 'fields', value: selected ?? undefined } })}
                    selected={filterValues['fields'] ? filterValues['fields'] : []}
                  />
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor={'country'}>{t('organizations.filter.country.label')}</Form.Label>
                  <Typeahead
                    maxResults={countryOptions.length}
                    options={countryOptions}
                    emptyLabel={t('organizations.filter.country.empty')}
                    iconName={'chevron-down'}
                    id={'countries'}
                    label={t('organizations.filter.country.label')}
                    labelKey={option => `${option}`}
                    placeholder={t('organizations.filter.country.placeholder')}
                    renderItem={(options) => (
                      <div key={options}>
                        <p className="my-1">{options}</p>
                      </div>
                    )}
                    t={t}
                    onChange={(selected) => onFormChange({ target: { id: 'countries', value: selected ?? undefined } })}
                    selected={filterValues['countries'] ? filterValues['countries'] : []}
                  />
                </Form.Group>
              </Col>
              <Col className="col-4">
                <Form.Group className="mb-3">
                  <Form.Label htmlFor={'searchTerm'}>&nbsp;</Form.Label>
                  <Typeahead
                    maxResults={Object.keys(nameOptions).length}
                    options={nameOptions}
                    emptyLabel={t('organizations.filter.field.empty')}
                    iconName={'chevron-down'}
                    id={'searchTerm'}
                    labelKey={option => `${option}`}
                    placeholder={t('organizations.filter.organizations.placeholder')}
                    renderItem={(options) => (
                      <div key={options}>
                        <p className="my-1">{options}</p>
                      </div>
                    )}
                    t={t}
                    onChange={(selected) => onFormChange({ target: { id: 'searchTerm', value: selected ?? undefined } })}
                    selected={filterValues['searchTerm'] ? filterValues['searchTerm'] : []}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              {loading ? <Loader /> :
                (!filteredOrganizationArray || filteredOrganizationArray.length === 0) ? <Empty messageKey={t('organizations.empty')} /> :
                  filteredOrganizationArray.map(el => <OrganizationCard el={el} t={t} />)}
            </Row>
          </div>
        </div>
      </Container>
    </Layout >
  )
}

export default CommunityListScreen;
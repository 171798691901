export default class Fair {
  private __id: number;
  private __featuredImage: string;
  private __startDateTime: string;
  private __title: string;
  private __description: string;
  private __daysLeftToApply: number;
  private __placesLeft: number;
  private __isActiveNow: boolean;
  private __durationInMinutes: number;
  private __numberOfParticipants: number;
  private __preLiveDateTime: string;
  private __applicationDateTime: string;
  private __roomUrl: string;

  constructor(
    id: number,
    featuredImage: string,
    startDateTime: string,
    title: string,
    description: string,
    daysLeftToApply: number,
    placesLeft: number,
    isActiveNow: boolean,
    durationInMinutes: number,
    numberOfParticipants: number,
    preLiveDateTime: string,
    applicationDateTime: string,
    roomUrl: string
  ) {
    this.__id = id;
    this.__featuredImage = featuredImage;
    this.__startDateTime = startDateTime;
    this.__title = title;
    this.__description = description;
    this.__daysLeftToApply = daysLeftToApply;
    this.__placesLeft = placesLeft;
    this.__isActiveNow = isActiveNow;
    this.__durationInMinutes = durationInMinutes;
    this.__numberOfParticipants = numberOfParticipants;
    this.__preLiveDateTime = preLiveDateTime;
    this.__applicationDateTime = applicationDateTime;
    this.__roomUrl = roomUrl;
  }

  public get id() { return this.__id; }
  public get featuredImage() { return this.__featuredImage; }
  public get startDateTime() { return this.__startDateTime; }
  public get title() { return this.__title; }
  public get description() { return this.__description; }
  public get daysLeftToApply() { return this.__daysLeftToApply; }
  public get placesLeft() { return this.__placesLeft; }
  public get isActiveNow() { return this.__isActiveNow; }
  public get durationInMinutes() { return this.__durationInMinutes; }
  public get numberOfParticipants() { return this.__numberOfParticipants; }
  public get preLiveDateTime() { return this.__preLiveDateTime; }
  public get applicationDateTime() { return this.__applicationDateTime; }
  public get roomUrl() { return this.__roomUrl; }

  public toJSON() {
    return {
      id: this.__id,
      featuredImage: this.__featuredImage,
      startDateTime: this.__startDateTime,
      title: this.__title,
      description: this.__description,
      daysLeftToApply: this.__daysLeftToApply,
      placesLeft: this.__placesLeft,
      isActiveNow: this.__isActiveNow,
      durationInMinutes: this.__durationInMinutes,
      numberOfParticipants: this.__numberOfParticipants,
      preLiveDateTime: this.__preLiveDateTime,
      applicationDateTime: this.__applicationDateTime,
      roomUrl: this.__roomUrl
    }
  }

}
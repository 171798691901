import { Layout } from 'components';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningSimulation3 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container className='page'>
        <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
          <colgroup>
            <col className='w-10'></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t('learning.volunteering.organization.135.1')}</th>
              <th>{t('learning.volunteering.organization.135.2')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('learning.volunteering.organization.136.1')}</td>
              <td>{t('learning.volunteering.organization.136.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.137.1')}</td>
              <td>{t('learning.volunteering.organization.137.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.138.1')}</td>
              <td>{t('learning.volunteering.organization.138.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.139.1')}</td>
              <td>{t('learning.volunteering.organization.139.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.140.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.140.2')}</li>
                  <li>{t('learning.volunteering.organization.140.3')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.141.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.141.2')}</li>
                  <li>{t('learning.volunteering.organization.141.3')}</li>
                  <li>{t('learning.volunteering.organization.141.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.142.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.142.2')}</li>
                  <li>{t('learning.volunteering.organization.142.3')}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  )

}

export default VolunteeringLearningSimulation3;
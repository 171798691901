export default class Session {
  private __id: number;
  private __isOpen: boolean;
  private __hostLink: string;
  private __hostMe: boolean;
  private __startTime: number;
  private __endTime: number;
  private __isSessionWithMe: boolean;
  private __host: any;

  constructor(
    id: number,
    isOpen: boolean,
    hostLink: string,
    hostMe: boolean,
    startTime: number,
    endTime: number,
    isSessionWithMe: boolean,
    host: any,
  ) {
    this.__id = id;
    this.__isOpen = isOpen;
    this.__hostLink = hostLink;
    this.__hostMe = hostMe;
    this.__startTime = startTime;
    this.__endTime = endTime;
    this.__isSessionWithMe = isSessionWithMe;
    this.__host = host;
  }

  public get id() { return this.__id; }
  public get isOpen() { return this.__isOpen; }
  public get hostLink() { return this.__hostLink; }
  public get hostMe() { return this.__hostMe; }
  public get startTime() { return this.__startTime; }
  public get endTime() { return this.__endTime; }
  public get isSessionWithMe() { return this.__isSessionWithMe; }
  public get host() { return this.__host; }

  public toJSON() {
    return {
      id: this.__id,
      isOpen: this.__isOpen,
      hostLink: this.__hostLink,
      startTime: this.__startTime,
      endTime: this.__endTime,
      isSessionWithMe: this.__isSessionWithMe,
      host: this.__host,
    }
  }

}
import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen46 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-7-2" className='text-engage'>{t('learning.engage.288')}</h4>
      <p>{t('learning.engage.289')}</p>
      <div className='d-flex justify-content-center'>
        <table className='table three-columns' style={{ width: '75%' }}>
          <colgroup>
            <col></col>
            <col className='w-50'></col>
            <col></col>
          </colgroup>
          <tbody>
            <tr>
              <td>{t('learning.engage.290')}</td>
              <td>{t('learning.engage.291')}</td>
              <td>{t('learning.engage.292')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.293')}</td>
              <td>
                {t('learning.engage.294')}<br />
                {t('learning.engage.295')}<br />
                {t('learning.engage.296')}
              </td>
              <td>{t('learning.engage.297')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.298')}</td>
              <td>
                {t('learning.engage.299')}<br />
                {t('learning.engage.300')}
              </td>
              <td>{t('learning.engage.301')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.302')}</td>
              <td>
                {t('learning.engage.303')}<br />
                {t('learning.engage.304')}
              </td>
              <td>{t('learning.engage.305')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.306')}</td>
              <td>
                {t('learning.engage.307')}<br />
                {t('learning.engage.308')}</td>
              <td>{t('learning.engage.309')}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{t('learning.engage.310')}</td>
              <td>
                {t('learning.engage.311')}<br />
                {t('learning.engage.312')}<br />
                {t('learning.engage.313')}
              </td>
              <td>{t('learning.engage.314')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )

}

export default EngageLearningScreen46;
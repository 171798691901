import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect23 } from 'assets/learning';

const ConnectLearningScreen7 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-1-5" className='text-connect'>{t('learning.connect.36')}</h4>
      <p>{t('learning.connect.37')}</p>
      <ul>
        <li>{t('learning.connect.38')}</li>
        <li>{t('learning.connect.39')}</li>
        <li>{t('learning.connect.40')}</li>
        <li>{t('learning.connect.41')}</li>
        <li>{t('learning.connect.42')}</li>
        <li>{t('learning.connect.43')}</li>
        <li>{t('learning.connect.44')}</li>
        <li>{t('learning.connect.45')}</li>
        <li>{t('learning.connect.46')}</li>
        <li>{t('learning.connect.47')}</li>
        <li>{t('learning.connect.48')}</li>
        <li>{t('learning.connect.49')}</li>
        <li>{t('learning.connect.50')}</li>
        <li>{t('learning.connect.51')}</li>
        <li>{t('learning.connect.52')}</li>
      </ul>
      <p>{t('learning.connect.53.1')}<a href="https://take.quiz-maker.com/QCSDPXK8M" target='_blank' rel='nooppener noreferrer' className='text-connect'>{t('learning.connect.53.2')}</a></p>
      <div className='text-center'>
        <Image src={connect23} width={'50%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen7;
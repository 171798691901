import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useFetch } from 'hooks/fetch';
import { FormInput } from 'components';
import { ServerConstants } from 'constant';
import { hexagonAdd } from 'assets/icons';
import { useValues } from 'hooks/values';
import { ModelUtils } from 'utils/index.js';

const { API_METHOD_GET, API_METHOD_PUT, API_METHOD_POST } = ServerConstants;
const HotspotModalForm = ({ el, onCancel, urlPrefix, defaultItemsUrlPrefix, defaultValue, type = false, editModelAllowed = false }) => {

  const { t } = useTranslation();
  const submitHotspotFetch = useFetch((!!el.id ? API_METHOD_PUT : API_METHOD_POST), (urlPrefix + '/' + (!!el.id ? type ? el.type : el.id : 'new')));
  const defaultItemsFetch = useFetch(API_METHOD_GET);
  const { values, onValueChange, setValue } = useValues(el?.id ? el : {
    type: el.type,
    items: el.items ?? []
  });
  const [items, setItems] = useState(el.items ?? []);
  const [imageOrVideo, setImageOrVideo] = useState(el.videoLink && el.videoLink !== '' ? true : false);
  const [defaultItems, setDefaultItems] = useState([]);

  useEffect(() => {
    if (defaultItemsUrlPrefix) {
      defaultItemsFetch.setFetchUrl(defaultItemsUrlPrefix + '/' + el.type + '/default-items');
      defaultItemsFetch.forceFetchData();
    }
  }, [defaultItemsUrlPrefix]);

  useEffect(() => {
    if (submitHotspotFetch.response) {
      onCancel();
    }
    return (() => {
      submitHotspotFetch.setBody(undefined);
    })
  }, [submitHotspotFetch.response]);

  useEffect(() => {
    if (defaultItemsFetch.response) {
      setDefaultItems(defaultItemsFetch.response.data);
    }
  }, [defaultItemsFetch.response]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }
    const body = ModelUtils.convertHotspotModelF2B(values, items.map(el => ModelUtils.convertHotspotItemModelF2B(el)));
    delete body[imageOrVideo ? 'image' : 'videoLink'];
    submitHotspotFetch.setBody(body);
    return;
  }, [values, items]);

  const onItemValueChange = useCallback((index, e) => {
    const name = e.target.id;
    const value = e.target.value;
    const arrayCopy = items;
    arrayCopy[index][name] = value
    setItems(arrayCopy);
  }, [items]);

  const removeImage = useCallback(() => setValue('image', undefined), []);
  const addItemModel = useCallback(() => setItems([...items, ModelUtils.getHotspotModelItem()]), [items]);
  const removeItemModel = useCallback((model) => setItems(items.filter(item => item.tempId !== model.tempId)), [items]);

  const replaceWithDefaultItems = useCallback(() => {
    setItems(undefined);
    setTimeout(() => {
      const copy = [];
      defaultItems.forEach((el, index) => copy[index] = { ...el });
      console.log(copy);
      setItems(copy);
    }, 50);
  }, [defaultItems]);

  return (
    <Container>
      <h3 className="mt-2 mb-4"><b>{defaultValue[el.type].title}</b></h3>
      <div className="mb-3">
        <p className="text-muted mb-2">
          <FontAwesomeIcon icon={"lock"} className="muted" size="lg" />
          <span className="ml-2">{t('hotspot.intro')}</span>
        </p>
        <p className="text-muted">{defaultValue[el.type].intro}</p>
      </div>
      {editModelAllowed ?
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormInput id="text" required={true} label={t('hotspot.description')} onChange={onValueChange} type="text" as="textarea" rows={5} defaultValue={el.text} />
          <div className="my-5">
            <p className="text-muted mb-1">{t('hotspot.media.title')}</p>
            <div className="mb-3">
              <div className="d-flex flex-direction-row align-items-center">
                <span className={"pt-1" + (imageOrVideo === false ? " text-primary" : " muted")}>{t('hotspot.media.image')}</span>
                <Form.Switch
                  defaultChecked={imageOrVideo}
                  className="form-switch primary mx-2"
                  onClick={() => setImageOrVideo(old => !old)}
                  value={imageOrVideo}
                />
                <span className={"pt-1" + (imageOrVideo === true ? " text-primary" : " muted")}>{t('hotspot.media.video')}</span>
              </div>
            </div>
            <div className="mb-3">
              {!imageOrVideo ?
                <>
                  {values['image'] ?
                    <div className="badge">
                      <Image src={values['image']} />
                      <a onClick={removeImage} className="text-danger clickable"><FontAwesomeIcon icon={'trash-alt'} /></a>
                    </div>
                    : <FormInput id="image" onChange={onValueChange} type="file" />
                  }
                </>
                :
                <FormInput id="videoLink" onChange={onValueChange} type="text" defaultValue={el.videoLink} placeholder="URL" />
              }
            </div>
          </div>
          <div className="my-5">
            <p className="text-muted mb-3">
              <FontAwesomeIcon icon={"cube"} className="muted" size="lg" />
              <span className="ml-2 muted">{t('hotspot.items.title')}</span>
            </p>
            {defaultItemsUrlPrefix ?
              <div className="mb-3">
                <a onClick={replaceWithDefaultItems} className="text-primary mb-3 clickable">
                  <Image src={hexagonAdd} height={22} />
                  <span className="ml-2">{t('hotspot.items.default')}</span>
                </a>
              </div>
              : undefined}
            {items ? items.map((item, index) => (
              <div className="hotspot-items">
                <a onClick={() => removeItemModel(item)} className="clickable">
                  <FontAwesomeIcon icon={'trash-alt'} />
                  <span className="ml-2">{t('hotspot.items.remove')}</span>
                </a>
                <hr />
                <FormInput id={'label'} label={t('hotspot.items.label')} required={true} onChange={(e) => onItemValueChange(index, e)} type="text" defaultValue={item.label} className="w-50" />
                <FormInput id={'link'} label={t('hotspot.items.link')} required={true} onChange={(e) => onItemValueChange(index, e)} type="text" defaultValue={item.link} className="w-50" />
                <FormInput id={'description'} label={t('hotspot.items.description')} required={true} onChange={(e) => onItemValueChange(index, e)} type="text" as="textarea" rows={3} defaultValue={item.description} />
              </div>
            )) : undefined}
            <div className="mb-3">
              <a onClick={addItemModel} className="text-primary mb-3 clickable">
                <FontAwesomeIcon icon={"plus"} size="lg" />
                <span className="ml-2">{t('hotspot.items.add')}</span>
              </a>
            </div>
          </div>
          <Row className="mt-5">
            <Col className="col-6">
              <Button onClick={onCancel} variant="secondary" className="w-100" disabled={submitHotspotFetch.loading}>{t('cancel')}</Button>
            </Col>
            <Col className="col-6">
              <Button type="submit" className='w-100' disabled={submitHotspotFetch.loading}>{t('confirm')}</Button>
            </Col>
          </Row>
        </Form>
        : undefined}
    </Container>
  )
}

export default HotspotModalForm;
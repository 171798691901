import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step441 } from 'assets/learning';

const EngageLearningScreen31 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-4-1" className='text-engage'>{t('learning.engage.161')}</h4>
      <p>{t('learning.engage.162')}</p>
      <ul>
        <li><b>{t('learning.engage.163.1')}</b>: {t('learning.engage.163.2')}</li>
        <li><b>{t('learning.engage.164.1')}</b>: {t('learning.engage.164.2')}</li>
        <li><b>{t('learning.engage.165.1')}</b>: {t('learning.engage.165.2')}</li>
        <li><b>{t('learning.engage.166.1')}</b>: {t('learning.engage.166.2')}</li>
        <li><b>{t('learning.engage.167.1')}</b>: {t('learning.engage.167.2')}</li>
        <li><b>{t('learning.engage.168.1')}</b>: {t('learning.engage.168.2')}</li>
        <li><b>{t('learning.engage.169.1')}</b>: {t('learning.engage.169.2')}</li>
        <li><b>{t('learning.engage.170.1')}</b>: {t('learning.engage.170.2')}</li>
      </ul>
      <p>{t('learning.engage.171')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/8ciAnHfIiFA?si=fifz6Wo2ZGy-hnaK" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
      <br />
      <div className='text-center'>
        <Image src={step441} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen31;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower11, empower12, empower13, empower14 } from 'assets/learning';

const EmpowerLearningScreen5 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t('learning.empower.27')}</h4>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower11} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.28')}</h5>
          <p>{t('learning.empower.29')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower12} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.30')}</h5>
          <p>{t('learning.empower.31')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower13} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.32')}</h5>
          <p>{t('learning.empower.33')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.34')}</h5>
          <p>{t('learning.empower.35')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower14} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.36')}</h5>
          <p>{t('learning.empower.37')}</p>
        </div>
      </div>
    </>
  )

}

export default EmpowerLearningScreen5;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';

import { connect22 } from 'assets/learning';

const ConnectLearningScreen6 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-1-4" className='text-connect'>{t('learning.connect.29')}</h4>
      <p>{t('learning.connect.30')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-connect' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.connect.31')}</span>
          </span>
        </li>
        <li>
          <FontAwesomeIcon icon={'arrow-right'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'>{t('learning.connect.32')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'arrow-right'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'>{t('learning.connect.33')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'arrow-right'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'>{t('learning.connect.34')}</span>
        </li>
      </ul>
      <p>{t('learning.connect.35.1')}<b>{t('learning.connect.35.2')}</b></p>
      <div className='text-center'>
        <Image src={connect22} width={'50%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen6;
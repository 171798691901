import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower21, empower22 } from 'assets/learning';

const EmpowerLearningScreen9 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="mingling">{t('learning.empower.70')}</h2>
      <p>{t('learning.empower.71.1')}<a href="https://teambuilding.com/blog/zoom-games" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.71.2')}</a>{t('learning.empower.71.3')}</p>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower21} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.72')}</b></h5>
          <p>{t('learning.empower.73.1')}<a href="https://www.aesir-copenhagen.com/blog/best-io-games/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.73.2')}</a>{t('learning.empower.73.3')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-2 text-center'>
          <Image src={empower22} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-10'>
          <h5><b>{t('learning.empower.74')}</b></h5>
          <p>{t('learning.empower.75')}</p>
          <p><span className="text-success">{t('learning.empower.76.1')}</span>: {t('learning.empower.76.2')}</p>
          <p><span className="text-danger">{t('learning.empower.77.1')}</span>: {t('learning.empower.77.2')}</p>
        </div>
      </div>
    </>
  )

}

export default EmpowerLearningScreen9;
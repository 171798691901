import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower19 } from 'assets/learning';

const EmpowerLearningScreen7 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="ice-breakers">{t('learning.empower.60')}</h2>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower19} width={'80%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <p>{t('learning.empower.61')}</p>
        </div>
      </div>
      <br />
      <p><b>{t('learning.empower.62.1')}</b>{t('learning.empower.62.2')}<a href="https://gartic.io/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.62.3')}</a>{t('learning.empower.62.4')}</p>
      <p><b>{t('learning.empower.63.1')}</b>{t('learning.empower.63.2')}<a href="https://imgflip.com/memegenerator" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.63.3')}</a>{t('learning.empower.63.4')}</p>
      <p><b>{t('learning.empower.64.1')}</b>{t('learning.empower.64.2')}<a href="https://randomwordgenerator.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.64.3')}</a>{t('learning.empower.64.4')}</p>
      <p>{t('learning.empower.65')}</p>
      <p><b>{t('learning.empower.66.1')}</b>{t('learning.empower.66.2')}<a href="https://www.geoguessr.com/" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.66.3')}</a>{t('learning.empower.66.4')}</p>
    </>
  )

}

export default EmpowerLearningScreen7;
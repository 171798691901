import React, { useEffect, useState } from 'react';
import { Container, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Empty, Layout, Loader, ScrollToTop } from 'components';
import { opportunity } from 'assets/icons';
import OpportunityCard from './card';
import { useFetch } from 'hooks/fetch';
import { ServerConstants } from 'constant';
import { ModelUtils } from 'utils';

const { API_METHOD_GET, API_URL_VOLUNTEERING_OPPORTUNITIES } = ServerConstants;

const OpportunityListScreen = () => {

  const { t } = useTranslation();
  const { response, loading } = useFetch(API_METHOD_GET, API_URL_VOLUNTEERING_OPPORTUNITIES)
  const [filteredArray, setFilteredArray] = useState([]);
  const [opportunities, setOpportunities] = useState([]);

  useEffect(() => {
    if (response) {
      setOpportunities(response.data.map(el => ModelUtils.convertOpportunityModelB2F(el)));
    }
  }, [response])

  useEffect(() => {
    setFilteredArray(opportunities);
  }, [opportunities]);

  return (
    <Layout>
      <Container className="mt-4 mb-5">
        <div className="mb-5">
          <div className="d-flex flex-direction-row justify-content-between flex-wrap">
            <div className="mt-4">
              <div className="d-flex flex-direction-row align-items-start">
                <Image src={opportunity} />
                <h2 className="logo-name mx-3 text-volunteering">{t('opportunity.title')}</h2>
              </div>
            </div>
          </div>
        </div>
        <p>{t('opportunity.subtitle')}</p>
        <div>
          <Row>
            {loading ? <Loader /> :
            (!filteredArray || filteredArray.length === 0) ? <Empty messageKey={t('opportunities.empty')} /> :
            filteredArray.map(el => <OpportunityCard el={el} t={t} />)}
          </Row>
        </div>
        <ScrollToTop />
      </Container>
    </Layout>
  )
}

export default OpportunityListScreen;
import React, { useCallback, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { useFetch } from 'hooks/fetch';
import { ServerConstants } from 'constant';

const { API_METHOD_DELETE, API_URL_CONNECT } = ServerConstants;

const DeleteContent = ({ fair, session, show, goBack, onSuccess, t }) => {

  const deleteFetch = useFetch(API_METHOD_DELETE);

  useEffect(() => {
    if (deleteFetch.response) {
      onSuccess();
    }
  }, [deleteFetch.response])

  const deleteSession = useCallback(() => {
    deleteFetch.setFetchUrl(API_URL_CONNECT + '/' + fair.id + '/calendar/session/' + session.id);
    deleteFetch.forceFetchData();
  }, [session, fair, show])

  if (!show) {
    return <></>;
  }

  return (
    <div className="px-3 pb-3">
      <h4 className="mb-4">{t('functions.connect.session.action.cancel.confirmation')}</h4>
      <Row>
        <Col className="col-6 mb-0">
          <Button variant='secondary' className="w-100" onClick={goBack}>{t('cancel')}</Button>
        </Col>
        <Col className="col-6 mb-0">
          <Button className="w-100" onClick={deleteSession}>{t('functions.connect.session.action.cancel.submit')}</Button>
        </Col>
      </Row>
    </div>
  )
}

export default DeleteContent;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect14, connect15, connect16, connect17, connect18, connect19 } from 'assets/learning';

const ConnectLearningScreen16 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-5-2" className='text-connect'>{t('learning.connect.155')}</h4>
      <p>{t('learning.connect.156')}</p>
      <h6 id="chapter-5-2-1">{t('learning.connect.157')}</h6>
      <div className='d-flex flex-direction-row'>
        <div className='col-3 text-center'>
          <Image src={connect14} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-9'>
          <p>
            <b>{t('learning.connect.158.1')}</b> {t('learning.connect.158.2')}<br />
            <b>{t('learning.connect.159.1')}</b> {t('learning.connect.159.2')}<br />
            <b>{t('learning.connect.160.1')}</b> {t('learning.connect.160.2')}<br />
            <b>{t('learning.connect.161.1')}</b> {t('learning.connect.161.2')}<br />
          </p>
        </div>
      </div>
      <br />
      <h6 id="chapter-5-2-2">{t('learning.connect.162')}</h6>
      <div className='d-flex flex-direction-row'>
        <div className='col-3 text-center'>
          <Image src={connect15} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-9'>
          <p>
            <b>{t('learning.connect.163.1')}</b> {t('learning.connect.163.2')}<br />
            <b>{t('learning.connect.164.1')}</b> {t('learning.connect.164.2')}<br />
            <b>{t('learning.connect.165.1')}</b> {t('learning.connect.165.2')}<a href="https://www.youtube.com/watch?v=D8JV3w4TOVw" target='_blank' rel='nooppener noreferrer' className='text-connect'>{t('learning.connect.165.3')}</a>{t('learning.connect.165.4')}<a href="https://www.youtube.com/watch?v=VtF2AgFSLAw" target='_blank' rel='nooppener noreferrer' className='text-connect'>{t('learning.connect.165.5')}</a>{t('learning.connect.165.6')}<a href="https://www.youtube.com/watch?v=1eFRxZMPn64" target='_blank' rel='nooppener noreferrer' className='text-connect'>{t('learning.connect.165.7')}</a>{t('learning.connect.165.8')}<br />
            <b>{t('learning.connect.166.1')}</b> {t('learning.connect.166.2')}<br />
          </p>
        </div>
      </div>
      <br />
      <h6 id="chapter-5-2-3">{t('learning.connect.167')}</h6>
      <div className='d-flex flex-direction-row'>
        <div className='col-3 text-center'>
          <Image src={connect16} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-9'>
          <p>
            <b>{t('learning.connect.168.1')}</b> {t('learning.connect.168.2')}<br />
            <b>{t('learning.connect.169.1')}</b> {t('learning.connect.169.2')}<br />
            <b>{t('learning.connect.170.1')}</b> {t('learning.connect.170.2')}<br />
            <b>{t('learning.connect.171.1')}</b> {t('learning.connect.171.2')}<br />
          </p>
        </div>
      </div>
      <br />
      <h6 id="chapter-5-2-4">{t('learning.connect.172')}</h6>
      <div className='d-flex flex-direction-row'>
        <div className='col-3 text-center'>
          <Image src={connect17} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-9'>
          <p>
            <b>{t('learning.connect.173.1')}</b> {t('learning.connect.173.2')}<br />
            <b>{t('learning.connect.174.1')}</b> {t('learning.connect.174.2')}<br />
            <b>{t('learning.connect.175.1')}</b> {t('learning.connect.175.2')}<br />
            <b>{t('learning.connect.176.1')}</b> {t('learning.connect.176.2')}<br />
          </p>
        </div>
      </div>
      <br />
      <h6 id="chapter-5-2-5">{t('learning.connect.177')}</h6>
      <div className='d-flex flex-direction-row'>
        <div className='col-3 text-center'>
          <Image src={connect18} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-9'>
          <p>
            <b>{t('learning.connect.178.1')}</b> {t('learning.connect.178.2')}<br />
            <b>{t('learning.connect.179.1')}</b> {t('learning.connect.179.2')}<br />
            <b>{t('learning.connect.180.1')}</b> {t('learning.connect.180.2')}<br />
            <b>{t('learning.connect.181.1')}</b> {t('learning.connect.181.2')}<br />
          </p>
        </div>
      </div>
      <br />
      <h6 id="chapter-5-2-6">{t('learning.connect.182')}</h6>
      <div className='d-flex flex-direction-row'>
        <div className='col-3 text-center'>
          <Image src={connect19} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-9'>
          <p>
            <b>{t('learning.connect.183.1')}</b> {t('learning.connect.183.2')}<br />
            <b>{t('learning.connect.184.1')}</b> {t('learning.connect.184.2')}<br />
            <b>{t('learning.connect.185.1')}</b> {t('learning.connect.185.2')}<br />
            <b>{t('learning.connect.186.1')}</b> {t('learning.connect.186.2')}<br />
          </p>
        </div>
      </div>

    </>
  )
}

export default ConnectLearningScreen16;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ServerConstants } from 'constant';
import { FormInput, Modal } from 'components';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';

const { API_METHOD_PUT, API_URL_VOLUNTEER } = ServerConstants;

const EngageFunctionEditMemberData = ({ el, onDismiss, onSuccess, show }) => {

  const { t } = useTranslation();
  const { loading, response, setBody } = useFetch(API_METHOD_PUT, API_URL_VOLUNTEER + '/' + el.id);
  const { values, onValueChange, addValues, resetValues } = useValues();

  useEffect(() => {
    if (show) {
      addValues({
        'fullName': el.fullName,
      });
    } else {
      setBody(undefined);
      resetValues();
    }
  }, [show])

  useEffect(() => {
    if (response) {
      onSuccess();
    }
  }, [response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    setBody(values);
    return
  }, [values]);

  return (
    <Modal onHide={!loading ? onDismiss : undefined} show={show}>
      <Container>
        <h3 className="mt-2 mb-4"><b>{t('functions.engage.invitations.edit.title')}</b></h3>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormInput id="fullName" required={true} label={t('functions.engage.invitations.fullName')} onChange={onValueChange} type="text" defaultValue={values['fullName']} />
          <Row className="mt-5">
            <Col className="col-6">
              <Button onClick={onDismiss} variant='secondary' className="w-100" disabled={loading}>{t('cancel')}</Button>
            </Col>
            <Col className="col-6">
              <Button type="submit" className='w-100' disabled={loading}>{t('save')}</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  )
}

export default EngageFunctionEditMemberData;
import { volunteeringVolunteerImage7, volunteeringVolunteerImage8 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const VolunteeringLearningScreen8 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Image src={volunteeringVolunteerImage7} width={'100%'} height={440} style={{ marginTop: -32, objectFit: 'cover' }} />
      <br/><br/>
      <h2 id="step-3">{t('learning.volunteering.volunteer.54')}</h2>
      <p>
        {t('learning.volunteering.volunteer.55.1')}
        <b>{t('learning.volunteering.volunteer.55.2')}</b>
        {t('learning.volunteering.volunteer.55.3')}
      </p>
      <p>
        “{t('learning.volunteering.volunteer.56.1')}“
        (
          <a href="https://op.europa.eu/en/publication-detail/-/publication/297a33c8-a1f3-11e9-9d01-01aa75ed71a1/language-en" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.56.2')}</a>
          {t('learning.volunteering.volunteer.56.3')}
        ).
      </p>
      <p>{t('learning.volunteering.volunteer.57')}</p>

      <Image src={volunteeringVolunteerImage8} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen8;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

import { FormInput, Modal } from 'components';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';
import { ServerConstants } from 'constant';

const { API_METHOD_POST, API_URL_FORUM_THEME } = ServerConstants;

const ForumThemeNewModal = ({ onDismiss, visible, t }) => {

  const { loading, response, setBody } = useFetch(API_METHOD_POST, API_URL_FORUM_THEME);
  const { values, onValueChange, resetValues } = useValues();

  useEffect(() => {
    return (() => {
      resetValues();
    })
  }, [])

  useEffect(() => {
    if (response) {
      onDismiss(true);
    }
  }, [response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    sendRequest();
    return
  }, [values]);

  const sendRequest = useCallback(() => {
    setBody({
      "title": values["title"],
    });
  }, [values])

  return (
    <Modal onHide={!loading ? () => onDismiss(false) : undefined} show={visible}>
      <Container>
        <h4 className="py-2"><b>{t('forum.theme.new')}</b></h4>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormInput id="title" required={true} label={t('forum.theme.name')} onChange={onValueChange} type="text" />
          <Row className="mt-5">
            <Col className="col-6">
              <Button onClick={() => onDismiss(false)} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
            </Col>
            <Col className="col-6">
              <Button type="submit" className='w-100' disabled={loading}>{t('forum.theme.create')}</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  )
}

export default ForumThemeNewModal;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Layout, Loader } from 'components';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import APIKit from 'config/axios/api';

const { PATH_COMMUNITY, PATH_PROFILE_OPPORTUNITY } = AppConstants;
const { API_METHOD_GET, API_URL_VOLUNTEERING_OPPORTUNITY } = ServerConstants;

const OpportunityViewScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[2];

  const opportunityFetch = useFetch(API_METHOD_GET, API_URL_VOLUNTEERING_OPPORTUNITY + '/' + id);
  const [opportunity, setOpportunity] = useState(undefined);

  useEffect(() => {
    if (opportunityFetch.response) {
      const data = ModelUtils.convertOpportunityModelB2F(opportunityFetch.response.data);
      setOpportunity(data);
    }
  }, [id, opportunityFetch.response]);

  const isEditAndDeleteAllowed = useCallback(() => {
    const loggedUser = AppUtils.getLoggedUser();
    if (!loggedUser || !AppUtils.isUserOrganization()) {
      return false;
    }
    return opportunity.active && opportunity.organizationId === loggedUser.id;
  }, [opportunity])

  const deleteOpportunity = useCallback(() => {
    if (window.confirm(t('opportunity.delete.confirm'))) {
      APIKit.delete(API_URL_VOLUNTEERING_OPPORTUNITY + '/' + id)
        .then((res) => window.history.back())
        .catch(e => console.log('Error while deleting', e))
    }
  }, [id, t])

  return (
    <Layout>
      <Container className="my-5">
        {opportunityFetch.loading ? <Loader /> :
          opportunity ?
            <>
            <div className='d-flex flex-direction-row justify-content-between align-items-start'>
                <h2>{opportunity.title}</h2>
                <span>
                  {isEditAndDeleteAllowed() ?
                    <Button onClick={() => window.location.href = PATH_PROFILE_OPPORTUNITY + '/' + opportunity.id + '/edit'} className="m-1">
                      <FontAwesomeIcon icon={'pen-to-square'} />&nbsp;&nbsp;
                      <span>{t('opportunity.edit.title')}</span>
                    </Button>
                    : undefined
                  }
                  {isEditAndDeleteAllowed() ?
                    <Button onClick={deleteOpportunity} className="m-1">
                      <FontAwesomeIcon icon={'trash-alt'} />&nbsp;&nbsp;
                      <span>{t('opportunity.delete.title')}</span>
                    </Button>
                    : undefined}
                </span>
              </div>
              <p>
                <i>{AppUtils.convertDateToLocaleDate(opportunity.datePublished, t('format.date.opportunities'))}</i>
                <span className="mx-2">&#x2022;</span>
                <i><a href={`${PATH_COMMUNITY}/${opportunity.organizationId}`}>{opportunity.organizationName}</a></i>
              </p>
              <Image src={opportunity.featuredImage} alt={opportunity.featuredImage} className="box-shadow" style={{ width: '100%', height: 400, objectFit: 'contain' }} />
              {opportunity.text ? <p className="my-4">{opportunity.text}</p> : undefined}
              {!AppUtils.isUserOrganization() && opportunity.active && opportunity.formUrl ?
                <div className="text-center my-5">
                  <Button onClick={() => window.open(opportunity.formUrl, '_blank')}>{t('opportunity.apply')}</Button>
                </div>
              : undefined}
            </>
            : undefined}
      </Container>
    </Layout>
  )
}

export default OpportunityViewScreen;
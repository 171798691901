import { Layout } from 'components';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningSimulation4 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container className='page'>
        <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
          <colgroup>
            <col className='w-10'></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t('learning.volunteering.organization.172.1')}</th>
              <th>{t('learning.volunteering.organization.172.2')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('learning.volunteering.organization.173.1')}</td>
              <td>{t('learning.volunteering.organization.173.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.174.1')}</td>
              <td>{t('learning.volunteering.organization.174.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.175.1')}</td>
              <td>{t('learning.volunteering.organization.175.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.176.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.176.2')}</li>
                  <li>{t('learning.volunteering.organization.176.3')}</li>
                  <li>{t('learning.volunteering.organization.176.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.177.1')}</td>
              <td>
              {t('learning.volunteering.organization.177.2')}
                <ul>
                  <li>{t('learning.volunteering.organization.177.3')}</li>
                  <li>{t('learning.volunteering.organization.177.4')}</li>
                </ul>
                {t('learning.volunteering.organization.177.5')}
                <ul>
                  <li>{t('learning.volunteering.organization.177.6')}</li>
                  <li>{t('learning.volunteering.organization.177.7')}</li>
                </ul>
                {t('learning.volunteering.organization.177.8')}
                <ul>
                  <li>{t('learning.volunteering.organization.177.9')}</li>
                  <li>{t('learning.volunteering.organization.177.10')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.178.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.178.2')}</li>
                  <li>{t('learning.volunteering.organization.178.3')}</li>
                  <li>{t('learning.volunteering.organization.178.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.179.1')}</td>
              <td>
                <a href={t('learning.volunteering.organization.179.2')} target='_blank' rel='nooppener noreferrer' className="text-volunteering" style={{ verticalAlign: 'sub'}}>{t('learning.volunteering.organization.179.2')}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  )

}

export default VolunteeringLearningSimulation4;
import { step4311 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen27 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-3-1" className='text-engage'>{t('learning.engage.142')}</h4>
      <p>{t('learning.engage.143')}</p>
      <p>{t('learning.engage.144')}</p>
      <p>{t('learning.engage.145')}</p>
      <p>{t('learning.engage.146.1')}<a href="https://docs.google.com/document/d/1Q1en1hnghSrmqkgi3nenIcPZf-JLlmNevFfMoqIUe5s/edit" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.146.2')}</a></p>
      <div className='text-center'>
        <Image src={step4311} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen27;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Empty, FormInput, Layout, Loader, Pager, ScrollToTop } from 'components';
import { forum } from 'assets/icons';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { profilePicture } from 'assets/images';
import { useValues } from 'hooks/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { HEADER_TYPES } = AppConstants;
const { API_METHOD_GET, API_METHOD_POST, API_URL_FORUM_THEME, API_URL_FORUM_POST } = ServerConstants;


const ForumThemePostsScreen = () => {

  const id = window.location.pathname.split('/')[2];
  const { t } = useTranslation();
  const [theme, setTheme] = useState();
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [itemsPerPage, ] = useState(20);
  const themeInfoFetch = useFetch(API_METHOD_GET, API_URL_FORUM_THEME + '/' + id);
  const postsListFetch = useFetch(API_METHOD_GET);
  const [replyTo, setReplyTo] = useState(undefined);

  useEffect(() => {
    postsListFetch.setFetchUrl(API_URL_FORUM_THEME + '/' + id + '/' + page + '/' + itemsPerPage);
    postsListFetch.forceFetchData();
  }, [page, itemsPerPage]);

  useEffect(() => {
    if (postsListFetch.response) {
      setPosts(postsListFetch.response.data.map(el => ModelUtils.convertForumThemePostB2F(el)));
    }
  }, [postsListFetch.response])

  useEffect(() => {
    if (themeInfoFetch.response) {
      setTheme(ModelUtils.convertForumThemeModelB2F(themeInfoFetch.response.data));
    }
  }, [themeInfoFetch.response])

  const isLoading = useCallback(() => (postsListFetch && postsListFetch.loading) || (themeInfoFetch && themeInfoFetch.loading), [postsListFetch, themeInfoFetch])

  const onPageChange = useCallback((val) => setPage(val), [itemsPerPage]);
  const reload = useCallback(() => {
    postsListFetch.forceFetchData();
    themeInfoFetch.forceFetchData();
    setReplyTo(undefined);
  }, []);

  const setReplyToPost = (object) => {
    setReplyTo(object);
    document.getElementById("content").scrollIntoView();
  };

  if (isLoading() || !theme) {
    return (
      <Layout type={HEADER_TYPES.FORUM}>
        <Container className="mt-4 mb-5">
          <Loader />
        </Container>
      </Layout>
    )
  }

  return (
    <Layout type={HEADER_TYPES.FORUM}>
      <Container className="mt-4 mb-5">
        <div className="mb-5">
          <div className="d-flex flex-direction-row justify-content-between flex-wrap">
            <div className="mt-4">
              <div className="d-flex flex-direction-row align-items-start">
                <Image src={forum} />
                <h2 className="logo-name mx-3 text-primary">{theme.title}</h2>
              </div>
            </div>
          </div>
        </div>
        <div>
          {(!posts || posts.length === 0) ? <Empty messageKey={t('forum.post.empty')} /> :
            <>
              {posts.map(el => <PostItem el={el} t={t} setReplyToPost={setReplyToPost} />)}
              <Pager
                page={page}
                numberOfItems={theme?.postsCount}
                itemsPerPage={itemsPerPage}
                onPageChange={onPageChange}
              />
            </>
          }
          <PostInput themeId={id} t={t} reload={reload} relatedPost={replyTo} dismiss={() => setReplyTo(undefined)} />
        </div>
        <ScrollToTop />
      </Container>
    </Layout>
  )
}

const PostItem = ({ el, t, setReplyToPost }) => {
  return (
    <div className='box box-shadow p-3' id={el.id} key={el.id}>
      <Row>
        <Col className='col-2 mb-0 text-center'>
          <Image src={el.authorImage ?? profilePicture} width={64} height={64} style={{ borderRadius: 64, objectFit: 'cover', border: '1px solid gray' }} />
          <h6 className='pt-2'><b>{el.authorName}</b></h6>
        </Col>
        <Col className='col-10 mb-0 pl-3' style={{ flexGrow: 1 }}>
          <div className='d-flex flex-direction-row justify-content-between'>
            <small>{AppUtils.convertDateToLocaleDate(el.createdAt, t('format.date.time'))}</small>
            <small><a onClick={() => setReplyToPost(el)} className='p-0'><FontAwesomeIcon icon='reply' /></a></small>
          </div>
          <RelatedPostItem id={el.relatedPostId} name={el.relatedPostAuthorName} content={el.relatedPostContent} />
          <p className={el.relatedPostId ? 'mt-2' : ''}>{el.content}</p>
        </Col>
      </Row>
    </div>
  )
}

const RelatedPostItem = ({ id, name, content, dismiss }) => {
  if (!id) {
    return <></>;
  }
  return (
    <div className='box px-3 mb-0' style={{ backgroundColor: 'var(--general-color-opacity)' }}>
      <div className='d-flex flex-direction-row justify-content-between align-items-center'>
        <h6><b>{name}</b></h6>
        <small>{dismiss && <a onClick={dismiss}><FontAwesomeIcon icon={'close'} /></a>}</small>
      </div>
      {content}
    </div>
  )
}

const PostInput = ({ themeId, relatedPost, reload, dismiss, t }) => {

  const { loading, response, setBody } = useFetch(API_METHOD_POST, API_URL_FORUM_POST);
  const { values, onValueChange, resetValues } = useValues();

  useEffect(() => {
    return (() => {
      resetValues();
    })
  }, []);

  useEffect(() => {
    if (response) {
      reload();
    }
  }, [response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    sendRequest();
    return
  }, [values]);

  const sendRequest = useCallback(() => {
    setBody({
      "themeId": themeId,
      "content": values["content"],
      "relatedPostId": relatedPost?.id
    });
  }, [values, relatedPost])

  console.log('relatedPost', relatedPost);

  return (
    <div id="input-form">
      <hr/>
      {relatedPost &&
        <Row className="mt-2 row-cols-2 g-0">
          <Col className="col-10 pr-4 mb-0">
            <RelatedPostItem id={relatedPost.id} name={relatedPost.authorName} content={relatedPost.content} dismiss={dismiss} />
          </Col>
        </Row>
      }
      <Form onSubmit={(e) => handleSubmit(e)}>
        <Row className="mt-2 row-cols-2 g-0">
          <Col className="col-10 pr-4">
            <FormInput id="content" required={true} onChange={onValueChange} type="text" as="textarea" rows={3} />
          </Col>
          <Col className="col-2">
            <Button type="submit" className='w-100' disabled={loading}>{t('forum.theme.create')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ForumThemePostsScreen;
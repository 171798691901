import React from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'react-bootstrap';

import { step421, step4211, step422, step423 } from 'assets/learning';

const EngageLearningScreen21 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-2" className='text-engage'>{t('learning.engage.99')}</h4>
      <p>{t('learning.engage.100')}</p>
      <p>{t('learning.engage.101')}</p>
      <p>{t('learning.engage.102')}</p>
      <div className='d-flex flex-direction-row justify-content-center m-5'>
        <Image src={step421} width={100} className='mx-3' />
        <Image src={step422} width={100} className='mx-3' />
        <Image src={step423} width={100} className='mx-3' />
        <Image src={step421} width={100} className='mx-3' />
        <Image src={step422} width={100} className='mx-3' />
        <Image src={step423} width={100} className='mx-3' />
      </div>
      <p>{t('learning.engage.103')}</p>
      <p>{t('learning.engage.104')}</p>
      <div className='text-center'>
        <Image src={step4211} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen21;
import React from 'react';
import DefaultHeader from './header/default';
import Footer from './footer';
import AdminHeader from './header/admin';
import { AppConstants } from 'constant';
import EngageHeader from './header/engage';
import EmpowerHeader from './header/empower';
import ConnectHeader from './header/connect';
import { AppUtils } from 'utils';
import { useTranslation } from 'react-i18next';
import VolunteerHeader from './header/volunteer';
import Page403Screen from 'screens/403';

const { HEADER_TYPES } = AppConstants;

const Layout = ({ children, type = HEADER_TYPES.DEFAULT, title = '', additional }) => {
  const { t } = useTranslation();
  if (type === HEADER_TYPES.ENGAGE) {
    const isViewAllowed = AppUtils.isUserOrganization() || AppUtils.isUserMember();
    if (!isViewAllowed) {
      return (
        <div className="main">
          <Page403Screen />
        </div>
      )
    }
    return (
      <div className="main">
        <EngageHeader />
        <div className="content vista">
          {children}
        </div>
      </div>
    )
  } else if (type === HEADER_TYPES.EMPOWER) {
    return (
      <div className="main">
        <EmpowerHeader />
        <div className="content vista">
          {children}
        </div>
      </div>
    )
  } else if (type === HEADER_TYPES.CONNECT) {
    const isViewAllowed = AppUtils.isUserOrganization() || AppUtils.isUserMember();
    if (!isViewAllowed) {
      return (
        <div className="main">
          <Page403Screen />
        </div>
      )
    }
    return (
      <div className="main">
        <ConnectHeader title={title} additional={additional} />
        <div className="content vista">
          {children}
        </div>
      </div>
    )
  } else if (type === HEADER_TYPES.VOLUNTEER) {
    return (
      <div className="main">
        <VolunteerHeader title={title} additional={additional} />
        <div className="content vista">
          {children}
        </div>
      </div>
    )
  } else if (type === HEADER_TYPES.ADMIN) {
    const isViewAllowed = AppUtils.isUserAdmin();
    if (!isViewAllowed) {
      return (
        <div className="main">
          <Page403Screen />
        </div>
      )
    }
    return (
      <div className="main">
        <AdminHeader />
        <div className="content">
          {children}
        </div>
      </div>
    )
  } else if (type === HEADER_TYPES.DEFAULT) {
    return (
      <div className="main">
        <DefaultHeader />
        <div className="content">
          {children}
        </div>
        <Footer />
      </div>
    )
  } else if (type === HEADER_TYPES.FORUM) {
    const isViewAllowed = AppUtils.isUserOrganization() || AppUtils.isUserMember();
    return (
      <div className="main">
        <DefaultHeader />
        <div className="content">
          {isViewAllowed ? children : <p>{t('forum.message.notAllowed')}</p>}
        </div>
        <Footer />
      </div>
    )
  }
  return (
    <div className="main">
      <div className="content">
        {children}
      </div>
    </div>
  )
}

export default Layout;
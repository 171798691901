/* eslint-disable react-hooks/exhaustive-deps */
import { Empty, FormInput, Layout } from 'components';
import { ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import AccountDeletion from 'screens/modal/deletion';
import { AppUtils } from 'utils';

const { API_METHOD_DELETE, API_URL_USER } = ServerConstants;

const ProfileDeleteScreen = () => {
  const { t } = useTranslation();
  const deleteFetch = useFetch(API_METHOD_DELETE);
  const loggedUser = AppUtils.getLoggedUser();
  const [values, setValues] = useState({});
  const [error, setError] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(false);
  const code = useRef(makeCode(6));

  useEffect(() => {
    if (!deleteFetch.response) {
      return;
    }
    if (deleteFetch.response.status === 200) {
      setModalVisible(true);
      AppUtils.logOut();
      window.location.href = '/';
    }
  } ,[deleteFetch]);

  const handleSubmit = useCallback((event) => {
    setError(undefined);
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    if (values.codeConfirm !== code.current) {
      setError(t('profile.delete.error'));
      return;
    }
    deleteFetch.setFetchUrl(API_URL_USER);
    deleteFetch.setBody({});
  }, [values]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  }, [values]);

  const isLoading = useCallback(() => deleteFetch.loading, [deleteFetch.loading])
  const onModalDismiss = useCallback(() => {
    setModalVisible(false);
    window.location.href = '/';
  }, []);

  if (!loggedUser) {
    return (
      <Layout>
        <Container className="my-5">
          <h2>{t('profile.delete.title')}</h2>
          <Empty messageKey={t('profile.delete.login')}/>
        </Container>
      </Layout>
    )
  }
  return (
    <Layout>
      <Container className="my-5">
        <AccountDeletion t={t} visible={modalVisible} onDismiss={onModalDismiss} />
        <h2>{t('profile.delete.title')}</h2>
        <p><Trans i18nKey={t('profile.delete.description', { code: code.current })} /></p>

        <Form onSubmit={handleSubmit}>
          <Row className="mt-4">
            <Col xs={0} sm={0} md={3} />
            <Col xs={12} sm={12} md={6}>
              <FormInput
                id="codeConfirm"
                label={"Code from above"}
                required={true}
                onChange={onFormChange}
                text={error}
                isInvalid={error}
              />
              <Button type='submit' disabled={isLoading()}>Delete</Button>
            </Col>
            <Col xs={0} sm={0} md={3} />
          </Row>
        </Form>
      </Container>
    </Layout>
  )
}

function makeCode(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export default ProfileDeleteScreen;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Button, Image } from 'react-bootstrap';

import { AppUtils } from 'utils';
import { profilePicture } from 'assets/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SessionContent = ({ fair, session, show, goBack, deleteConfirmation, t }) => {

  const member = session?.host;

  const getSessionLink = useCallback(() => session.hostLink, [show, session]);

  const isTimeForMeeting = useCallback(() => {
    if (!session || !getSessionLink()) {
      return false;
    }
    const now = new Date().getTime();
    const startTime = new Date(AppUtils.convertDateToLocaleDate(session.startTime)).getTime();
    const endTime = new Date(AppUtils.convertDateToLocaleDate(session.endTime)).getTime();
    return now >= startTime && now <= endTime;
  }, [show])

  if (!show) {
    return <></>;
  }

  return (
    <div className="px-3 pb-3">
      <div className="d-flex flex-direction-row align-items-center mt-2">
        <Image src={member?.profilePicture ? member.profilePicture : profilePicture} width={64} height={64} style={{ borderRadius: 64, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
        <div className="mx-4">
          <h5 className="mb-0 pt-3"><b>{member.name}</b></h5>
          <p>{member.position}</p>
        </div>
      </div>
      <p className="my-2">
        {t('functions.connect.session.time', {
          start: AppUtils.convertDateToLocaleDate(session.startTime, t("format.time")).toString(),
          end: AppUtils.convertDateToLocaleDate(session.endTime, t("format.time")).toString(),
          date: AppUtils.convertDateToLocaleDate(session.startTime, t("format.date.fair")).toString()
        })}
      </p>
      <div className="my-3">
        <hr />
        {AppUtils.isFairLive(fair) ?
          <>
            <p>{t('functions.connect.session.host.' + (session.hostMe ? 'you' : 'another'), { name: member.name })}</p>
            {isTimeForMeeting() ?
              <a href={AppUtils.renderWebAddress(getSessionLink())} target='_blank' className="mb-3 clickable" rel='noreferrer'>
                <span>{t('functions.connect.session.action.join.title')}</span>
              </a>
              : undefined}
          </>
          :
          AppUtils.isFairPreLive(fair) ?
            <a onClick={deleteConfirmation} className="mb-3 clickable">
              <FontAwesomeIcon icon={'trash-alt'} size='xl' />
              <span className="pl-2">{t('functions.connect.session.action.cancel.title')}</span>
            </a>
            : undefined
        }
      </div>
      <div className="text-center mt-4">
        <Button variant='secondary' className="w-50" onClick={goBack}>{t('functions.connect.session.action.back')}</Button>
      </div>
    </div>
  )
}

export default SessionContent;
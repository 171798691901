/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import { Modal } from 'components';

const { API_METHOD_DELETE, API_URL_VOLUNTEERING_INVITATION } = ServerConstants;

const EngageFunctionDeleteMemberData = ({ el, onDismiss, onSuccess, show }) => {

  const { t } = useTranslation();
  const { loading, response, setFetchUrl, forceFetchData } = useFetch(API_METHOD_DELETE);

  useEffect(() => {
    if (response) {
      onSuccess();
    }
    return (() => {
      setFetchUrl(undefined);
    })
  }, [response]);

  const sendRequest = useCallback(() => {
    setFetchUrl(API_URL_VOLUNTEERING_INVITATION + '/' + el.id);
    forceFetchData();
  }, []);

  return (
    <Modal onHide={!loading ? onDismiss : undefined} show={show}>
      <Container>
        <h3 className="mt-2 mb-4"><b>{t('functions.engage.invitations.delete.title')}</b></h3>
        <div className="d-flex flex-direction-row align-items-center">
          <span>{el.fullName}</span>
          <span className="mx-2">&#x2022;</span>
          <span>{el.email}</span>
        </div>
        <Row className="mt-5">
          <Col className="col-6">
            <Button onClick={onDismiss} variant='secondary' className="w-100" disabled={loading}>{t('cancel')}</Button>
          </Col>
          <Col className="col-6">
            <Button onClick={sendRequest} className='w-100' disabled={loading}>{t('yes')}</Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  )
}

export default EngageFunctionDeleteMemberData;
import { Layout } from 'components';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningSimulation1 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container className='page'>
        <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
          <colgroup>
            <col className='w-10'></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t('learning.volunteering.organization.21.1')}</th>
              <th>{t('learning.volunteering.organization.21.2')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('learning.volunteering.organization.22.1')}</td>
              <td>{t('learning.volunteering.organization.22.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.23.1')}</td>
              <td>{t('learning.volunteering.organization.23.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.24.1')}</td>
              <td>{t('learning.volunteering.organization.24.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.25.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.25.2')}</li>
                  <li>{t('learning.volunteering.organization.25.3')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.26.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.26.2')}</li>
                  <li>{t('learning.volunteering.organization.26.3')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.27.1')}</td>
              <td>
                <p>{t('learning.volunteering.organization.27.2')}:</p>
                <b>{t('learning.volunteering.organization.27.3')}</b>
                <p>{t('learning.volunteering.organization.27.4')}</p>
                <b>{t('learning.volunteering.organization.27.5')}</b>
                <ul>
                  <li>{t('learning.volunteering.organization.27.6')}</li>
                  <li>{t('learning.volunteering.organization.27.7')}</li>
                </ul>
                <br/>
                <b>{t('learning.volunteering.organization.27.8')}:</b>
                <p>{t('learning.volunteering.organization.27.9')}</p>
                <b>{t('learning.volunteering.organization.27.10')}</b>
                <ul>
                  <li>{t('learning.volunteering.organization.27.11')}</li>
                  <li>{t('learning.volunteering.organization.27.12')}</li>
                  <li>{t('learning.volunteering.organization.27.13')}</li>
                </ul>
                <br/>
                <b>{t('learning.volunteering.organization.27.14')}:</b>
                <p>{t('learning.volunteering.organization.27.15')}</p>
                <b>{t('learning.volunteering.organization.27.16')}:</b>
                <ul>
                  <li>{t('learning.volunteering.organization.27.17')}</li>
                  <li>{t('learning.volunteering.organization.27.18')}</li>
                </ul>
                <p>{t('learning.volunteering.organization.27.19')}</p>
                <p>{t('learning.volunteering.organization.27.20')}</p>
                <p>{t('learning.volunteering.organization.27.21')}</p>
                <p>{t('learning.volunteering.organization.27.22')}</p>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.28.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.organization.28.2')}</li>
                  <li>{t('learning.volunteering.organization.28.3')}</li>
                  <li>{t('learning.volunteering.organization.28.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.organization.29.1')}</td>
              <td>{t('learning.volunteering.organization.29.2')}</td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  )

}

export default VolunteeringLearningSimulation1;
import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';

import { FormInput, FormUpload, Layout, Loader } from 'components';
import { useFetch } from 'hooks/fetch';
import { AppUtils, ModelUtils } from 'utils';
import { AppConstants, ServerConstants } from 'constant';
import { CountriesData, FieldsData } from 'data';
import { useValues } from 'hooks/values';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { PATH_PROFILE, USER_DATA } = AppConstants;
const { API_V1_GET, API_METHOD_POST, API_URL_ORGANIZATION } = ServerConstants;

const ProfileEditScreen = () => {

  const { t } = useTranslation();

  const profileFetch = useFetch(API_V1_GET, API_URL_ORGANIZATION + '/' + AppUtils.getLoggedUser().id);
  const submitFetch = useFetch(API_METHOD_POST, API_URL_ORGANIZATION + '/edit');
  const { values, onValueChange, addValues, setValue } = useValues({});
  const fieldsTypeAheadRef = useRef();

  useEffect(() => {
    if (profileFetch.response) {
      addValues(ModelUtils.convertOrganizationB2F(profileFetch.response.data));
    }
  }, [profileFetch.response]);

  useEffect(() => {
    if (submitFetch.response) {
      localStorage.setItem(USER_DATA, JSON.stringify({
        ...JSON.parse(localStorage.getItem(USER_DATA)),
        ...submitFetch.response.data
      }));
      window.location.href = PATH_PROFILE;
    }
  }, [submitFetch.response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    submitFetch.setBody(ModelUtils.convertOrganizationProfileModelF2B(values));
    return;
  }, [values]);

  return (
    <Layout>
      <Container className="my-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <h2>{t('profile.edit')}</h2>
          </div>
        </div>
        {profileFetch.loading ? <Loader /> :
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Row>
              <Col className="col-6">
                <div className="my-3">
                  <h3>{t('profile.organization')}</h3>
                </div>
                <FormInput id="name" required={true} label={t('profile.name')} onChange={onValueChange} type="text" defaultValue={values["name"]} />
                <FormUpload id="profilePicture" label={t('profile.image')} onValueChange={onValueChange} value={values["profilePicture"]} removeImage={() => setValue('profilePicture', undefined)} types={['.svg']} />
                <FormInput id="about" required={true} label={t('profile.about')} onChange={onValueChange} type="text" as="textarea" rows="6" defaultValue={values["about"]} />
                <FormInput id="website" required={true} label={t('profile.website')} onChange={onValueChange} type="text" defaultValue={values["website"]} />
                <FormInput id="address"required={true} label={t('profile.address')} onChange={onValueChange} type="text" defaultValue={values["address"]} />
                <FormInput id="city" required={true} label={t('profile.city')} onChange={onValueChange} type="text" defaultValue={values["city"]} />
                <Form.Group className="mb-3">
                  <Form.Label htmlFor={'country'}>{t('profile.country.title')}</Form.Label>
                  <Typeahead
                    maxResults={Object.keys(CountriesData).length}
                    options={Object.keys(CountriesData).map((el, i) => CountriesData[el])}
                    paginate={true}
                    emptyLabel={t('profile.country.empty')}
                    iconName={'chevron-down'}
                    id={'country'}
                    label={t('profile.country.label')}
                    labelKey={option => `${option}`}
                    renderItem={(options) => (
                      <div key={options}>
                        <p className="my-1">{options}</p>
                      </div>
                    )}
                    t={t}
                    inputProps={{ required: true, defaultValue: values['country'] }}
                    onChange={(selected) => onValueChange({ target: { id: 'country', value: selected ? Object.keys(CountriesData).find(el => CountriesData[el] === selected[0]) : undefined } })}
                    selected={values['country'] ? [CountriesData[values['country']]] : []}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor={'fields'}>{t('profile.fields')}</Form.Label>
                  <Typeahead
                    ref={fieldsTypeAheadRef}
                    maxResults={Object.keys(FieldsData).length}
                    options={Object.keys(FieldsData).map((el, i) => FieldsData[el])}
                    paginate={true}
                    emptyLabel={t('profile.country.empty')}
                    iconName={'chevron-down'}
                    id={'country'}
                    label={t('profile.country.label')}
                    labelKey={option => `${option}`}
                    renderItem={(options) => (
                      <div key={options}>
                        <p className="my-1">{options}</p>
                      </div>
                    )}
                    t={t}
                    onChange={(selected) => {
                      if (!values['fields'] || !values['fields'].includes(selected[0])) {
                        setValue('fields', [...values['fields'], selected[0]]);
                      }
                      fieldsTypeAheadRef.current.clear();
                    }}
                  />
                </Form.Group>
                <div className='mt-2 d-flex flex-direction-row flex-wrap'>{values['fields'] && values['fields'].map(el => 
                  <span className="clickable tag" onClick={() => setValue('fields', values['fields'].filter(field => field !== el))}>
                    {el}&nbsp;<FontAwesomeIcon icon={'close'} color='white' />
                  </span>
                )}</div>
              </Col>
              <Col className="col-6">
                <div className="my-3">
                  <h3>{t('profile.social')}</h3>
                </div>
                <FormInput id="instagram" label={t('profile.instagram')} onChange={onValueChange} type="text" defaultValue={values["instagram"]} />
                <FormInput id="linkedIn" label={t('profile.linkedIn')} onChange={onValueChange} type="text" defaultValue={values["linkedIn"]} />
                <FormInput id="facebook" label={t('profile.facebook')} onChange={onValueChange} type="text" defaultValue={values["facebook"]} />
                <FormInput id="twitter" label={t('profile.twitter')} onChange={onValueChange} type="text" defaultValue={values["twitter"]} />
                <FormInput id="youtube" label={t('profile.youtube')} onChange={onValueChange} type="text" defaultValue={values["youtube"]} />
                <Row className="mt-5">
                  <Col className="col-6">
                    <Button onClick={() => window.location.href = PATH_PROFILE} variant='secondary' className="w-100" disabled={submitFetch.loading}>{t('profile.cancel')}</Button>
                  </Col>
                  <Col className="col-6">
                    <Button type="submit" className='w-100' disabled={submitFetch.loading}>{t('profile.save')}</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        }
      </Container>
    </Layout>
  )
}

export default ProfileEditScreen;
import { volunteeringVolunteerImage14 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen14 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-5-4" className='text-volunteering'>{t('learning.volunteering.volunteer.90')}</h4>
      <p>{t('learning.volunteering.volunteer.91')}</p>
      <p>{t('learning.volunteering.volunteer.92')}</p>
      <p>{t('learning.volunteering.volunteer.93')}</p>
      <div className='box mb-5'>
        <i>
          {t('learning.volunteering.volunteer.94.1')}
          <a href="https://www.youtube.com/watch?v=ERhuMm9lFgw" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.94.2')}</a>
          {t('learning.volunteering.volunteer.94.3')}
        </i>
      </div>
      <p><b>{t('learning.volunteering.volunteer.95')}</b></p>
      <p>{t('learning.volunteering.volunteer.96')}</p>
      <p><b>{t('learning.volunteering.volunteer.97.1')}</b></p>
      <ul>
        <li><b>{t('learning.volunteering.volunteer.97.2')}</b>: {t('learning.volunteering.volunteer.97.3')}</li>
        <li><b>{t('learning.volunteering.volunteer.97.4')}</b>: {t('learning.volunteering.volunteer.97.5')}</li>
        <li><b>{t('learning.volunteering.volunteer.97.6')}</b>: {t('learning.volunteering.volunteer.97.7')}</li>
        <li><b>{t('learning.volunteering.volunteer.97.8')}</b>: {t('learning.volunteering.volunteer.97.9')}</li>
      </ul>
      <p><b>{t('learning.volunteering.volunteer.98')}</b></p>
      <p>{t('learning.volunteering.volunteer.99')}</p>
      <p><b>{t('learning.volunteering.volunteer.100.1')}</b></p>
      <ol>
        <li className='pb-0'><b>{t('learning.volunteering.volunteer.100.2')}</b>: {t('learning.volunteering.volunteer.100.3')}</li>
        <li className='pb-0'><b>{t('learning.volunteering.volunteer.100.4')}</b>: {t('learning.volunteering.volunteer.100.5')}</li>
        <li className='pb-0'><b>{t('learning.volunteering.volunteer.100.6')}</b>: {t('learning.volunteering.volunteer.100.7')}</li>
      </ol>
      <Image src={volunteeringVolunteerImage14} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen14;
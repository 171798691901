import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Empty, Layout, Loader } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import NewsApproveModal from './modal/approve';
import NewsRejectModal from './modal/reject';
import { AppUtils, ModelUtils } from 'utils';
import APIKit from 'config/axios/api';

const {
  HEADER_TYPES,
  PATH_ADMIN_NEWS,
  NEWS_STATUS_PUBLISHED, NEWS_STATUS_SUBMITTED, NEWS_STATUS_REJECTED
} = AppConstants;

const { API_METHOD_GET, API_URL_NEWS_ADMIN, API_URL_NEWS } = ServerConstants;

const modals = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
};

const filter = {
  PUBLISHED: NEWS_STATUS_PUBLISHED,
  SUBMITTED: NEWS_STATUS_SUBMITTED,
  REJECTED: NEWS_STATUS_REJECTED
}
const AdminNewsListScreen = () => {

  const { response, loading } = useFetch(API_METHOD_GET, API_URL_NEWS_ADMIN);
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState(filter[filter.ALL]);
  const [news, setNews] = useState(undefined);
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (!response) {
      return;
    }
    setNews(response.data.map(el => ModelUtils.convertArticleModelB2F(el)));
  }, [response]);

  useEffect(() => {
    let filtered = news;
    if (filter[filterBy]) {
      filtered = filtered.filter(el => el.status.toUpperCase() === filterBy);
    }
    setFilteredArray(filtered);
  }, [filterBy, news]);

  const deleteArticle = useCallback((id) => {
    if (window.confirm(t('news.delete.confirm'))) {
      APIKit.delete(API_URL_NEWS + '/' + id)
        .then((res) => window.location.href = PATH_ADMIN_NEWS)
        .catch(e => console.log('Error while deleting', e))
    }
  }, [])

  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="mt-4 mb-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <div className="d-flex flex-direction-row align-items-start flex-grow">
              <h2 className="pr-4">{t('news.admin.title')}</h2>
              <Button className="mx-2 px-3" onClick={() => window.location.href = PATH_ADMIN_NEWS + '/new'}><FontAwesomeIcon icon={'plus'} /></Button>
            </div>
          </div>
          {loading ? <Loader /> :
            <>
              <div className="d-flex flex-direction-row flex-shrink">
                <div className={'admin-community-filter'} key={'filter-all'}>
                  <Button variant='link' className={filterBy === undefined ? ' active' : ''} onClick={() => setFilterBy(undefined)}>{t('organizations.admin.filter.ALL')}{news && news.length > 0 ? ' (' + news.length + ')' : ''}</Button>
                </div>
                {Object.keys(filter).map(el => (
                  <div className={'admin-community-filter'} key={'filter-' + el}>
                    <Button variant='link' className={filter[el] === filter[filterBy] ? ' active' : ''} onClick={() => setFilterBy(el)}>{t('news.admin.filter.' + el)}</Button>
                  </div>
                ))}
              </div>
              {(!filteredArray || filteredArray.length === 0) ? <Empty messageKey={t('news.empty')} /> :
                <Table bordered={true} className="mt-4">
                  <thead>
                    <tr>
                      <th>{t('news.name')}</th>
                      <th>{t('news.submission')}</th>
                      <th>{t('news.submitter')}</th>
                      <th>{t('news.status.title')}</th>
                      <th>{t('news.action.title')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredArray.map(el => <TableItem el={el} t={t} deleteArticle={deleteArticle} />)}
                  </tbody>
                </Table>}
            </>}
        </div>
      </Container>
    </Layout >
  )
}

const TableItem = ({ el, t, deleteArticle }) => {

  const [modalVisible, setModalVisible] = useState(undefined);

  const showModals = useCallback((modal) => setModalVisible(modal), []);
  const hideModals = useCallback(() => setModalVisible(undefined), []);

  return (
    <tr key={el.articleId}>
      <NewsApproveModal el={el} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.APPROVE} />
      <NewsRejectModal el={el} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.REJECT} />
      <>
        <td><Link to={PATH_ADMIN_NEWS + '/' + el.id}>{el.title}</Link></td>
        <td className="w-15">{AppUtils.convertDateToLocaleDate(el.submissionDate, t('format.date.submission'))}</td>
        <td className="w-20">{el.organizationName}</td>
        <td className="w-15">
          {el.status === filter.PUBLISHED ? <div className="d-flex flex-direction-row align-items-center text-muted"><FontAwesomeIcon icon={'circle-check'} />&nbsp;&nbsp;<span className="d-none d-md-block">{t('news.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
          {el.status === filter.REJECTED ? <div className="d-flex flex-direction-row align-items-center text-muted"><FontAwesomeIcon icon={'circle-xmark'} />&nbsp;&nbsp;<span className="d-none d-md-block">{t('news.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
          {el.status === filter.SUBMITTED ? <div className="d-flex flex-direction-row align-items-center"><FontAwesomeIcon icon={'clock'} />&nbsp;&nbsp;<span className="d-none d-md-block">{t('news.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
        </td>
        <td className="w-20">
          <div className="d-flex flex-direction-row align-items-center flex-wrap">
            {el.status === filter.SUBMITTED ?
              <>
                <a onClick={() => showModals(modals.APPROVE)} className='text-success clickable'>{t('news.action.approve')}</a>
                <a onClick={() => showModals(modals.REJECT)} className='text-error clickable'>{t('news.action.reject')}</a>
              </>
              : undefined}
            {el.editable ?
              <Link onClick={() => window.location.href = PATH_ADMIN_NEWS + '/' + el.id + '/edit'}><FontAwesomeIcon icon={'pen-to-square'} /></Link>
              : undefined
            }
            {el.deletable ?
              <Link onClick={() => deleteArticle(el.id)}><FontAwesomeIcon icon={'trash-alt'} /></Link>
              : undefined}
          </div>
        </td>
      </>
    </tr>
  )
}

export default AdminNewsListScreen;
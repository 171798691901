/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';

import { AppUtils, ModelUtils } from 'utils';
import { FormUpload, Modal } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';

const { USER_DATA } = AppConstants;
const { API_METHOD_PATCH, API_URL_MEMBER_PICTURE, API_URL_VOLUNTEER_PICTURE } = ServerConstants;

const ChangeProfilePictureModal = ({ onDismiss, show, t }) => {

  const loggedUser = AppUtils.getLoggedUser();
  const { values, onValueChange, setValue, addValues } = useValues(loggedUser);
  const changePictureFetch = useFetch(API_METHOD_PATCH, undefined);

  useEffect(() => {
    if (AppUtils.isUserMember()) {
      addValues(ModelUtils.convertLoggedUserMember());
    } else if (AppUtils.isUserVolunteer()) {
      addValues(ModelUtils.convertLoggedUserMember());
    }
  }, []);

  useEffect(() => {
    if (changePictureFetch.response) {
      localStorage.setItem(USER_DATA, JSON.stringify({
        ...JSON.parse(localStorage.getItem(USER_DATA)),
        ...{ 'profilePicture': values['profilePicture'] }
      }));
      window.location.reload();
    }
  }, [changePictureFetch.response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    if (AppUtils.isUserMember()) {
      changePictureFetch.setFetchUrl(API_URL_MEMBER_PICTURE);
      changePictureFetch.setBody(ModelUtils.convertLoggedUserMemberF2B(values));
    } else if (AppUtils.isUserVolunteer()) {
      changePictureFetch.setFetchUrl(API_URL_VOLUNTEER_PICTURE);
      changePictureFetch.setBody(ModelUtils.convertLoggedUserVolunteerF2B(values));
    }
  }, [values])

  return (
    <Modal onHide={onDismiss} show={show}>
      <Container>
        <h5 className="mb-4">{t('profile.picture.change.title')}</h5>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormUpload id="profilePicture"
            onValueChange={onValueChange}
            removeImage={() => setValue('profilePicture', undefined)}
            value={values['profilePicture']}
            required={false}
          />
          <Button type="submit" className='w-100 my-3' disabled={changePictureFetch.loading || !values['profilePicture']}>{t('save')}</Button>
        </Form>
      </Container>
    </Modal>
  )
}

export default ChangeProfilePictureModal;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';

import { User } from 'models';
import { CheckBox, FormInput, Modal } from 'components';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils } from 'utils';

const { API_METHOD_GET, API_METHOD_POST, API_URL_USER_LOGIN, API_URL_USER_FIND, API_URL_ORGANIZATION, API_URL_MEMBER, API_URL_VOLUNTEER } = ServerConstants;
const { AUTH_TOKEN, AUTH_TOKEN_TO_DATE, USER_DATA, PATH_ADMIN_COMMUNITY } = AppConstants;

const LoginModal = ({ onDismiss, modals, show, showForgetPasswordModal, showSignUpModal, t }) => {

  const [values, setValues] = useState({});
  const [rememberMe, setRememberMe] = useState(true);
  const [loginError, setLoginError] = useState(undefined);
  const loginFetch = useFetch(API_METHOD_POST, API_URL_USER_LOGIN);
  const userFetch = useFetch(API_METHOD_GET);
  const moreDataFetch = useFetch(API_METHOD_GET);

  useEffect(() => {
    if (!show) {
      setValues({});
      setRememberMe(true);
      setLoginError(undefined);
      loginFetch.clearData();
      userFetch.clearData();
      moreDataFetch.clearData();
    }
  }, [show]);

  useEffect(() => {
    if (!loginFetch.response) {
      return;
    }
    if (loginFetch.response.status === 200) {
      const { accessToken } = loginFetch.response.data;
      localStorage.setItem(AUTH_TOKEN, accessToken);
      if (!rememberMe) {
        localStorage.setItem(AUTH_TOKEN_TO_DATE, (new Date().getTime() + (1000 * 60 * 60 * 24))); // one day
      }
      userFetch.setFetchUrl(API_URL_USER_FIND + "?email=" + values['email']);
      userFetch.forceFetchData();
    }
  }, [loginFetch.response]);

  useEffect(() => {
    if (loginFetch.error) {
      setLoginError(t('login.invalid.credentials'))
    }
  }, [loginFetch.error]);

  useEffect(() => {
    if (!userFetch.response) {
      return;
    }
    if (userFetch.response.status === 200) {
      const { id, role } = userFetch.response.data;
      const userModel = new User(id, role, values['email']);
      localStorage.setItem(USER_DATA, JSON.stringify(userModel.toJSON()));
      if (AppUtils.isUserOrganization()) {
        moreDataFetch.setFetchUrl(API_URL_ORGANIZATION + '/' + id);
        moreDataFetch.forceFetchData();
      } else if (AppUtils.isUserMember()) {
        moreDataFetch.setFetchUrl(API_URL_MEMBER + '/' + id);
        moreDataFetch.forceFetchData();
      } else if (AppUtils.isUserVolunteer()) {
        moreDataFetch.setFetchUrl(API_URL_VOLUNTEER + '/' + id);
        moreDataFetch.forceFetchData();
      } else if (AppUtils.isUserAdmin()) {
        window.location.href = PATH_ADMIN_COMMUNITY;
      }
    }
  }, [userFetch.response]);

  useEffect(() => {
    if (moreDataFetch.response) {
      const mergedData = {
        ...JSON.parse(localStorage.getItem(USER_DATA)),
        ...moreDataFetch.response.data,
      };
      localStorage.setItem(USER_DATA, JSON.stringify(mergedData));
      AppUtils.changeLanguage();
      window.location.href = '/';
    }
  }, [moreDataFetch.response]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  }, [values]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    loginFetch.setBody(values);
  }, [values]);

  const openAnotherModal = useCallback((e, modalId) => {
    e.preventDefault();
    if (modalId === modals.SIGN_UP) { showSignUpModal() }
    else if (modalId === modals.FORGET_PASSWORD) { showForgetPasswordModal() }
  }, []);

  const isLoading = useCallback(() => loginFetch.loading || userFetch.loading || moreDataFetch.loading, [loginFetch.loading, userFetch.loading, moreDataFetch.loading])

  return (
    <Modal onHide={onDismiss} show={show}>
      <Container>
        <h3 className="text-center py-3">{t('login.welcome')}</h3>
        <Form onSubmit={handleSubmit}>
          <FormInput id="email" required={true} label={t('login.email')} onChange={onFormChange} type="email" text={loginError} isInvalid={loginError} />
          <FormInput id="password" required={true} label={t('login.password')} onChange={onFormChange} type="password" />
          <Button type="submit" className='w-100 mt-1 mb-4' disabled={isLoading()}>{t('login.submit')}</Button>
          <div className="d-flex justify-content-between align-items-center mt-3">
            <CheckBox id="remember" label={t('login.remember')} onChange={(e) => setRememberMe(old => !old)} type="checkbox" checked={rememberMe} />
            <a href="/#" onClick={(e) => openAnotherModal(e, modals.FORGET_PASSWORD)}>{t('login.forget.question')}</a>
          </div>
          <hr />
          <p className="text-center mt-4">
            <span>{t('login.create.title')}</span>
            <a href="/#" onClick={(e) => openAnotherModal(e, modals.SIGN_UP)}>{t('login.create.action')}</a>
          </p>
        </Form>
      </Container>
    </Modal>
  )
}

export default LoginModal;
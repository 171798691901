import { Layout } from 'components';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningSimulation1 = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Container className='page'>
        <table className='table two-columns text-volunteering' style={{ width: '100%' }}>
          <colgroup>
            <col className='w-10'></col>
            <col></col>
          </colgroup>
          <thead>
            <tr>
              <th>{t('learning.volunteering.volunteer.44.1')}</th>
              <th>{t('learning.volunteering.volunteer.44.2')}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t('learning.volunteering.volunteer.45.1')}</td>
              <td>{t('learning.volunteering.volunteer.45.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.46.1')}</td>
              <td>{t('learning.volunteering.volunteer.46.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.47.1')}</td>
              <td>{t('learning.volunteering.volunteer.47.2')}</td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.48.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.48.2')}</li>
                  <li>{t('learning.volunteering.volunteer.48.3')}</li>
                  <li>{t('learning.volunteering.volunteer.48.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.49.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.49.2')}</li>
                  <li>{t('learning.volunteering.volunteer.49.3')}</li>
                  <li>{t('learning.volunteering.volunteer.49.4')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.50.1')}</td>
              <td>
                <ol>
                  <li>
                    {t('learning.volunteering.volunteer.50.2')}<br/>
                    <u>{t('learning.volunteering.volunteer.50.3')}</u><br/>
                    {t('learning.volunteering.volunteer.50.4')}<br/>
                    {t('learning.volunteering.volunteer.50.5')}<br/>
                    {t('learning.volunteering.volunteer.50.6')}<br/>
                    {t('learning.volunteering.volunteer.50.7')}
                    <a href="https://www.goodthingsfoundation.org/our-work/areas-of-work/digital-inclusion/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.goodthingsfoundation.org/our-work/areas-of-work/digital-inclusion/</a>
                    <br/><br/>
                    <u>{t('learning.volunteering.volunteer.50.8')}</u><br/>
                    {t('learning.volunteering.volunteer.50.9')}<br/>
                    {t('learning.volunteering.volunteer.50.10')}<br/>
                    {t('learning.volunteering.volunteer.50.11')}<br/>
                    {t('learning.volunteering.volunteer.50.12')}
                    <a href="https://www.duolingo.com/mission" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.duolingo.com/mission</a>
                    <br/><br/>
                    <u>{t('learning.volunteering.volunteer.50.13')}</u><br/>
                    {t('learning.volunteering.volunteer.50.14')}<br/>
                    {t('learning.volunteering.volunteer.50.15')}<br/>
                    {t('learning.volunteering.volunteer.50.16')}<br/>
                    {t('learning.volunteering.volunteer.50.17')}
                    <a href="https://www.greenpeace.org/usa/act/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.greenpeace.org/usa/act/</a>
                  </li>
                  <li>{t('learning.volunteering.volunteer.50.18')}</li>
                  <li>{t('learning.volunteering.volunteer.50.19')}</li>
                  <li>{t('learning.volunteering.volunteer.50.20')}</li>
                  <li>{t('learning.volunteering.volunteer.50.21')}</li>
                  <li>{t('learning.volunteering.volunteer.50.22')}</li>
                  <li>{t('learning.volunteering.volunteer.50.23')}</li>
                  <li>{t('learning.volunteering.volunteer.50.24')}</li>
                  <li>{t('learning.volunteering.volunteer.50.25')}</li>
                </ol>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.51.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.51.2')}</li>
                  <li>{t('learning.volunteering.volunteer.51.3')}</li>
                  <li>{t('learning.volunteering.volunteer.51.4')}</li>
                  <li>{t('learning.volunteering.volunteer.51.5')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.52.1')}</td>
              <td>
                <ul>
                  <li>{t('learning.volunteering.volunteer.52.2')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>{t('learning.volunteering.volunteer.53.1')}</td>
              <td>{t('learning.volunteering.volunteer.53.2')}</td>
            </tr>
          </tbody>
        </table>
      </Container>
    </Layout>
  )

}

export default VolunteeringLearningSimulation1;
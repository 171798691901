import { volunteeringVolunteerImage5 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen5 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-1-4" className='text-volunteering'>{t('learning.volunteering.volunteer.27')}</h4>
      <p>{t('learning.volunteering.volunteer.28')}</p>
      <p>{t('learning.volunteering.volunteer.29')}</p>
      <p>{t('learning.volunteering.volunteer.30')}</p>
      <p>
        {t('learning.volunteering.volunteer.31.1')}
        <b>{t('learning.volunteering.volunteer.31.2')}</b>
        {t('learning.volunteering.volunteer.31.3')}
        <b>{t('learning.volunteering.volunteer.31.4')}</b>
        {t('learning.volunteering.volunteer.31.5')}
        <a href="https://www.connect-international.org/2023/11/01/results-of-the-e-volunteering-research/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.31.6')}</a>.
      </p>
      <p>
        {t('learning.volunteering.volunteer.32.1')}
        <b>{t('learning.volunteering.volunteer.32.2')}</b>
        {t('learning.volunteering.volunteer.32.3')}
        <b>{t('learning.volunteering.volunteer.32.4')}</b>
        {t('learning.volunteering.volunteer.32.5')}
        <a href="https://digital-strategy.ec.europa.eu/en/library/european-declaration-digital-rights-and-principles" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.32.6')}</a>.
        {t('learning.volunteering.volunteer.32.7')}
      </p>
      <Image src={volunteeringVolunteerImage5} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen5;
import React from 'react';
import { Image, Nav, Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AppConstants } from 'constant';
import { engageColored } from 'assets/icons';

const { PATH_ENGAGE } = AppConstants;

const EngageHeader = () => {

  const { t } = useTranslation();

  return (
    <Navbar expand="md" className="header-vista" sticky="top" >
      <Nav>
        <Nav.Link href={PATH_ENGAGE}>
          <FontAwesomeIcon icon={'circle-arrow-left'} size='lg' />
          <span className="mx-2">{t('functions.engage.header.vista.back')}</span>
        </Nav.Link>
      </Nav>
      <Navbar.Brand>
        <div className="d-flex flex-row align-items-center">
          <Image src={engageColored} width={32} height={32} />
          <h3 className='mb-0 mx-3'>{t('functions.engage.header.vista.title')}</h3>
        </div>
      </Navbar.Brand>
    </Navbar>
  )
}

export default EngageHeader;
import React, { useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AppConstants } from 'constant/index.js';
import EngageLearningScreens from './index.js';
import Layout from 'components/layout.js';
import Page404Screen from 'screens/404';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'components/index.js';
import { engageWhite } from 'assets/icons/index.js';

const { PATH_ENGAGE_LEARNING } = AppConstants;

const EngageLearningScreen = () => {
  const { t } = useTranslation();
  
  const params = new URLSearchParams(window.location.search);
  const page = Number.parseInt(params.get('page') ?? 1);
  const element = EngageLearningScreens['page' + page];

  const [showContent, setShowContent] = useState(false);

  if (!element) {
    return <Page404Screen />;
  }
  return (
    <Layout>
      {page !== 1 &&
        (
          <div className='engage-background pt-3 pb-3'>
            <div className='d-flex flex-direction-row align-items-center justify-content-center'>
              <Image src={engageWhite} width={24} height={24} className="text-white" />
              <h4 className='pl-2 pb-0 mb-0 text-white'>{t('learning.engage.header.1')}</h4>
            </div>
            <p className='pb-0 mb-0 text-white text-center'>{t('learning.engage.header.2')}</p>
          </div>
        )}
      <ContentModal visible={showContent} onHide={() => setShowContent(false)} />
      <div className='d-flex flex-direction-column justify-content-between p-5 pt-0 h-100'>
        <Container className='page'>
          {element}
        </Container>
        <div className='page d-flex flex-direction-row align-items-center justify-content-center mb-5'>
          <Button variant='outline-engage' disabled={page === 1} style={{ opacity: (page === 1) ? 0 : 1 }} onClick={() => window.location.href = PATH_ENGAGE_LEARNING + '?page=' + (page - 1)}>
            <div className='d-flex flex-direction-row align-items-center'>
              <FontAwesomeIcon icon={'arrow-left'} className='text-engage' />
              <span>&nbsp;{t('previous')}</span>
            </div>
          </Button>
          <div className='px-5'>
            <Button variant='link text-engage' onClick={() => setShowContent(true)}>
              {page} / {Object.keys(EngageLearningScreens).length}
            </Button>
          </div>
          <Button variant='outline-engage' disabled={page === Object.keys(EngageLearningScreens).length} style={{ opacity: (page === Object.keys(EngageLearningScreens).length) ? 0 : 1 }} onClick={() => window.location.href = PATH_ENGAGE_LEARNING + '?page=' + (page + 1)}>
            <div className='d-flex flex-direction-row align-items-center'>
              <span>{t('next')}&nbsp;</span>
              <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />
            </div>
          </Button>
        </div>
      </div>
    </Layout>
  )
}

const ContentModal = ({ visible, onHide }) => {
  const { t } = useTranslation();

  return (
    <Modal show={visible} onHide={onHide} className={'p-0'}>
      <h4 className={'px-4'}>{t('learning.engage.menu.1')}</h4>
      <ul style={{ listStyle: 'none' }} className='px-4'>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=2'}>{t('learning.engage.menu.2')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=2'}>{t('learning.engage.menu.3')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=2'}>{t('learning.engage.menu.4')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=2'}>{t('learning.engage.menu.5')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=2'}>{t('learning.engage.menu.6')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=2'}>{t('learning.engage.menu.7')}</a></li>
        </ul>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=3'}>{t('learning.engage.menu.8')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=3'}>{t('learning.engage.menu.9')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=4'}>{t('learning.engage.menu.10')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=5'}>{t('learning.engage.menu.11')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=6'}>{t('learning.engage.menu.12')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=7'}>{t('learning.engage.menu.13')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=8'}>{t('learning.engage.menu.14')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=9'}>{t('learning.engage.menu.15')}</a></li>
        </ul>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=10'}>{t('learning.engage.menu.16')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=10'}>{t('learning.engage.menu.17')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=11'}>{t('learning.engage.menu.18')}</a></li>
        </ul>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=12'}>{t('learning.engage.menu.19')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=12'}>{t('learning.engage.menu.20')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=13'}>{t('learning.engage.menu.21')}</a></li>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=14'}>{t('learning.engage.menu.22')}</a></li>
        </ul>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=15'}>{t('learning.engage.menu.23')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=15'}>{t('learning.engage.menu.24')}</a></li>
          <ul style={{ listStyle: 'none' }}>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=16'}>{t('learning.engage.menu.25')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=17'}>{t('learning.engage.menu.26')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=18'}>{t('learning.engage.menu.27')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=19'}>{t('learning.engage.menu.28')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=20'}>{t('learning.engage.menu.29')}</a></li>
          </ul>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=21'}>{t('learning.engage.menu.30')}</a></li>
          <ul style={{ listStyle: 'none' }}>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=22'}>{t('learning.engage.menu.31')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=23'}>{t('learning.engage.menu.32')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=24'}>{t('learning.engage.menu.33')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=25'}>{t('learning.engage.menu.34')}</a></li>
          </ul>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=26'}>{t('learning.engage.menu.35')}</a></li>
          <ul style={{ listStyle: 'none' }}>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=27'}>{t('learning.engage.menu.36')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=28'}>{t('learning.engage.menu.37')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=29'}>{t('learning.engage.menu.38')}</a></li>
          </ul>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=30'}>{t('learning.engage.menu.39')}</a></li>
          <ul style={{ listStyle: 'none' }}>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=31'}>{t('learning.engage.menu.40')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=32'}>{t('learning.engage.menu.41')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=33'}>{t('learning.engage.menu.42')}</a></li>
          </ul>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=34'}>{t('learning.engage.menu.43')}</a></li>
          <ul style={{ listStyle: 'none' }}>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=35'}>{t('learning.engage.menu.44')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=36'}>{t('learning.engage.menu.45')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=37'}>{t('learning.engage.menu.46')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=38'}>{t('learning.engage.menu.47')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=39'}>{t('learning.engage.menu.48')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=40'}>{t('learning.engage.menu.49')}</a></li>
          </ul>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=41'}>{t('learning.engage.menu.50')}</a></li>
          <ul style={{ listStyle: 'none' }}>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=42'}>{t('learning.engage.menu.51')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=43'}>{t('learning.engage.menu.52')}</a></li>
          </ul>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=44'}>{t('learning.engage.menu.53')}</a></li>
          <ul style={{ listStyle: 'none' }}>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=45'}>{t('learning.engage.menu.54')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=46'}>{t('learning.engage.menu.55')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=47'}>{t('learning.engage.menu.56')}</a></li>
            <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=48'}>{t('learning.engage.menu.57')}</a></li>
          </ul>
        </ul>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=49'}>{t('learning.engage.menu.58')}</a></li>
        <ul style={{ listStyle: 'none' }}>
          <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=50'}>{t('learning.engage.menu.59')}</a></li>
        </ul>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=51'}>{t('learning.engage.menu.60')}</a></li>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=52'}>{t('learning.engage.menu.61')}</a></li>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=53'}>{t('learning.engage.menu.62')}</a></li>
        <li><a className='text-engage' href={PATH_ENGAGE_LEARNING + '?page=54'}>{t('learning.engage.menu.63')}</a></li>
      </ul>
    </Modal>
  )
}

export default EngageLearningScreen;
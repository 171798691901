import React, { useCallback, useState } from 'react';
import { Button, Col, Image } from 'react-bootstrap';

import { editConnect, hexagon } from 'assets/icons';
import ApplyModal from 'screens/modal/fair-apply';
import { AppConstants } from 'constant';
import ConnectBeforeEnterModal from './modal/before-enter';
import { AppUtils } from 'utils';
import InfoModal from 'screens/modal/fair-info';

const { PATH_CONNECT_FAIR } = AppConstants;

const FunctionConnectCardSmall = ({ el, my = false, t, linkModalVisible, setLinkModalVisible, alreadyApplied }) => {

  const [applyModalVisible, setApplyModalVisible] = useState(false);
  const [fairInfoVisible, setFairInfoVisible] = useState(false);

  const renderAdditionalClass = useCallback((item) => {
    if (item.isActiveNow) {
      return ' text-muted';
    }
    if (item.daysLeftToApply < 5 || item.placesLeft < 5) {
      return ' text-error';
    }
    return '';
  }, []);

  const dismissLinkModal = useCallback(() => setLinkModalVisible(false), []);
  const submitLinkModal = useCallback(() => {
    dismissLinkModal();
    window.location.href = PATH_CONNECT_FAIR + '/' + el.id;
  }, [el]);

  if (my) {
    return (
      <>
        <InfoModal visible={fairInfoVisible} onDismiss={() => setFairInfoVisible(false)} fair={el} t={t} />
        <Col key={'my-connect-' + el.id} className="col-4">
          <ConnectBeforeEnterModal el={el} show={linkModalVisible === el} onDismiss={dismissLinkModal} onSubmit={submitLinkModal} />
          <div className="card card-fair small">
            <Image src={el.featuredImage} alt={el.featuredImage} className={"card-cover-image small clickable"} onClick={() => setFairInfoVisible(true)} />
            <div className="my-2 px-3 clickable w-100" onClick={() => setFairInfoVisible(true)}>
              <p className="my-1">{AppUtils.convertDateToLocaleDate(el.startDateTime, t('format.date.fair'))}</p>
              <h5 className="pt-1">
                <span>{el.title}</span>
                {AppUtils.isFairLive(el) ?
                  <span>
                    <span className="mx-3">&#183;</span>
                    <span className="text-connect">{t('functions.connect.fairs.live')}</span>
                  </span>
                  : undefined}
              </h5>
            </div>
            {AppUtils.isUserLogged() ?
              <Button variant="link" onClick={() => window.location.href = PATH_CONNECT_FAIR + '/' + el.id} className="w-100 text-connect mb-3 clickable">
                <Image src={editConnect} height={18} width={18} />
                <span className="pl-2">{t('functions.connect.fairs.edit.stand')}</span>
              </Button>
              : undefined
            }
          </div>
        </Col>
      </>
    )
  }

  return (
    <Col key={'connect-' + el.id} className="col-4">
      <InfoModal visible={fairInfoVisible} onDismiss={() => setFairInfoVisible(false)} fair={el} t={t} />
      <ApplyModal fairId={el.id} onDismiss={() => setApplyModalVisible(false)} visible={applyModalVisible} t={t} />
      <div className={'card card-fair small'}>
        <Image src={el.featuredImage} alt={el.featuredImage} className={"card-cover-image small clickable"} onClick={() => setFairInfoVisible(true)} />
        <div className="my-2 px-3 clickable w-100" onClick={() => setFairInfoVisible(true)}>
          <p className="my-1">{AppUtils.convertDateToLocaleDate(el.startDateTime, t('format.date.fair'))}</p>
          <h5 className="pt-1">{el.title}</h5>
          <div className="d-flex flex-direction-row align-items-center">
            <Image src={hexagon} />
            <span className={"mx-2" + renderAdditionalClass(el)}>{t('functions.connect.fairs.left.places', { number: el.placesLeft })}</span>
          </div>
        </div>
        <div className="text-center align-self-center mt-3 w-100">
          {AppUtils.isFairApplyAllowed(el) && !alreadyApplied ? <Button variant="warning" disabled={el.isActiveNow} className="mb-2 w-90" onClick={() => setApplyModalVisible(true)}>{t('functions.connect.fairs.action.apply')}</Button> : undefined}
          {el.daysLeftToApply >= 0 ? <p className={renderAdditionalClass(el)}>{t('functions.connect.fairs.left.days', { number: el.daysLeftToApply })}</p> : undefined}
        </div>
      </div>
    </Col>
  )
}

export default FunctionConnectCardSmall;
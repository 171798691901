import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppConstants } from 'constant';
import { Button } from 'react-bootstrap';
import { AppUtils } from 'utils';
import { Countdown } from 'components';

const { EVENT_STATUS } = AppConstants;

const CountdownTimer = ({ event, onChange, goToConferenceRoom }) => {

  const { t } = useTranslation();
  const [countdownTo, setCountdownTo] = useState(undefined);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    if (!event) {
      return;
    }
    setCountdownTo(new Date(AppUtils.convertDateToLocaleDate(event.startTime)).getTime());
  }, [event]);

  const renderer = (props) => {
    let { days, hours, minutes, seconds, completed } = props;
    let display = '';
    if (hours > 0) {
      display += days * 24 + hours;
    }
    display += (display.length > 0 ? ':' : '');
    display += (minutes.toString().length === 1 ? '0' : '') + minutes
    display += ':'
    display += (seconds.toString().length === 1 ? '0' : '') + seconds
    if (finished !== completed) {
      setFinished(completed);
    }
    if (completed) {
      if (new Date().getTime() > (new Date(AppUtils.convertDateToLocaleDate(event.endTime)).getTime())) {
        onChange(EVENT_STATUS.ENDED);
        return <span>{t('functions.empower.events.countdown.ENDED')}</span>
      } else {
        onChange(EVENT_STATUS.STARTED);
        return (
          <span>
            <span className="mr-3">{t('functions.empower.events.countdown.STARTED', { time: display })}</span>
            <span><Button size='sm' onClick={goToConferenceRoom}>{t('functions.empower.events.join')}</Button></span>
          </span>
        );
      }
    } else {
      onChange(EVENT_STATUS.STARTING);
      return <span>{t('functions.empower.events.countdown.STARTING', { time: display })}</span>;
    }
  };

  if (!event || !countdownTo) {
    return <></>
  }

  return (
    <Countdown
      completed={finished}
      countdownTo={countdownTo}
      renderer={renderer}
    />
  )
}

export default CountdownTimer;
export default class ForumThemePost {
  private __id: number;
  private __createdAt: string;
  private __authorId: number;
  private __authorRole: string;
  private __authorName: string;
  private __authorImage: string;
  private __content: string;
  private __relatedPostId: number;
  private __relatedPostContent: string;
  private __relatedPostAuthorName: string;

  constructor(
    id: number,
    createdAt: string,
    authorId: number,
    authorRole: string,
    authorName: string,
    authorImage: string,
    content: string,
    relatedPostId: number,
    relatedPostContent: string,
    relatedPostAuthorName: string,

  ) {
    this.__id = id;
    this.__createdAt = createdAt;
    this.__authorId = authorId;
    this.__authorRole = authorRole;
    this.__authorName = authorName;
    this.__authorImage = authorImage;
    this.__content = content;
    this.__relatedPostId = relatedPostId;
    this.__relatedPostContent = relatedPostContent;
    this.__relatedPostAuthorName = relatedPostAuthorName;

  }

  public get id() { return this.__id; }
  public get createdAt() { return this.__createdAt; }
  public get authorId() { return this.__authorId; }
  public get authorRole() { return this.__authorRole; }
  public get authorName() { return this.__authorName; }
  public get authorImage() { return this.__authorImage; }
  public get content() { return this.__content; }
  public get relatedPostId() { return this.__relatedPostId; }
  public get relatedPostContent() { return this.__relatedPostContent; }
  public get relatedPostAuthorName() { return this.__relatedPostAuthorName; }

  public toJSON() {
    return {
      id: this.__id,
      createdAt: this.__createdAt,
      authorId: this.__authorId,
      authorRole: this.__authorRole,
      authorName: this.__authorName,
      authorImage: this.__authorImage,
      content: this.__content,
      relatedPostId: this.__relatedPostId,
      relatedPostContent: this.__relatedPostContent,
      relatedPostAuthorName: this.__relatedPostAuthorName
    }
  }

}
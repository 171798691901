import React from 'react';
import { Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { AppConstants } from 'constant';
import { AppUtils } from 'utils';

const { PATH_OPPORTUNITIES } = AppConstants;

const OpportunityCard = ({ disabled = false, el, t }) => {
  if (disabled) {
    return (
      <Col key={'opportunity-' + el.id} className="col-6">
        <ContentView el={el} t={t} />
      </Col>
    )
  }

  return (
    <Col key={'opportunity-' + el.id} className="col-6">
      <Link to={PATH_OPPORTUNITIES + '/' + el.id} className="clickable">
        <ContentView el={el} t={t} />
      </Link>
    </Col >
  )
}

const ContentView = ({ el, t }) => (
  <div className={'card card-opportunity'} style={{ height: '100%' }}>
    <Image src={el.featuredImage} alt={el.featuredImage} className="card-cover-image" />
    <div>
      <h4>{el.title}</h4>
      <p>
        <small>
          <i>{AppUtils.convertDateToLocaleDate(el.datePublished, t('format.date.opportunities'))}</i>
          <span className="mx-2">&#x2022;</span>
          <i>{el.organizationName}</i>
        </small>
      </p>
    </div>
  </div>
)

export default OpportunityCard;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step433 } from 'assets/learning';

const EngageLearningScreen29 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-3-3" className='text-engage'>{t('learning.engage.157')}</h4>
      <p><a href='https://asana.com' target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.158.1')}</a>{t('learning.engage.158.2')}<a href="https://drive.google.com/file/d/1ZuQqR7nA-SU4dPcRI_-kmZVIdyQynDJx/view?usp=sharing" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.158.3')}</a>{t('learning.engage.158.4')}</p>
      <br/><br/>
      <div className='text-center'>
        <Image src={step433} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen29;
import React from 'react';
import { Button, Col, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { AppConstants } from 'constant';
import { editConnect } from 'assets/icons';
import { AppUtils } from 'utils';

const { PATH_CONNECT_FAIR } = AppConstants;

const FunctionConnectCard = ({ disabled = false, el, t }) => (
  <Col key={'connect-' + el.id} className="col-4">
    {disabled ?
      <ContentView el={el} t={t} />
      :
      <Link to={PATH_CONNECT_FAIR + '/' + el.id} className="clickable w-100">
        <ContentView el={el} t={t} />
      </Link>
    }
  </Col>
)

const ContentView = ({ el, t }) => (
  <div className="card card-fair small">
    <Image src={el.featuredImage} alt={el.featuredImage} className={"card-cover-image"} />
    <div className="my-2 px-3 w-100">
      <p className="my-1">{AppUtils.convertDateToLocaleDate(el.startDateTime, t('format.date.fair'))}</p>
      <h5 className="pt-1">
        <span>{el.title}</span>
      </h5>
    </div>
    <Button variant="link" onClick={() => {}} className="w-100 text-connect mb-3 clickable">
      <Image src={editConnect} height={18} width={18} />
      <span className="pl-2">{t('functions.connect.fairs.edit.stand')}</span>
    </Button>
  </div>

)

export default FunctionConnectCard;
import React from 'react';
import { Form } from 'react-bootstrap';

const CheckBox = (props) => (
  <Form.Group className={props.className}>
    <Form.Check
      {...props}
    />
  </Form.Group>
)

export default CheckBox;
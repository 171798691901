import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower1, empower2, empower3,  } from 'assets/learning';

const EmpowerLearningScreen2 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="benefits">{t('learning.empower.3')}</h2>
      <p>{t('learning.empower.4')}</p>
      <p>{t('learning.empower.5')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/xUgB62Xo7_0?si=9MtT6vc42wfxWBx8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
      <br />
      <div className='text-center'>
        <Image src={empower1} width={'50%'} />
      </div>
      <br />
      <p>{t('learning.empower.6')}</p>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.empower.7')}</p>
          <p>{t('learning.empower.8')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={empower2} width={'50%'} />
        </div>
      </div>
      <p>{t('learning.empower.9')}</p>
      <div className='text-center'>
        <Image src={empower3} width={'50%'} />
      </div>
      <br />
      <p>{t('learning.empower.10.1')}<a href="https://quizizz.com/join/quiz/5d2c5a7ff09b1b001b55dc63/start?studentShare=true" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.10.2')}</a></p>
    </>
  )

}

export default EmpowerLearningScreen2;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Empty, Layout, Loader } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import { AppUtils } from 'utils';
import APIKit from 'config/axios/api';
import fileDownload from 'js-file-download';

const {
  HEADER_TYPES,
  PATH_ADMIN_FAIRS,
  FAIRS_STATUS_PUBLISHED, FAIRS_STATUS_DRAFTED
} = AppConstants;

const { API_METHOD_GET, API_URL_FAIRS, API_URL_FAIR } = ServerConstants;

const filter = {
  PUBLISHED: FAIRS_STATUS_PUBLISHED,
  DRAFTED: FAIRS_STATUS_DRAFTED
}
const AdminFairListScreen = () => {

  const { response, loading } = useFetch(API_METHOD_GET, API_URL_FAIRS + '/dashboard');
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState(filter[filter.ALL]);
  const [fairs, seFairs] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (response) {
      seFairs(response.data);
    }
  }, [response]);

  useEffect(() => {
    let filtered = fairs;
    if (filter[filterBy]) {
      filtered = filtered.filter(el => el.status.toUpperCase() === filterBy);
    }
    setFilteredArray(filtered);
  }, [filterBy, fairs]);

  const downloadImages = useCallback((el) => {
    APIKit.get(API_URL_FAIR + '/' + el.fairId + '/images', { responseType: 'blob' })
      .then((res) => fileDownload(res.data, el.title + '.zip', 'zip'))
      .catch(e => console.log('Error while download', e))
  }, []);

  const deleteFair = useCallback((el) => {
    if (window.confirm("Are you sure you want to delete this fair?")) {
      APIKit.delete(API_URL_FAIR + '/' + el.fairId)
      .then((res) =>  window.location.reload())
      .catch(e => console.log('Error while deleting', e))
  }
  }, [])

  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="mt-4 mb-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <div className="d-flex flex-direction-row align-items-start flex-grow">
              <h2 className="pr-4">{t('fairs.admin.title')}</h2>
              <Button className="mx-2 px-3" onClick={() => window.location.href = PATH_ADMIN_FAIRS + '/new'}><FontAwesomeIcon icon={'plus'} /></Button>
            </div>
          </div>
          {loading ? <Loader /> :
            <>
              <div className="d-flex flex-direction-row flex-shrink">
                <div className={'admin-community-filter'} key={'filter-all'}>
                  <Button variant='link' className={filterBy === undefined ? ' active' : ''} onClick={() => setFilterBy(undefined)}>{t('fairs.admin.filter.ALL')}{fairs && fairs.length > 0 ? ' (' + fairs.length + ')' : ''}</Button>
                </div>
                {Object.keys(filter).map(el => (
                  <div className={'admin-community-filter'} key={el}>
                    <Button variant='link' className={filter[el] === filter[filterBy] ? ' active' : ''} onClick={() => setFilterBy(el)}>{t('fairs.admin.filter.' + el.toUpperCase())}</Button>
                  </div>
                ))}
              </div>
              {(!filteredArray || filteredArray.length === 0) ? <Empty messageKey={t('fairs.empty')} /> :
                <Table bordered={true} className="mt-4">
                  <thead>
                    <tr>
                      <th>{t('fairs.name')}</th>
                      <th>{t('fairs.start.date')}</th>
                      <th>{t('fairs.status.title')}</th>
                      <th>{t('fairs.action.title')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredArray.map(el => <TableItem el={el} t={t} downloadImages={downloadImages} deleteFair={deleteFair} />)}
                  </tbody>
                </Table>}
            </>}
        </div>
      </Container>
    </Layout >
  )
}

const TableItem = ({ el, t, downloadImages, deleteFair }) => {
  const isEditable = useCallback((fair) => !AppUtils.isFairLive(fair) && !AppUtils.isFairFinished(fair), []);
  return (
    <tr key={el.fairId}>
      <td><Link to={PATH_ADMIN_FAIRS + '/' + el.fairId}>{el.title}</Link></td>
      <td className="w-20">{AppUtils.convertDateToLocaleDate(el.startDateTime, t('format.date.time'))}</td>
      <td className="w-15">
        {el.status === filter.PUBLISHED ? <div className="d-flex flex-direction-row align-items-center text-muted"><span className="d-none d-md-block">{t('fairs.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
        {el.status === filter.DRAFTED ? <div className="d-flex flex-direction-row align-items-center text-muted"><span className="d-none d-md-block">{t('fairs.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
      </td>
      <td className="w-15">
        <div className="d-flex flex-direction-row align-items-center flex-wrap">
          {AppUtils.isFairDownloadContentAllowed(el) ?
            <Link onClick={() => downloadImages(el)}><FontAwesomeIcon icon={'download'} /></Link>
            : undefined
          }
          {isEditable(el) ?
            <Link to={PATH_ADMIN_FAIRS + '/' + el.fairId + '/edit'}><FontAwesomeIcon icon={'pencil'} /></Link>
            : undefined}
          {!AppUtils.isFairPreLive(el) && !AppUtils.isFairLive(el) ?
            <Link onClick={() => deleteFair(el)}><FontAwesomeIcon icon={'trash-alt'} /></Link>
            : undefined}
        </div>
      </td>
    </tr>
  )
}

export default AdminFairListScreen;
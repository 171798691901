import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { FormInput, Modal } from "components";
import { useFetch } from "hooks/fetch";
import { ServerConstants } from "constant";
import StepsView from "./steps";

const {
  API_METHOD_GET, API_METHOD_POST,
  API_URL_USER_FIND, API_URL_USER_PASSWORD_REQUEST_CHANGE, API_URL_USER_PASSWORD_CHANGE_VERIFY_CODE, API_URL_USER_PASSWORD_NEW
} = ServerConstants;

const propertiesPerPage = [
  ["email"],
  ["passwordChangeVerificationCode"],
  ["newPassword", "confirmation"],
]

const ForgetPasswordModal = ({ onDismiss, show, showLoginModal, t }) => {

  const stepsNumber = 3;

  const findUserFetch = useFetch(API_METHOD_GET);
  const requestCodeFetch = useFetch(API_METHOD_POST, API_URL_USER_PASSWORD_REQUEST_CHANGE);
  const verifyCodeFetch = useFetch(API_METHOD_POST, API_URL_USER_PASSWORD_CHANGE_VERIFY_CODE);
  const setNewFetch = useFetch(API_METHOD_POST, API_URL_USER_PASSWORD_NEW);
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const [userExists, setUserExists] = useState(undefined);
  const tempUserData = useRef({});

  useEffect(() => {
    return (() => {
      tempUserData.current = {};
      setValues({});
      setStep(1);
    })
  }, []);

  useEffect(() => {
    if (!findUserFetch.response) {
      return;
    }
    if (findUserFetch.response.status === 200) {
      const data = findUserFetch.response.data
      if (data) {
        tempUserData.current = data;
        requestCodeFetch.setBody({
          "role": data.role,
          "id": data.id
        })
      }
    } else {
      setUserExists(true);
    }
  }, [findUserFetch.response]);

  useEffect(() => {
    if (requestCodeFetch.response) {
      setStep(2);
    }
  }, [requestCodeFetch.response]);

  useEffect(() => {
    if (verifyCodeFetch.response) {
      setStep(3);
    }
  }, [verifyCodeFetch.response]);

  useEffect(() => {
    if (setNewFetch.response) {
      setStep(4);
    }
  }, [setNewFetch.response]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  }, [values]);

  const handleSubmit = useCallback((event, goToStep) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    if (goToStep === 2) {
      findUserFetch.setFetchUrl(API_URL_USER_FIND + '?email=' + values['email']);
      findUserFetch.forceFetchData();
    } else if (goToStep === 3) {
      verifyCodeFetch.setBody({
        "role": tempUserData.current.role,
        "id": tempUserData.current.id,
        "passwordChangeVerificationCode": values['passwordChangeVerificationCode']
      });
    }
    if (goToStep - 1 === 3) {
      if (values['newPassword'] && values['confirmation'] && (values['newPassword'] !== values['confirmation'])) {
        return;
      }
      setNewFetch.setBody({
        "role": tempUserData.current.role,
        "id": tempUserData.current.id,
        "newPassword": values['newPassword']
      });
    }
    return;
  }, [values]);

  const goBack = useCallback((goto) => {
    const newValues = values;
    const resetValues = propertiesPerPage[goto].map(el => el);
    resetValues.forEach(el => {
      newValues[el] = undefined;
    })
    setValues(newValues);
    setUserExists(undefined);
    setStep(goto);
  }, [values]);

  const onDismissWrapper = useCallback(() => {
    onDismiss();
    setTimeout(() => {
      setValues({});
      setStep(1);
    }, 500)
  }, [onDismiss]);

  return (
    <Modal onHide={onDismissWrapper} show={show}>
      <Container>
        <StepsView stepsNumber={stepsNumber} step={step} t={t} translationPrefix={'login.forget.step'} />
        {step === 1 &&
          <Step1
            handleSubmit={handleSubmit}
            onFormChange={onFormChange}
            loading={findUserFetch.loading}
            invalidEmail={userExists}
            t={t}
          />
        }
        {step === 2 &&
          <Step2
            loading={verifyCodeFetch.loading}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormChange={onFormChange}
            t={t}
          />
        }
        {step === 3 &&
          <Step3
            loading={setNewFetch.loading}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormChange={onFormChange}
            values={values}
            t={t}
          />
        }
        {step === 4 &&
          <Step4
            onDismiss={() => { onDismissWrapper(); showLoginModal && showLoginModal(); }}
            t={t}
          />
        }
      </Container>
    </Modal>
  )
}

const Step1 = ({ handleSubmit, onFormChange, loading, invalidEmail, t }) => (
  <Form onSubmit={(e) => handleSubmit(e, 2)}>
    <FormInput id="email" required={true} label={t('login.email')} onChange={onFormChange} type="email" isInvalid={invalidEmail} text={invalidEmail ? t('login.forget.invalid.email') : undefined} />
    <Button type="submit" className='w-100 my-3' disabled={loading}>{t('next')}</Button>
  </Form>
)

const Step2 = ({ loading, goBack, handleSubmit, onFormChange, t }) => (
  <Form onSubmit={(e) => handleSubmit(e, 3)}>
    <p className="pt-2">{t('login.forget.verification.description')}</p>
    <FormInput id="passwordChangeVerificationCode" required={true} label={t('login.forget.verification.code')} onChange={onFormChange} />
    <Row className="mt-5">
      <Col className="col-6">
        <Button onClick={() => goBack(1)} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
      </Col>
      <Col className="col-6">
        <Button type="submit" className='w-100' disabled={loading}>{t('next')}</Button>
      </Col>
    </Row>
  </Form>
)

const Step3 = ({ handleSubmit, goBack, onFormChange, values, loading, t }) => (
  <Form onSubmit={(e) => handleSubmit(e, 4)}>
    <FormInput id="newPassword" required={true} label={t('login.forget.password.new')} onChange={onFormChange} type="password" />
    <FormInput id="confirmation" required={true} label={t('login.forget.password.confirmation')} onChange={onFormChange} type="password" isInvalid={values['newPassword'] && values['newPassword'] !== values['confirmation']} />
    <Row className="mt-5">
      <Col className="col-6">
        <Button onClick={() => goBack(2)} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
      </Col>
      <Col className="col-6">
        <Button type="submit" className='w-100' disabled={loading}>{t('next')}</Button>
      </Col>
    </Row>
  </Form>
)

const Step4 = ({ onDismiss, t }) => (
  <div className="my-3">
    <div className="d-flex flex-direction-row justify-content-center align-items-center mb-3">
      <FontAwesomeIcon icon={'circle-check'} size={'4x'} className="text-primary" />
      <h3 className="mx-3">{t('login.forget.completion')}</h3>
    </div>
    <Button type="submit" className='w-100 mt-3' onClick={onDismiss}>{t('login.forget.goto')}</Button>
  </div>
)

export default ForgetPasswordModal;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from 'components';
import { ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';
import { editEmpower } from 'assets/icons';
import EmpowerEventForm from './form';

const { API_METHOD_POST, API_URL_EVENT } = ServerConstants;
const steps = { DETAILS: 0, FINISH: 1 }

const EmpowerEventFormModal = ({ el, onDismiss, editRoom, show }) => {

  const { t } = useTranslation();
  const eventsFetch = useFetch(API_METHOD_POST);

  const [step, setStep] = useState(steps.DETAILS);
  const [events, setEvents] = useState(undefined);
  const [event, setEvent] = useState(undefined);

  useEffect(() => {
    if (show && el && !el.id) {
      setEvent(el);
      setEvents([]);
    } else {
      setEvent(undefined);
      setEvents(undefined);
      setStep(steps.DETAILS);
    }
  }, [show]);

  const onSuccess = useCallback((object) => {
    setEvent(object);
    setStep(steps.FINISH);
  }, []);

  if (!AppUtils.isUserOrganization()) {
    return <></>
  }

  return (
    <Modal onHide={onDismiss} show={show}>
      <Container>
        {/* {events === undefined ? <div className='m-4'><Loader /></div>
            : */}
        <>
          {step === steps.DETAILS ?
            <Step1
              event={event}
              events={events}
              loading={eventsFetch.loading}
              onCancel={onDismiss}
              onSuccess={onSuccess}
              t={t}
            /> : undefined}
          {step === steps.FINISH ?
            <Step2
              event={event}
              editRoom={editRoom}
              onCancel={onDismiss}
              t={t} /> : undefined}
        </>
        {/* } */}
      </Container>
    </Modal>
  )
}

const Step1 = ({ event, events, onCancel, onSuccess, t }) => {


  const eventActionFetch = useFetch(API_METHOD_POST, API_URL_EVENT);
  const { values, onValueChange, resetValues, setValue } = useValues();

  const disabledDays = events ? events.map(el => el.date) : [];

  useEffect(() => {
    resetValues();
    setValue('type', event?.type);
  }, [event]);

  useEffect(() => {
    if (eventActionFetch.response && eventActionFetch.response.data) {
      onSuccess(eventActionFetch.response.data);
    }
    return (() => {
      eventActionFetch.setBody(undefined);
    })
  }, [eventActionFetch.response])

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }
    eventActionFetch.setBody(ModelUtils.convertEventModelF2B(values));
  }, [values]);

  return (
    <div>
      <Form onSubmit={(e) => handleSubmit(e)}>
        <EmpowerEventForm
          disabledDays={disabledDays}
          event={event}
          onValueChange={onValueChange}
          t={t}
          values={values}
        />
        <Row className="mt-5">
          <Col className="col-6">
            <Button onClick={onCancel} variant="secondary" className="w-100" disabled={eventActionFetch.loading}>{t('cancel')}</Button>
          </Col>
          <Col className="col-6">
            <Button type="submit" className='w-100' disabled={eventActionFetch.loading}>{t('next')}</Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const Step2 = ({ editRoom, event, onCancel, t }) => {

  return (
    <div>
      <div className="d-flex flex-direction-row align-items-center pt-2">
        <FontAwesomeIcon icon={'circle-check'} size={'4x'} className="text-primary" />
        <h3 className="mx-3">{t('functions.empower.events.success.title')}</h3>
      </div>
      <div className="pt-4">
        <p className="mb-2">{t('functions.empower.events.link')}</p>
        <div className="fake-input">
          <span>{event?.link}</span>
          <Button variant="link" onClick={() => AppUtils.copyToClipboard(event?.link)}>
            <FontAwesomeIcon icon={'copy'} size='1x' />
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <Button variant="link" onClick={() => editRoom(ModelUtils.convertEventModelB2F(event))}>
          <Image src={editEmpower} />
          <span className="pl-2">{t('functions.empower.events.edit.room')}</span>
        </Button>
      </div>
      <Row className="mt-4">
        <Col className="col-12">
          <Button onClick={onCancel} className="w-100">{t('functions.empower.events.success.action')}</Button>
        </Col>
      </Row>
    </div>
  )
}

export default EmpowerEventFormModal;
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const FormInput = (props) => {
  const [show, setShow] = useState(props.type !== 'password');

  useEffect(() => {
    return () => {
      setShow(props.type !== 'password');
    };
  }, []);

  return (
    <Form.Group className="mb-3">
      {props.label ? <Form.Label htmlFor={props.id}>{props.label}</Form.Label> : undefined}
      <InputGroup>
        <Form.Control
          {...props}
          type={props.type !== 'password' ? props.type : (props.type === 'password' && !show) ? 'password' : 'text'}
        />
        {props.type === 'password' ?
          <InputGroup.Text onClick={() => setShow(old => !old)} style={{ width: '10%' }}>
            <FontAwesomeIcon icon={show ? 'eye' : 'eye-slash'} />
          </InputGroup.Text>
          : undefined}
      </InputGroup>
      {props.text ? <Form.Text className={props.isInvalid ? 'text-danger' : undefined}>{props.text}</Form.Text> : undefined}
    </Form.Group>
  )
}

export default FormInput;
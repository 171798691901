import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect2 } from 'assets/learning';

const ConnectLearningScreen3 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="chapter-1">{t('learning.connect.3')}</h2>
      <h4 id="chapter-1-1" className='text-connect'>{t('learning.connect.4')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={connect2} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <p>{t('learning.connect.5')}</p>
          <p>{t('learning.connect.6.1')}<b>{t('learning.connect.6.2')}</b>{t('learning.connect.6.3')}</p>
        </div>
      </div>
      <p>{t('learning.connect.7.1')}<b>{t('learning.connect.7.2')}</b>{t('learning.connect.7.3')}</p>
      <p>{t('learning.connect.8.1')}<b>{t('learning.connect.8.2')}</b>{t('learning.connect.8.3')}</p>
      <p>{t('learning.connect.9')}</p>
      <p><b>{t('learning.connect.10.1')}</b>{t('learning.connect.10.2')}</p>
      <p><b>{t('learning.connect.11.1')}</b>{t('learning.connect.11.2')}</p>
      <p><b>{t('learning.connect.12.1')}</b>{t('learning.connect.12.2')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/fP4G6Bp_gj0?si=aHztxqOeBRp6oTdQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
    </>
  )
}

export default ConnectLearningScreen3;
import React from 'react';
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { AppUtils } from 'utils';
import { Modal } from 'components';

const EmpowerEventShareModal = ({ el, onDismiss, show }) => {

  const { t } = useTranslation();

  return (
    <Modal onHide={onDismiss} show={show}>
      <Container>
        <h3 className="mt-2">{t('functions.empower.events.share.title')}</h3>
        <div className="my-4">
          <p className="mb-2">{t('functions.empower.events.link')}</p>
          <div className="fake-input">
            <span>{el.link}</span>
            <Button variant="link" onClick={() => AppUtils.copyToClipboard(el.link)}>
              <FontAwesomeIcon icon={'copy'} size='1x' />
            </Button>
          </div>
        </div>
      </Container>
    </Modal>
  )
}

export default EmpowerEventShareModal;
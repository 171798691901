import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen9 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-1-7" className='text-engage'>{t('learning.engage.36')}</h4>
      <p>{t('learning.engage.37')}</p>
      <p>{t('learning.engage.38')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/xcDW4FHhEto?si=pwwuTQ-YeVl2ikwv" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
    </>
  )
}

export default EngageLearningScreen9;
import React, { useCallback } from 'react';
import { Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppUtils } from 'utils';
import { connectColored, empowerColored, engageColored, volunteerColored, share } from 'assets/icons';

const HotspotModalView = ({ el, toEditMode, defaultValue, functionType }) => {

  const { t } = useTranslation();

  const getDefaultMedia = useCallback(() => {
    if (functionType === 'CONNECT') {
      return connectColored;
    }
    if (functionType === 'EMPOWER') {
      return empowerColored;
    }
    if (functionType === 'ENGAGE') {
      return engageColored;
    }
    if (functionType === 'VOLUNTEER') {
      return volunteerColored;
    }
    return undefined;
  }, [functionType]);

  return (
    <Container>
      <div className="d-flex flex-direction-row justify-content-between align-items-center">
        <h3 className="mt-2 mb-4"><b>{defaultValue[el.type].title}</b></h3>
        {toEditMode ?
          <a href="/#" onClick={toEditMode}>
            <FontAwesomeIcon icon={'pencil'} />
            <span className="ml-2">{t('hotspot.edit')}</span>
          </a> : undefined}
      </div>
      <p className="mb-3">{defaultValue[el.type].intro}</p>
      <p className="mb-3">{el.text}</p>
      <div className="mb-3">
        {el.image ?
          <Image src={el.image} width="100%" height="240px" style={{ objectFit: 'contain', backgroundRepeat: 'no-repeat' }} />
          : el.videoLink ?
            <iframe width="100%" height="240px" src={el.videoLink} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title="Video"></iframe>
            : <div className='text-center'><Image src={getDefaultMedia()} width="50%" height="180px" style={{ objectFit: 'fill', backgroundRepeat: 'no-repeat', }} /></div>}
      </div>
      {el.items && el.items.length > 0 ?
        <>
          <div className="mb-3">
            <p className="text-muted mb-3">
              <FontAwesomeIcon icon={"cube"} className="muted" size="lg" />
              <span className="ml-2 muted">{t('hotspot.items.title')}</span>
            </p>
          </div>
          <div className="mb-3">
            {el.items && el.items.map(item => <HotspotItem item={item} />)}
          </div>
        </>
        : undefined}
    </Container>
  )
}

const HotspotItem = ({ item }) => (
  <div className="hotspot-items" key={'hotspot-item-' + item.id}>
    <a href={AppUtils.renderWebAddress(item.link)} target='_blank' rel="noreferrer">
      <Image src={share} />
      {item.label}
    </a>
    <p className="mt-2 mb-0">{item.description}</p>
  </div>
)

export default HotspotModalView;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Empty, Layout, Loader } from 'components';
import { useFetch } from 'hooks/fetch';
import { AppConstants, ServerConstants } from 'constant';
import { Button, Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModelUtils } from 'utils';
import OrganizationCard from './card';

const { PATH_ADMIN_FAIRS, HEADER_TYPES } = AppConstants;
const { API_METHOD_GET, API_URL_FAIR } = ServerConstants;

const AdminFairOrganizationsViewScreen = () => {

  const id = window.location.pathname.split('/')[3];
  const fairFetch = useFetch(API_METHOD_GET, API_URL_FAIR + '/' + id);
  const participantsFetch = useFetch(API_METHOD_GET, API_URL_FAIR + '/' + id + '/participants');
  const { t } = useTranslation();
  const [fair, setFair] = useState(undefined);
  const [organizations, setOrganizations] = useState(undefined);

  useEffect(() => {
    if (fairFetch.response) {
      setFair(fairFetch.response.data ? ModelUtils.convertFairModelB2F(fairFetch.response.data) : []);
    }
  }, [fairFetch.response]);

  useEffect(() => {
    if (participantsFetch.response) {
      setOrganizations(participantsFetch.response.data ? participantsFetch.response.data.map(el => ModelUtils.convertOrganizationB2F(el)) : []);
    }
  }, [participantsFetch.response]);

  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="mt-4 mb-5">
        {fairFetch.loading || participantsFetch.loading ? <Loader /> :
          <div className="mb-4">
            <div className="mt-5 mb-4">
              <div className="d-flex flex-direction-row justify-content-between align-items-center flex-grow">
                <div>
                  <span>{t('fairs.single')}</span><br />
                  <h3 className="pr-4">{fair.title}</h3>
                </div>
                <Button onClick={() => window.location.href = PATH_ADMIN_FAIRS + '/' + id}>
                  <FontAwesomeIcon icon={'circle-left'} />&nbsp;&nbsp;
                  <span>{t('fairs.back.overview')}</span>
                </Button>
              </div>
            </div>
            <Row>
              {(!organizations || organizations.length === 0) ? <Empty messageKey={t('fairs.participants.empty')} /> :
                organizations.map(el => <OrganizationCard el={el} t={t} />)}
            </Row>
          </div>}
      </Container>
    </Layout>
  )
}

export default AdminFairOrganizationsViewScreen;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step13 } from 'assets/learning';

const EngageLearningScreen5 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-1-3" className='text-engage'>{t('learning.engage.19')}</h4>
      <p>{t('learning.engage.20.1')}<a href="https://www.charitywater.org/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.20.2')}</a>{t('learning.engage.20.3')}</p>
      <p><b>{t('learning.engage.21.1')}</b>{t('learning.engage.21.2')}"<a href="https://www.charitywater.org/stories/videos/the-spring" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.21.3')}</a>"{t('learning.engage.21.4')}</p>
      <p>{t('learning.engage.22.1')}<b>{t('learning.engage.22.2')}</b>{t('learning.engage.22.3')}</p>
      <p><b>{t('learning.engage.23.1')}</b>{t('learning.engage.23.2')}</p>
      <br />
      <div className='text-center'>
        <Image src={step13} width={'50%'} />
      </div>

      <div className='page-break'></div>
      <h4 className='text-engage'>{t('learning.engage.24')}</h4>
      <br />
      <ol>
        <li>
          <b>{t('learning.engage.25')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.26')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.27')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.28')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.29')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ol>
    </>
  )

}

export default EngageLearningScreen5;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useFetch } from 'hooks/fetch';
import { FormInput, FormUpload, Modal } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { useValues } from 'hooks/values';
import { editEmpower } from 'assets/icons';

const { PATH_CONNECT_FAIR } = AppConstants;
const { API_METHOD_POST, API_URL_FAIR_APPLY } = ServerConstants;

const ApplyModal = ({ fairId, onDismiss, visible, t }) => {

  const { loading, response, setBody } = useFetch(API_METHOD_POST, API_URL_FAIR_APPLY);
  const { values, onValueChange, setValue, resetValues } = useValues();
  const [successView, setSuccessView] = useState(false);

  useEffect(() => {
    return (() => {
      resetValues();
    })
  }, [])

  useEffect(() => {
    if (response) {
      setSuccessView(true);
    }
  }, [response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    sendRequest();
    return
  }, [values])

  const sendRequest = useCallback(() => {
    setBody({
      "fairId": fairId,
      "message": values["message"],
      "standText": values["standText"] ?? '',
      "image": values["image"],
    });
  }, [values])

  return (
    <Modal onHide={!loading ? onDismiss : undefined} show={visible}>
      <Container>
        {successView ?
          <>
            <div className="my-3">
              <div className="d-flex flex-direction-row justify-content-center align-items-center mb-3">
                <FontAwesomeIcon icon={'circle-check'} size={'4x'} className="text-primary" />
                <h3 className="mx-3">{t('fairs.apply.success')}</h3>
              </div>
              <Button variant="link" onClick={(e) => { e.preventDefault(); window.location.href = (PATH_CONNECT_FAIR + '/' + fairId) }} className="w-100 py-3 clickable">
                <h5 className="pl-2"><Image src={editEmpower} height={24} width={24} /> {t('functions.connect.fairs.edit.stand')}</h5>
              </Button>
              <Button onClick={onDismiss} className='w-100 mt-3'>{t('fairs.action.hurray')}</Button>
            </div>
          </>
          :
          <>
            <h4 className="py-2"><b>{t('fairs.apply.title')}</b></h4>
            <p>{t('fairs.apply.description')}</p>
            <Form onSubmit={(e) => handleSubmit(e)}>
              <FormInput id="message" required={true} label={t('fairs.apply.message')} onChange={onValueChange} type="text" as="textarea" rows={5} />
              {/* <FormInput id="standText" required={true} label={t('fairs.apply.stand')} onChange={onValueChange} type="text" as="textarea" rows={5} /> */}
              <FormUpload id="image" label={t('fairs.apply.image')} onValueChange={onValueChange} removeImage={() => setValue('image', undefined)} value={values['image']} />
              <Row className="mt-5">
                <Col className="col-6">
                  <Button onClick={onDismiss} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
                </Col>
                <Col className="col-6">
                  <Button type="submit" className='w-100' disabled={loading}>{t('fairs.action.apply')}</Button>
                </Col>
              </Row>
            </Form>
          </>
        }
      </Container>
    </Modal>
  )
}

export default ApplyModal;
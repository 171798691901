import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ai } from 'assets/learning';

const EngageLearningScreen51 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h2 id="one-last-thing">{t('learning.engage.408')}</h2>
      <h4 className='text-engage'>{t('learning.engage.409')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={ai} width={'50%'} />
        </div>
        <div className='col-8'>
          <p>{t('learning.engage.410')}</p>
          <p>{t('learning.engage.411')}</p>
        </div>
      </div>
      <p>{t('learning.engage.412.1')}<a href="https://www.adcocksolutions.com/post/6-limitations-of-ai-why-it-wont-quite-take-over-in-2023" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.412.2')}</a>{t('learning.engage.412.3')}</p>
      <p>{t('learning.engage.413')}</p>
      <p>{t('learning.engage.414.1')}<a href="https://www.instagram.com/jordanuevamedia/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.414.2')}</a>{t('learning.engage.414.3')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/Kfn2M3eNSlE?si=9FPE_VLmOHW6asBN" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
    </>
  )

}

export default EngageLearningScreen51;
import { step4741 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen48 = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <h4 id="step-4-7-4" className='text-engage'>{t('learning.engage.360')}</h4>
      <p>{t('learning.engage.361.1')}<a href="https://www.citiesoflearning.net/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.361.2')}</a>{t('learning.engage.361.3')}</p>
      <p>{t('learning.engage.362.1')}<a href="https://hbr.org/1965/11/exploit-the-product-life-cycle" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.362.2')}</a>{t('learning.engage.362.3')}</p>
      <br/>
      <p className='text-center'><b>{t('learning.engage.363')}</b></p>
      <br/>
      <p><b>{t('learning.engage.364')}</b></p>
      <p>{t('learning.engage.365.1')}<b>{t('learning.engage.365.2')}</b> (<a href="https://www.facebook.com/CitiesOfLearning.eu/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.365.3')}</a>, {t('learning.engage.365.4')}<a href="https://www.linkedin.com/company/cities-of-learning/?originalSubdomain=lt" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.365.5')}</a>, <a href="https://mailchi.mp/090d4bedf171/col-updated-september?fbclid=IwAR2SSBpapUYzr6Hjh3ry0-RnUegCtV77UPCnYZw-XHXSPiiGntmai6Mr9Sw" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.365.6')}</a>, <a href="https://www.citiesoflearning.net/blog/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.365.7')}</a>){t('learning.engage.365.8')}</p>
      <p>{t('learning.engage.366.1')}<b>{t('learning.engage.366.2')}</b>{t('learning.engage.366.3')}</p>
      <p>{t('learning.engage.367.1')}<b>{t('learning.engage.367.2')}</b>{t('learning.engage.367.3')}<a href="https://www.google.com/search?q=what+is+canva&rlz=1C1CHBD_slSI935SI937&ei=o4j7Y7vWIpeK9u8PkoC8uAg&ved=0ahUKEwj7s8fNzbP9AhUXhf0HHRIAD4cQ4dUDCA8&uact=5&oq=what+is+canva&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIECAAQQzIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDIFCAAQgAQyBQgAEIAEMgUIABCABDoKCAAQRxDWBBCwAzoHCAAQsAMQQ0oECEEYAFDzA1jzA2DvBWgBcAF4AIABW4gBW5IBATGYAQCgAQHIAQrAAQE&sclient=gws-wiz-serp" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.367.4')}</a>{t('learning.engage.367.5')}</p>
      <p>{t('learning.engage.368')}</p>
      <p>{t('learning.engage.369.1')}<b>{t('learning.engage.369.2')}</b>{t('learning.engage.369.3')}<b>{t('learning.engage.369.4')}</b>{t('learning.engage.369.5')}<a href="https://ljubljana.cityoflearning.eu/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.369.6')}</a>{t('learning.engage.369.7')}<a href="https://www.instagram.com/ljubljana_mestoucenja/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.369.8')}</a>{t('learning.engage.369.9')}<a href="https://www.linkedin.com/in/dutch-cities-of-learning-0429951ba/?originalSubdomain=nl" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.369.10')}</a>{t('learning.engage.369.11')}</p>

      <div className='page-break'></div>
      <ol>
        <li>
          <b>{t('learning.engage.370')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.371')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.372')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.373')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.374')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ol>
      <div className='text-center'>
        <Image src={step4741} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen48;
import React from 'react';

const StepsView = ({ step, stepsNumber, t, translationPrefix }) => {
  if (step > stepsNumber) {
    return <></>
  }
  return (
    <div className="my-3">
      <div className="wizard">
        <div className="wizard-progress"></div>
        <div className="wizard-steps">
          {[...Array(stepsNumber)].map((el, i) => (
            <div key={'step-' + i} className="wizard-step" style={{ width: `${100 / (stepsNumber + 2)}%` }}>
              <div className={"wizard-circle" + (i === (step - 1) ? " active" : "")}></div>
            </div>
          ))}
        </div>
      </div>
      <h6 className="text-center mt-3">{t(translationPrefix + '.' + step)}</h6>
    </div>
  )
}

export default StepsView;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step425 } from 'assets/learning';

const EngageLearningScreen24 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-2-3" className='text-engage'>{t('learning.engage.123')}</h4>
      <div className='d-print-none'>
        <div className='d-flex flex-direction-row'>
          <div className='col-8'>
            <p>{t('learning.engage.124')}</p>
          </div>
          <div className='col-4 text-center'>
            <Image src={step425} width={'50%'} style={{ objectFit: 'contain' }} />
          </div>
        </div>
        <br />
        <p className='text-center'><b>{t('learning.engage.125')}</b></p>
        <div className='d-flex flex-direction-row'>
          <div className='col-6 px-2'><iframe width={'100%'} height="315" src="https://www.youtube.com/embed/QggcoOQmXVU?si=91BFGDWMNRWAINHH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
          <div className='col-6 px-2'><iframe width={'100%'} height="315" src="https://www.youtube.com/embed/-HeFuJRb1N4?si=7_a8L9dIo-bmMqoL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
        </div>
      </div>
      <br />
      <p>{t('learning.engage.126.1')}<a href="https://www.jotform.com/nonprofit/online-fundraising-platforms-for-nonprofits/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.126.2')}</a>{t('learning.engage.126.3')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/-d-aNJBTWOY?si=45_q4dEO4R39sxN-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    </>
  )

}

export default EngageLearningScreen24;
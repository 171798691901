import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step45 } from 'assets/learning';

const EngageLearningScreen34 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-5" className='text-engage'>{t('learning.engage.177')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.178.1')}<b>{t('learning.engage.178.2')}</b>{t('learning.engage.178.3')}<b>{t('learning.engage.178.4')}</b>{t('learning.engage.178.5')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step45} width={'100%'} style={{ objectFit: 'contain' }} />
        </div>
      </div>
    </>
  )

}

export default EngageLearningScreen34;
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower4, empower6, empower7 } from 'assets/learning';

const EmpowerLearningScreen3 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="online-learning">{t('learning.empower.11')}</h2>
      <p>{t('learning.empower.12.1')}<b>{t('learning.empower.12.2')}</b>{t('learning.empower.12.3')}</p>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/k3uQ2ho0Zw8?si=2CnKAPe2Lvv4Nu6k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
      <p>{t('learning.empower.13')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/reja_Mk6RLo?si=HwvHsStTE7UT5Lbs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
      <p>{t('learning.empower.14')}</p>
      <p>{t('learning.empower.15')}</p>
      <p>{t('learning.empower.16.1')}<a href="https://www.youtube.com/watch?v=ZpwzzdHYCHU" target='_blank' rel='nooppener noreferrer'>{t('learning.empower.16.2')}</a>{t('learning.empower.16.3')}</p>
      <div className='text-center'>
        <Image src={empower6} width={'50%'} />
      </div>
      <br />
      <p>{t('learning.empower.17.1')}<b>{t('learning.empower.17.2')}</b>{t('learning.empower.17.3')}</p>
      <p>{t('learning.empower.18.1')}<b>{t('learning.empower.18.2')}</b>{t('learning.empower.18.3')}<b>{t('learning.empower.18.4')}</b>{t('learning.empower.18.5')}<b>{t('learning.empower.18.6')}</b>{t('learning.empower.18.7')}<a href="https://digital-competence.eu/" target='_blank' rel='nooppener noreferrer'>https://digital-competence.eu/</a></p>
      <p><b>{t('learning.empower.19')}</b></p>
      <div className='text-center'>
        <Image src={empower7} width={'50%'} />
      </div>
    </>
  )

}

export default EmpowerLearningScreen3;
import React from 'react';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen22 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 id="references">{t('learning.volunteering.organization.269')}</h2>

      <ul>
        <li>
          {t('learning.volunteering.organization.270')}
          <a href='https://charitydigital.org.uk/topics/a-complete-guide-to-volunteer-management-systems-9021' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://charitydigital.org.uk/topics/a-complete-guide-to-volunteer-management-systems-9021</a>
        </li>
        <li>
          {t('learning.volunteering.organization.271')}
          <a href='https://df2253af-c034-4026-aac2-5d1c91f60490.filesusr.com/ugd/3ec99c_0ee09c8d7b7a4715bc1fdd5d66086378.pdf' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://df2253af-c034-4026-aac2-5d1c91f60490.filesusr.com/ugd/3ec99c_0ee09c8d7b7a4715bc1fdd5d66086378.pdf</a>
        </li>
        <li>
          {t('learning.volunteering.organization.272')}
          <a href='https://www.rhythmsoftware.com/blog/10-ways-to-recognize-and-reward-your-associations-volunteers' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.rhythmsoftware.com/blog/10-ways-to-recognize-and-reward-your-associations-volunteers</a>
        </li>
        <li>
          {t('learning.volunteering.organization.273')}
          <a href='https://brenebrown.com/resources/dare-to-lead-list-of-values/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://brenebrown.com/resources/dare-to-lead-list-of-values/</a>
        </li>
        <li>
          {t('learning.volunteering.organization.274')}
          <a href='https://www.good-deeds-day.org/es/voluntariado-virtual-ventajas/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.good-deeds-day.org/es/voluntariado-virtual-ventajas/</a>
        </li>
        <li>
          {t('learning.volunteering.organization.275')}
          <a href='https://www.tercersector.com.es/blog/fomentar-adaptar-el-voluntariado-online-de-ong' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.tercersector.com.es/blog/fomentar-adaptar-el-voluntariado-online-de-ong</a>
        </li>
        <li>
          {t('learning.volunteering.organization.276')}
          <a href='https://digital-strategy.ec.europa.eu/en/policies/digital-principles#tab_1' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://digital-strategy.ec.europa.eu/en/policies/digital-principles#tab_1</a>
        </li>
        <li>
          {t('learning.volunteering.organization.277')}
          <a href='http://e-volunteering.eu/wp-content/uploads/2014/11/evolunteering-handbook.pdf' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> http://e-volunteering.eu/wp-content/uploads/2014/11/evolunteering-handbook.pdf</a>
        </li>
        <li>
          {t('learning.volunteering.organization.278')}
          <a href='https://www.youtube.com/watch?v=BDpHhELO_b0' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=BDpHhELO_b0</a>
        </li>
        <li>
          {t('learning.volunteering.organization.279')}
          <a href='https://doi.org/10.1080/10447318.2022.2109246' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://doi.org/10.1080/10447318.2022.2109246</a>
        </li>
        <li>
          {t('learning.volunteering.organization.280')}
          <a href='https://www.nten.org/blog/5-tips-to-plan-engaging-virtual-volunteer-opportunities' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.nten.org/blog/5-tips-to-plan-engaging-virtual-volunteer-opportunities</a>
        </li>
        <li>
          {t('learning.volunteering.organization.281')}
          <a href='https://iave.org/iavewp/wp-content/uploads/2020/09/Volunteering-and-the-Digital-World-Extending-the-Power-of-Volunteering-through-New-Technologies.pdf' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://iave.org/iavewp/wp-content/uploads/2020/09/Volunteering-and-the-Digital-World-Extending-the-Power-of-Volunteering-through-New-Technologies.pdf</a>
        </li>
        <li>
          {t('learning.volunteering.organization.282')}
          <a href='https://www.youtube.com/watch?v=7hl5adqpYdQ' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=7hl5adqpYdQ</a>
        </li>
        <li>
          {t('learning.volunteering.organization.283')}
          <a href='https://www.salto-youth.net/tools/otlas-partner-finding/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.salto-youth.net/tools/otlas-partner-finding/</a>
        </li>
        <li>
          {t('learning.volunteering.organization.284')}
          <a href='https://www.salto-youth.net/tools/toolbox/tool/hot-air-balloon.2942/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.salto-youth.net/tools/toolbox/tool/hot-air-balloon.2942/</a>
        </li>
        <li>
          {t('learning.volunteering.organization.285')}
          <a href='https://www.youtube.com/watch?v=zpzZumZCdWA' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=zpzZumZCdWA</a>
        </li>
        <li>
          {t('learning.volunteering.organization.286')}
          <a href='https://www.care2services.com/care2blog/meaningfully-connecting-with-volunteers-in-the-virtual-space-key-tips-to-know' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.care2services.com/care2blog/meaningfully-connecting-with-volunteers-in-the-virtual-space-key-tips-to-know</a>
        </li>
        <li>
          {t('learning.volunteering.organization.287')}
          <a href='https://www.youtube.com/watch?v=pKOSIHFL0eE' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=pKOSIHFL0eE</a>
        </li>
        <li>
          {t('learning.volunteering.organization.288')}
          <a href='https://thebossmagazine.com/volunteer-management-software-coordination/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://thebossmagazine.com/volunteer-management-software-coordination/</a>
        </li>
        <li>
          {t('learning.volunteering.organization.289')}
          <a href='https://www.youtube.com/watch?v=wtl5UrrgU8c' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=wtl5UrrgU8c</a>
        </li>
        <li>
          {t('learning.volunteering.organization.290')}
          <a href='https://www.youtube.com/watch?v=zq7CfAlgDfI' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=zq7CfAlgDfI</a>
        </li>
        <li>
          {t('learning.volunteering.organization.291')}
          <a href='https://www.youtube.com/watch?v=jbV1TDZQAFc' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=jbV1TDZQAFc</a>
        </li>
        <li>
          {t('learning.volunteering.organization.292')}
          <a href='https://app.unv.org/explore?type=online' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://app.unv.org/explore?type=online</a>
        </li>
        <li>
          {t('learning.volunteering.organization.292')}
          <a href='https://app.unv.org/explore?type=online' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://app.unv.org/explore?type=online</a>
        </li>
        <li>
          {t('learning.volunteering.organization.293')}
          <a href='https://www.youtube.com/watch?v=IdeJLoM295I' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.youtube.com/watch?v=IdeJLoM295I</a>
        </li>
        <li>
          {t('learning.volunteering.organization.294')}
          <a href='https://www.galaxydigital.com/blog/volunteer-recruitment-ideas' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> https://www.galaxydigital.com/blog/volunteer-recruitment-ideas</a>
        </li>
      </ul>
    </>
  )
}

export default VolunteeringLearningScreen22;
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Form, FormCheck, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

import { FormInput, Modal } from "components";
import { AppConstants, ServerConstants } from "constant";
import { CountriesData } from 'data';
import { useFetch } from "hooks/fetch";
import StepsView from "./steps";
import { AppUtils } from "utils";

const {
  API_METHOD_GET, API_METHOD_POST,
  API_URL_ORGANIZATION_REGISTER, API_URL_ORGANIZATION_VERIFY, API_URL_USER_EMAIL_VERIFY
} = ServerConstants;

const { PATH_PRIVACY_AND_POLICY } = AppConstants;

const propertiesPerPage = [
  ["email", "password", "confirmation"],
  ["name", "contactEmail", "country", "representativeFullName", "representativePosition"],
  ["piC_OID", "alternativeReason"],
]
const SignUpModal = ({ onDismiss, modals, show, showLoginModal, t }) => {

  const stepsNumber = 4;

  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const [error, setError] = useState(undefined);
  const [emailExists, setEmailExists] = useState(false);
  const emailFetch = useFetch(API_METHOD_GET);
  const registerFetch = useFetch(API_METHOD_POST, API_URL_ORGANIZATION_REGISTER);
  const verifyFetch = useFetch(API_METHOD_POST, API_URL_ORGANIZATION_VERIFY);

  useEffect(() => {
    return (() => {
      setValues({});
      setStep(1);
      setError(undefined);
      registerFetch.clearData();
      verifyFetch.clearData();
    })
  }, []);

  useEffect(() => {
    if (emailFetch.response) {
      if (emailFetch.response.data) {
        setStep(2);
      } else {
        setEmailExists(true);
      }
    }
  }, [emailFetch.response]);


  useEffect(() => {
    if (registerFetch.response) {
      if (registerFetch.response.status === 208) {
        setError(t('signup.error.exists'))
      } else {
        setStep(4);
      }
    }
  }, [registerFetch.response]);

  useEffect(() => {
    if (verifyFetch.response) {
      setStep(5);
    }
  }, [verifyFetch.response]);

  const onFormChange = useCallback((e) => {
    const name = e.target.id;
    const value = e.target.value;
    setValues({ ...values, [name]: value });
  }, [values]);

  const openAnotherModal = useCallback((e, modalId) => {
    e.preventDefault();
    if (modalId === modals.LOGIN) { showLoginModal() }
  }, []);

  const handleSubmit = useCallback((event, goToStep) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    if (goToStep - 1 === 1) {
      if (values['password'] && values['confirmation'] && (values['password'] !== values['confirmation'])) {
        return;
      }
    }
    if (goToStep === 2) {
      emailFetch.setFetchUrl(API_URL_USER_EMAIL_VERIFY + '?email=' + values['email']);
      emailFetch.forceFetchData();
    } else if (goToStep < 4) {
      setStep(goToStep);
    } else if (goToStep === 4) {
      setError(undefined);
      registerFetch.setBody({
        "email": values["email"] ?? undefined,
        "password": values["password"] ?? undefined,
        "name": values["name"] ?? undefined,
        "contactEmail": values["contactEmail"] ?? undefined,
        "representativeFullName": values["representativeFullName"] ?? undefined,
        "representativePosition": values["representativePosition"] ?? undefined,
        "country": values["country"] ?? undefined,
        "piC_OID": values["piC_OID"] ?? undefined,
        "alternativeReason": values["alternativeReason"] ?? undefined,
      });
    } else if (goToStep === 5) {
      verifyFetch.setBody({
        "email": values["email"] ?? undefined,
        "verificationCode": values["verificationCode"] ?? undefined
      });
    }
    return;
  }, [values]);

  const goBack = useCallback((goto) => {
    const newValues = values;
    const resetValues = propertiesPerPage[goto].map(el => el);
    resetValues.forEach(el => {
      newValues[el] = undefined;
    })
    setValues(newValues);
    setEmailExists(false);
    setStep(goto);
  }, [step, values]);

  const onDismissWrapper = useCallback(() => {
    onDismiss();
    setTimeout(() => {
      setValues({});
      setStep(1);
    }, 500)
  }, []);


  return (
    <Modal onHide={onDismissWrapper} show={show}>
      <Container>
        <StepsView stepsNumber={stepsNumber} step={step} t={t} translationPrefix={'signup.step'} />
        {step === 1 &&
          <Step1
            handleSubmit={handleSubmit}
            modals={modals}
            onFormChange={onFormChange}
            openAnotherModal={openAnotherModal}
            loading={emailFetch.loading}
            invalidEmail={emailExists}
            values={values}
            t={t}
          />
        }
        {step === 2 &&
          <Step2
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormChange={onFormChange}
            values={values}
            t={t} />
        }
        {step === 3 &&
          <Step3
            error={error}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormChange={onFormChange}
            t={t}
            loading={registerFetch.loading}
          />
        }
        {step === 4 &&
          <Step4
            goBack={goBack}
            onFormChange={onFormChange}
            handleSubmit={handleSubmit}
            loading={verifyFetch.loading}
            t={t}
          />
        }
        {step === 5 &&
          <Step5
            onDismiss={onDismissWrapper}
            t={t}
          />
        }
      </Container>
    </Modal>
  )
}

const Step1 = ({ handleSubmit, modals, onFormChange, openAnotherModal, values, loading, invalidEmail, t }) => {
  const [agree, setAgree] = useState(false);

  const isEmpty = () => {
    if (!values['email']) {
      return true;
    }
    if (!values['password'] || values['password'] !== values['confirmation']) {
      return true;
    }
    return false;
  }

  return (
    <Form onSubmit={(e) => handleSubmit(e, 2)}>
      <FormInput id="email" required={true} label={t('login.email')} onChange={onFormChange} type="email" isInvalid={invalidEmail} text={invalidEmail ? t('login.invalid.email') : undefined} />
      <FormInput id="password" required={true} label={t('login.password')} onChange={onFormChange} type="password" />
      <FormInput id="confirmation" required={true} label={t('signup.confirmation')} onChange={onFormChange} type="password" isInvalid={values['password'] && values['password'] !== values['confirmation']} />
      <div className="d-flex">
        <FormCheck id="policy" onChange={() => setAgree(old => !old)} value={agree} />
        <span className="pl-3">{t('login.agree.title')}</span>
        <a href={PATH_PRIVACY_AND_POLICY}>{t('login.agree.privacy')}</a>
      </div>
      <Button type="submit" className='w-100 my-3' disabled={loading || !agree || isEmpty()}>{t('next')}</Button>
      <hr />
      <p className="text-center mt-4">
        <span>{t('login.already')}</span>
        <a href="/#" onClick={(e) => openAnotherModal(e, modals.LOGIN)}>{t('login.title')}</a>
      </p>
    </Form>
  )
}

const Step2 = ({ goBack, handleSubmit, onFormChange, values, t }) => (
  <Form onSubmit={(e) => handleSubmit(e, 3)}>
    <FormInput id="name" required={true} label={t('organizations.name')} onChange={onFormChange} />
    <FormInput id="contactEmail" required={true} label={t('organizations.email')} onChange={onFormChange} type="email" text={t('signup.email.info')} />
    <FormInput id="representativeFullName" required={true} label={t('organizations.representative.name')} onChange={onFormChange} />
    <FormInput id="representativePosition" required={true} label={t('organizations.representative.position')} onChange={onFormChange} />
    <Form.Group className="mb-3">
      <Form.Label htmlFor={'country'}>{t('organizations.country')}</Form.Label>
      <Typeahead
        maxResults={Object.keys(CountriesData).length}
        options={Object.keys(CountriesData).map((el, i) => CountriesData[el])}
        paginate={true}
        emptyLabel={t('organizations.filter.country.empty')}
        iconName={'chevron-down'}
        id={'country'}
        label={t('organizations.filter.country.label')}
        labelKey={option => `${option}`}
        placeholder={t('organizations.filter.country.placeholder')}
        renderItem={(options) => (
          <div key={options}>
            <p className="my-1">{options}</p>
          </div>
        )}
        t={t}
        inputProps={{ required: true, defaultValue: values['country'] }}
        onChange={(selected) => onFormChange({ target: { id: 'country', value: selected ? Object.keys(CountriesData).find(el => CountriesData[el] === selected[0]) : undefined } })}
        selected={values['country'] ? [CountriesData[values['country']]] : []}
      />
    </Form.Group>
    <Row className="mt-5">
      <Col className="col-6">
        <Button onClick={() => goBack(1)} variant='secondary' className="w-100">{t('back')}</Button>
      </Col>
      <Col className="col-6">
        <Button type="submit" className='w-100'>{t('next')}</Button>
      </Col>
    </Row>
  </Form>
)

const Step3 = ({ error, goBack, handleSubmit, loading, onFormChange, t }) => {

  const [showPICOrOID, setShowPICOrOID] = useState(undefined);

  return (
    <Form onSubmit={(e) => handleSubmit(e, 4)}>
      {showPICOrOID === undefined &&
        <>
          <hr />
          {/* <p className="text-center">{t('signup.pic.info')}</p> */}
          <p className="text-center">{t('signup.pic.enter')}</p>
          <hr />
          <p className="text-center">{t('signup.pic.question')}</p>
          <Row className="mt-5">
            <Col className="col-4">
              <Button onClick={() => goBack(2)} variant='secondary' className="w-100">{t('back')}</Button>
            </Col>
            <Col className="col-4">
              <Button onClick={() => setShowPICOrOID(0)} variant='secondary' className="w-100" disabled={loading}>{t('no')}</Button>
            </Col>
            <Col className="col-4">
              <Button onClick={() => setShowPICOrOID(1)} className="w-100" disabled={loading}>{t('yes')}</Button>
            </Col>
          </Row>
        </>
      }
      {showPICOrOID === 0 &&
        <>
          <FormInput id="alternativeReason" required={true} label={t('signup.pic.no')} onChange={onFormChange} as="textarea" />
          <Row className="mt-5">
            <Col className="col-6">
              <Button onClick={() => { setShowPICOrOID(undefined); goBack(2); }} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
            </Col>
            <Col className="col-6">
              <Button type="submit" className='w-100' disabled={loading}>{t('next')}</Button>
            </Col>
          </Row>
        </>
      }
      {showPICOrOID === 1 &&
        <>
          <p className="text-center">{t('signup.pic.title')}</p>
          <FormInput id="piC_OID" required={true} label={t('signup.pic.title')} onChange={onFormChange} text={error} isInvalid={error} />
          <Row className="mt-5">
            <Col className="col-6">
              <Button onClick={() => { setShowPICOrOID(undefined); goBack(2); }} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
            </Col>
            <Col className="col-6">
              <Button type="submit" className='w-100' disabled={loading}>{t('next')}</Button>
            </Col>
          </Row>
        </>
      }
    </Form>
  )
}

const Step4 = ({ loading, goBack, handleSubmit, onFormChange, t }) => (
  <Form onSubmit={(e) => handleSubmit(e, 5)}>
    <p>{t('signup.verification')}</p>
    <FormInput id="verificationCode" required={true} label={t('code')} onChange={onFormChange} />
    <Row className="mt-5">
      <Col className="col-6">
        <Button onClick={() => goBack(3)} variant='secondary' className="w-100" disabled={loading}>{t('back')}</Button>
      </Col>
      <Col className="col-6">
        <Button type="submit" className='w-100' disabled={loading}>{t('finish')}</Button>
      </Col>
    </Row>
  </Form>
)

const Step5 = ({ onDismiss, t }) => (
  <div className="my-4">
    <p>{t('signup.completion')}</p>
    <Button type="submit" className='w-100 mt-3' onClick={onDismiss}>{t('ok')}</Button>
  </div>
)

export default SignUpModal;
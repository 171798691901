import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppConstants } from 'constant';
import { volunteeringOrganizationIllustration21 } from 'assets/learning';
import { Image } from 'react-bootstrap';

const { PATH_VOLUNTEERING_LEARNING_ORGANIZATION } = AppConstants;

const VolunteeringLearningScreen11 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-3-4" className='text-volunteering'>{t('learning.volunteering.organization.123')}</h4>

      <p>{t('learning.volunteering.organization.124')}</p>
      <p>
        {t('learning.volunteering.organization.125.1')}
        <b>{t('learning.volunteering.organization.125.2')}</b>
        {t('learning.volunteering.organization.125.3')}
      </p>
      <p>
        {t('learning.volunteering.organization.126.1')}
        <b>{t('learning.volunteering.organization.126.2')}</b>
        {t('learning.volunteering.organization.126.3')}
        <b>{t('learning.volunteering.organization.126.4')}</b>
        {t('learning.volunteering.organization.126.5')}
        <a href='https://www.youtube.com/watch?v=ED4lYu-0t5Y' target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.youtube.com/watch?v=ED4lYu-0t5Y</a>
      </p>
      <p>
        {t('learning.volunteering.organization.127.1')}
        <b>{t('learning.volunteering.organization.127.2')}</b>
        {t('learning.volunteering.organization.127.3')}
      </p>
      <p>
        {t('learning.volunteering.organization.128.1')}
        <b>{t('learning.volunteering.organization.128.2')}</b>
        {t('learning.volunteering.organization.128.3')}
        <a href='https://www.pexels.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.pexels.com</a>
      </p>
      <p>{t('learning.volunteering.organization.130')}</p>
      <ul>
        <li>
          {t('learning.volunteering.organization.131.1')}
          <a href='https://digital-strategy.ec.europa.eu/en/policies/digital-principles#videogame' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.131.2')}</a>
        </li>
        <li>
          {t('learning.volunteering.organization.132')}
          <a href='https://www.coe.int/en/web/freedom-expression/digital-rights-quiz#0' target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://www.coe.int/en/web/freedom-expression/digital-rights-quiz#0</a>
        </li>
        <li>
          {t('learning.volunteering.organization.133.1')}
          <a href='https://www.connect-international.org/2019/08/20/updataed-guide-is-now-available-online/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.133.2')}</a>
        </li>
        <li>
          {t('learning.volunteering.organization.134.1')}
          <a href='https://digitalcitizenship.connect-international.org/learning-package/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.134.2')}</a>
          {t('learning.volunteering.organization.134.3')}
        </li>
      </ul>
      <div className='text-center'>
        <Image src={volunteeringOrganizationIllustration21} width={400} />
      </div>
      <div className='text-center mt-5'>
        <button onClick={() => window.open(PATH_VOLUNTEERING_LEARNING_ORGANIZATION + '/simulation-3', '_blank')} className='btn btn-volunteering text-white'>{t('learning.volunteering.organization.simulation.3')}</button>
      </div>
    </>
  )

}

export default VolunteeringLearningScreen11;
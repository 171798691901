/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { FormInput, FormUpload, Layout } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';

const { PATH_PROFILE } = AppConstants;
const { API_METHOD_POST, API_URL_VOLUNTEERING_OPPORTUNITY } = ServerConstants;

const ProfileOpportunitiesNewScreen = () => {

  const { t } = useTranslation();
  const submitFetch = useFetch(API_METHOD_POST, API_URL_VOLUNTEERING_OPPORTUNITY);
  const { values, onValueChange, setValue } = useValues({});

  useEffect(() => {
    setValue('organizationId', AppUtils.getLoggedUser().id);
  }, []);

  useEffect(() => {
    if (submitFetch.response) {
      window.location.href = PATH_PROFILE;
    }
  }, [submitFetch.response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    submitFetch.setBody(ModelUtils.convertOpportunityModelF2B(values));
  }, [values]);

  return (
    <Layout>
      <Container className="my-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <h2>{t('profile.opportunity.new')}</h2>
          </div>
        </div>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormInput id="title" required={true} label={t('opportunity.name')} onChange={onValueChange} type="text" defaultValue={values["title"]} className="w-50" />
          <FormUpload id="featuredImage" required={true} label={t('opportunity.image')} onValueChange={onValueChange} removeImage={() => setValue('featuredImage', undefined)} value={values['featuredImage']} inputClassName={'w-50'} />
          <FormInput id="text" required={true} label={t('opportunity.description')} onChange={onValueChange} type="text" as="textarea" rows={10} defaultValue={values["text"]} />
          <FormInput id="formUrl" required={true} label={t('opportunity.formUrl')} onChange={onValueChange} type="text" defaultValue={values["formUrl"]} className="w-50" />
          <div className="text-center">
            <Button className="mr-2 px-5" variant="secondary" onClick={() => window.location.href = PATH_PROFILE} disabled={submitFetch.loading}>{t('cancel')}</Button>
            <Button className="ml-2 px-5" type="submit" disabled={submitFetch.loading}>{t('publish')}</Button>
          </div>
        </Form>
      </Container>
    </Layout>
  )
}

export default ProfileOpportunitiesNewScreen;
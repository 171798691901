import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { connect25, connect6 } from 'assets/learning';

const ConnectLearningScreen11 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="chapter-3" className='text-connect'>{t('learning.connect.82')}</h2>
      <div className='text-center'>
        <Image src={connect25} width={'50%'} />
      </div>
      <h4 id="chapter-3-1">{t('learning.connect.83')}</h4>
      <p>{t('learning.connect.84')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.85.1')}</b>: {t('learning.connect.85.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.86.1')}</b>: {t('learning.connect.86.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.87.1')}</b>: {t('learning.connect.87.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.88.1')}</b>: {t('learning.connect.88.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.89.1')}</b>: {t('learning.connect.89.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.90.1')}</b>: {t('learning.connect.90.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.91.1')}</b>: {t('learning.connect.91.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.92.1')}</b>: {t('learning.connect.92.2')}</span>
        </li>
        <li>
          <FontAwesomeIcon icon={'check'} className='text-connect' />&nbsp;&nbsp;
          <span className='pl-1'><b>{t('learning.connect.93.1')}</b>: {t('learning.connect.93.2')}</span>
        </li>
      </ul>
      <p>{t('learning.connect.94')}</p>
      <p>{t('learning.connect.95.1')}<a href="https://puzzel.org/en/crossword/play?p=-N_Jrs4osqeFgiSOlgwa" target='_blank' rel='nooppener noreferrer' className='text-connect'>{t('learning.connect.95.2')}</a></p>
      <div className='text-center'>
        <Image src={connect6} width={'80%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen11;
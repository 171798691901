import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { euLogo, logo } from 'assets/learning';

const EngageLearningScreen1 = () => {
  const { t } = useTranslation();

  return (
    <div className='p-5 pt-2'>
      <div className='text-center mt-5'>
        <Image src={euLogo} width={320} height={64} />
      </div>
      <div className='text-center'>
        <Image src={logo} width={'75%'} />
      </div>
        <div className='text-center'>
          <h1>{t('learning.engage.1')}</h1>
          <h2>{t('learning.engage.2')}</h2>
        </div>
    </div>
  )

}

export default EngageLearningScreen1;
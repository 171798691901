export default class VolunteeringInvitation {
  private __id: number;
  private __email: string;
  private __fullName: string;
  private __date: string;
  private __accepted: boolean;

  constructor(
    id: number,
    email: string,
    fullName: string,
    date: string,
    accepted: boolean,
  ) {
    this.__id = id;
    this.__email = email
    this.__fullName = fullName;
    this.__date = date;
    this.__accepted = accepted;
  }

  public get id() { return this.__id; }
  public get email() { return this.__email; }
  public get fullName() { return this.__fullName; }
  public get date() { return this.__date; }
  public get accepted() { return this.__accepted; }

  public toJSON() {
    return {
      id: this.__id,
      email: this.__email,
      fullName: this.__fullName,
      date: this.__date,
      accepted: this.__accepted
    }
  }

}
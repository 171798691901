import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';

const EngageLearningScreen40 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-5-6" className='text-engage'>{t('learning.engage.202')}</h4>
      <p><b>{t('learning.engage.203')}</b></p>
      <p>{t('learning.engage.204.1')}<a href="https://www.consilium.europa.eu/en/policies/data-protection/data-protection-regulation/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.204.2')}</a>{t('learning.engage.204.3')}</p>
      <ul style={{ listStyle: 'none' }}>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.205')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.206')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.207')}</span>
          </span>
        </li>
        <li>
          <span>
            <FontAwesomeIcon icon={'arrow-right'} className='text-engage' />&nbsp;&nbsp;
            <span className='pl-1'>{t('learning.engage.208')}</span>
          </span>
        </li>
      </ul>
      <p>{t('learning.engage.209')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/GFsikTOj4qU?si=VdgPYf94ASz968f-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
    </>
  )

}

export default EngageLearningScreen40;
export default class ForumTheme {
  private __id: number;
  private __createdAt: string;
  private __authorId: number;
  private __authorRole: string;
  private __authorName: string;
  private __authorImage: string;
  private __title: string;
  private __postsCount: number;

  constructor(
    id: number,
    createdAt: string,
    authorId: number,
    authorRole: string,
    authorName: string,
    authorImage: string,
    title: string,
    postsCount: number,

  ) {
    this.__id = id;
    this.__createdAt = createdAt;
    this.__authorId = authorId;
    this.__authorRole = authorRole;
    this.__authorName = authorName;
    this.__authorImage = authorImage;
    this.__title = title;
    this.__postsCount = postsCount;
  }

  public get id() { return this.__id; }
  public get createdAt() { return this.__createdAt; }
  public get authorId() { return this.__authorId; }
  public get authorRole() { return this.__authorRole; }
  public get authorName() { return this.__authorName; }
  public get authorImage() { return this.__authorImage; }
  public get title() { return this.__title; }
  public get postsCount() { return this.__postsCount; }

  public toJSON() {
    return {
      id: this.__id,
      createdAt: this.__createdAt,
      authorId: this.__authorId,
      authorRole: this.__authorRole,
      authorName: this.__authorName,
      authorImage: this.__authorImage,
      title: this.__title,
      postsCount: this.__postsCount
    }
  }

}
import { connect5 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ConnectLearningScreen10 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h4 id="chapter-2-2" className='text-connect'>{t('learning.connect.77')}</h4>
      <p>{t('learning.connect.78')}</p>
      <ul>
        <li><b>{t('learning.connect.79.1')}</b> – {t('learning.connect.79.2')}</li>
        <li><b>{t('learning.connect.80.1')}</b> – {t('learning.connect.80.2')}</li>
        <li><b>{t('learning.connect.81.1')}</b> – {t('learning.connect.81.2')}</li>
      </ul>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/Vl3gf7zoqsg?si=CWKKyNayOg2AIVMn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
      <div className='text-center'>
        <Image src={connect5} width={'50%'} />
      </div>
    </>
  )
}

export default ConnectLearningScreen10;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { Trans } from 'react-i18next';

import { AppUtils, ModelUtils } from 'utils';
import { profilePicture } from 'assets/images';
import { useFetch } from 'hooks/fetch';
import { FormInput, Loader } from 'components';
import { ServerConstants } from 'constant';
import { useValues } from 'hooks/values';

const { API_METHOD_GET, API_URL_CONNECT } = ServerConstants;

const CalendarContent = ({ show, fair, member, organization, t, goBack, showInfo, scheduleSession, meetingLink, loadingUpdateMeetingLink, updateMeetingLink, reload }) => {

  const calendarFetch = useFetch(API_METHOD_GET);
  const [sessions, setSessions] = useState(undefined);
  const { values, onValueChange, addValues } = useValues();
  const isMyCalendar = AppUtils.isMyCalendar(member, organization);

  useEffect(() => {
    addValues({ 'meetingLink': meetingLink });
    if (member && show) {
      calendarFetch.setFetchUrl(API_URL_CONNECT + '/' + fair.id + '/calendar/' + (isMyCalendar ? 'my' : (member.id + '/' + member.accountType)));
      calendarFetch.forceFetchData();
    }
    return (() => {
      setSessions(undefined);
    })
  }, [member, show]);

  useEffect(() => {
    addValues({ 'meetingLink': meetingLink });
    if (member && show && reload > 0) {
      calendarFetch.setFetchUrl(API_URL_CONNECT + '/' + fair.id + '/calendar/' + (isMyCalendar ? 'my' : (member.id + '/' + member.accountType)));
      calendarFetch.forceFetchData();
    }
  }, [reload])

  useEffect(() => {
    if (calendarFetch.response) {
      const converted = calendarFetch.response.data.map(el => ModelUtils.convertSessionModelB2F(el));
      let array = [];
      let sliceSize = 4;
      for (let i = 0; i < converted.length; i += sliceSize) {
        array.push(converted.slice(i, i + sliceSize));
      }
      setSessions(array);
      calendarFetch.clearData();
    }
  }, [calendarFetch.response]);

  const getSessionClassName = useCallback((el) => {
    const isMyCalendar = AppUtils.isMyCalendar(member, organization);
    let className = "session-card";
    if (isMyCalendar) {
      if (el.host) {
        className += " open clickable";
      }
    } else {
      if (AppUtils.isFairSessionInfoAllowed(fair, el)) {
        className += " open clickable";
      } else if (AppUtils.isFairSessionScheduleAllowed(fair, el)) {
        className += " bg-primary open clickable";
      }
    }
    return className;
  }, [fair, member, organization])

  const updateMeetingLinkWrapper = useCallback((e) => {
    e.preventDefault();
    updateMeetingLink(values['meetingLink']);
  }, [values]);

  if (!show) {
    return <></>
  }

  return (
    <div className="px-3 pb-3">
      <div className="d-flex flex-direction-row align-items-center">
        <Image src={member?.profilePicture ? member.profilePicture : profilePicture} width={64} height={64} style={{ borderRadius: 64, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
        <div className="mx-4">
          <h5 className="mb-0 pt-3"><b>{member.fullName}</b></h5>
          <p>{member.position}</p>
        </div>
      </div>

      <div className="mt-3">
        {!sessions || calendarFetch.loading ? <div className="text-center m-5"><Loader /></div> :
          <div>
            {(AppUtils.isFairPreLive(fair) || AppUtils.isFairLive(fair)) && <p><Trans i18nKey={t('functions.connect.calendar.schedule')} /></p>}
            {sessions && sessions.length > 0 ?
              <div className="mt-3 d-flex flex-direction-row flex-wrap px-4">
                {sessions.map((array) => (
                  array.map((el, i) =>
                    <div className='session'>
                      <div className="session-header">
                        <small>{i === 0 && AppUtils.convertDateToLocaleDate(el.startTime, t('format.time'))}</small>
                        <small>{AppUtils.convertDateToLocaleDate(el.endTime, t('format.time'))}</small>
                      </div>
                      {isMyCalendar
                        ? <MyView el={el} t={t} className={getSessionClassName(el)} showInfo={showInfo} fair={fair} />
                        : <ParticipantView el={el} t={t} className={getSessionClassName(el)} showInfo={showInfo} scheduleSession={scheduleSession} fair={fair} />}
                    </div>
                  )
                ))}
              </div>
              : undefined}
            {isMyCalendar ?
              <Form onSubmit={(e) => updateMeetingLinkWrapper(e)}>
                <div className='d-flex flex-direction-row'>
                  <div style={{ flexGrow: 1 }}>
                    <FormInput id="meetingLink" required={true} label={t('functions.connect.calendar.link.my')} onChange={onValueChange} type="text" defaultValue={values['meetingLink']} />
                  </div>
                  <div className='ml-4'>
                    <p className='mt-1 mb-2'>&nbsp;</p>
                    <Button type='submit' disabled={loadingUpdateMeetingLink}>{t('functions.connect.calendar.link.update')}</Button>
                  </div>
                </div>
              </Form>
              : undefined}
          </div>
        }
        <div className="text-center mt-3">
          <Button variant='secondary' className="w-50" onClick={goBack}>{t('functions.connect.calendar.back.organization', { name: organization.name })}</Button>
        </div>
      </div>
    </div>
  )
}

const MyView = ({ el, t, className, showInfo, fair }) => {
  return (
    <div className={className} onClick={() => el.host ? showInfo(el) : undefined}>
      <div className="d-flex flex-direction-row align-items-center">
        {el.host ?
          <div className="d-flex flex-direction-row align-items-center">
            <Image src={el.host.profilePicture ?? profilePicture} height={24} width={24} style={{ borderRadius: 24, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
            <span className="pl-2">{el.host.name}</span>
          </div>
          :
          <span className="text-center" style={{ flexGrow: 1 }}>{!AppUtils.isFairLive(fair) ? t('functions.connect.calendar.open') : undefined}&nbsp;</span>}
      </div>
    </div>
  )
}
const ParticipantView = ({ el, t, className, showInfo, scheduleSession, fair }) => {
  const actionWrapper = (session) => {
    if (AppUtils.isFairSessionInfoAllowed(fair, session)) {
      return showInfo(session);
    } else if (AppUtils.isFairSessionScheduleAllowed(fair, session)) {
      return scheduleSession(session);
    }
    return undefined
  }

  return (
    <div className={className} onClick={() => actionWrapper(el)}>
      {el.host ?
        <div className="d-flex flex-direction-row align-items-center">
          <Image src={el.host.profilePicture ?? profilePicture} height={24} width={24} style={{ borderRadius: 24, objectFit: 'cover', backgroundRepeat: 'no-repeat' }} />
          <span className="pl-2 text-white">{el.host.name}</span>
        </div>
        :
        <span className="text-center" style={{ flexGrow: 1 }}>{el.isOpen && AppUtils.isFairSessionScheduleAllowed(fair, el) && t('functions.connect.calendar.open')}&nbsp;</span>}
    </div >
  )
}

export default CalendarContent;
import React, { useCallback, useEffect, useState } from 'react';

import { ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils';
import { Loader, Modal } from 'components';
import APIKit from 'config/axios/api';
import HotspotModalView from 'screens/functions/hotspot/preview';
import HotspotModalForm from 'screens/functions/hotspot/form';
import hotspotTypes from '../../../../../assets/hotspots.js';

const { API_URL_EMPOWER } = ServerConstants;

const EmpowerHotspotModal = ({ event, el, onDismiss, show }) => {

  const [hotspot, setHotspot] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(undefined);

  useEffect(() => {
    if (show) {
      setHotspot(el);
      if (el.type) {
        setLoading(true);
        APIKit.get(API_URL_EMPOWER + '/' + event.id + '/hotspots/' + el.type).then(res => {
          if (res.data) {
            setHotspot(ModelUtils.convertHotspotModelB2F(res.data));
          } else {
            if (editModeAllowed()) {
              setEditMode(true);
            }
          }
          setLoading(false);
        }).catch(e => console.log(e))
      }
    }
  }, [show]);

  const onDismissWrapper = useCallback(() => {
    onDismiss();
    setTimeout(() => setEditMode(false), 500);
  }, [onDismiss]);

  const editModeAllowed = useCallback(() => AppUtils.isUserOrganization() && !AppUtils.isEventEnded(event), [event])

  return (
    <Modal onHide={onDismissWrapper} show={!!show} size={'lg'}>
      {loading ? <div className="m-5"><Loader /></div>
        :
        editMode ?
          <HotspotModalForm el={hotspot} onCancel={onDismissWrapper} urlPrefix={API_URL_EMPOWER + '/' + event.id + '/hotspots'} defaultItemsUrlPrefix={API_URL_EMPOWER+ '/hotspots'} defaultValue={hotspotTypes.Empower} type={true} editModelAllowed={editModeAllowed()} />
          :
          !editMode ?
            <HotspotModalView
              el={hotspot}
              event={event}
              toEditMode={editModeAllowed() ? (e) => { e.preventDefault(); setEditMode(true) } : undefined}
              defaultValue={hotspotTypes.Empower}
              functionType={'EMPOWER'}
            />
            : undefined
      }
    </Modal >
  )
}

export default EmpowerHotspotModal;
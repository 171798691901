import { volunteeringVolunteerIllustration9, volunteeringVolunteerImage6 } from 'assets/learning';
import React from 'react';
import { Accordion, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen6 = () => {
  const { t } = useTranslation();

  return (
    <>
      <br/><br/>
      <h2 id="step-2">{t('learning.volunteering.volunteer.33')}</h2>
      <div className='text-center'>
        <Image src={volunteeringVolunteerIllustration9} width={320} height={220} />
      </div>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.volunteer.34')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.volunteer.35')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('learning.volunteering.volunteer.36')}</Accordion.Header>
          <Accordion.Body>
            <p>{t('learning.volunteering.volunteer.37')}</p>
            <p>{t('learning.volunteering.volunteer.38')}</p>
            <p>
              {t('learning.volunteering.volunteer.39.1')}
              <a href="https://www.youtube.com/watch?v=jbV1TDZQAFc" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.39.2')}</a>
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br/>
      <br/>
      <Image src={volunteeringVolunteerImage6} width={'100%'} height={400} style={{ objectFit: 'cover'}} />
    </>
  )

}

export default VolunteeringLearningScreen6;
const hotspotTypes = {
  Engage: {
    TEAM_MEETINGS: {
      intro: "Place to be used for scheduling team meetings.",
      title: "Team Meetings"
    },
    TODO_LIST: {
      intro: "Place for all people involved to share their to-do lists.",
      title: "To-Do Lists"
    },
    WHITEBOARD: {
      intro: "If you wish to share something with the entire team of brainstorm together.",
      title: "Info Table"
    },
    PROJECT_MANAGEMENT: {
      intro: "Have all your project management tools at one place available for the entire team.",
      title: "Projects"
    },
    CALENDAR: {
      intro: "Tracking meetings, events, travels, organizing and following up the tasks, reminders of the events.",
      title: "Calendar"
    },
    FILE_STORAGE: {
      intro: "This will be used for keeping information safe and guaranteeing access to all.",
      title: "File Storage and Share"
    },
    FINANCIAL_MANAGEMENT: {
      intro: "With these apps we can organize our finances, project budget, the profit loss balance, we can predict the costs for any events and help with our taxes.",
      title: "Finances"
    },
    SOCIAL_MEDIA: {
      intro: "This will help us store all the management tools at one place, helping coordinate efforts on social media.",
      title: "Communication"
    }
  },
  Empower: {
    ICE_BREAKERS: {
      intro: "Make sure that participants get to know each other, break the ice in the room and energize through this hotspot.",
      title: "Games and Icebreakers"
    },
    PROJECTING: {
      intro: "Offer your participants presentations relevant to the topic of your event.",
      title: "Presentations"
    },
    MINGLING: {
      intro: "Use for activities to associate, mix, or interact with others, as at a social event or coffee breaks.",
      title: "Mingling"
    },
    BRAINSTORMING: {
      intro: "Help your  participants unleash their ideas and work together as a team.",
      title: "Brainstorming"
    },
    BREAKOUT_ROOMS: {
      intro: "Share links for your participants to be able to access separate working groups during the meeting.",
      title: "Breakout Rooms"
    },
    LIVE_SESSIONS: {
      intro: "Share the link for the main live session.",
      title: "Live Session"
    },
    EVALUATION: {
      intro: "Share a link to receive the evaluation and feedback of your participants.",
      title: "Evaluation"
    },
    FILE_STORAGE: {
      intro: "Keep the event's information safe and guarantee access to all.",
      title: "File Storage and Share"
    },
    AGENDA: {
      intro: "Share your agenda with the participants of the event.",
      title: "Agenda"
    }
  },
  Connect: {
    THOUGHTS: {
      intro: "Get in Touch With Us! This is an open space for feedback and contacting the organization. For easily connecting with visitors to your virtual stand, we suggest you to create a WhatsApp group and paste the link leading to it in the “Items” field.",
      title: "Get in Touch With Us"
    },
    ABOUT: {
      intro: "Learn about the organization!",
      title: "About Us"
    },
    WORK: {
      intro: "Our experience that brought us here",
      title: "Our Work"
    }
  },
  Volunteer: {
    HOST_ORGANIZATIONS_INFO_POINT: {
      title: "Host organization's info point",
      intro: "Learn more about the host organization's organization's work, activities and values."
    },
    VOLUNTEERS_INFO_POINT: {
      title: "Volunteer's info point",
      intro: "Learn more about the competencies, skills and experiences of volunteers"
    },
    MEETINGS: {
      title: "Meetings",
      intro: "Attend video calls and meetings"
    },
    FILE_SHARE: {
      title: "File share",
      intro: "Share and edit important documents and files."
    },
    CALENDAR_VOLUNTEERING: {
      title: "Calendar",
      intro: "Tracking meetings, events and other activities involving volunteers."
    },
    TASKS: {
      title: "Tasks",
      intro: "Overview of tasks with descriptions and timelines."
    },
    EVALUATION_AND_MONITORING: {
      title: "Evaluation and Monitoring",
      intro: "Tracking and assessing the work engagement of volunteers."
    },
    COMMUNICATIONS: {
      title: "Communications",
      intro: "Manage communication and mentoring support between organization and volunteers."
    }
  }
}

export default hotspotTypes;
import React from 'react';
import { Container } from 'react-bootstrap';

import { Modal } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountDeletion = ({ onDismiss, visible, t }) => {

  return (
    <Modal onHide={onDismiss} show={visible}>
      <Container>
        <div className="my-3">
          <div className="d-flex flex-direction-row justify-content-center align-items-start mb-3">
            <FontAwesomeIcon icon={'circle-check'} size={'4x'} className="text-primary" />
            <div className="mx-3">
              <h3>{t('profile.delete.modal.title')}</h3>
              <p><i>{t('profile.delete.modal.description')}.</i></p>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  )
}

export default AccountDeletion;
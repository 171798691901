import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const EngageLearningScreenAnswers = () => {
  const { t } = useTranslation();
  
  return (
    <div className='d-flex flex-direction-column justify-content-between p-5 pt-0 h-100'>
      <Container className='page'>
        <p>{t('learning.engage.469')}</p>
        <div className='d-flex justify-content-center'>
          <table className='table two-columns text-center' style={{ width: '75%' }}>
            <tbody>
              <tr>
                <td>{t('learning.engage.470')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.471')}</td>
                <td>{t('learning.engage.472')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.473')}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>{t('learning.engage.474')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.475')}</td>
                <td>{t('learning.engage.476')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.477')}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>{t('learning.engage.478')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.479')}</td>
                <td>{t('learning.engage.480')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.481')}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>{t('learning.engage.482')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.483')}</td>
                <td>{t('learning.engage.484')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.485')}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>{t('learning.engage.486')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.487')}</td>
                <td>{t('learning.engage.488')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.489')}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>{t('learning.engage.490')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.491')}</td>
                <td>{t('learning.engage.492')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.493')}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>{t('learning.engage.494')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.495')}</td>
                <td>{t('learning.engage.496')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.497')}</td>
                <td></td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>{t('learning.engage.498')}</td>
                <td></td>
              </tr>
              <tr>
                <td>{t('learning.engage.499')}</td>
                <td>{t('learning.engage.500')}</td>
              </tr>
              <tr>
                <td>{t('learning.engage.501')}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </Container>
    </div>
  )

}

export default EngageLearningScreenAnswers;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormInput from './input';

const FormUpload = ({ id, label, value, removeImage, required, onValueChange, inputClassName, types }) => {

  const { t } = useTranslation()

  const onValueChangeWrapper = (e) => {
    if (!types) {
      onValueChange(e);
      return;
    }
    const file = e.target.files[0];
    const extension = file.name.split('.').pop();
    console.log(types, extension, types.includes(extension));
    if (!types.includes('.' + extension)) {
      e.target.value = null;
      return;
    }
    onValueChange(e);
  };
  return (
    <Form.Group className={'mb-3 ' + inputClassName}>
      {label ? <Form.Label htmlFor={id}>{label}</Form.Label> : undefined}
      <div>
        {value ?
          <div className="badge">
            <Image src={value} />
            <a onClick={removeImage} className="text-danger clickable"><FontAwesomeIcon icon={'trash-alt'} /></a>
          </div>
          : <>
            <FormInput
              id={id}
              required={required}
              onChange={onValueChangeWrapper}
              type={'file'}
              accept={types}
              text={types && t('upload.extensions', { extensions: types })}
              />
          </>
        }
      </div>
    </Form.Group>
  )
}

export default FormUpload;
import React, { useCallback, useEffect, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Empty, FormDate, FormInput, FormUpload, Layout, Loader } from "components";
import { AppConstants, ServerConstants } from "constant";
import { useFetch } from "hooks/fetch";
import moment from "moment";
import { AppUtils, ModelUtils } from "utils";
import { useValues } from "hooks/values";

const { PATH_ADMIN_FAIRS, HEADER_TYPES, FAIR_APPLICATION_DEADLINE, FAIR_PARTICIPANTS_MAX } = AppConstants;
const { API_METHOD_GET, API_METHOD_POST, API_URL_FAIR, API_URL_FAIRS_ADMIN_DRAFT, API_URL_FAIRS_ADMIN_PUBLISH } = ServerConstants;

const AdminFairsEditScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[3];
  const fairFetch = useFetch(API_METHOD_GET, API_URL_FAIR + '/' + id);
  const submitFetch = useFetch(API_METHOD_POST);
  const { values, onValueChange, addValues, setValue } = useValues({});
  const buttonActionUrl = useRef(undefined);

  useEffect(() => {
    if (fairFetch.response && fairFetch.response.data) {
      const data = ModelUtils.convertFairModelB2F(fairFetch.response.data);
      addValues(data);
    }
  }, [fairFetch.response]);

  useEffect(() => {
    if (values['numberOfParticipants'] && values['numberOfParticipants'] > FAIR_PARTICIPANTS_MAX) {
      setValue('numberOfParticipants', FAIR_PARTICIPANTS_MAX);
    }
  }, [values])

  useEffect(() => {
    if (submitFetch.response) {
      window.location.href = PATH_ADMIN_FAIRS;
    }
  }, [submitFetch.response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      buttonActionUrl.current = false
      return;
    }
    if (buttonActionUrl.current) {
      submitFetch.setFetchUrl(buttonActionUrl.current);
      submitFetch.setBody(ModelUtils.convertFairModelF2B(values));
    }
    return;
  }, [values]);

  const isEditable = useCallback(() => {
    if (!values) {
      return false;
    }
    return !AppUtils.isFairLive(values) && !AppUtils.isFairFinished(values);
  }, [values])

  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="mt-4 mb-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <h2>{t('fairs.edit')}</h2>
          </div>
        </div>
        {fairFetch.loading ? <Loader /> :
          <>
            {!isEditable() ?
              <div className='text-center'><Empty messageKey={t('fairs.message.finished')} /></div>
              :
              <Row>
                <Col className="col-6">
                  <Form onSubmit={(e) => handleSubmit(e)}>
                    <FormInput id="title" required={true} label={t('fairs.name')} onChange={onValueChange} type="text" defaultValue={values["title"]} />
                    <FormUpload id="featuredImage" required={true} label={t('fairs.image')} onValueChange={onValueChange} removeImage={() => setValue('featuredImage', undefined)} value={values['featuredImage']} />
                    <FormInput id="description" required={true} label={t('fairs.description')} onChange={onValueChange} type="text" as="textarea" rows="6" defaultValue={values["description"]} />
                    <FormInput id="numberOfParticipants" required={true} label={t('fairs.participants.number')} onChange={onValueChange} type="number" defaultValue={values["numberOfParticipants"]} max={FAIR_PARTICIPANTS_MAX} />
                    <FormDate
                      required={true}
                      label={t('fairs.start.date')}
                      minDate={new Date()}
                      selected={values['startDateTime'] ? new Date(!values['editedStartDateTime'] ? AppUtils.convertDateToLocaleDate(values['startDateTime']) : (values['startDateTime'])) : undefined}
                      onChange={(e) => {
                        onValueChange({ target: { id: 'editedStartDateTime', value: true } })
                        onValueChange({ target: { id: 'startDateTime', value: e } })
                        onValueChange({ target: { id: 'applicationDateTime', value: undefined } })
                        onValueChange({ target: { id: 'preLiveDateTime', value: undefined } })
                      }}
                      showTimeSelect={true}
                      dateFormat={t('format.picker.date.time')}
                    />
                    <FormDate
                      required={true}
                      label={t('fairs.prelive')}
                      maxDate={moment(values['startDateTime']).unix() * 1000}
                      selected={values['preLiveDateTime'] ? new Date(!values['editedPreLiveDateTime'] ? AppUtils.convertDateToLocaleDate(values['preLiveDateTime']) : (values['preLiveDateTime'])) : undefined}
                      onChange={(e) => {
                        onValueChange({ target: { id: 'editedPreLiveDateTime', value: true } })
                        onValueChange({ target: { id: 'preLiveDateTime', value: e } })
                      }}
                      showTimeSelect={true}
                      dateFormat={t('format.picker.date.time')}
                    />
                    <FormDate
                      required={true}
                      label={t('fairs.application')}
                      maxDate={moment(values['startDateTime']).unix() * 1000 - FAIR_APPLICATION_DEADLINE}
                      selected={values['applicationDateTime'] ? new Date(!values['editedApplicationDateTime'] ? AppUtils.convertDateToLocaleDate(values['applicationDateTime']) : (values['applicationDateTime'])) : undefined}
                      onChange={(e) => {
                        onValueChange({ target: { id: 'editedApplicationDateTime', value: true } })
                        onValueChange({ target: { id: 'applicationDateTime', value: e } })
                        onValueChange({ target: { id: 'preLiveDateTime', value: undefined } })
                      }}
                      showTimeSelect={true}
                      dateFormat={t('format.picker.date.time')}
                    />
                    <FormInput id="durationInMinutes" required={true} label={t('fairs.duration')} onChange={onValueChange} type="number" defaultValue={values["durationInMinutes"] ?? 60} min={60} />
                    <FormInput id="roomUrl" label={t('fairs.room.title')} onChange={onValueChange} type="text" defaultValue={values["roomUrl"]} />
                    <Row className="mt-5">
                      <Col className="col-6">
                        <Button type="submit" onClick={() => buttonActionUrl.current = API_URL_FAIRS_ADMIN_DRAFT} variant='secondary' className="w-100">{t('fairs.draft')}</Button>
                      </Col>
                      <Col className="col-6">
                        <Button type="submit" onClick={() => buttonActionUrl.current = API_URL_FAIRS_ADMIN_PUBLISH} className='w-100'>{t('publish')}</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            }
          </>
        }
      </Container>
    </Layout>
  )
}

export default AdminFairsEditScreen;
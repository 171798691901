/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { Empty, Layout, Loader } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { AppUtils, ModelUtils } from 'utils/index.js';
import ConnectHotspotModal from './modal/hotspot.js';
import { useFetch } from 'hooks/fetch.js';
import OrganizationInfoModal from './modal/organization/view.js';
import ConnectBeforeEnterModal from '../modal/before-enter.js';
import CountdownTimer from './modal/timer.js';
import { Button, Image, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoModal from './modal/info.js';
import VirtualRoomModal from './modal/virtual-room.js';

const { HEADER_TYPES } = AppConstants;
const { API_METHOD_GET, API_METHOD_POST, API_URL_FAIR, API_URL_CONNECT } = ServerConstants;

const baseSrc = '/vista/connect/index.htm?skip-loading';
const modals = {
  MEMBERS: 1,
  HOTSPOT: 2,
  FAIR_INFO: 3,
  VIRTUAL_ROOM: 4
}

const ConnectVistaFairViewScreen = () => {

  const { t } = useTranslation();
  const fairId = window.location.pathname.split('/')[4]
  const loggedUser = AppUtils.getLoggedUser();

  const fairFetch = useFetch(API_METHOD_GET);
  const fairLinkFetch = useFetch(API_METHOD_GET);
  const standNumberFetch = useFetch(API_METHOD_GET);
  const calendarFetch = useFetch(API_METHOD_GET);
  const participantsFetch = useFetch(API_METHOD_GET);
  const updateLinkFetch = useFetch(API_METHOD_POST);

  const [fair, setFair] = useState(undefined);
  const [src, setSrc] = useState(undefined);
  const [finished, setFinished] = useState(false);
  const [showModal, setShowModal] = useState(undefined);
  const [standNumber, setStandNumber] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [linkModalVisible, setLinkModalVisible] = useState(false);
  const [mySessions, setMySessions] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [reload, setReload] = useState(0);
  const loggedUserMeetingLink = useRef(undefined);
  const hotspotType = useRef(undefined);
  const myOrganizationStandNumber = useRef(undefined);

  useEffect(() => {
    if (!loggedUser) {
      setMessage(t('fairs.message.login'));
      return;
    }
    if (!loggedUser.profilePicture || loggedUser.profilePicture === '') {
      setMessage(t('fairs.message.picture.' + (AppUtils.isUserMember() ? 'member' : 'organization')));
      return;
    }
    fairFetch.setFetchUrl(API_URL_FAIR + '/' + fairId);
    fairFetch.forceFetchData();
  }, []);

  useEffect(() => {
    if (fairFetch.response && fairFetch.response.data) {
      const data = ModelUtils.convertFairModelB2F(fairFetch.response.data);
      setFair(data);
      if (AppUtils.isFairFinished(data)) {
        setMessage(t('fairs.message.finished'));
        return;
      }
      fairLinkFetch.setFetchUrl(API_URL_CONNECT + '/' + data.id + '/calendar/link')
      fairLinkFetch.forceFetchData();
    }
  }, [fairFetch.response]);

  useEffect(() => {
    if (fairLinkFetch.response) {
      if (fairLinkFetch.response.data && fairLinkFetch.response.data.meetingLink !== '') {
        onSubmitLinkModal(fairLinkFetch.response.data.meetingLink);
      } else {
        setLinkModalVisible(true);
      }
    }
  }, [fairLinkFetch.response]);

  useEffect(() => {
    if (standNumberFetch.response && standNumberFetch.response.data) {
      const standNumber = standNumberFetch.response.data.standNumber;
      myOrganizationStandNumber.current = standNumber;
      setStandNumber(standNumber);
      setSrc(baseSrc + "#media-name=Stand" + standNumber + hiddenHotspots())
    }
  }, [standNumberFetch.response]);

  const hiddenHotspots = useCallback(() => {
    if (AppUtils.isFairLive(fair)) {
      return '';
    }
    if (AppUtils.isFairPreLive(fair)) {
      return '&hot=VIRTUAL_ROOM';
    }
    return '&hot=GT_STAND';
  }, [fair]);

  useEffect(() => {
    if (!finished) {
      return;
    }
    window.frames['vista'].triggerAction = (e, type) => { hotspotType.current = type; setShowModal(modals.HOTSPOT) };
    window.frames['vista'].goToStand = (number) => setStandNumber(number);
    window.frames['vista'].triggerMembers = () => setShowModal(modals.MEMBERS);
    window.frames['vista'].openInfo = () => setShowModal(modals.FAIR_INFO);
    window.frames['vista'].openVirtualRoom = () => setShowModal(modals.VIRTUAL_ROOM);
  }, [finished]);

  useEffect(() => {
    if (!fair) {
      return;
    }
    if (showModal || !myOrganizationStandNumber.current) {
      calendarFetch.setFetchUrl(API_URL_CONNECT + '/' + fair.id + '/calendar/my');
      calendarFetch.forceFetchData();
    }
    return (() => {
      calendarFetch.setFetchUrl(undefined);
    })
  }, [fair, showModal])

  useEffect(() => {
    if (calendarFetch.response) {
      setMySessions(calendarFetch.response.data.map(el => ModelUtils.convertSessionModelB2F(el)));
      setReload(old => old + 1);
    }
  }, [calendarFetch.response])

  useEffect(() => {
    if (participantsFetch.response) {
      const data = participantsFetch.response.data;
      setParticipants(data.map(el => ModelUtils.convertOrganizationB2F(el)));
    }
  }, [participantsFetch.response])

  useEffect(() => {
    if (updateLinkFetch.response) {
      calendarFetch.setFetchUrl(API_URL_CONNECT + '/' + fairId + '/calendar/my');
      calendarFetch.forceFetchData();
      updateLinkFetch.clearData();
    }
  }, updateLinkFetch.response);

  const onSubmitLinkModal = useCallback((link) => {
    loggedUserMeetingLink.current = link;
    setLinkModalVisible(false);
    standNumberFetch.setFetchUrl(API_URL_FAIR + '/' + fairId + '/stand/' + (AppUtils.isUserOrganization() ? loggedUser.id : loggedUser.organizationId));
    standNumberFetch.forceFetchData();
    if (AppUtils.isFairPreLive(fair) || AppUtils.isFairLive(fair)) {
      participantsFetch.setFetchUrl(API_URL_FAIR + '/' + fairId + '/participants');
      participantsFetch.forceFetchData();
    }
  }, [fair, fairId]);

  const updateMeetingLink = useCallback((link) => {
    loggedUserMeetingLink.current = link;
    updateLinkFetch.setFetchUrl(API_URL_CONNECT + '/' + fairId + '/calendar/link');
    updateLinkFetch.setBody({ 'meetingLink': link });
  }, [fairId]);

  const isLoading = useCallback(() => fairFetch.loading || fairLinkFetch.loading || standNumberFetch.loading, [fairFetch.loading, fairLinkFetch.loading, standNumberFetch.loading]);

  const getNextSession = useCallback(() => {
    if (!AppUtils.isFairLive(fair) || mySessions.length === 0) {
      return undefined;
    }
    const now = new Date().getTime();
    const sessionsInFuture = mySessions.filter(session => (
      !session.isOpen
      && new Date(AppUtils.convertDateToLocaleDate(session.startTime)).getTime() > now
      && now < new Date(AppUtils.convertDateToLocaleDate(session.endTime)).getTime()
    ));
    return sessionsInFuture.length > 0 ? sessionsInFuture[0] : undefined;
  }, [mySessions, fair])

  const getActiveSession = useCallback(() => {
    if (!AppUtils.isFairLive(fair) || mySessions.length === 0) {
      return undefined;
    }
    const now = new Date().getTime();
    const activeSession = mySessions.filter(session => (
      !session.isOpen
      && now > new Date(AppUtils.convertDateToLocaleDate(session.startTime)).getTime()
      && now < new Date(AppUtils.convertDateToLocaleDate(session.endTime)).getTime()
    ));
    return activeSession.length > 0 ? activeSession[0] : undefined;
  }, [mySessions, fair, reload])

  const goToStand = useCallback((number, e) => {
    if (e) {
      e.nativeEvent.target.parentElement.parentElement.parentElement.classList.remove('show');
    }
    setStandNumber(number);
    setSrc(baseSrc + "#media-name=Stand" + number + hiddenHotspots() + '&timestamp=' + new Date().getTime());
  }, [fair]);

  const ParticipantsView = () => {
    if (participants.length === 0) {
      return;
    }
    return (
      <>
        <Nav.Item className="p-2 ml-3" onClick={() => goToStand(37)}>
          <div className="d-flex flex-direction-row align-items-center">
            <FontAwesomeIcon icon={'circle-info'} />
            <p className="px-2 m-0">{t('fairs.info')}</p>
          </div>
        </Nav.Item>
        {AppUtils.isFairPreLive(fair) || AppUtils.isFairLive(fair) ?
          <Nav.Item className="p-2 ml-3" onClick={() => goToStand(myOrganizationStandNumber.current)}>
            <div className="d-flex flex-direction-row align-items-center">
              <FontAwesomeIcon icon={'home'} />
              <p className="px-2 m-0">{t('fairs.back.stand')}</p>
            </div>
          </Nav.Item>
          : undefined}
        {AppUtils.isFairLive(fair) ?
          <Nav.Item className="p-2 ml-3" onClick={() => goToStand(38)}>
            <div className="d-flex flex-direction-row align-items-center">
              <FontAwesomeIcon icon={'door-open'} />
              <p className="px-2 m-0">{t('fairs.room.virtual')}</p>
            </div>
          </Nav.Item>
          : undefined}
        {AppUtils.isFairPreLive(fair) || AppUtils.isFairLive(fair) ?
          <NavDropdown
            title={
              <>
                <FontAwesomeIcon icon={'users'} />&nbsp;&nbsp;
                <span>{t('fairs.participants.title')}</span>
              </>
            }
            align="end"
            className="primary-background dropdown-menu-right"
            >
            {participants.map(el => (
              <Nav.Item className="p-2" onClick={(e) => goToStand(el.standNumber, e)}>
                <div className="d-flex flex-direction-row align-items-center">
                  <Image src={el.profilePicture} width={40} height={40} style={{ borderRadius: 40, backgroundRepeat: 'no-repeat', objectFit: 'cover' }} />
                  <p className="px-2 m-0 text">{el.name}</p>
                </div>
              </Nav.Item>
            ))}
          </NavDropdown>
          : undefined}
      </>
    )
  }

  return (
    <Layout type={HEADER_TYPES.CONNECT} title={fair?.title} additional={<ParticipantsView />}>
      <div>
        {isLoading() ? <div className="m-5"><Loader color="#fff" /></div> :
          !fair || message ? <div className='text-center'><Empty messageKey={message} /></div>
            :
            <>
              <ConnectBeforeEnterModal el={fair} show={linkModalVisible} onDismiss={() => setLinkModalVisible(false)} onSubmit={onSubmitLinkModal} forceView={1} />
              {!!standNumber ?
                <>
                  {!showModal && (
                    <div className='d-flex flex-direction-column align-items-center justify-content-center'>
                      <ActiveSessionContent session={getActiveSession()} t={t} />
                      <CountdownTimer session={getNextSession()} onFinish={() => setReload(old => old + 1)} />
                    </div>
                  )}
                  <OrganizationInfoModal fair={fair} meetingLink={loggedUserMeetingLink.current} updateMeetingLink={updateMeetingLink} loadingUpdateMeetingLink={updateLinkFetch.loading} standNumber={standNumber} onDismiss={() => setShowModal(undefined)} show={showModal === modals.MEMBERS} reload={reload} />
                  <ConnectHotspotModal fair={fair} standNumber={standNumber} el={{ type: hotspotType.current }} onDismiss={() => setShowModal(undefined)} show={showModal === modals.HOTSPOT} myOrganization={myOrganizationStandNumber.current === standNumber} />
                  <InfoModal fair={fair} onDismiss={() => setShowModal(undefined)} show={showModal === modals.FAIR_INFO} />
                  <VirtualRoomModal fair={fair} onDismiss={() => setShowModal(undefined)} show={showModal === modals.VIRTUAL_ROOM} />
                  <iframe
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                    src={src}
                    id='vista'
                    name="vista"
                    title="vista"
                    width="100%"
                    height="100%"
                    allowFullScreen={false}
                    onLoad={() => setFinished(true)}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                    sandbox="allow-scripts allow-same-origin">
                  </iframe>
                </>
                : undefined}
            </>
        }
      </div>
    </Layout>
  )
}

const ActiveSessionContent = ({ session, t }) => {

  const getSessionLink = useCallback(() => session.hostLink, [session]);

  if (!session || !session.hostLink) {
    return <></>;
  }
  return (
    <div className="timer">
      <span>
        <span className="mr-3">{t('functions.connect.session.started')}</span>
        <span><Button onClick={() => window.open(AppUtils.renderWebAddress(getSessionLink()))}>{t('functions.connect.session.action.join.short')}</Button></span>
      </span>
    </div>
  )
}

export default ConnectVistaFairViewScreen;
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Typeahead } from 'react-bootstrap-typeahead';

import { Empty, FormInput, FormUpload, Layout, Loader } from "components";
import { AppConstants, ServerConstants } from "constant";
import { useFetch } from "hooks/fetch";
import { useValues } from "hooks/values";
import { ModelUtils } from "utils";

const { PATH_ADMIN_NEWS, HEADER_TYPES, NEWS_CATEGORY_CONNECT } = AppConstants;
const { API_METHOD_POST, API_METHOD_GET, API_URL_NEWS, API_URL_FAIRS_UPCOMING } = ServerConstants;

const AdminNewsEditScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[3];
  const newsFetch = useFetch(API_METHOD_GET, API_URL_NEWS + '/' + id);
  const fairsFetch = useFetch(API_METHOD_GET, API_URL_FAIRS_UPCOMING);
  const submitFetch = useFetch(API_METHOD_POST,);

  const [fairs, setFairs] = useState([]);
  const { values, onValueChange, addValues, setValue } = useValues({});

  useEffect(() => {
    if (newsFetch.response) {
      const data = ModelUtils.convertArticleModelB2F(newsFetch.response.data);
      data.fairId = 54;
      addValues(data);
    }
  }, [newsFetch.response])

  useEffect(() => {
    if (submitFetch.response) {
      goToView();
    }
  }, [submitFetch.response]);

  useEffect(() => {
    if (fairsFetch.response && fairsFetch.response.data) {
      setFairs(fairsFetch.response.data.map(el => ModelUtils.convertFairModelB2F(el)));
    }
  }, [fairsFetch.response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    submitFetch.setFetchUrl(API_URL_NEWS + "/" + values['id']);
    submitFetch.setBody(ModelUtils.convertArticleModelF2B(values))
    return;
  }, [values]);

  const goToView = useCallback(() => window.location.href = PATH_ADMIN_NEWS + '/' + values['id'], [values]);

  const selectedFair = useCallback(() => {
    const fair = fairs.find(fair => fair.id === values['fairId']);
    if (fair) {
      return [fair.title];
    }
    return '';
  }, [fairs]);


  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="mt-4 mb-5">
        {newsFetch.loading || !values ? <Loader /> :
          !values['editable'] ? <div className='text-center'><Empty messageKey={t('news.edit.no')} /></div>
          :
          <>
            <div className="mb-4">
              <div className="mt-5 mb-4">
                <h2>{t('news.edit.title')}</h2>
              </div>
            </div>
            <Row>
              <Col className="col-6">
                <Form onSubmit={(e) => handleSubmit(e)}>
                  <FormInput id="title" required={true} label={t('news.name')} onChange={onValueChange} type="text" defaultValue={values['title']} />
                  <FormUpload id="featuredImage" required={true} label={t('news.image')} onValueChange={onValueChange} removeImage={() => setValue('featuredImage', undefined)} value={values['featuredImage']} />
                  <FormInput id="intro" required={true} label={t('news.intro')} onChange={onValueChange} type="text" as="textarea" rows="3" defaultValue={values['intro']} />
                  <FormInput id="description" required={true} label={t('news.description')} onChange={onValueChange} type="text" as="textarea" rows="6" defaultValue={values['text']} />
                  {values['type'] === NEWS_CATEGORY_CONNECT ?
                    <Form.Group className="mb-3">
                      <Form.Label htmlFor={'fairId'}>{t('news.fair.label')}</Form.Label>
                      <Typeahead
                        maxResults={Object.keys(fairs).length}
                        options={Object.keys(fairs).map((el, i) => fairs[el].title)}
                        emptyLabel={t('news.fair.empty')}
                        iconName={'chevron-down'}
                        id={'fairId'}
                        label={t('news.fair.label')}
                        labelKey={option => `${option}`}
                        placeholder={t('news.fair.placeholder')}
                        renderItem={(options) => (
                          <div key={options}>
                            <p className="my-1">{options}</p>
                          </div>
                        )}
                        paginate={false}
                        t={t}
                        onChange={(selected) => onValueChange({ target: { id: 'fairId', value: fairs.find(el => el.title === selected[0])?.id ?? undefined } })}
                        selected={selectedFair(values['fairId'])}
                      />
                    </Form.Group>
                    : undefined}
                  <Row className="mt-5">
                    <Col className="col-6">
                      <Button onClick={goToView} disabled={submitFetch.loading} variant='secondary' className="w-100">{t('back')}</Button>
                    </Col>
                    <Col className="col-6">
                      <Button type="submit" className='w-100'>{t('publish')}</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </>
        }
      </Container>
    </Layout>
  )
}

export default AdminNewsEditScreen;
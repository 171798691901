import { volunteeringOrganizationPhoto7, volunteeringOrganizationPhoto81, volunteeringOrganizationPhoto82, volunteeringOrganizationPhoto83, volunteeringOrganizationPhoto84, volunteeringOrganizationPhoto9 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32, marginLeft: -16, marginRight: -16 }}>
        <Image src={volunteeringOrganizationPhoto7} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br/>
      <h4 id="step-1-3" className='text-volunteering'>{t('learning.volunteering.organization.30')}</h4>
      <p>{t('learning.volunteering.organization.31')}</p>
      <p>
        {t('learning.volunteering.organization.32.1')}
        <b>{t('learning.volunteering.organization.32.2')}</b>
        {t('learning.volunteering.organization.32.3')}
      </p>
      <p>
        {t('learning.volunteering.organization.33.1')}
        <b>{t('learning.volunteering.organization.33.2')}</b>
        {t('learning.volunteering.organization.33.3')}
        <b>{t('learning.volunteering.organization.33.4')}</b>
        {t('learning.volunteering.organization.33.5')}
      </p>
      <p>
        {t('learning.volunteering.organization.34.1')}
        <b>{t('learning.volunteering.organization.34.2')}</b>
        {t('learning.volunteering.organization.34.3')}
      </p>
      <p>
        {t('learning.volunteering.organization.35.1')}
        <b>{t('learning.volunteering.organization.35.2')}</b>
        {t('learning.volunteering.organization.35.3')}
        <b>{t('learning.volunteering.organization.35.4')}</b>
        {t('learning.volunteering.organization.35.5')}
        <a href="https://www.connect-international.org/2023/11/01/results-of-the-e-volunteering-research/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.35.6')}</a>.
      </p>
      <div className='text-center mb-5'>
        <Image src={volunteeringOrganizationPhoto81} width={'50%'} style={{ objectFit: 'cover' }} />
      </div>
      <div className='text-center mb-5'>
        <Image src={volunteeringOrganizationPhoto82} width={'75%'} style={{ objectFit: 'cover' }} />
      </div>
      <div className='text-center mb-5'>
        <Image src={volunteeringOrganizationPhoto83} width={'75%'} style={{ objectFit: 'cover' }} />
      </div>
      <div className='text-center mb-5'>
        <Image src={volunteeringOrganizationPhoto84} width={'75%'} style={{ objectFit: 'cover' }} />
      </div>
      <p>
        {t('learning.volunteering.organization.36.1')}
        <b>{t('learning.volunteering.organization.36.2')}</b>
        {t('learning.volunteering.organization.36.3')}
        <b>{t('learning.volunteering.organization.36.4')}</b>
        {t('learning.volunteering.organization.36.5')}
        <a href="https://www.connect-international.org/2023/11/01/results-of-the-e-volunteering-research/" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.36.6')}</a>.
        {t('learning.volunteering.organization.36.7')}
      </p>
      <p>{t('learning.volunteering.organization.37')}</p>
      <p>{t('learning.volunteering.organization.38')}</p>
      <br/>
      <Image src={volunteeringOrganizationPhoto9} width={'100%'} height={400} style={{ objectFit: 'cover' }} />
    </>
  )

}

export default VolunteeringLearningScreen4;
import React, { useCallback, useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { ServerConstants } from 'constant';
import { FormInput, Modal } from 'components';
import { useFetch } from 'hooks/fetch';
import { useValues } from 'hooks/values';

const { API_METHOD_POST, API_URL_INVITATION } = ServerConstants;

const EngageFunctionResendInvitation = ({ el, onDismiss, onSuccess, show }) => {

  const { t } = useTranslation();
  const { loading, response, setBody } = useFetch(API_METHOD_POST, API_URL_INVITATION + '/' + el.id + '/resend');
  const { values, onValueChange, resetValues, addValues } = useValues();

  useEffect(() => {
    if (show) {
      addValues({
        "fullName": el.fullName,
        "position": el.position,
        "email": el.email
      })
    } else {
      resetValues()
      setBody(undefined);
    }
  }, [show])

  useEffect(() => {
    if (response) {
      onSuccess();
    }
  }, [response]);

  const handleSubmit = useCallback((event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      return;
    }
    sendRequest();
    return
  }, [values]);

  const sendRequest = useCallback(() => {
    setBody(values);
  }, [values]);

  return (
    <Modal onHide={!loading ? onDismiss : undefined} show={show}>
      <Container>
        <h3 className="mt-2"><b>{t('functions.engage.invitations.resend.title')}</b></h3>
        <p className="mb-4">{t('functions.engage.invitations.resend.description')}</p>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <FormInput id="fullName" required={true} label={t('functions.engage.invitations.fullName')} onChange={onValueChange} type="text" defaultValue={el.fullName} />
          <FormInput id="position" required={true} label={t('functions.engage.invitations.position')} onChange={onValueChange} type="text" defaultValue={el.position} />
          <FormInput id="email" required={true} label={t('functions.engage.invitations.email')} onChange={onValueChange} type="email" defaultValue={el.email} />
          <Row className="mt-5">
            <Col className="col-6">
              <Button onClick={onDismiss} variant='secondary' className="w-100" disabled={loading}>{t('cancel')}</Button>
            </Col>
            <Col className="col-6">
              <Button type="submit" className='w-100' disabled={loading}>{t('functions.engage.invitations.send')}</Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </Modal>
  )
}

export default EngageFunctionResendInvitation;
export default class Organization {
  __id: number;
  __name: string;
  __profilePicture: string;
  __website: string;
  __address: string;
  __phone: string;
  __email: string;
  __fields: string[];
  __about: string;
  __country: string;
  __city: string;
  __instagram: string;
  __linkedIn: string;
  __facebook: string;
  __twitter: string;
  __youTube: string;
  __standNumber: number | undefined

  constructor(
    id: number,
    name: string,
    profilePicture: string,
    website: string,
    address: string,
    phone: string,
    email: string,
    fields: string[],
    about: string,
    country: string,
    city: string,
    instagram: string,
    linkedIn: string,
    facebook: string,
    twitter: string,
    youTube: string,
    standNumber: number | undefined,
  ) {
    this.__id = id;
    this.__name = name;
    this.__profilePicture = profilePicture;
    this.__website = website;
    this.__address = address;
    this.__phone = phone;
    this.__email = email;
    this.__fields = fields;
    this.__about = about;
    this.__country = country;
    this.__city = city;
    this.__instagram = instagram;
    this.__linkedIn = linkedIn;
    this.__facebook = facebook;
    this.__twitter = twitter;
    this.__youTube = youTube;
    this.__standNumber = standNumber;
  }

  public get id() { return this.__id; }
  public get name() { return this.__name; }
  public get profilePicture() { return this.__profilePicture; }
  public get website() { return this.__website; }
  public get address() { return this.__address; }
  public get phone() { return this.__phone; }
  public get email() { return this.__email; }
  public get fields() { return this.__fields; }
  public get about() { return this.__about; }
  public get country() { return this.__country; }
  public get city() { return this.__city; }
  public get instagram() { return this.__instagram; }
  public get linkedIn() { return this.__linkedIn; }
  public get facebook() { return this.__facebook; }
  public get twitter() { return this.__twitter; }
  public get youTube() { return this.__youTube; }
  public get standNumber() { return this.__standNumber; }

  public toJSON() {
    return {
      id: this.__id,
      name: this.__name,
      profilePicture: this.__profilePicture,
      website: this.__website,
      address: this.__address,
      phone: this.__phone,
      email: this.__email,
      fields: this.__fields,
      about: this.__about,
      country: this.__country,
      city: this.__city,
      instagram: this.__instagram,
      linkedIn: this.__linkedIn,
      facebook: this.__facebook,
      twitter: this.__twitter,
      youTube: this.__youTube,
      standNumber: this.__standNumber
    }
  }

}
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step41 } from 'assets/learning';

const EngageLearningScreen15 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h2 id="step-4">{t('learning.engage.58')}</h2>
      <h4 id="step-4-1" className='text-engage'>{t('learning.engage.59')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.60')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step41} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
      </div>
      <p><b>{t('learning.engage.61')}</b></p>
      <ol>
        <li>
          <b>{t('learning.engage.62')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.63')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
        <li>
          <b>{t('learning.engage.64')}</b>
          <div className='fake-input x2'>&nbsp;</div>
        </li>
      </ol>
    </>
  )

}

export default EngageLearningScreen15;
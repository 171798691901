import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

const override = {
  display: "block",
  margin: "0 auto",
};

const Loader = ({ size = 32, color = "#656565" }) => {
  const { t } = useTranslation();

  return (
    <ClipLoader
      color={color}
      loading={true}
      cssOverride={override}
      size={size}
      aria-label={t('search.loading')}
      data-testid="loader"
    />
  )
}

export default Loader;
import { volunteeringVolunteerIllustration25, volunteeringVolunteerImage16, volunteeringVolunteerImage17 } from 'assets/learning';
import { AppConstants } from 'constant';
import React from 'react';
import { Accordion, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const { PATH_VOLUNTEERING_LEARNING_VOLUNTEER } = AppConstants;

const VolunteeringLearningScreen16 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-5-4" className='text-volunteering'>{t('learning.volunteering.volunteer.106')}</h4>
      <p>{t('learning.volunteering.volunteer.107')}</p>
      <p>{t('learning.volunteering.volunteer.108')}</p>
      <p>{t('learning.volunteering.volunteer.109')}</p>
      <p>{t('learning.volunteering.volunteer.110')}</p>
      <div className='box mb-5'>
        <i>
          {t('learning.volunteering.volunteer.111.1')}
          <a href="https://www.youtube.com/watch?v=n0uwTBrgqxI" target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.volunteer.111.2')}</a>
          {t('learning.volunteering.volunteer.111.3')}
        </i>
      </div>
      <div className='text-center'>
        <Image src={volunteeringVolunteerIllustration25} width={320} height={220}/>
      </div>
      <Accordion activeKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <p><b>{t('learning.volunteering.volunteer.112')}</b></p>
            <p>{t('learning.volunteering.volunteer.113')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion activeKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <p><b>{t('learning.volunteering.volunteer.114')}</b></p>
            <p>{t('learning.volunteering.volunteer.115')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion activeKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Body>
            <p><b>{t('learning.volunteering.volunteer.116')}</b></p>
            <p>{t('learning.volunteering.volunteer.117')}</p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <br/>
      <Image src={volunteeringVolunteerImage16} width={'100%'} height={400} />
      <br/>
      <br/>
      <br/>
      <table className='table table-bordered text-volunteering'>
        <tbody>
          <tr>
            <td>{t('learning.volunteering.volunteer.118.1')}</td>
            <td>{t('learning.volunteering.volunteer.118.2')}</td>
            <td>{t('learning.volunteering.volunteer.118.3')}</td>
            <td>{t('learning.volunteering.volunteer.118.4')}</td>
            <td>{t('learning.volunteering.volunteer.118.5')}</td>
            <td>{t('learning.volunteering.volunteer.118.6')}</td>
            <td>{t('learning.volunteering.volunteer.118.7')}</td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.volunteer.119')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.volunteer.120')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>{t('learning.volunteering.volunteer.121')}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <div className='text-center mt-5'>
        <button onClick={() => window.open(PATH_VOLUNTEERING_LEARNING_VOLUNTEER + '/simulation-2', '_blank')} className='btn btn-volunteering text-white'>{t('learning.volunteering.volunteer.simulation.2')}</button>
      </div>
      <br/>
      <br/>
      <Image src={volunteeringVolunteerImage17} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen16;
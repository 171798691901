import React, { useCallback, useEffect, useState } from 'react'
import { Empty, Layout, Loader } from 'components';
import { AppConstants, ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch.js';
import hotspotTypes from '../../../../assets/hotspots.js';
import EmpowerHotspotModal from './conference/hotspot.js';
import CountdownTimer from './modal/timer.js';
import { AppUtils, ModelUtils } from 'utils';
import { useTranslation } from 'react-i18next';

const { EVENT_STATUS, HEADER_TYPES } = AppConstants;
const { API_METHOD_GET, API_URL_EVENT } = ServerConstants;

const baseSrc = '/vista/empower/index.htm?skip-loading#media-name=Lobby_Live';
const baseHidden = '&hon=ROOM_1,ROOM_2,ROOM_3,ROOM_4,ROOM_5'
const rooms = {
  LOBBY: 0,
  CONFERENCE: 1
}

const EmpowerEventViewScreen = () => {

  const { t } = useTranslation();
  const eventId = window.location.pathname.split('/')[2];
  const { response, loading } = useFetch(API_METHOD_GET, API_URL_EVENT + '/' + eventId);
  const [src, setSrc] = useState(baseSrc);

  const [event, setEvent] = useState(undefined);
  const [finished, setFinished] = useState(false);
  const [showModal, setShowModal] = useState(undefined);
  const [time, setTime] = useState(undefined);
  const [room, setRoom] = useState(rooms.LOBBY);

  useEffect(() => {
    if (response && response.data) {
      setEvent(ModelUtils.convertEventModelB2F(response.data));
    }
  }, [response]);

  useEffect(() => {
    if (!finished || !event) {
      return;
    }
  }, [event, time, finished, room]);

  useEffect(() => {
    if (room === rooms.LOBBY) {
      if (time && time === EVENT_STATUS.STARTED) {
        setSrc(baseSrc + baseHidden + '&son=' + event.type);
      } else {
        setSrc(baseSrc + baseHidden);
      }
    }
  }, [time]);

  useEffect(() => {
    if (!finished) {
      return;
    }
    window.frames['vista'].goToConferenceRoom = (e, type) => goToConferenceRoom();
    window.frames['vista'].onLoadAction = (e) => {};
    window.frames['vista'].triggerAction = (e, type) => setShowModal({ ...hotspotTypes.Empower[type], type: type });
    window.frames['vista'].backToLobby = (e, type) => goBackToLobby();
  }, [finished, src])

  const onTimeEventChange = useCallback((timeEvent) => {
    if (timeEvent !== time) {
      setTime(timeEvent);
    }
  }, [time]);

  const goToConferenceRoom = useCallback(() => {
    setRoom(rooms.CONFERENCE);
    setSrc(baseSrc + '&trigger-overlay-name=GT_CONFERENCE_' + event.type + "&son=GT_LOBBY_LIVE")
  }, [event]);

  const goBackToLobby = useCallback(() => {
    setRoom(rooms.LOBBY);
    if (time && time === EVENT_STATUS.STARTED) {
      setSrc(baseSrc + baseHidden + '&son=' + event.type);
    } else {
      setSrc(baseSrc + baseHidden);
    }
  }, [event]);

  const isLoading = useCallback(() => loading || !event, [event, loading])

  return (
    <Layout type={HEADER_TYPES.EMPOWER}>
      {isLoading() ? <div className="m-5"><Loader color="#fff" /></div>
        : AppUtils.isEventEnded(event) ? <div className='text-center'><Empty messageKey={t('functions.empower.events.countdown.ENDED')} /></div>
        :
        <div>
          {finished && room === rooms.LOBBY ? <CountdownTimer event={event} onChange={onTimeEventChange} goToConferenceRoom={goToConferenceRoom} /> : undefined}
          {room === rooms.CONFERENCE ? <EmpowerHotspotModal event={event} el={showModal} onDismiss={() => setShowModal(undefined)} show={showModal} /> : undefined}
          <iframe
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            src={src}
            id='vista'
            name="vista"
            title="vista"
            width="100%"
            height="100%"
            allowFullScreen={false}
            onLoad={() => setFinished(true)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            sandbox="allow-scripts allow-same-origin">
          </iframe>
        </div>
      }
    </Layout>
  )
}

export default EmpowerEventViewScreen;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Layout, Loader } from 'components';
import { connectWhite, empowerWhite, engageWhite, infinityWhite } from 'assets/icons';
import { useFetch } from 'hooks/fetch';
import NewsApproveModal from './modal/approve';
import NewsRejectModal from './modal/reject';
import { AppConstants, ServerConstants } from 'constant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppUtils, ModelUtils } from 'utils';
import APIKit from 'config/axios/api';

const iconTypes = {
  'general': infinityWhite,
  'connect': connectWhite,
  'engage': engageWhite,
  'empower': empowerWhite
}

const modals = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
};

const { HEADER_TYPES, NEWS_STATUS_PUBLISHED, NEWS_STATUS_APPROVED, NEWS_STATUS_SUBMITTED, NEWS_STATUS_REJECTED, PATH_ADMIN_NEWS } = AppConstants;
const { API_METHOD_GET, API_URL_NEWS } = ServerConstants;

const AdminNewsViewScreen = () => {

  const { t } = useTranslation();
  const id = window.location.pathname.split('/')[3];
  const { response, loading } = useFetch(API_METHOD_GET, API_URL_NEWS + '/' + id);

  const [article, setArticle] = useState(undefined);
  const [modalVisible, setModalVisible] = useState(undefined);

  useEffect(() => {
    if (response) {
      setArticle(ModelUtils.convertArticleModelB2F(response.data))
    }
  }, [response, id]);

  const showModals = useCallback((modal) => setModalVisible(modal), []);
  const hideModals = useCallback(() => setModalVisible(undefined), []);

  const deleteArticle = useCallback(() => {
    if (window.confirm(t('news.delete.confirm'))) {
      APIKit.delete(API_URL_NEWS + '/' + id)
        .then((res) => window.location.href = PATH_ADMIN_NEWS)
        .catch(e => console.log('Error while deleting', e))
    }
  }, [id])

  const ChangeStatus = useCallback(() => {
    if (!article.statusChangeable) {
      return <></>;
    }
    if (article.status === NEWS_STATUS_SUBMITTED) {
      return (
        <>
          <h5 className="text-success clickable" onClick={() => showModals(modals.APPROVE)}>
            <FontAwesomeIcon icon={'circle-check'} size={'lg'} />
            <span className="mx-2">{t('news.action.approve')}</span>
          </h5>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <h5 className="text-danger clickable" onClick={() => showModals(modals.REJECT)}>
            <FontAwesomeIcon icon={'circle-xmark'} size={'lg'} />
            <span className="mx-2">{t('news.action.reject')}</span>
          </h5>
        </>
      )
    } else if (article.status === NEWS_STATUS_PUBLISHED || article.status === NEWS_STATUS_APPROVED) {
      return (
        <div>
          <FontAwesomeIcon icon={'circle-check'} className="text-success" size={'lg'} />
          <span className="mx-2">{t('news.admin.filter.' + NEWS_STATUS_PUBLISHED.toUpperCase())}</span>
          <a className='clickable mx-2' onClick={() => showModals(modals.REJECT)}>{t('news.status.change.to.rejected')}</a>
        </div>
      )
    } else if (article.status === NEWS_STATUS_REJECTED) {
      return (
        <div>
          <FontAwesomeIcon icon={'circle-xmark'} className="text-danger" size={'lg'} />
          <span className="mx-2">{t('news.admin.filter.' + NEWS_STATUS_REJECTED.toUpperCase())}</span>
          <a className='clickable mx-2' onClick={() => showModals(modals.APPROVE)}>{t('news.status.change.to.approved')}</a>
        </div>
      )
    }
    return <></>;
  }, [article]);

  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="my-5">
        {loading || !article ? <Loader /> :
          article ?
            <>
              <NewsApproveModal el={article} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.APPROVE} />
              <NewsRejectModal el={article} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.REJECT} />
              <div className='d-table'>
                <div className={`pill-sm ${article.type.toLowerCase()}-background`}>
                  <Image src={iconTypes[article.type.toLowerCase()]} />
                  <span style={{ color: 'var(--white)', fontSize: 16 }}>{t('functions.' + article.type.toLowerCase().toLowerCase() + '.title')}</span>
                </div>
              </div>
              <div className='d-flex flex-direction-row justify-content-between align-items-start'>
                <h2>{article.title}</h2>
                <span>
                  {article.editable ?
                    <Button onClick={() => window.location.href = PATH_ADMIN_NEWS + '/' + article.id + '/edit'} className="m-1">
                      <FontAwesomeIcon icon={'pen-to-square'} />&nbsp;&nbsp;
                      <span>{t('news.edit.title')}</span>
                    </Button>
                    : undefined
                  }
                  {article.deletable ?
                    <Button onClick={deleteArticle} className="m-1">
                      <FontAwesomeIcon icon={'trash-alt'} />&nbsp;&nbsp;
                      <span>{t('news.delete.title')}</span>
                    </Button>
                    : undefined}
                </span>
              </div>

              <p><i>{AppUtils.convertDateToLocaleDate(article.created, t('format.date.news'))}</i></p>
              <div className="d-flex flex-direction-row align-items-center my-4">
                <ChangeStatus />
              </div>
              <Image src={article.featuredImage} alt={article.featuredImage} className="box-shadow" style={{ width: '100%', height: 400, objectFit: 'cover' }} />
              {article.intro ? <p className="my-4">{article.intro}</p> : undefined}
              {article.text ? <p className="my-4">{article.text}</p> : undefined}
            </>
            : undefined}
      </Container>
    </Layout>
  )
}

export default AdminNewsViewScreen;
import React from 'react';
import { Container } from 'react-bootstrap';

import { Modal } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ContactEmail = ({ onDismiss, visible, t }) => {

  return (
    <Modal onHide={onDismiss} show={visible}>
      <Container>
        <div className="my-3">
          <div className="d-flex flex-direction-row justify-content-center align-items-center mb-3">
            <FontAwesomeIcon icon={'circle-check'} size={'4x'} className="text-primary" />
            <h2 className="mx-3">{t('contact.success')}</h2>
          </div>
        </div>
      </Container>
    </Modal>
  )
}

export default ContactEmail;
import { volunteeringVolunteerImage4 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen4 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-1-3" className='text-volunteering'>{t('learning.volunteering.volunteer.21')}</h4>
      <div className='d-flex flex-direction-row pb-3'>
        <div className='pl-4 pt-3'>
          <p>{t('learning.volunteering.volunteer.22')}</p>
          <p>{t('learning.volunteering.volunteer.23')}</p>
        </div>
      </div>
      <p>{t('learning.volunteering.volunteer.24')}</p>
      <p>{t('learning.volunteering.volunteer.25')}</p>
      <p>{t('learning.volunteering.volunteer.26')}</p>
      <Image src={volunteeringVolunteerImage4} width={'100%'} height={400} />
    </>
  )

}

export default VolunteeringLearningScreen4;
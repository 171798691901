import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { empower8, empower9, empower10 } from 'assets/learning';

const EmpowerLearningScreen4 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h2 id="hotspots">{t('learning.empower.20')}</h2>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower8} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.21')}</h5>
          <p>{t('learning.empower.22')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower9} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.23')}</h5>
          <p>{t('learning.empower.24')}</p>
        </div>
      </div>
      <br />
      <div className='d-flex flex-direction-row'>
        <div className='col-4 text-center'>
          <Image src={empower10} width={'50%'} height={104} style={{ objectFit: 'contain' }} />
        </div>
        <div className='col-8'>
          <h5>{t('learning.empower.25')}</h5>
          <p>{t('learning.empower.26')}</p>
        </div>
      </div>
      <div className='text-center d-print-none'>
        <br />
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/TCAyMqh-6Kg?si=OD0EuZoIjWf7ryf-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br />
      </div>
    </>
  )

}

export default EmpowerLearningScreen4;
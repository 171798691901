import React from 'react';
import { useTranslation } from 'react-i18next';
import { volunteeringOrganizationPhoto32, volunteeringOrganizationPhoto321 } from 'assets/learning';
import { Image, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VolunteeringLearningScreen18 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32 }}>
        <Image src={volunteeringOrganizationPhoto32} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br />
      <h2 id="step-6">{t('learning.volunteering.organization.220')}</h2>
      <h4 id="step-6-1" className='text-volunteering'>{t('learning.volunteering.organization.221')}</h4>

      <p>
        {t('learning.volunteering.organization.222.1')}
        <a href='https://drive.google.com/file/d/16Uxr8bbzb81gNwVZE5YpUpHfmgNmTUpp/view?usp=drive_link' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.222.2')}</a>
      </p>
      <p>{t('learning.volunteering.organization.223')}</p>

      <h6>{t('learning.volunteering.organization.224')}
        <Popover>
          <b>{t('learning.volunteering.organization.230.1')}</b>
          <ol>
            <li>{t('learning.volunteering.organization.230.2')}</li>
            <li>{t('learning.volunteering.organization.230.3')}</li>
            <li>{t('learning.volunteering.organization.230.4')}</li>
          </ol>
        </Popover>
      </h6>
      <p>
        <span>{t('learning.volunteering.organization.225')}</span>
        <br/>
        <span>
          {t('learning.volunteering.organization.226.1')}
          <a href='https://drive.google.com/file/d/1uVK5_MBuHy6yru0spXUMO1N5pWcI6ndJ/view?usp=drive_link' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.226.2')}</a>
          {t('learning.volunteering.organization.226.3')}
        </span>
        <br/>
        <span>{t('learning.volunteering.organization.227')}</span>
        <br/>
        <span>
          {t('learning.volunteering.organization.228.1')}
          <a href='https://drive.google.com/file/d/1fbYGcaXvlr8C0uyKLxuAIvRCoxK0kNMO/view?usp=sharing' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.228.2')}</a>
          {t('learning.volunteering.organization.228.3')}
        </span>
      </p>
      <div className='text-center mb-5'>
        <Image src={volunteeringOrganizationPhoto321} width={'50%'} />
      </div>

      <h6>{t('learning.volunteering.organization.231')}
        <Popover>
          <b>{t('learning.volunteering.organization.234.1')}</b>
          <ol>
            <li>{t('learning.volunteering.organization.234.2')}</li>
            <li>{t('learning.volunteering.organization.234.3')}</li>
            <li>{t('learning.volunteering.organization.234.4')}</li>
          </ol>
        </Popover>
      </h6>
      <p>
        <span>
          {t('learning.volunteering.organization.232.1')}
          <a href='https://dotstorming.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.232.2')}</a>
          {t('learning.volunteering.organization.232.3')}
        </span>
        <br/>
        <span>
          {t('learning.volunteering.organization.233.1')}
          <a href='https://www.canva.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.233.2')}</a>
          {t('learning.volunteering.organization.233.3')}
          <a href='https://www.capcut.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.233.4')}</a>
          {t('learning.volunteering.organization.233.5')}
        </span>
      </p>

      <h6>{t('learning.volunteering.organization.235')}
        <Popover>
          <b>{t('learning.volunteering.organization.238.1')}</b>
          <ol>
            <li>{t('learning.volunteering.organization.238.2')}</li>
            <li>{t('learning.volunteering.organization.238.3')}</li>
            <li>{t('learning.volunteering.organization.238.4')}</li>
            <li>{t('learning.volunteering.organization.238.5')}</li>
          </ol>
        </Popover>
      </h6>
      <p>
        <span>{t('learning.volunteering.organization.236')}</span>
        <br/>
        <br/>
        <div className='d-flex justify-content-center'>
          <table className='table table-bordered w-50'>
            <thead>
              <tr>
                <th>{t('learning.volunteering.organization.239')}</th>
                <th>{t('learning.volunteering.organization.240')}</th>
              </tr>
              <tr>
                <td>...</td>
                <td>...</td>
              </tr>
            </thead>
          </table>
        </div>
        <br/>
        <span>
          {t('learning.volunteering.organization.237.1')}
          <a href='https://docs.google.com/forms/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.237.2')}</a>
          {t('learning.volunteering.organization.237.3')}
        </span>
      </p>

      <h6>{t('learning.volunteering.organization.241')}
        <Popover>
          <b>{t('learning.volunteering.organization.243.1')}</b>
          <ol>
            <li>{t('learning.volunteering.organization.243.2')}</li>
            <li>{t('learning.volunteering.organization.243.3')}</li>
            <li>{t('learning.volunteering.organization.243.4')}</li>
          </ol>
        </Popover>
      </h6>
      <p>
        {t('learning.volunteering.organization.242.1')}
        <a href='https://www.youtube.com/watch?v=wtl5UrrgU8c' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.242.2')}</a>
        {t('learning.volunteering.organization.242.3')}
      </p>

      <p>{t('learning.volunteering.organization.244')}</p>
      <ul>
        <li><a href='https://forum-ids.org/wp-content/uploads/2016/03/The-Global-Volunteering-Standard-EN-2021-compressed.pdf' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.245.1')}</a></li>
        <li><a href='https://explore.unv.org/sites/default/files/2024-03/Consolidated_SOP_0.pdf' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.245.2')}</a></li>
      </ul>
    </>
  )
}

export default VolunteeringLearningScreen18;

const Popover = ({ children }) => (
  <OverlayTrigger
    trigger={'hover' | 'focus'}
    placement={'bottom'}
    overlay={
      <div style={{
        backgroundColor: 'var(--volunteering-color)',
        color: '#fff',
        paddingTop: 8,
        paddingRight: 16,
        paddingBottom: 8,
        paddingLeft: 16,
        borderRadius: 8,
        marginTop: 8
      }}>{children}</div>
    }
  >
    <FontAwesomeIcon icon='question-circle' className='ml-2 text-volunteering' />
  </OverlayTrigger>
)
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { step4551, step4552 } from 'assets/learning';

const EngageLearningScreen39 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-4-5-5" className='text-engage'>{t('learning.engage.198')}</h4>
      <div className='d-flex flex-direction-row'>
        <div className='col-8'>
          <p>{t('learning.engage.199')}</p>
          <p>{t('learning.engage.200.1')}<a href="https://1password.com/" target='_blank' rel='nooppener noreferrer' className='text-engage'>{t('learning.engage.200.2')}</a>{t('learning.engage.200.3')}</p>
        </div>
        <div className='col-4 text-center'>
          <Image src={step4551} width={'50%'} style={{ objectFit: 'contain' }} />
        </div>
      </div>
      <p>{t('learning.engage.201')}</p>
      <div className='text-center d-print-none'>
        <iframe width={'50%'} height="315" src="https://www.youtube.com/embed/BXeTyryS3kU?si=B93ajcAUtcFKm1MY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <br /><br />
      </div>
      <div className='text-center'>
        <Image src={step4552} width={'50%'} />
      </div>
    </>
  )

}

export default EngageLearningScreen39;
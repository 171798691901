import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Modal } from 'react-bootstrap';

const CustomModal = ({
  className,
  xClassName = undefined,
  children,
  onHide,
  size,
  show,
  style
}) => {
  return (
    <Modal
      animation={true}
      centered={true}
      onHide={undefined}
      show={show}
      size={size}
      backdrop={true}
      style={style}
    >
      <div className={"d-block pt-2 pr-2 text-end " + xClassName}>
        <a onClick={onHide}><FontAwesomeIcon icon={'close'} className='text-muted' size='lg' /></a>
      </div>
      <Modal.Body className={className + ' pt-0'}>
        {children}
      </Modal.Body>
    </Modal>
  )
}

export default CustomModal;
import React, { useCallback, useEffect } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AppConstants, ServerConstants } from 'constant';
import { ModelUtils } from 'utils';
import { useValues } from 'hooks/values';
import { useFetch } from 'hooks/fetch';
import { editEmpower } from 'assets/icons';
import EmpowerEventForm from '../vista/modal/form';
import { Modal } from 'components';

const { PATH_EMPOWER_CONFERENCE } = AppConstants;
const { API_METHOD_PUT, API_URL_EVENTS } = ServerConstants;

const EmpowerEventEditModal = ({ event, events, onDismiss, onSubmit, show }) => {

  const { t } = useTranslation();
  const { values, onValueChange, addValues, resetValues } = useValues(event);
  const eventActionFetch = useFetch(API_METHOD_PUT, API_URL_EVENTS + '/' + event.id);

  const disabledDays = events.length > 0 ? events.map(el => el.date) : [];

  useEffect(() => {
    addValues(event);
    return (() => {
      resetValues();
    })
  }, [show]);

  useEffect(() => {
    if (eventActionFetch.response) {
      onSubmit(eventActionFetch.response.data);
    }
    return (() => {
      eventActionFetch.setBody(undefined);
    })
  }, [eventActionFetch.response])

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      return;
    }
    eventActionFetch.setBody(ModelUtils.convertEventModelF2B(values));
  }, [values]);

  return (
    <Modal onHide={onDismiss} show={show}>
      <Container>
        <Form onSubmit={(e) => handleSubmit(e)}>
          <EmpowerEventForm
            disabledDays={disabledDays}
            event={event}
            onValueChange={onValueChange}
            t={t}
            values={values}
          />
          <Row className="mt-5">
            <Col className="col-6">
              <Button onClick={onDismiss} variant="secondary" className="w-100" disabled={eventActionFetch.loading}>{t('cancel')}</Button>
            </Col>
            <Col className="col-6">
              <Button type="submit" className='w-100' disabled={eventActionFetch.loading}>{t('finish')}</Button>
            </Col>
          </Row>
          <Button variant="link" onClick={() => window.location.href = PATH_EMPOWER_CONFERENCE + '/' + event.id} className="w-100" disabled={eventActionFetch.loading}>
            <Image src={editEmpower} />
            <span className="pl-2">{t('functions.empower.events.edit.room')}</span>
          </Button>
        </Form>
      </Container>
    </Modal>
  )
}

export default EmpowerEventEditModal
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebook, faInstagram, faLinkedin, faTwitter, faYoutube, fab } from '@fortawesome/free-brands-svg-icons';
import { faEye, faArrowUp, faMagnifyingGlass, faChevronDown, faClose, faCircleCheck, faCircleXmark, faClock, faPlus, faPencil, faUsers, faArrowRightFromBracket, faCircleLeft, faCircleUser, faLock, faGlobe, faPenToSquare, faCalendar, faCircleArrowLeft, faTrashAlt, faCube, faShareFromSquare, faCopy, faShareAlt, faCamera, faCheck, faArrowRight, faInfoCircle, faDoorOpen, faCircleArrowRight, faArrowLeft, faDownload, faEyeSlash, faHome, faFilePdf, faLanguage, faQuestionCircle, faReply } from '@fortawesome/free-solid-svg-icons';

export default library.add(
  fab,
  faArrowUp,
  faArrowRight,
  faArrowLeft,
  faMagnifyingGlass,
  faCamera,
  faCircleXmark,
  faChevronDown,
  faClose,
  faCircleCheck,
  faCircleXmark,
  faCheck,
  faClock,
  faPlus,
  faPencil,
  faUsers,
  faArrowRightFromBracket,
  faCircleLeft,
  faCircleUser,
  faEye,
  faLock,
  faGlobe,
  faPenToSquare,
  faCalendar,
  faCircleArrowLeft,
  faCircleArrowRight,
  faLock,
  faTrashAlt,
  faCube,
  faShareFromSquare,
  faCopy,
  faShareAlt,
  faInfoCircle,
  faDoorOpen,
  faDownload,
  faEye,
  faEyeSlash,
  faTrashAlt,
  faHome,
  faFilePdf,
  faLanguage,
  faQuestionCircle,
  faReply,

  faInstagram,
  faFacebook,
  faLinkedin,
  faTwitter,
  faYoutube
);
import { volunteeringOrganizationPhoto18, volunteeringOrganizationPhoto181, volunteeringOrganizationPhoto182, volunteeringOrganizationPhoto183 } from 'assets/learning';
import React from 'react';
import { Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen9 = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ marginTop: -32 }}>
        <Image src={volunteeringOrganizationPhoto18} width={'100%'} height={240} style={{ objectFit: 'cover' }} />
      </div>
      <br />
      <h2 id="step-3">{t('learning.volunteering.organization.95')}</h2>
      <h4 id="step-3-1" className='text-volunteering'>{t('learning.volunteering.organization.96')}</h4>

      <p>{t('learning.volunteering.organization.97')}</p>
      <h6>{t('learning.volunteering.organization.98')}</h6>
      <p>
        {t('learning.volunteering.organization.99.1')}
        <a href='https://www.trello.com' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.99.2')}</a>,
        <a href='https://www.asana.com' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.99.3')}</a>,
        <a href='https://www.notion.so' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.99.4')}</a>
        {t('learning.volunteering.organization.99.5')}
        <a href='https://monday.com' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.99.6')}</a>
        {t('learning.volunteering.organization.99.7')}
        <a href='https://www.slack.com' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.99.8')}</a>
        {t('learning.volunteering.organization.99.9')}
      </p>



      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationPhoto181} width={'50%'} height={'50%'} />
        <div className='pl-4'>
          <p>{t('learning.volunteering.organization.100.1')}</p>
          <p>
            <a href='https://youtu.be/xky48zyL9iA' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.100.2')}</a>
          </p>
          <p>
            <a href='https://www.youtube.com/watch?v=yyn7FfHKO90' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.100.3')}</a>
          </p>
          <p>
            <a href='https://www.youtube.com/watch?v=GImxy01wW0o' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.100.4')}</a>
          </p>
          <p>
            <a href='https://www.youtube.com/watch?v=Bw7sg-7QCxQ' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.100.5')}</a>
          </p>
          <p>
            {t('learning.volunteering.organization.101.1')}
            <a href='https://zapier.com/blog/free-project-management-software/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.101.2')}</a>
          </p>
        </div>
      </div>

      <h6>{t('learning.volunteering.organization.102')}</h6>
      <p>
        {t('learning.volunteering.organization.103.1')}
        <a href='https://www.google.com/sheets/about/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.103.2')}</a>
        {t('learning.volunteering.organization.103.3')}
      </p>

      <h6>{t('learning.volunteering.organization.104')}</h6>
      <p>
        {t('learning.volunteering.organization.105.1')}
        <a href='https://dy-test.netlify.app/functions/engage' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.105.2')}</a>
      </p>
      <Image src={volunteeringOrganizationPhoto182} width={'100%'} height={'30%'} />

      <br/><br/>
      <h6>{t('learning.volunteering.organization.106')}</h6>
      <p>{t('learning.volunteering.organization.107')}</p>
      <p>
        {t('learning.volunteering.organization.108.1')}
        <a href='https://bloomerang.co/blog/volunteer-management-software/?utm_source=initlive.com' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.108.2')}</a>
      </p>
      <br/>
      <div className='d-flex flex-direction-row'>
        <Image src={volunteeringOrganizationPhoto183} width={600} height={'auto'} style={{ objectFit: 'contain' }} />
        <div className='pl-4'>
          <p>{t('learning.volunteering.organization.109')}</p>
        </div>
      </div>

      <br/>
      <h6>{t('learning.volunteering.organization.110')}</h6>
      <p>
        {t('learning.volunteering.organization.111.1')}
        <a href='https://padlet.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.111.2')}</a>,
        <a href='https://mural.co/?utm_medium=paid-search&utm_source=adwords&utm_campaign=11265145092&utm_campaign_id=11265145092&utm_term=mural&gad_source=1&gclid=Cj0KCQjwltKxBhDMARIsAG8KnqVHqoRi8XiwHjT-CeaZDRbiaA3IFPp73TzMCUY_4hvW-A-Q8-Mw9mMaAm8NEALw_wcB' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.111.3')}</a>,
        {t('learning.volunteering.organization.111.4')}
        <a href='https://dotstorming.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.111.5')}</a>
        {t('learning.volunteering.organization.111.6')}
        <a href='https://www.mentimeter.com/es-ES' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.111.7')}</a>,
        <a href='https://kahoot.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.111.8')}</a>
        {t('learning.volunteering.organization.111.9')}
        <a href='https://app.genial.ly/teams/656ee07a6531510014537c74/spaces/656ee07a6531510014537c8c/dashboard?from=login-true' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.111.10')}</a>.
      </p>
      <p>
        {t('learning.volunteering.organization.112.1')}
        <a href='https://www.canva.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.112.2')}</a>,
        {t('learning.volunteering.organization.112.3')}
        <a href='https://www.inshot.com/' target='_blank' rel='nooppener noreferrer' className="text-volunteering"> {t('learning.volunteering.organization.112.4')}</a>
        {t('learning.volunteering.organization.112.5')}
        <a href='https://www.capcut.com/es-es/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.112.6')}</a>
        {t('learning.volunteering.organization.112.7')}
      </p>
      <p>
        {t('learning.volunteering.organization.113.1')}
        <a href='https://filestage.io/blog/free-online-project-collaboration/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">{t('learning.volunteering.organization.113.2')}</a>,
        {t('learning.volunteering.organization.113.3')}
      </p>
    </>
  )

}

export default VolunteeringLearningScreen9;
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Empty, Layout, Loader } from 'components';
import { CountriesData } from 'data';
import { AppConstants, ServerConstants } from 'constant';
import { useFetch } from 'hooks/fetch';
import OrganizationApproveModal from './modal/approve';
import OrganizationRejectModal from './modal/reject';
import { AppUtils } from 'utils';

const {
  HEADER_TYPES,
  PATH_ADMIN_COMMUNITY,
  ORGANIZATION_STATUS_APPROVED, ORGANIZATION_STATUS_REJECTED, ORGANIZATION_STATUS_PENDING
} = AppConstants;

const { API_METHOD_GET, API_URL_ORGANIZATIONS } = ServerConstants;

const modals = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT'
};

const filter = {
  APPROVED: ORGANIZATION_STATUS_APPROVED,
  REJECTED: ORGANIZATION_STATUS_REJECTED,
  PENDING: ORGANIZATION_STATUS_PENDING
}
const AdminCommunityListScreen = () => {

  const { response, loading } = useFetch(API_METHOD_GET, API_URL_ORGANIZATIONS);
  const { t } = useTranslation();
  const [filterBy, setFilterBy] = useState(filter[filter.ALL]);
  const [organizations, setOrganizations] = useState(undefined);
  const [filteredArray, setFilteredArray] = useState([]);

  useEffect(() => {
    if (!response) {
      return;
    }
    setOrganizations(response.status ? response.data : []);
  }, [response]);

  useEffect(() => {
    let filtered = organizations;
    if (filter[filterBy]) {
      filtered = filtered.filter(el => el.status.toUpperCase() === filterBy);
    }
    setFilteredArray(filtered);
  }, [filterBy, organizations]);

  return (
    <Layout type={HEADER_TYPES.ADMIN}>
      <Container className="mt-4 mb-5">
        <div className="mb-4">
          <div className="mt-5 mb-4">
            <div className="d-flex flex-direction-row align-items-start">
              <h2>{t('organizations.admin.title')}</h2>
            </div>
          </div>
          {loading ? <Loader /> :
            <>
              <div className="d-flex flex-direction-row flex-shrink">
                <div className={'admin-community-filter'} key={'filter-all'}>
                  <Button variant='link' className={filterBy === undefined ? ' active' : ''} onClick={() => setFilterBy(undefined)}>{t('organizations.admin.filter.ALL')}{organizations && organizations.length > 0 ? ' (' + organizations.length + ')' : ''}</Button>
                </div>
                {Object.keys(filter).map(el => (
                  <div className={'admin-community-filter'} key={'filter-' + el}>
                    <Button variant='link' className={filter[el] === filter[filterBy] ? ' active' : ''} onClick={() => setFilterBy(el)}>{t('organizations.admin.filter.' + el)}</Button>
                  </div>
                ))}
              </div>
              {(!filteredArray || filteredArray.length === 0) ? <Empty messageKey={t('organizations.empty')} /> :
                <Table bordered={true} className="mt-4">
                  <thead>
                    <tr>
                      <th>{t('organizations.name')}</th>
                      <th>{t('organizations.registration')}</th>
                      <th>{t('organizations.country')}</th>
                      <th>{t('organizations.pic')}</th>
                      <th>{t('organizations.status.title')}</th>
                      <th>{t('organizations.action.title')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredArray.map(el => <TableItem el={el} t={t} />)}
                  </tbody>
                </Table>}
            </>}
        </div>
      </Container>
    </Layout >
  )
}

const TableItem = ({ el, t }) => {

  const [modalVisible, setModalVisible] = useState(undefined);

  const showModals = useCallback((modal) => setModalVisible(modal), []);
  const hideModals = useCallback(() => setModalVisible(undefined), []);

  return (
    <>
      <OrganizationApproveModal el={el} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.APPROVE} />
      <OrganizationRejectModal el={el} onDismiss={hideModals} visible={modalVisible && modalVisible === modals.REJECT} />
      <tr key={el.organizationId}>
        <td className="w-25"><Link to={(PATH_ADMIN_COMMUNITY + '/' + el.organizationId)}>{el.organizationName}</Link></td>
        <td className="w-10">{AppUtils.convertDateToLocaleDate(el.registrationDate, t('format.date.registration'))}</td>
        <td className="w-20">{CountriesData[el.country]}</td>
        <td className="w-20">{el.piC_OID}</td>
        <td className="w-10">
          {el.status === filter.APPROVED ? <div className="d-flex flex-direction-row align-items-center text-muted"><FontAwesomeIcon icon={'circle-check'} />&nbsp;&nbsp;<span className="d-none d-md-block">{t('organizations.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
          {el.status === filter.REJECTED ? <div className="d-flex flex-direction-row align-items-center text-muted"><FontAwesomeIcon icon={'circle-xmark'} />&nbsp;&nbsp;<span className="d-none d-md-block">{t('organizations.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
          {el.status === filter.PENDING ? <div className="d-flex flex-direction-row align-items-center"><FontAwesomeIcon icon={'clock'} />&nbsp;&nbsp;<span className="d-none d-md-block">{t('organizations.admin.filter.' + el.status.toUpperCase())}</span></div> : undefined}
        </td>
        <td className="w-15">
          {el.status === filter.PENDING ?
            <div className="d-flex flex-direction-row align-items-center flex-wrap">
              <a onClick={() => showModals(modals.APPROVE)} className='text-success clickable'>{t('organizations.action.approve')}</a>
              <a onClick={() => showModals(modals.REJECT)} className='text-error clickable'>{t('organizations.action.reject')}</a>
            </div>
            : undefined}
        </td>
      </tr>
    </>
  )
}

export default AdminCommunityListScreen;
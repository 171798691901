const fields = [
  'Health',
  'Sports',
  'Education',
  'Human Rights',
  'Digital Literacy and Access',
  'Reconciliation and Peacebuilding',
  'Humanitarian work',
  'Ecology and environmental protection',
  'Children rights',
  'STEM',
  'Animal rights protection',
  'Other'
]

export default fields;
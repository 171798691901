import React from 'react';
import { useTranslation } from 'react-i18next';

const VolunteeringLearningScreen16 = () => {
  const { t } = useTranslation();

  return (
    <>
      <h4 id="step-5-2" className='text-volunteering'>{t('learning.volunteering.organization.196')}</h4>

      <p>{t('learning.volunteering.organization.197')}</p>
      <ul>
        <li>
          {t('learning.volunteering.organization.198.1')}
          <b>{t('learning.volunteering.organization.198.2')}</b>
          {t('learning.volunteering.organization.198.3')}
        </li>
        <li>
          <b>{t('learning.volunteering.organization.199.1')}</b>
          {t('learning.volunteering.organization.199.2')}
        </li>
        <li>
          <b>{t('learning.volunteering.organization.200.1')}</b>
          {t('learning.volunteering.organization.200.2')}
        </li>
        <li>
          <b>{t('learning.volunteering.organization.201.1')}</b>
          {t('learning.volunteering.organization.201.2')}
        </li>
        <li>
          {t('learning.volunteering.organization.202.1')}
          <b>{t('learning.volunteering.organization.202.2')}</b>
          {t('learning.volunteering.organization.202.3')}
        </li>
        <li>
          {t('learning.volunteering.organization.203.1')}
          <b>{t('learning.volunteering.organization.203.2')}</b>
          {t('learning.volunteering.organization.203.3')}
        </li>
      </ul>
      <p>{t('learning.volunteering.organization.204')}</p>

      <div className='p-3' style={{ border: '1px solid black' }}>
        <p className='text-center'><b>{t('learning.volunteering.organization.205')}</b></p>
        <ol>
          <li className='pb-0'>{t('learning.volunteering.organization.206.1')}</li>
          <ul>
            <li>{t('learning.volunteering.organization.206.2')}</li>
            <li>{t('learning.volunteering.organization.206.3')}</li>
            <li>{t('learning.volunteering.organization.206.4')}</li>
          </ul>
          <li className='pt-3 pb-0'>{t('learning.volunteering.organization.207.1')}</li>
          <ul>
            <li>{t('learning.volunteering.organization.207.2')}</li>
            <li>{t('learning.volunteering.organization.207.3')}</li>
            <li>{t('learning.volunteering.organization.207.4')}</li>
          </ul>
          <li className='pt-3 pb-0'>{t('learning.volunteering.organization.208.1')}</li>
          <ul>
            <li>{t('learning.volunteering.organization.208.2')}</li>
            <li>{t('learning.volunteering.organization.208.3')}</li>
            <li>{t('learning.volunteering.organization.208.4')}</li>
          </ul>
          <li className='pt-3 pb-0'>{t('learning.volunteering.organization.209.1')}</li>
          <ul>
            <li>{t('learning.volunteering.organization.209.2')}</li>
            <li>{t('learning.volunteering.organization.209.3')}</li>
            <li>{t('learning.volunteering.organization.209.4')}</li>
          </ul>
          <li className='pt-3 pb-0'>{t('learning.volunteering.organization.210.1')}</li>
          <ul>
            <li>{t('learning.volunteering.organization.210.2')}</li>
            <li>{t('learning.volunteering.organization.210.3')}</li>
            <li>{t('learning.volunteering.organization.210.4')}</li>
          </ul>
          <li className='pt-3 pb-0'>{t('learning.volunteering.organization.211.1')}</li>
          <ul>
            <li>{t('learning.volunteering.organization.211.2')}</li>
            <li>{t('learning.volunteering.organization.211.3')}</li>
            <li>{t('learning.volunteering.organization.211.4')}</li>
          </ul>
        </ol>
        <p>{t('learning.volunteering.organization.212')}: ________________________________________</p>
        <p>
          {t('learning.volunteering.organization.213')}
          <a href='https://ied.eu/project-updates/projects/e-volunteering/check-test-report-e-volunteering/' target='_blank' rel='nooppener noreferrer' className="text-volunteering">https://ied.eu/project-updates/projects/e-volunteering/check-test-report-e-volunteering/</a>
        </p>
      </div>
    </>
  )
}

export default VolunteeringLearningScreen16;